import styled from "styled-components";

export const Footer = styled.div`
  box-sizing: border-box;

  grid-area: appfoot;
  background-color: #f2f2f2;
  height: 30px;
  border-top: 1px solid #c1c8d4;
  padding: 0 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const ClintLogo = styled.img`
  max-height: 15px;
  margin-right: 15px;
`;

export const Version = styled.div`
  color: #666666;
  font-size: 12px;
`;

export const HealthpalsLogo = styled.img`
  max-height: 25px;
`;
