import React from "react";

import { ErrorMessage, Loading } from "./styles";

const LoadingHOC = () => (WrappedComponent) => {
  function LoadingHOCComponent({ displayLoading, errorMessage, ...rest }) {
    // Error Handler
    if (errorMessage) {
      return (
        <ErrorMessage>
          <h1>We are sorry.</h1>
          <p>{errorMessage}</p>
          <p>
            Our engineering team was already notified and we are working quickly
            to solve the issue.
          </p>
        </ErrorMessage>
      );
    }

    // Loading otherwise load original component
    if (displayLoading) {
      return <Loading />;
    }
    return <WrappedComponent {...rest} />;
  }
  return LoadingHOCComponent;
};

export default LoadingHOC;
