import styled from "styled-components";

export const CellElement = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const ContainerLoader = styled.div`
  width: 100%;
  height: 300px;
`;

export const CollapsiblesContainer = styled.div`
  padding-top: 16px;
`;

export const PanelTitleContainer = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-left: 5px;
  }
`;

export const DetailsContainer = styled.div`
  margin-bottom: 15px;
  h4 {
    display: inline;
    color: #4d5f80;
  }

  > div {
    line-height: 163%;
  }
`;
