import React, { useEffect, useState } from "react";

import { isEmpty } from "lodash";

import Select from "../basic-inputs/react-select";
import Choropleth from "./choropleth";

import InternalPanel from "../panel/internal";

import { Container, SelectLabel, Color } from "./styles";

import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../services/mixpanel-tracking";

function ChoroplethWrapper({
  maps,
  topography = [],
  overlayLayers = [],
  onFeatureClick,
  defaultLayer = 0,
  defaultMap = 0,
  ...rest
}) {
  const [mapsOptions, setMapsOptions] = useState([]);
  const [mapSelected, setMapSelected] = useState(defaultMap);

  useEffect(() => {
    const options = maps.map((map, index) => {
      const mapColor = map.colorPalette ? map.colorPalette[1] : "#5F8BAF";
      return {
        value: index,
        label: (
          <SelectLabel>
            <Color color={mapColor} /> {map.name}
          </SelectLabel>
        ),
        color: mapColor,
      };
    });
    setMapsOptions(options);
  }, [maps]);

  useEffect(() => {
    setMapSelected(defaultMap);
  }, [defaultMap]);

  const handleMapChange = (value) => {
    mixpanelIdentify(localStorage.email);
    mixpanelTrack(
      `${
        maps[mapSelected].title
          ? maps[mapSelected].title
          : maps[mapSelected].name
      } - Map Change`,
      {
        map: value,
        urlPathname: location.pathname,
      }
    );
    setMapSelected(value.value);
  };

  const renderUILayer = () => {
    /*
    Create the html of the UI Layer.
    Currenlty, only accepts a dropdown.
    */
    if (isEmpty(maps) || isEmpty(topography)) return null;

    const assembledElements = [];

    if (mapsOptions.length > 1)
      assembledElements.push(
        <Select
          key="normalSelect"
          options={mapsOptions}
          value={mapsOptions[mapSelected]}
          onChange={handleMapChange}
          marginBottom={0}
        />
      );

    return assembledElements;
  };

  const getMapTitle = () => {
    if (isEmpty(maps) || isEmpty(topography)) return null;

    if (maps[mapSelected]) {
      if (maps[mapSelected].title) return maps[mapSelected].title;

      return maps[mapSelected].name;
    }

    return null;
  };

  return (
    <InternalPanel
      style={{ position: "relative" }}
      title={getMapTitle()}
      actions={renderUILayer()}
      {...rest}
    >
      <Container>
        <Choropleth
          map={maps ? maps[mapSelected] : {}}
          topography={topography}
          overlayLayers={overlayLayers}
          defaultLayer={defaultLayer}
        />
      </Container>
    </InternalPanel>
  );
}

export default ChoroplethWrapper;
