import React from "react";
import { Scope } from "@unform/core";

import { Container, Header, Content } from "./styles";

export default function FormScope({
  inline = false,
  children,
  label,
  required,
  boldTitle = false,
  labelOffset = false,
  ...rest
}) {
  return (
    <Scope {...rest}>
      <Container inline={inline} labelOffset={label ? false : labelOffset}>
        {label ? (
          <Header inline={inline} required={required} boldTitle={boldTitle}>
            {label}
          </Header>
        ) : (
          ""
        )}
        <Content>{children}</Content>
      </Container>
    </Scope>
  );
}
