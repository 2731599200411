import styled from "styled-components";

export const Container = styled.div``;

export const AttributesLabels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;

  label {
    margin-bottom: 10px;
    font-size: 12px;
  }
`;
