/*
 * Re-usable methods for Routes
 */
import React from "react";
import { includes } from "lodash";

const RouteHelper = class {
  static getAvailableRoutesURLs(workspaces) {
    const urls = [];

    if (includes(workspaces, "admin") || includes(workspaces, "healthpals"))
      urls.push({ url: "/administration", label: "User Administration" });
    if (includes(workspaces, "mcm"))
      urls.push({ url: "/content-manager", label: "Content Manager" });
    if (includes(workspaces, "mcm"))
      urls.push({ url: "/medication-manager", label: "Medication Manager" });
    if (includes(workspaces, "identify"))
      urls.push({ url: "/clint-identify", label: "Clint Identify" });
    return urls;
  }

  static getHomeURL(workspaces) {
    if (includes(workspaces, "identify")) return "/clint-identify";
    if (includes(workspaces, "mcm")) return "/content-manager";
    if (includes(workspaces, "admin") || includes(workspaces, "healthpals"))
      return "/administration";
  }
};

export default RouteHelper;
