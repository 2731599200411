import styled from "styled-components";

export const Container = styled.li`
  flex: 1;
  text-align: center;
  border-bottom: 3px solid;
  border-color: ${(props) => (props.active ? "#26344D" : "transparent")};
  a {
    color: ${(props) => (props.active ? "#26344D" : "#A6A6A6")};
    font-weight: ${(props) => (props.active ? 700 : 400)};
    padding: 12px 0;
    display: block;
  }
`;
