import React from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";

import LoadingHOC from "../../../../components/loadingHoc";

import GuidelineForm from "../../c-guidelines-rules-form";
import { Container, BackLink, Header } from "./styles";

function GuidelineAdd() {
  return (
    <Container>
      <Header>
        <BackLink to="/content-manager/rules">
          <RiArrowDropLeftLine size={18} />
          back to guideline rules
        </BackLink>
        <h3>Create New Guideline rule</h3>
      </Header>
      <GuidelineForm />
    </Container>
  );
}

export default LoadingHOC()(GuidelineAdd);
