import React, { createContext, useContext, useEffect, useState } from "react";
import { findIndex } from "lodash";
import { api } from "../services/api";

const GuidelineDocumentContext = createContext({});

export function GuidelineDocumentProvider(props) {
  const { children } = props;
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    GetDocuments();
  }, []);

  async function GetDocuments() {
    const response = await api.get(`intelligence/guideline_documents`, {
      withCredentials: true,
    });
    const { data } = response.data;
    setDocuments(data);
  }

  async function EditDocument(document, id, successHandler, errorHandler) {
    const response = await api.put(
      `intelligence/guideline_documents/${id}`,
      document,
      { withCredentials: true }
    );
    if (response.data.valid) {
      const documentIndex = findIndex(documents, ["id", id]);
      const newDocuments = [...documents];
      newDocuments.splice(documentIndex, 1, response.data.data);
      setDocuments(newDocuments);
      successHandler(response.data.data);
    } else {
      errorHandler(response.data.messages);
    }
  }

  async function DeleteDocument(id, successHandler, errorHandler) {
    const response = await api.delete(
      `intelligence/guideline_documents/${id}`,
      { withCredentials: true }
    );
    if (response.data.valid) {
      const documentIndex = findIndex(documents, ["id", id]);
      const newDocuments = [...documents];
      newDocuments.splice(documentIndex, 1);
      setDocuments(newDocuments);
      successHandler(id);
    } else {
      errorHandler(response.data.messages);
    }
  }

  async function AddDocument(document, successHandler, errorHandler) {
    const response = await api.post(
      `intelligence/guideline_documents`,
      document,
      { withCredentials: true }
    );
    if (response.data.valid) {
      setDocuments([...documents, response.data.data]);
      successHandler(response.data.data);
    } else {
      errorHandler(response.data.messages);
    }
  }

  return (
    <GuidelineDocumentContext.Provider
      value={{
        documents,
        AddDocument,
        EditDocument,
        DeleteDocument,
        GetDocuments,
      }}
    >
      {children}
    </GuidelineDocumentContext.Provider>
  );
}

export function useGuidelineDocument() {
  const context = useContext(GuidelineDocumentContext);

  return context;
}
