import { createGlobalStyle } from "styled-components";

export const TypographyStyle = createGlobalStyle`
/*
Sizing
10px = 0.714 rem
11px = 0.785 rem
12px = 0.857 rem
13px = 0.928 rem
14px = 1 rem
15px = 1.071 rem
16px = 1.142 rem
17px = 1.214 rem
18px = 1.285 rem
19px = 1.357 rem
20px = 1.428 rem
21px = 1.5 rem
28px = 2rem
*/

:root {
  --primary: #26344D;
  --secundary: #4D5F80;
  --tertiary: #6B7C99;

  --link: #1C66D2;

  --gray: #B0B0B0;
  --darkGray: #666666;

  --white: #fff;
}

html,th,td{
  font-family: 'Roboto',sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 87.5%;// 14px
  font-weight: 400;
  font-style: normal;
  line-height: 1.2em;
  color: var(--primary);
}

small{
  font-size: 0.857rem;
  font-weight: 400;
}

h1{
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing:-0.02em;
}

h2{
  font-size: 1.285rem;
  font-weight: 500;
  letter-spacing:-0.02em;
}

h3{
  font-size: 1.142rem;
  font-weight: 400;
  letter-spacing:-0.02em;
}

h4{
  font-size: 1rem;
  font-weight: 500;
  letter-spacing:-0.02em;
}

h5{
  font-size: 0.857rem;
  font-weight: 500;
  letter-spacing:-0.02em;
}

h6{
  font-size:0px;
}

a{
  color: var(--link);
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
}

button{
  font-weight: 500;
  letter-spacing:3%;
}

p{
  margin-bottom:1rem;
}
`;
