import styled from "styled-components";

export const CategoryRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  > div {
    margin-right: 5px;
    flex: 1;
  }
  > button {
    margin-left: 0;
    margin-bottom: 15px;
  }
`;

export const CategoriesGroup = styled.div`
  background-color: #fafcff;
  padding: 11px;
  margin-bottom: 1rem;

  button {
    margin-left: 0;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;
