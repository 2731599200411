import axios from "axios";
import config from "../config.jsx";
import { allowFeatureFlags } from "../helpers/feature-flags-helper.js";

const ENDPOINTS_WHITELIST = [
  "insights/event_rate_curve",
  "insights/geo_population",
  "insights/geojson",
  "insights/table1",
  "insights/table2",
  "insights/sites",
  "insights/dataset_status",
  "insights/dataset_config",
];

const apiUrl = config.api_address;

function useCache(config) {
  // only cache responses to healthpals-api
  if (config.baseURL !== apiUrl) {
    return false;
  }

  // only cache endpoints included in whitelist
  return ENDPOINTS_WHITELIST.includes(config.url);
}

function getCacheKey(config) {
  const { method, url, data } = config;
  const accessToken = localStorage.getItem("accessToken");

  return JSON.stringify({
    accessToken,
    method,
    url,
    data,
  });
}

/**
 * Add caching interceptors to Axios instance
 *
 * Enables caching responses by using interceptors
 * https://axios-http.com/docs/interceptors
 *
 * @param {AxiosInstance} api
 */
function addCachingInterceptors(api) {
  const cachedResponses = new Map();

  api.interceptors.request.use(
    function (config) {
      const newConfig = { ...config };
      newConfig.useCache = useCache(config);

      if (!newConfig.useCache) {
        return newConfig;
      }

      newConfig.cacheKey = getCacheKey(config);
      if (!cachedResponses.has(newConfig.cacheKey)) {
        return newConfig;
      }

      const response = cachedResponses.get(newConfig.cacheKey);

      // replace request config adapter to provide response from cache store
      // https://github.com/axios/axios/blob/v1.x/lib/adapters/README.md
      newConfig.adapter = () => {
        return Promise.resolve(response);
      };

      return newConfig;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    function (response) {
      if (!response.config.useCache) {
        return response;
      }

      const { cacheKey } = response.config;
      if (!cachedResponses.has(cacheKey)) {
        cachedResponses.set(cacheKey, response);
      }

      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}

export function createApiInstance(useCachingInterceptors = false) {
  const api = axios.create({ baseURL: apiUrl });

  if (useCachingInterceptors) {
    addCachingInterceptors(api);
  }

  return api;
}

function getCacheFeatureFlag() {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has("cache")) {
    return searchParams.get("cache") === "true";
  }

  return true;
}

// enable caching interceptors by default
const useCachingInterceptors = allowFeatureFlags()
  ? getCacheFeatureFlag()
  : true;

export const api = createApiInstance(useCachingInterceptors);
