import React from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";

import LoadingHOC from "../../../../components/loadingHoc";

import GuidelineAttributeForm from "../../c-patient-attribute-form";
import { Container, BackLink, Header } from "./styles";

function AttributeAdd() {
  return (
    <Container>
      <Header>
        <BackLink to="/content-manager/patient-attributes/">
          <RiArrowDropLeftLine size={18} />
          back to patient attributes
        </BackLink>
        <h3>Create New Patient Attribute</h3>
      </Header>
      <GuidelineAttributeForm />
    </Container>
  );
}

export default LoadingHOC()(AttributeAdd);
