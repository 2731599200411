import styled from "styled-components";
import { rgba } from "polished";

export const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid;
  border-radius: 5px;
  border-color: ${(props) => props.color};
  background-color: ${(props) => rgba(props.color, 0.1)};
  padding: 3px 5px;
  margin-bottom: 15px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-radius: 50%;
  border-color: ${(props) => props.color};
  background-color: white;
  margin-right: 5px;
  flex: 0 0 20px;
`;
