import { rgba } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-shrink: 0;
`;

export const TablesWrapper = styled.div`
  flex-grow: 1;
  margin: -18px 0 0 0;
  overflow: auto;
`;

export const FilterNoResultMsg = styled.small`
  color: #6b7c99;
  height: 100%;
  display: flex;
  max-height: 240px;
  justify-content: center;
  align-items: center;
`;

export const TableElement = styled.table`
  width: 100%;
  max-width: 100%;
  margin: 5px 0px 0px 0px;
  position: relative;
`;

export const TableHeader = styled.thead`
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 500;

  > tr {
    border-bottom: 1px solid #1a1a1a;
    > th {
      padding: 5px 10px;
      line-height: normal;
    }
  }
`;

export const HeaderContainer = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0;
`;

export const TableHeaderSpecialCell = styled.th`
  background: ${(props) => rgba(props.color, 0.07)};
  text-align: left;
  border-left: 5px solid;
  border-color: ${(props) => props.color};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
