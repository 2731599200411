import React, { useState, useRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

import {
  Container,
  OuterMenuContainer,
  InnerMenuContainer,
  TitleContainer,
} from "./styles";

const DEFAULT_WIDTH = "124px";

export const ACTIVATION_TYPES = {
  CLICK: "click",
  HOVER: "hover",
};

export const POSITIONS = {
  DOWN: "down",
  RIGHT: "right",
  LEFT: "left",
};

function KebabMenu({
  width = DEFAULT_WIDTH,
  item = null,
  position = POSITIONS.DOWN,
  activation = ACTIVATION_TYPES.CLICK,
  leftOffset = 0,
  title = null,
  children,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    const isClickInside =
      wrapperRef.current && wrapperRef.current.contains(event.target);

    if (!isClickInside) {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const clickHandler = () => {
    if (activation !== ACTIVATION_TYPES.CLICK) {
      return;
    }

    setIsOpen(!isOpen);
    if (!isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const mouseEnterHandler = () => {
    if (activation !== ACTIVATION_TYPES.HOVER) {
      return;
    }

    setIsOpen(true);
  };

  const mouseLeaveHandler = () => {
    if (activation !== ACTIVATION_TYPES.HOVER) {
      return;
    }

    setIsOpen(false);
  };

  return (
    <Container
      ref={wrapperRef}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <div onClick={clickHandler}>
        {item || (
          <BsThreeDotsVertical
            size={24}
            color="4D5F80"
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
      {isOpen && (
        <OuterMenuContainer
          width={width}
          position={position}
          leftOffset={leftOffset}
        >
          <InnerMenuContainer>
            {title && <TitleContainer>{title}</TitleContainer>}
            {children}
          </InnerMenuContainer>
        </OuterMenuContainer>
      )}
    </Container>
  );
}

export default KebabMenu;
