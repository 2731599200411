import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { FaTimes, FaChevronDown } from "react-icons/fa";

import {
  Container,
  Control,
  Value,
  Controls,
  Tag,
  Menu,
  Separator,
  Indicator,
} from "./styles";
import logicExpressionHelper from "../../helpers/logic-expression-helper";

function LogicExpressionSelect(
  {
    onChange,
    className,
    invalid,
    statments = [],
    statmentsInfo = [],
    disabled,
    ...rest
  },
  ref
) {
  const [valuesInput, setValuesInput] = useState([]);
  const [options, setOptions] = useState([]);
  const [listOpen, setListOpen] = useState(false);
  const selectRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      value: valuesInput.map((value) => value.value).join(" "),
      setValue(stringValuesInput) {
        const box_value = logicExpressionHelper.updateSelectValues(
          stringValuesInput,
          statments,
          statmentsInfo
        );
        setValuesInput(box_value);
      },
    }),
    [valuesInput.map((value) => value.value).join(" ")]
  );

  // This is to Update the available options and only show options that can be added to the expression
  useEffect(() => {
    if (valuesInput.length > 0) {
      const box_value = logicExpressionHelper.updateSelectValues(
        valuesInput.map((value) => value.value).join(" "),
        statments,
        statmentsInfo
      );
      setOptions(
        logicExpressionHelper.getSelectOptions(
          statments,
          box_value,
          statmentsInfo
        )
      );
    } else {
      setOptions(
        logicExpressionHelper.getSelectOptions(statments, [], statmentsInfo)
      );
    }
  }, [valuesInput, statments]);

  // TODO implement in a way to not reset the logic expression
  // useEffect(() => {
  //   if(valuesInput.length>0){
  //     // const box_value = logicExpressionHelper.updateSelectValues(valuesInput.map(value=>value.value).join(' '),statments, statmentsInfo)
  //     // setValuesInput(box_value)
  //     // setOptions(logicExpressionHelper.getSelectOptions(statments,box_value, statmentsInfo))
  //     setValuesInput([])
  //     setOptions(logicExpressionHelper.getSelectOptions(statments,[], statmentsInfo))
  //   }
  // },[statments])

  function handleChange(newValue) {
    setValuesInput([...valuesInput, newValue]);
    setListOpen(false);
    if (onChange) onChange(valuesInput.map((value) => value.value).join(" "));
  }

  const toggleMenu = function (event) {
    if (!disabled) {
      const isClickInside =
        event.currentTarget.dataset &&
        event.currentTarget.dataset.id == "optionList";
      if (isClickInside && !listOpen) {
        setListOpen(!listOpen);
        document.addEventListener("mousedown", closeMenu);
      } else {
        setListOpen(!listOpen);
        document.removeEventListener("mousedown", closeMenu);
      }
    }
  };

  const closeMenu = function (event) {
    const isClickInside =
      event.currentTarget.dataset &&
      event.currentTarget.dataset.id == "optionList";
    if (!isClickInside) {
      setListOpen(false);
      document.removeEventListener("mousedown", closeMenu);
    }
  };

  const removeTag = function (index) {
    if (!disabled) {
      setValuesInput([...valuesInput.slice(0, index)]);
    }
  };

  return (
    <Container
      ref={selectRef}
      invalid={invalid}
      className={className}
      onChange={handleChange}
      {...rest}
    >
      <Control onClick={(event) => toggleMenu(event)} disabled={disabled}>
        <Value>
          {valuesInput.map((value, index) => (
            <Tag key={index} type={value.value}>
              <span>{value.label}</span>
              {index == valuesInput.length - 1 && !disabled ? (
                <a
                  onClick={(event) => {
                    removeTag(index);
                    event.stopPropagation();
                  }}
                >
                  <FaTimes />
                </a>
              ) : (
                ""
              )}
            </Tag>
          ))}
        </Value>
        <Controls>
          {valuesInput.length > 0 && !disabled ? (
            <Indicator onClick={() => setValuesInput([])}>
              <FaTimes />
            </Indicator>
          ) : (
            ""
          )}
          <Separator />
          <Indicator>
            <FaChevronDown />
          </Indicator>
        </Controls>
      </Control>
      {!listOpen ? (
        ""
      ) : (
        <Menu>
          <ul data-id="optionList">
            {options.map((option, index) => (
              <li key={index} onClick={() => handleChange(option)}>
                {option.label}
              </li>
            ))}
          </ul>
        </Menu>
      )}
    </Container>
  );
}
export default forwardRef(LogicExpressionSelect);
