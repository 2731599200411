import React, { useState, useMemo } from "react";
import { MdStarOutline, MdStar, MdLocationPin } from "react-icons/md";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { includes, isEmpty, uniq } from "lodash";

import FilteredTable from "../../../../components/tables/filtered-table";
import KebabMenu from "../../../../components/kebab-menu";
import DownloadCsvMenuItem from "../../../../components/download-csv-menu-item";
import Panel from "../../../../components/panel";
import SitesGeomapDetails from "./site-geomap-details";
import Loader from "../../../../components/loader";
import SearchInput from "../../../../components/basic-inputs/input/search-input";
import Collapsible from "../../../../components/collapsible";
import InfoModal from "../../../../components/info-modal";

import {
  CellElement,
  ContainerLoader,
  CollapsiblesContainer,
  PanelTitleContainer,
  DetailsContainer,
} from "./styles";

import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySubcohortList } from "../../../../hooks/useIdentifySubcohortList";
import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../services/mixpanel-tracking";

import { getSitesCsvString } from "./convert-csv";
import { useModal } from "../../../../components/modal";
import { Heading4, Small } from "../../../../components/typography";

export const SITE_COLUMNS_LABELS = {
  study_site_display_id: "ID",
  institution: "Name",
  institution_type: "Institution Type",
  eligible_patients: "Eligible Patients",
  zip3_eligibility: "Eligibility",
  zip3_eligible_high_event_risk: "Eligible High Risk",
  zip3_eligible_med_event_risk: "Eligible Moderate Risk",
  zip3_eligible_low_event_risk: "Eligible Low Risk",
  estimated_enrollment_score: "Enrollment",
  irb_type: "IRB Type",
  estimated_start_up_time: "Start-Up Time",
  center_size: "Size",
  has_ct_scanner: "CT scan",
  pi_name: "PI",
  city: "City",
  county: "County",
  address: "Address",
  state: "State",
  zipcode: "Zipcode",
  ascvd_7point5_to_20_percent: "10-year ASCVD Risk 7.5% - 20%",
  ascvd_over_20_percent: "10-year ASCVD Risk >20%",
  ldl_70_to_100_mg_per_dl: "LDL Cholesterol 70 - 100 mg/dL",
  ldl_100_to_190_mg_per_dl: "LDL Cholesterol 100 - 190 mg/dL",
  pts_with_0_risk_factors: "Patients with 0 Risk factors",
  pts_with_1_risk_factors: "Patients with 1 Risk factors",
  pts_with_2_risk_factors: "Patients with 2 Risk factors",
  pts_with_3_risk_factors: "Patients with 3 Risk factors",
  site_score: "Site Score",
};

const DETAILS_KEYS = ["trial", "data_source"];

export default function SiteSelectionTable({
  sites,
  activeSite,
  setActiveSite,
  selectedCohort,
  favoriteSitesIdList,
  handleFavoriteSite,
  setValidCharts,
  setErrorMsg,
}) {
  const [siteSearchValue, setSiteSearchValue] = useState("");
  const [filteredSites, setFilteredSites] = useState([]);

  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { sitesConfig, cohortIndex } = cohortConfigs;
  const { subcohortList } = useIdentifySubcohortList();

  const handleActiveSite = (site) => {
    setActiveSite(site.id);

    mixpanelIdentify(localStorage.email);
    mixpanelTrack("Select Site - Active Site from Table", {
      site: site,
    });
  };

  const sitesColumnsKeys = useMemo(() => {
    if (sites && sites.length > 0)
      return uniq([...Object.keys(sites[0]), ...Object.keys(sites[1])]);
  }, [sites]);

  const siteTableInfo = useMemo(() => {
    if (sitesConfig) {
      const compositeEndpoints = {};
      for (const config of sitesConfig.sites_table ?? []) {
        if (!("description" in config)) {
          continue;
        }
        const title = config.unit
          ? `${config.display_name} (${config.unit})`
          : config.display_name;
        compositeEndpoints[title] = config.description;
      }

      const details = new Map();
      if ("details" in sitesConfig) {
        for (const detail of sitesConfig.details) {
          details.set(detail.name, detail.value);
        }
      } else {
        for (const key of DETAILS_KEYS) {
          if (key in sitesConfig) {
            details.set(capitalizeFirstLetter(key), sitesConfig[key]);
          }
        }
      }

      return {
        title: "Table Information",
        compositeEndpoints,
        details,
      };
    }
    return null;
  }, [sitesConfig]);

  let columnsDefaultSort = [
    { id: "site_status", desc: true },
    { id: "center_size", desc: true },
  ];

  const columns = [
    {
      accessor: "id",
      id: "location",
      Cell: ({ row }) => {
        let pin = <MdLocationPin size={20} color="#26344D" />;
        if (row.original.id === activeSite) return pin;
        return "";
      },
      disableSortBy: true,
    },
    {
      accessor: "favorite",
      filterType: "SelectCheckbox",
      filterLabel: <MdStarOutline size={20} color="#B3C0D6" />,
      disableSortBy: true,
      Cell: ({ row }) => {
        let star = (
          <MdStarOutline
            size={20}
            color="#B3C0D6"
            style={{ cursor: "pointer" }}
            onClick={() => handleFavoriteSite(row.original, "Favorite")}
          />
        );
        if (row.original.favorite === "Favorite")
          star = (
            <MdStar
              size={20}
              color="#26344D"
              style={{ cursor: "pointer" }}
              onClick={() => handleFavoriteSite(row.original, "Unfavorite")}
            />
          );
        return star;
      },
    },
    {
      id: "site_status",
      accessor: "site_status",
      Cell: ({ row }) => {
        const siteStatus = String(
          row?.original?.site_status ?? ""
        ).toLowerCase();

        switch (siteStatus) {
          case "removed":
            return <BiXCircle size={20} color="#C64E45" />;
          case "selected":
            return <BiCheckCircle size={20} color="#3CC23A" />;
          default:
            return "";
        }
      },
      disableSortBy: true,
    },
  ];

  // columns.push({
  //   accessor: "study_site_display_id",
  //   Cell: ({ row }) => (
  //     <CellElement onClick={() => handleActiveSite(row.original)}>
  //       {row.original.study_site_display_id
  //         ? row.original.study_site_display_id
  //         : "-"}
  //     </CellElement>
  //   ),
  // });

  columns.push({
    accessor: "pi_name",
    unit: "Last, First",
    // Cell: ({ row }) => (
    //   <CellElement>
    //     {row.original["pi_name"] ? row.original["pi_name"] : "-"}
    //   </CellElement>
    // ),
    hiddenColumn: true,
  });

  if (includes(sitesColumnsKeys, "institution"))
    columns.push({
      accessor: "institution",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original.institution ? row.original.institution : "-"}
        </CellElement>
      ),
    });

  if (includes(sitesColumnsKeys, "address"))
    columns.push({
      accessor: "address",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "city"))
    columns.push({
      accessor: "city",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "county"))
    columns.push({
      accessor: "county",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "state"))
    columns.push({
      accessor: "state",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "zipcode"))
    columns.push({
      accessor: "zipcode",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "center_size"))
    columns.push({
      accessor: "center_size",
      filterType: "SelectCheckbox",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["center_size"] ? row.original["center_size"] : "-"}
        </CellElement>
      ),
    });

  if (includes(sitesColumnsKeys, "institution_type"))
    columns.push({
      accessor: "institution_type",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original.institution_type ? row.original.institution_type : "-"}
        </CellElement>
      ),
    });

  if (includes(sitesColumnsKeys, "eligible_patients"))
    columns.push({
      accessor: "eligible_patients",
      unit: "# by ZIP3",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["eligible_patients"]
            ? row.original["eligible_patients"].toLocaleString("en-US")
            : "-"}
        </CellElement>
      ),
    });

  if (includes(sitesColumnsKeys, "zip3_eligibility"))
    columns.push({
      accessor: "zip3_eligibility",
      filterType: "SelectSliderRange",
      unit: "% by ZIP3",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["zip3_eligibility"]
            ? row.original["zip3_eligibility"].toFixed(2)
            : "-"}
        </CellElement>
      ),
    });

  if (includes(sitesColumnsKeys, "zip3_eligible_high_event_risk"))
    columns.push({
      accessor: "zip3_eligible_high_event_risk",
      filterType: "SelectSliderRange",
      unit: "% by ZIP3",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["zip3_eligible_high_event_risk"]
            ? row.original["zip3_eligible_high_event_risk"].toFixed(2)
            : "-"}
        </CellElement>
      ),
    });

  if (includes(sitesColumnsKeys, "zip3_eligible_med_event_risk"))
    columns.push({
      accessor: "zip3_eligible_med_event_risk",
      unit: "% by ZIP3",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "zip3_eligible_low_event_risk"))
    columns.push({
      accessor: "zip3_eligible_low_event_risk",
      unit: "% by ZIP3",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "pts_with_0_risk_factors"))
    columns.push({
      accessor: "pts_with_0_risk_factors",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "pts_with_1_risk_factors"))
    columns.push({
      accessor: "pts_with_1_risk_factors",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "pts_with_2_risk_factors"))
    columns.push({
      accessor: "pts_with_2_risk_factors",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "pts_with_3_risk_factors"))
    columns.push({
      accessor: "pts_with_3_risk_factors",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "estimated_enrollment_score"))
    columns.push({
      accessor: "estimated_enrollment_score",
      unit: "Estimated %",
      filterType: "SelectSliderRange",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["estimated_enrollment_score"]
            ? row.original["estimated_enrollment_score"].toFixed(1)
            : "-"}
        </CellElement>
      ),
    });

  if (includes(sitesColumnsKeys, "estimated_start_up_time"))
    columns.push({
      accessor: "estimated_start_up_time",
      unit: "Estimated Days",
      filterType: "SelectCheckboxRange",
      options: [
        { value: "75-125", label: "75 - 125" },
        { value: "126-176", label: "126 - 176" },
        { value: "177-227", label: "177 - 227" },
        { value: "228-278", label: "228 - 278" },
        { value: "279-329", label: "279 - 329" },
        { value: "330-380", label: "330 - 380" },
        { value: "381-431", label: "381 - 431" },
      ],
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["estimated_start_up_time"]
            ? row.original["estimated_start_up_time"]
            : "-"}
        </CellElement>
      ),
    });

  if (includes(sitesColumnsKeys, "irb_type"))
    columns.push({
      accessor: "irb_type",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["irb_type"] ? row.original["irb_type"] : "-"}
        </CellElement>
      ),
    });

  if (includes(sitesColumnsKeys, "has_ct_scanner"))
    columns.push({
      accessor: "has_ct_scanner",
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["has_ct_scanner"]
            ? row.original["has_ct_scanner"]
            : "-"}
        </CellElement>
      ),
    });

  if (includes(sitesColumnsKeys, "ascvd_7point5_to_20_percent"))
    columns.push({
      accessor: "ascvd_7point5_to_20_percent",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "ascvd_over_20_percent"))
    columns.push({
      accessor: "ascvd_over_20_percent",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "ldl_70_to_100_mg_per_dl"))
    columns.push({
      accessor: "ldl_70_to_100_mg_per_dl",
      hiddenColumn: true,
    });

  if (includes(sitesColumnsKeys, "ldl_100_to_190_mg_per_dl"))
    columns.push({
      accessor: "ldl_100_to_190_mg_per_dl",
      hiddenColumn: true,
    });

  // For the ICMPGN index we want to make two modifications to the table:
  // 1. We want to add a column for the site score, with a filter for the score range
  // 2. We want to sort the table by patients counts, then site score by default
  if (cohortIndex.index_name === "ic_mpgn_linked_claims") {
    columns.push({
      accessor: "site_score",
      filterType: "SelectCheckboxRange",
      options: [
        { value: "0-500", label: "0 - 500" },
        { value: "501-1000", label: "501 - 1000" },
        { value: "1001-1500", label: "1001 - 1500" },
        { value: "1501-2000", label: "1501 - 2000" },
        { value: "2001-2500", label: "2001 - 2500" },
        { value: "2501-3000", label: "2501 - 3000" },
        { value: "3001-3500", label: "3001 - 3500" },
      ],
      Cell: ({ row }) => (
        <CellElement onClick={() => handleActiveSite(row.original)}>
          {row.original["site_score"]
            ? row.original["site_score"].toLocaleString("en-US")
            : "-"}
        </CellElement>
      ),
    });

    columnsDefaultSort = [
      { id: "eligible_patients", desc: true },
      { id: "site_score", desc: true },
    ];
  }

  // get Header properties from SITE_COLUMNS_LABEL object
  for (const column of columns) {
    column.Header = ({ column }) => {
      if (!(column.id in SITE_COLUMNS_LABELS)) {
        return "";
      }

      return SITE_COLUMNS_LABELS[column.id];
    };
  }

  const conditionalRowStyles = [
    {
      when: (row) => row.original.activeSite,
      styles: {
        fontWeight: "700",
        color: "var(--primary)",
      },
    },
  ];

  const tableData = useMemo(() => {
    return sites.map((site) => {
      return {
        ...site,
        activeSite: site.id === activeSite,
        favorite: includes(favoriteSitesIdList, site.id)
          ? "Favorite"
          : "Non-Favorite",
        pi_name: `${site.pi_last}, ${site.pi_first}`,
        zip3_score: site.site_score,
      };
    });
  }, [sites, activeSite, favoriteSitesIdList]);

  function getPanelActions() {
    const actions = [];
    if (isEmpty(sites)) return actions;

    actions.push(
      <SearchInput
        marginBottom={0}
        onChange={(value) => setSiteSearchValue(value)}
        resetSearch={() => setSiteSearchValue("")}
        value={siteSearchValue}
      />
    );

    actions.push(
      <KebabMenu key="kebabMenu" width="200px">
        <DownloadCsvMenuItem
          key="downloadAllSites"
          label="Download all sites"
          filename="all-sites.csv"
          csvString={getSitesCsvString(
            subcohortList[selectedCohort].name,
            columns,
            tableData
          )}
        />
        <DownloadCsvMenuItem
          key="downloadFavoriteSites"
          icon={<MdStar size={12} color="26344D" />}
          label="Download favorite sites"
          filename="favorite-sites.csv"
          csvString={getSitesCsvString(
            subcohortList[selectedCohort].name,
            columns,
            tableData,
            favoriteSitesIdList
          )}
        />
      </KebabMenu>
    );

    return actions;
  }

  const panelActions = getPanelActions();

  const showInfoCircle = siteTableInfo !== null;
  const { toggleModal, isShowingModal } = useModal();

  function toggleInfoBoxModal() {
    const newIsShowingModal = !isShowingModal;
    toggleModal();

    mixpanelIdentify(localStorage.email);
    mixpanelTrack(`Site Selection - Toggle Info Box Modal`, {
      isShowingModal: newIsShowingModal,
      urlPathname: location.pathname,
    });
  }

  const infoBoxModal = showInfoCircle
    ? getInfoBoxModal(siteTableInfo, toggleInfoBoxModal, isShowingModal)
    : null;

  const getPanelTitle = () => {
    if (showInfoCircle)
      return (
        <PanelTitleContainer>
          Site Selection
          <BsInfoCircle
            size={16}
            color="4d5f80"
            style={{ cursor: "pointer" }}
            onClick={() => {
              toggleInfoBoxModal();
            }}
          />
        </PanelTitleContainer>
      );
    return "Site Selection";
  };

  const onFilterTableDataChange = (newFilteredSites) => {
    if (
      newFilteredSites.length > 0 &&
      !includes(
        newFilteredSites.map((f) => f.id),
        activeSite
      )
    )
      setActiveSite(newFilteredSites[0].id);
    setFilteredSites([...newFilteredSites]);
  };

  return (
    <Panel title={getPanelTitle()} actions={panelActions}>
      {isEmpty(sites) ? (
        <ContainerLoader>
          <Loader />
        </ContainerLoader>
      ) : (
        <FilteredTable
          columns={columns}
          data={tableData}
          globalFilterValue={siteSearchValue}
          conditionalRowStyles={conditionalRowStyles}
          defaultSort={columnsDefaultSort}
          onFilterDataChange={onFilterTableDataChange}
          tableHeight={360}
          stickyHeader
        />
      )}

      <SitesGeomapDetails
        sites={filteredSites}
        activeSite={activeSite}
        setActiveSite={setActiveSite}
        selectedCohort={subcohortList[selectedCohort]}
        setValidCharts={setValidCharts}
        setErrorMsg={setErrorMsg}
      />

      {showInfoCircle && infoBoxModal}
    </Panel>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getInfoBoxModal(
  { title, compositeEndpoints, details },
  toggleModal,
  isShowingModal
) {
  return (
    <InfoModal
      key="infoModal"
      title={title}
      toggleModal={toggleModal}
      isShowingModal={isShowingModal}
    >
      <DetailsContainer>
        {Array.from(details.entries()).map(([key, value]) => {
          return (
            <div key={key}>
              <Heading4>
                {key
                  .split("_")
                  .map((s) => capitalizeFirstLetter(s))
                  .join(" ")}
                :{" "}
              </Heading4>
              {value}
            </div>
          );
        })}
      </DetailsContainer>
      <Small>
        The SELECT tab displays both the officially selected and potential sites
        for a given trial. Population data estimates are calculated using the
        ZIP3 population from the dataset.
      </Small>
      {compositeEndpoints && Object.keys(compositeEndpoints).length !== 0 && (
        <CollapsiblesContainer>
          {Object.entries(compositeEndpoints).map(([title, description], i) => {
            const titleComponent =
              title === "Check mark" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Heading4>Selected Status</Heading4>
                  <BiCheckCircle
                    size={20}
                    color="#3CC23A"
                    style={{ marginLeft: 5 }}
                  />
                </div>
              ) : title === "Removed mark" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Heading4>Removed Status</Heading4>
                  <BiXCircle
                    size={20}
                    color="#C64E45"
                    style={{ marginLeft: 5 }}
                  />
                </div>
              ) : (
                <Heading4>{title}</Heading4>
              );
            return (
              <Collapsible
                key={title}
                title={titleComponent}
                border="1px solid #e6e6e6"
                borderTop={i === 0 ? null : 0}
                padding="10px"
              >
                {description}
              </Collapsible>
            );
          })}
        </CollapsiblesContainer>
      )}
    </InfoModal>
  );
}
