import { isEmpty } from "lodash";
import { disable } from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import { XSmall, Small } from "../../../../../../components/typography";
import { useIdentifyCohortConfigs } from "../../../../../../hooks/useIdentifyCohortConfig";
import { getFieldValuesFromQuery } from "../../../../../../components/advanced-query-builder/helpers";

import {
  Container,
  AppliedItem,
  Values,
  SpaceBetweenRow,
  PreviewLink,
} from "./styles";

function AppliedFilters({ subcohort, disabled, previewAdvancedFilter }) {
  const appliedFilterList = getFieldValuesFromQuery(subcohort.query);
  const appliedFiltersKeys = Object.keys(appliedFilterList);
  const appliedFilterCount = appliedFiltersKeys.length;
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { filtersConfig } = cohortConfigs;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (disabled) setIsOpen(false);
  }, [disabled]);

  const getLengthElement = () => {
    if (appliedFilterCount > 0) return `(${appliedFilterCount})`;
    return "";
  };

  const getItemElement = (key) => {
    let itemPharse = "";
    switch (filtersConfig[key].type) {
      case "numerical":
        itemPharse = `between `;
        break;
      default:
        itemPharse = `equals to `;
        break;
    }

    return (
      <AppliedItem key={key}>
        <Small color="#495F83">{filtersConfig[key].label}</Small>
        <Small color="#666666">
          {itemPharse}{" "}
          <Values
            content={filtersConfig[key].type === "numerical" ? "and" : "or"}
          >
            {appliedFilterList[key].map((v) => (
              <b>
                {filtersConfig[key].type === "boolean"
                  ? v === "true"
                    ? "Yes"
                    : "No"
                  : v}
              </b>
            ))}
          </Values>
        </Small>
      </AppliedItem>
    );
  };

  const getListElement = () => {
    console.log(appliedFiltersKeys);
    if (appliedFilterCount > 0)
      return appliedFiltersKeys.sort().map((key) => getItemElement(key));
    return "";
  };

  function handleToggleAppliedFilters(e) {
    e.stopPropagation();
    if (!disabled) setIsOpen(!isOpen);
  }

  function handlePreviewAdvancedFilters(e) {
    e.stopPropagation();
    if (!disabled) previewAdvancedFilter();
  }
  return (
    <Container>
      <SpaceBetweenRow disabled={disabled}>
        {!subcohort.isAdvanced ? (
          <XSmall
            color="--tertiary"
            textTransform="capitalize"
            onClick={handleToggleAppliedFilters}
          >
            <PreviewLink>
              Filters {getLengthElement()}
              {isOpen ? (
                <MdKeyboardArrowUp size={18} />
              ) : (
                <MdKeyboardArrowDown size={18} />
              )}
            </PreviewLink>
          </XSmall>
        ) : (
          <XSmall
            color="--tertiary"
            textTransform="capitalize"
            onClick={handlePreviewAdvancedFilters}
          >
            <PreviewLink>Adv. Filters</PreviewLink>
          </XSmall>
        )}
        {subcohort.subcohortSize ? (
          <Small color={"#666666"}>
            N={subcohort.subcohortSize?.toLocaleString("en-US")}
          </Small>
        ) : (
          ""
        )}
      </SpaceBetweenRow>
      {isOpen ? getListElement() : ""}
    </Container>
  );
}

export default AppliedFilters;
