import React, { useState, useRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import {
  Container,
  Title,
  IconContainer,
  DropdownContainer,
  DropdownList,
  CloseOpenIconContainer,
} from "./styles";

export default function NavItem({
  icon,
  title,
  children,
  showCloseOpenIcon = true,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const toggleDropDown = function () {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const handleClickOutside = function (event) {
    const isClickInside =
      wrapperRef.current && wrapperRef.current.contains(event.target);
    if (!isClickInside) {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const getCloseOpenIcon = () => {
    if (!showCloseOpenIcon) return "";
    let openCloseIcon = <FaChevronDown size={10} />;
    if (isOpen) {
      openCloseIcon = <FaChevronUp size={10} />;
    }
    return <CloseOpenIconContainer>{openCloseIcon}</CloseOpenIconContainer>;
  };

  return (
    <Container ref={wrapperRef} onClick={toggleDropDown}>
      <Title>
        {icon && <IconContainer>{icon}</IconContainer>}
        {title && <span>{title}</span>}
        {getCloseOpenIcon()}
      </Title>
      {isOpen && children}
    </Container>
  );
}

export function DropdownMenu({ children, align = "left" }) {
  return (
    <DropdownContainer align={align}>
      <DropdownList>{children}</DropdownList>
    </DropdownContainer>
  );
}
