import React, { useMemo, Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { find } from "lodash";

import { FaEdit } from "react-icons/fa";
import { RiArrowDropLeftLine } from "react-icons/ri";

import GuidelineForm from "../../c-guidelines-rules-form";
import GuidelineRulesOverview from "../../c-guideline-rules-overview";

import Button from "../../../../components/button";
import LoadingHOC from "../../../../components/loadingHoc";

import { useGuidelineRule } from "../../../../hooks/useGuidelineRules";

import { Container, Header, BackLink, HeaderRow } from "./styles";

function GuidelineView() {
  const { guidelineId } = useParams();
  const [isEditing, setIsEditing] = useState(false);

  const { guidelines } = useGuidelineRule();

  const history = useHistory();

  const guideline = useMemo(() => {
    const guideline = find(guidelines, ["id", parseInt(guidelineId)]);
    if (guideline) {
      return guideline;
    }

    history.push("/content-manager/rules/");
    return {};
  }, [guidelineId, guidelines]);

  const cancelEditingForm = () => {
    setIsEditing(false);
  };

  return (
    <Container>
      <Header>
        <BackLink to="/content-manager/rules/">
          <RiArrowDropLeftLine size={18} />
          back to guideline rules
        </BackLink>
        <HeaderRow>
          <h3>{guideline.name}</h3>
          {isEditing ? (
            ""
          ) : (
            <Button
              variant="outline"
              type="button"
              leftIcon={<FaEdit />}
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
          )}
        </HeaderRow>
      </Header>
      {isEditing ? (
        <GuidelineForm
          cancelFormAction={cancelEditingForm}
          guideline={guideline}
        />
      ) : (
        <div>
          <GuidelineRulesOverview guideline={guideline} />
        </div>
      )}
    </Container>
  );
}

export default LoadingHOC()(GuidelineView);
