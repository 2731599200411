import styled from "styled-components";

export const Container = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
`;

export const Input = styled.input.attrs({ type: "radio" })`
  margin-right: ${(props) => (props.ratioPosition === "left" ? "5px" : "0")};
  margin-left: ${(props) => (props.ratioPosition === "right" ? "5px" : "0")};
  margin-top: 0;
`;
