import React, { useRef } from "react";

import { BiSearch } from "react-icons/bi";
import { FaTimesCircle } from "react-icons/fa";
import Input from "../index";

function SearchInput({ placeholder = "Search", resetSearch, ...rest }) {
  const inputRef = useRef(null);

  const getResetIcon = () => {
    let icon = "";
    if (resetSearch) {
      if (rest.value.length > 0) icon = FaTimesCircle;
    }
    return icon;
  };

  return (
    <Input
      leftIcon={<BiSearch />}
      placeholder={placeholder}
      rightIcon={getResetIcon()}
      rightIconAction={resetSearch}
      ref={inputRef}
      {...rest}
    />
  );
}
export default SearchInput;
