import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { find } from "lodash";

import GuidelineHelper from "../../../helpers/guideline-helper.js";
import Table from "../../../components/tables/filtered-table";
import Button from "../../../components/button";

import { useGuidelineRule } from "../../../hooks/useGuidelineRules.js";

function PatientAttributesTable(props) {
  const { attributes } = props;
  const { guidelines } = useGuidelineRule();

  const columns = [
    {
      Header: "#",
      accessor: "id",
      disableGlobalFilter: true,
    },
    {
      accessor: "id",
      id: "view-btn",
      disableSortBy: true,
      disableGlobalFilter: true,
      Cell: ({ cell: { value } }) => (
        <Button variant="outline">
          <Link to={`/content-manager/patient-attributes/${value}`}>View</Link>
        </Button>
      ),
    },
    {
      Header: "Name",
      accessor: "human_readable_name",
    },
    {
      Header: "Variable",
      accessor: "name",
      breakWord: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Category",
      accessor: "category",
      Cell: ({ cell: { value } }) => value || "-",
      filterType: "SelectCheckbox",
    },
    {
      Header: "Intermediate Variable",
      disableGlobalFilter: true,
      accessor: (row) => (row.is_auxiliary ? "Yes" : "No"),
      id: "intermediateVariable",
      filterType: "SelectCheckbox",
    },
    {
      Header: "Data Type",
      disableGlobalFilter: true,
      accessor: "data_type",
      filterType: "SelectCheckbox",
    },
    {
      Header: "# of Rules",
      accessor: (row) =>
        GuidelineHelper.numberOfRulesWithAttribute(row.id, guidelines),
      disableGlobalFilter: true,
    },
  ];

  return <Table columns={columns} data={attributes} urlState />;
}

export default PatientAttributesTable;
