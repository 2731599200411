import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import {
  BsThreeDotsVertical,
  BsFillPeopleFill,
  BsPeople,
  BsEye,
  BsEyeSlash,
} from "react-icons/bs";
import { includes, isEmpty } from "lodash";

import {
  Container,
  SubcohortsWrapper,
  Card,
  CardTitle,
  HeaderActions,
  CardContent,
  CenterRow,
  SpaceBetweenRow,
  AddSubcohortButton,
} from "./styles";

import { useModal } from "../../../../components/modal";
import CohortModal from "./cohort-modal";

import { Heading4, Small } from "../../../../components/typography";

import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySubcohortList } from "../../../../hooks/useIdentifySubcohortList";

import SubcohortBuilder from "./subcohort";
import AddSimulation from "./add-simulation";

import { MdAdd } from "react-icons/md";

import Tooltip from "../../../../components/tooltip";
import InfoTooltip from "../../../../components/tooltip-info-icon";

import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../services/mixpanel-tracking";

function DashboardFilters({ selectedCohort, setSelectedCohort }) {
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { cohortIndexList, cohortIndex, filtersConfig, simulationsConfig } =
    cohortConfigs;
  const { subcohortList, dispatch: subcohortListDispatch } =
    useIdentifySubcohortList();

  const {
    toggleModal: toggleCohortModal,
    isShowingModal: isShowingCohortModal,
  } = useModal();

  const statusList = Object.keys(subcohortList).map(
    (id) => subcohortList[id].status
  );

  const disabledAddSubcohort = useMemo(() => {
    return (
      includes(statusList, "isCreating") ||
      includes(statusList, "isEditing") ||
      !filtersConfig
    );
  }, [statusList, filtersConfig]);

  function handleAddSubcohort() {
    if (!disabledAddSubcohort) {
      subcohortListDispatch({ type: "ADD_SUBCOHORT" });
      mixpanelIdentify(localStorage.email);
      mixpanelTrack("Add Subcohort");
    }
  }

  function toggleVisibility() {
    subcohortListDispatch({
      type: "TOOGLE_VISIBILITY_SUBCOHORT",
      payload: "cohort",
    });
  }

  function sortEditingAndCreatingAllwaysLast() {
    return function (a, b) {
      // isCreating and  sort after anything else
      if (
        subcohortList[a].status === "isCreating" ||
        subcohortList[a].status === "isEditing"
      ) {
        return 1;
      }
      if (
        subcohortList[b].status === "isCreating" ||
        subcohortList[b].status === "isEditing"
      ) {
        return -1;
      }

      return 0;
    };
  }

  const isSomeSubcohortOpen = includes(
    Object.keys(subcohortList).map((s) => {
      return (
        subcohortList[s].status === "isCreating" ||
        subcohortList[s].status === "isEditing"
      );
    }),
    true
  );

  const showHideCohortOption = () => {
    let response = false;
    if (selectedCohort) return response;
    if (Object.keys(subcohortList).length < 2) return response;
    Object.keys(subcohortList).forEach((id) => {
      if (id !== "cohort" && subcohortList[id].status !== "isCreating")
        response = true;
    });
    return response;
  };

  const getCohortIcon = () => {
    let icon = <BsFillPeopleFill size={18} />;
    if (selectedCohort !== undefined && selectedCohort !== "cohort")
      icon = <BsPeople size={18} />;
    return icon;
  };

  const getCardOpacity = () => {
    if (isSomeSubcohortOpen) return 1;
    if (!selectedCohort) {
      if (subcohortList.cohort.visible) return 1;
      else return 0.4;
    }
    if (selectedCohort !== "cohort") return 0.4;
    return 1;
  };

  const selectCohort = () => {
    if (setSelectedCohort) setSelectedCohort("cohort");
  };

  return (
    <Container>
      <SubcohortsWrapper>
        {"cohort" in subcohortList ? (
          <Card
            id="cohortSelection"
            border={1}
            opacity={getCardOpacity()}
            onClick={selectCohort}
            pointerCursor={selectedCohort && true}
          >
            <CardTitle>
              <CenterRow>
                {getCohortIcon()}
                <InfoTooltip
                  description={
                    "A cohort is a primary predefined patient population."
                  }
                >
                  Cohort
                </InfoTooltip>
              </CenterRow>
              {cohortIndexList.length > 0 ? (
                <CenterRow>
                  <HeaderActions>
                    {showHideCohortOption() ? (
                      subcohortList.cohort.visible ? (
                        <Tooltip description={"Hide cohort"}>
                          <BsEye
                            size={16}
                            color="495F83"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleVisibility();
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip description={"Show cohort"}>
                          <BsEyeSlash
                            size={16}
                            color="495F83"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleVisibility();
                            }}
                          />
                        </Tooltip>
                      )
                    ) : (
                      ""
                    )}
                  </HeaderActions>
                  <Tooltip description={"View details or change cohort"}>
                    <BsThreeDotsVertical
                      size={16}
                      color="495F83"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleCohortModal();
                      }}
                    />
                  </Tooltip>
                </CenterRow>
              ) : (
                ""
              )}
            </CardTitle>
            {!isEmpty(cohortIndex) ? (
              <CardContent color="#6450C3">
                <SpaceBetweenRow>
                  <Heading4>{subcohortList.cohort.name}</Heading4>
                  {subcohortList.cohort?.subcohortSize ? (
                    <Small color="#666666">
                      N=
                      {subcohortList.cohort.subcohortSize?.toLocaleString(
                        "en-US"
                      )}
                    </Small>
                  ) : (
                    ""
                  )}
                </SpaceBetweenRow>
              </CardContent>
            ) : (
              ""
            )}
          </Card>
        ) : (
          ""
        )}

        {Object.keys(subcohortList)
          .sort(sortEditingAndCreatingAllwaysLast())
          .map((id, index) => {
            if (id !== "cohort")
              return (
                <SubcohortBuilder
                  selectedCohort={selectedCohort}
                  setSelectedCohort={setSelectedCohort}
                  subcohort={subcohortList[id]}
                  subcohortID={id}
                  key={id}
                  index={index}
                  isSomeSubcohortOpen={isSomeSubcohortOpen}
                />
              );
          })}
        {Object.keys(subcohortList).length <= 3 ? (
          <AddSubcohortButton
            disabled={disabledAddSubcohort}
            onClick={() => handleAddSubcohort()}
          >
            <span>
              <InfoTooltip
                description={
                  "A subcohort is a subset of patients within the cohort."
                }
              >
                Add Subcohort
              </InfoTooltip>
            </span>
            <Tooltip description={"Add a subcohort to dashboard"}>
              <MdAdd />
            </Tooltip>
          </AddSubcohortButton>
        ) : (
          ""
        )}
        <CohortModal
          toggleModal={toggleCohortModal}
          isShowingModal={isShowingCohortModal}
        />
      </SubcohortsWrapper>
      {simulationsConfig ? <AddSimulation /> : ""}
    </Container>
  );
}

export default withRouter(DashboardFilters);
