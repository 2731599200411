import styled from "styled-components";

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  min-height: 22px;
  align-items: center;
  padding-left: 6px;
  width: 100%;

  &:hover {
    background-color: rgba(179, 192, 214, 0.37);
  }

  > a,
  > span {
    color: #26344d;
    text-decoration: none;
    padding-left: 6px;
    font-weight: 400;
  }
`;
