import React, { useRef, useState, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { difference, findIndex, last, dropRight } from "lodash";
import { TabsContext } from "./context";

import {
  TabsBar,
  TabLink,
  MoreTabLink,
  DropdownContainer,
  DropdownList,
  DropdownLink,
} from "./styles";
import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../services/mixpanel-tracking";

export default function TabsNav() {
  const { mainTabs } = useContext(TabsContext);
  const tabsRef = useRef(null);
  const [moreTabs, setMoreTabs] = useState();
  const [tabs, setTabs] = useState();
  const [tabsSizes, setTabsSizes] = useState({});
  const windowSize = useWindowSize();
  const navBarItens = tabsRef.current ? tabsRef.current.children : [];
  const location = useLocation();

  useEffect(() => {
    setTabs([...mainTabs]);
    setMoreTabs([]);
  }, [mainTabs]);

  useEffect(() => {
    if (tabsRef.current && navBarItens.length > 0) {
      const newTabsSizes = tabsSizes;
      for (let i = 0; i < navBarItens.length; i++) {
        newTabsSizes[navBarItens[i].text] = navBarItens[i].offsetWidth;
      }
      setTabsSizes(newTabsSizes);
    }
  }, [navBarItens.length]);

  useEffect(() => {
    if (tabsRef.current) {
      const navBarWidth = tabsRef.current.offsetWidth;
      let currentItemsWidth = 0;
      let countFitItens = 0;
      for (let i = 0; i < mainTabs.length; i++) {
        currentItemsWidth += tabsSizes[mainTabs[i].name];
        if (navBarWidth > currentItemsWidth) countFitItens++;
      }
      let newTabs =
        countFitItens < mainTabs.length
          ? mainTabs.slice(0, countFitItens - 1)
          : mainTabs;
      let newMoreTabs = difference(mainTabs, newTabs);
      const activeTabIndex = findIndex(newMoreTabs, ["url", location.pathname]);
      if (activeTabIndex >= 0 && newTabs.length > 0) {
        const activeLinkinMore = newMoreTabs[activeTabIndex];
        const lastLinkinTabs = last(newTabs);
        newMoreTabs.splice(activeTabIndex, 1);
        newMoreTabs = [lastLinkinTabs, ...newMoreTabs];
        newTabs = [...dropRight(newTabs), activeLinkinMore];
      }
      setMoreTabs([...newMoreTabs]);
      setTabs([...newTabs]);
    }
  }, [windowSize, navBarItens.length]);

  const handleActiveLinkinMore = (linkIndex) => {
    if (tabs.length > 0) {
      const newMoreTabs = [...moreTabs];
      let newTabs = [...tabs];

      const activeLinkinMore = newMoreTabs[linkIndex];
      const lastLinkinTabs = last(newTabs);
      newMoreTabs.splice(linkIndex, 1);
      newTabs = dropRight(newTabs);
      setMoreTabs([lastLinkinTabs, ...newMoreTabs]);
      setTabs([...newTabs, activeLinkinMore]);
    }
  };

  const handleMixpanel = (tab) => {
    mixpanelIdentify(localStorage.email);
    mixpanelTrack("Change Cohort", {
      tabUrl: tab.url,
    });
  };

  return (
    <TabsBar ref={tabsRef}>
      {tabs &&
        tabs.map((tab) =>
          tab.displayTab ? (
            <TabLink
              key={tab.url}
              to={tab.disabled ? "#" : tab.url}
              activeClassName="is-active"
              onClick={() => handleMixpanel(tab)}
              disabled={tab.disabled}
            >
              {tab.name}
            </TabLink>
          ) : (
            ""
          )
        )}
      {moreTabs && moreTabs.length > 0 ? (
        <MoreTab>
          <DropdownMenu>
            {moreTabs.map((t, i) => (
              <DropdownLink
                key={i}
                disabled={t.disabled}
                to={t.disabled ? "#" : t.url}
                onClick={() => handleActiveLinkinMore(i)}
              >
                {t.name}
              </DropdownLink>
            ))}
          </DropdownMenu>
        </MoreTab>
      ) : (
        ""
      )}
    </TabsBar>
  );
}

function MoreTab({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MoreTabLink to="#" onClick={() => setIsOpen(!isOpen)}>
      <span>More</span>
      {isOpen && children}
    </MoreTabLink>
  );
}

export function DropdownMenu({ children }) {
  return (
    <DropdownContainer>
      <DropdownList>{children}</DropdownList>
    </DropdownContainer>
  );
}

// Hook Extract this to a helper
function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
