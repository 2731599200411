import React, { forwardRef } from "react";

import { Container, Input } from "./styles";

const Checkbox = forwardRef(
  ({ label, value, onChange, name, ...rest }, ref) => {
    return (
      <Container htmlFor={name + value} key={value}>
        <Input
          ref={ref}
          value={value}
          id={name + value}
          onChange={() =>
            onChange
              ? onChange(name, event.target.value, event.target.checked)
              : null
          }
          {...rest}
        />
        {label}
      </Container>
    );
  }
);

export default Checkbox;
