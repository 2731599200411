import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content 1fr min-content;
  grid-template-areas:
    "trial trial"
    "toph toph"
    "aside main"
    "appfoot appfoot";
`;
