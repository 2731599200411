import React from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";

import LoadingHOC from "../../../../components/loadingHoc";

import MedicationMechanismForm from "../../c-medication-mechanism-form";

import { Container, BackLink, Header } from "./styles";

function MedicationMechanismAdd() {
  return (
    <Container>
      <Header>
        <BackLink to="/medication-manager/medication-mechanisms">
          <RiArrowDropLeftLine size={18} />
          back to medication mechanisms
        </BackLink>
        <h3>Create New Medication Mechanisms</h3>
      </Header>
      <MedicationMechanismForm />
    </Container>
  );
}

export default LoadingHOC()(MedicationMechanismAdd);
