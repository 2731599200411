import { isEmpty, isNumber } from "lodash";
import React from "react";
import { MdLocationPin } from "react-icons/md";
import PieChart from "../../../../../../components/pie-chart-draft";

import Loader from "../../../../../../components/loader";
import {
  Heading2,
  Heading3,
  Heading4,
  Small,
} from "../../../../../../components/typography";

import {
  Container,
  Header,
  Content,
  EligiblePatients,
  BreakdownWrapper,
  Breakdown,
  BreakdownHeader,
  BreakdownContent,
  ErrorMessageWrapper,
} from "./styles";

export default function SiteDetails({ site }) {
  console.log(site);
  if (!isEmpty(site))
    return (
      <Container>
        <Header>
          <div>
            <Heading2>
              <MdLocationPin />
              {site.institution}
            </Heading2>
            <Small color="var(--tertiary)" marginBottom={10}>
              {site.address}, {site.city}, {site.state} {site.zipcode}
            </Small>
          </div>
          <div style={{ textAlign: "right" }}>
            <Small color="#666666">Principal Investigator (PI)</Small>
            <span>
              {site["pi_first"]
                ? `${site["pi_first"]} ${site["pi_last"]}`
                : "Unknown"}
              {site["email"] ? ` - ${site["email"]}` : ""}
            </span>
          </div>
        </Header>
        <EligiblePatients>
          <Heading2>Patients by Zip3</Heading2>
          <Heading3 color="#666666">Eligible patients</Heading3>
          <Heading2 color="#666666">
            {site["eligible_patients"]
              ? site["eligible_patients"].toLocaleString("en-US")
              : "N/A"}
          </Heading2>
        </EligiblePatients>
        <Content>
          {site["eligible_patients"] ? (
            <BreakdownWrapper>
              {isNumber(site["pts_with_0_risk_factors"]) &&
              isNumber(site["pts_with_1_risk_factors"]) &&
              isNumber(site["pts_with_2_risk_factors"]) &&
              isNumber(site["pts_with_3_risk_factors"]) ? (
                <Breakdown>
                  <Heading4 color="--secundary">
                    Patients with Risk factors
                  </Heading4>
                  <BreakdownContent>
                    <div>
                      <Heading4 color="--secundary">0 risk factors: </Heading4>
                      <Heading4>
                        {site["pts_with_0_risk_factors"]?.toFixed(2)}%
                      </Heading4>
                    </div>
                    <div>
                      <Heading4 color="--secundary">1 risk factor: </Heading4>
                      <Heading4>
                        {site["pts_with_1_risk_factors"]?.toFixed(2)}%
                      </Heading4>
                    </div>
                    <div>
                      <Heading4 color="--secundary">2 risk factors: </Heading4>
                      <Heading4>
                        {site["pts_with_2_risk_factors"]?.toFixed(2)}%
                      </Heading4>
                    </div>
                    <div>
                      <Heading4 color="--secundary">3 risk factors: </Heading4>
                      <Heading4>
                        {site["pts_with_3_risk_factors"]?.toFixed(2)}%
                      </Heading4>
                    </div>
                  </BreakdownContent>
                </Breakdown>
              ) : (
                ""
              )}

              {site["ascvd_under_7point5_percent"] ||
              site["ascvd_7point5_to_20_percent"] ||
              site["ascvd_over_20_percent"] ? (
                <Breakdown>
                  <Heading4 color="--secundary">10-year ASCVD Risk</Heading4>
                  <BreakdownContent>
                    {site["ascvd_under_7point5_percent"] ? (
                      <div>
                        <Heading4 color="--secundary">&#8804;7.5%: </Heading4>
                        <Heading4>
                          {site["ascvd_under_7point5_percent"]?.toFixed(2)}%
                        </Heading4>
                      </div>
                    ) : (
                      ""
                    )}
                    {site["ascvd_7point5_to_20_percent"] ? (
                      <div>
                        <Heading4 color="--secundary">7.5% - 20%: </Heading4>
                        <Heading4>
                          {site["ascvd_7point5_to_20_percent"]?.toFixed(2)}%
                        </Heading4>
                      </div>
                    ) : (
                      ""
                    )}
                    {site["ascvd_over_20_percent"] ? (
                      <div>
                        <Heading4 color="--secundary">&gt;20%: </Heading4>
                        <Heading4>
                          {site["ascvd_over_20_percent"]?.toFixed(2)}%
                        </Heading4>
                      </div>
                    ) : (
                      ""
                    )}
                  </BreakdownContent>
                </Breakdown>
              ) : (
                ""
              )}

              {site["ldl_70_to_100_mg_per_dl"] &&
              site["ldl_100_to_190_mg_per_dl"] ? (
                <Breakdown>
                  <Heading4 color="--secundary">LDL Cholesterol</Heading4>
                  <BreakdownContent>
                    <div>
                      <Heading4 color="--secundary">70 - 100 mg/dL: </Heading4>
                      <Heading4>
                        {site["ldl_70_to_100_mg_per_dl"]?.toFixed(2)}%
                      </Heading4>
                    </div>
                    <div>
                      <Heading4 color="--secundary">100 - 190 mg/dL: </Heading4>
                      <Heading4>
                        {site["ldl_100_to_190_mg_per_dl"]?.toFixed(2)}%
                      </Heading4>
                    </div>
                  </BreakdownContent>
                </Breakdown>
              ) : (
                ""
              )}

              {site["zip3_eligible_high_event_risk"] ||
              site["zip3_eligible_low_event_risk"] ||
              site["zip3_eligible_med_event_risk"] ? (
                <Breakdown>
                  <Heading4 color="--secundary">MACE Risk Breakdown</Heading4>
                  <BreakdownContent>
                    <PieChart
                      data={[
                        {
                          key: "Low",
                          value:
                            site["zip3_eligible_low_event_risk"].toFixed(2),
                        },
                        {
                          key: "Moderate",
                          value:
                            site["zip3_eligible_med_event_risk"].toFixed(2),
                        },
                        {
                          key: "High",
                          value:
                            site["zip3_eligible_high_event_risk"].toFixed(2),
                        },
                      ]}
                      outerRadius={60}
                      innerRadius={30}
                      colorPalette={["#6DCE66", "#F1D386", "#CA3838"]}
                    />
                  </BreakdownContent>
                </Breakdown>
              ) : (
                ""
              )}

              {site["hp_network_status"] ||
              site["icmpgn_literature"] ||
              site["fwa"] ? (
                <Breakdown>
                  <BreakdownContent>
                    {site["hp_network_status"] && (
                      <div>
                        <Heading4 color="--secundary">
                          HealthPals In-Network Status
                        </Heading4>
                        <Heading4>{site["hp_network_status"]}</Heading4>
                      </div>
                    )}
                    {site["icmpgn_literature"] && (
                      <div>
                        <Heading4 color="--secundary">
                          IC-MPGN Engagement in Literature
                        </Heading4>
                        <Heading4>{site["icmpgn_literature"]}</Heading4>
                      </div>
                    )}
                    {site["fwa"] && (
                      <div>
                        <Heading4 color="--secundary">FWA</Heading4>
                        <Heading4>{site["fwa"]}</Heading4>
                      </div>
                    )}
                  </BreakdownContent>
                </Breakdown>
              ) : (
                ""
              )}
            </BreakdownWrapper>
          ) : (
            <ErrorMessageWrapper>
              <p>
                This site's ZIP3 resulted in <b>too few patients</b>.
              </p>
              <p>
                To protect patient privacy, we are unable to display the
                results.
              </p>
              <br />
              <p>Please try a different filter / combination of filters.</p>
            </ErrorMessageWrapper>
          )}
        </Content>
      </Container>
    );

  return (
    <Container>
      <Loader />
    </Container>
  );
}
