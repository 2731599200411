import React, { useMemo } from "react";
import Joyride from "react-joyride";
import { useLocation } from "react-router-dom";

function AppTips({ steps, run = true, ...rest }) {
  const location = useLocation();

  const runTips = useMemo(() => {
    const hashes = new URLSearchParams(location.hash);
    return hashes.get("#tips") == 1;
  }, [location.hash]);

  return (
    <Joyride
      run={run && runTips}
      steps={steps}
      styles={{ overlay: { height: "100vh" }, options: defaultStyles }}
      {...rest}
    />
  );
}

const defaultStyles = {
  primaryColor: "#345373",
  textColor: "#26344D",
  zIndex: 5000,
};
export default AppTips;
