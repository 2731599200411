import React, { createContext, useContext, useEffect, useState } from "react";
import { findIndex } from "lodash";
import { api } from "../services/api";

const GuidelineRuleContext = createContext({});

export function GuidelineRuleProvider(props) {
  const { children } = props;
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    GetGuidelines();
  }, []);

  async function GetGuidelines() {
    const response = await api.get(`intelligence/guidelines`, {
      withCredentials: true,
    });
    const { data } = response.data;
    setGuidelines(data);
  }

  async function EditGuideline(document, id, successHandler, errorHandler) {
    const response = await api.put(`intelligence/guidelines/${id}`, document, {
      withCredentials: true,
    });
    if (response.data.valid) {
      const documentIndex = findIndex(guidelines, ["id", id]);
      const newRules = [...guidelines];
      newRules.splice(documentIndex, 1, response.data.data);
      setGuidelines(newRules);
      successHandler(response.data.data);
    } else {
      errorHandler(response.data.messages);
    }
  }

  async function DeleteGuideline(id, successHandler, errorHandler) {
    const response = await api.delete(`intelligence/guidelines/${id}`, {
      withCredentials: true,
    });
    if (response.data.valid) {
      const documentIndex = findIndex(guidelines, ["id", id]);
      const newRules = [...guidelines];
      newRules.splice(documentIndex, 1);
      setGuidelines(newRules);
      successHandler(id);
    } else {
      errorHandler(response.data.messages);
    }
  }

  async function AddGuideline(document, successHandler, errorHandler) {
    const response = await api.post(`intelligence/guidelines`, document, {
      withCredentials: true,
    });
    if (response.data.valid) {
      setGuidelines([...guidelines, response.data.data]);
      successHandler(response.data.data);
    } else {
      errorHandler(response.data.messages);
    }
  }

  return (
    <GuidelineRuleContext.Provider
      value={{
        guidelines,
        AddGuideline,
        EditGuideline,
        DeleteGuideline,
        GetGuidelines,
      }}
    >
      {children}
    </GuidelineRuleContext.Provider>
  );
}

export function useGuidelineRule() {
  const context = useContext(GuidelineRuleContext);

  return context;
}
