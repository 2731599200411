import React, { useState, useMemo } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { MdDownload, MdKeyboardArrowRight } from "react-icons/md";

import Select from "../../../../../components/basic-inputs/select";
import ModalSelect from "../select-dropdown";
import KebabMenu from "../../../../../components/kebab-menu";
import Modal, { useModal } from "../../../../../components/modal";
import KebabMenuItem from "../../../../../components/kebab-menu-item";
import Chart from "../../../../../components/event-rate-curves-chart";
import DownloadCsvMenuItem from "../../../../../components/download-csv-menu-item";

import DownloadChart from "./download-chart";

import {
  convertGraphToCsvString,
  convertTableToCsvString,
} from "../convert-csv";

import { useIdentifyCohortConfigs } from "../../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySubcohortList } from "../../../../../hooks/useIdentifySubcohortList";

import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../../services/mixpanel-tracking";
import PageControl from "../../../../../components/page-control";

const NUMBER_OF_FEATURES_PER_PAGE = 20;

function PanelActions({
  charts,
  table,
  setSelectedChart,
  selectedChart = 0,
  disabledChartSelect,
  view,
}) {
  const { isShowingModal, toggleModal } = useModal();

  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { riskModelConfig, settings } = cohortConfigs;
  const { subcohortList } = useIdentifySubcohortList();
  const exportEnabled = settings.risk_model_export_enabled;

  function getDownloadItens() {
    const downloadMenuItems = [];

    if (view == "Chart") {
      downloadMenuItems.push(
        <DownloadChart key="download-chart" chart={charts[selectedChart]} />
      );

      const names = Object.keys(subcohortList).map(
        (id) => subcohortList[id].name
      );
      downloadMenuItems.push(
        <DownloadCsvMenuItem
          key="graph-csv"
          label="Table (.csv)"
          filename="risk-model-table.csv"
          csvString={convertGraphToCsvString(names, charts[selectedChart])}
          icon={null}
        />
      );
    }

    if (view == "AUC Values") {
      const { columns, rows } = table;

      downloadMenuItems.push(
        <DownloadCsvMenuItem
          key="auc-values-csv"
          label="Download Table"
          filename="auc-values.csv"
          csvString={convertTableToCsvString(columns, rows)}
          icon={<MdDownload size={14} color="26344D" />}
        />
      );
    }

    if (downloadMenuItems.length > 1) {
      return (
        <KebabMenu
          key="download-kebab-menu"
          item={
            <KebabMenuItem>
              <MdDownload size={14} color="26344D" />
              <span>Download Chart</span>
              <MdKeyboardArrowRight size={14} color="26344D" />
            </KebabMenuItem>
          }
          position="left"
          activation="hover"
          title="Format"
        >
          {downloadMenuItems}
        </KebabMenu>
      );
    } else {
      return downloadMenuItems;
    }
  }

  function getChartSelect() {
    const options = charts.map(({ name }, index) => ({
      value: index,
      label: name,
    }));

    function handleChartDropdownChange(value) {
      mixpanelIdentify(localStorage.email);
      mixpanelTrack(`Risk model module - Change Chart`, {
        selectedChart: options[value].label,
        urlPathname: location.pathname,
      });
      setSelectedChart(Number(value));
    }

    return (
      <Select
        key="dropdown"
        options={options}
        value={selectedChart}
        onChange={(value) => {
          handleChartDropdownChange(value);
        }}
        marginBottom={0}
        disabled={disabledChartSelect}
      />
    );
  }

  function getWeightInfoModalItem() {
    function toggleInfoBoxModal() {
      const newIsShowingModal = !isShowingModal;

      mixpanelIdentify(localStorage.email);
      mixpanelTrack(`Toggle Risk Model Feature Importances Modal`, {
        isShowingModal: newIsShowingModal,
        urlPathname: location.pathname,
      });

      toggleModal();
    }

    return (
      <KebabMenuItem key="importances" onClick={toggleInfoBoxModal}>
        <BsInfoCircle size={14} color="26344D" />
        <span>Feature Importances</span>
      </KebabMenuItem>
    );
  }

  function getKebabMenu() {
    const kebabMenuItems = [];

    if (riskModelConfig) kebabMenuItems.push(getWeightInfoModalItem());

    if (exportEnabled) kebabMenuItems.push(getDownloadItens());

    if (kebabMenuItems.length > 0)
      return (
        <KebabMenu key="kebab-menu" width="160px">
          {kebabMenuItems}
        </KebabMenu>
      );
  }

  function getWeightInfoModal() {
    const options = riskModelConfig.map((model, value) => ({
      label: model.display_name,
      value,
    }));

    const onCurrentPageChange = (page) => {
      setCurrentPage(page);
    };

    const [selectedModuleView, setSelectedModuleView] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const chartConfig = useMemo(() => {
      if (riskModelConfig[selectedModuleView]) {
        const xvalues = riskModelConfig[
          selectedModuleView
        ].feature_importances.map((f) => f.importance);
        const maxValue = Math.max(...xvalues) * 1.2;
        const features = riskModelConfig[selectedModuleView].feature_importances
          .sort((a, b) => b.importance - a.importance)
          .filter(
            (f, i) =>
              i <= NUMBER_OF_FEATURES_PER_PAGE * currentPage &&
              i >=
                NUMBER_OF_FEATURES_PER_PAGE * currentPage -
                  NUMBER_OF_FEATURES_PER_PAGE
          )
          .sort((a, b) => a.importance - b.importance);

        return {
          tooltip: {
            show: false,
          },
          color: ["#FF0052"],
          grid: {
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            containLabel: true,
          },
          xAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
            position: "top",
            max: maxValue,
          },
          yAxis: {
            type: "category",
            data: features.map((feature) => feature.display_name),
          },
          series: [
            {
              name: "Importance",
              type: "bar",
              label: {
                show: true,
                position: "right",
              },
              data: features.map((feature) => feature.importance.toFixed(4)),
            },
          ],
        };
      } else {
        return {};
      }
    }, [selectedModuleView, currentPage]);

    return (
      <Modal
        key="infoModal"
        title={"Risk Model Feature Importances"}
        hide={toggleModal}
        isShowing={isShowingModal}
      >
        <ModalSelect
          key="dropdown"
          options={options}
          value={selectedModuleView}
          onChange={(value) => {
            setSelectedModuleView(Number(value));
            setCurrentPage(1);
          }}
          marginBottom={0}
          style={{
            maxWidth: "200px",
          }}
        />

        <Chart chartOption={chartConfig} />
        <PageControl
          currentPage={currentPage}
          onCurrentPageChange={onCurrentPageChange}
          numberOfItensTotal={
            riskModelConfig[selectedModuleView]?.feature_importances?.length
          }
          numberOfItensPerPage={NUMBER_OF_FEATURES_PER_PAGE}
          itemType={"features"}
        />
      </Modal>
    );
  }

  const actions = [];

  actions.push(getChartSelect());

  actions.push(getKebabMenu());

  actions.push(getWeightInfoModal());

  if (!charts || !charts.length) {
    return null;
  } else {
    return actions;
  }
}

export default PanelActions;
