import React, { createContext, useContext, useReducer } from "react";

const IdentifySimulationContext = createContext({});

const IdentifySimulationReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_SELECTED_COHORT":
      return {
        ...state,
        selectedCohort: action.payload,
      };
    case "CHANGE_BASE_ADOPTION_RATE":
      return {
        ...state,
        baseAdoptionRate: action.payload,
      };
    case "CHANGE_SIMULATION_ADOPTION_RATE":
      return {
        ...state,
        simulationAdoptionRate: action.payload,
      };
    case "CHANGE_SIMULATION_CONFIG":
      return {
        ...state,
      };
    case "CHANGE_SIMULATION_PARAMS":
      return {
        ...state,
      };
    default:
      return state;
  }
};

const defalutState = {
  selectedCohort: {},
  simulationAdoptionRate: 100,
  feature: {
    value: "genetic_test_cardio_or_unspecified_183d_before_or_183d_after",
    label:
      "Evidence of genetic testing, cardiovascular/unspecified, within 6mo of index date",
  },
  baseAdoptionRate: null,
};

export function IdentifySimulationProvider({ children }) {
  const [simulation, dispatch] = useReducer(
    IdentifySimulationReducer,
    defalutState
  );

  return (
    // put here the variables that component need to share
    <IdentifySimulationContext.Provider value={{ simulation, dispatch }}>
      {children}
    </IdentifySimulationContext.Provider>
  );
}

export function useIdentifySimulation() {
  const context = useContext(IdentifySimulationContext);

  return context;
}
