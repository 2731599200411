import styled from "styled-components";

export const Container = styled.div``;

export const ErrorMessage = styled.div`
  margin: 50px auto 0;
  width: 60%;
  min-height: 300px;
  min-width: 300px;

  h1 {
    margin-bottom: 10px;
    text-align: center;
  }

  p,
  h4 {
    text-align: center;
  }
`;

export const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 2.28571429rem;
  height: 2.28571429rem;

  &:before {
    margin: 0em;
    position: absolute;
    content: "";
    top: 0%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.1);
  }

  &:after {
    margin: 0em;
    position: absolute;
    content: "";
    top: 0%;
    left: 50%;
    width: 100%;
    height: 100%;
    animation: loader 0.6s linear;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #767676 transparent transparent;
    border-style: solid;
    border-width: 0.2em;
    box-shadow: 0px 0px 0px 1px transparent;
  }

  @-webkit-keyframes loader {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes loader {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
