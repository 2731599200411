import { cloneDeep, includes, isEmpty } from "lodash";
import React, { createContext, useContext, useReducer } from "react";
import { useEffect } from "react";

const IdentifySubcohortListContext = createContext({});

let colorPallet = ["#FD765D", "#3CC23A", "#0CA6C8"];
let idAvailableOptions = ["subcohort-a", "subcohort-b", "subcohort-c"];
const statusAvailableOptions = [
  "isCreating",
  "isEditing",
  "isLoading",
  "isFullyLoaded",
];

const IdentifySubcohortListReducer = (state, action) => {
  let newState = { ...state };
  switch (action.type) {
    case "ADD_SUBCOHORT":
      if (idAvailableOptions.length !== 0) {
        const id = idAvailableOptions.shift();
        const color = colorPallet.shift();
        newState[id] = {
          name: "",
          filterList: {},
          color: "",
          subcohortSize: null,
          visible: true,
          status: "isCreating",
          isSaved: false,
          fetchedData: {
            populationMap: null,
            erc: null,
            tableOne: null,
            tableTwo: null,
          },
        };
        newState[id].color = color;
      }
      return newState;
    case "REMOVE_SUBCOHORT":
      if (newState[action.payload.id]) {
        idAvailableOptions.unshift(action.payload.id);
        idAvailableOptions.sort();
        colorPallet.unshift(newState[action.payload.id].color);
        delete newState[action.payload.id];
        if (Object.keys(newState).length == 1) newState.cohort.visible = true;
      }
      return newState;
    case "APPLY_SUBCOHORT_FILTERS":
      newState[action.payload.id].filterList = action.payload.filterList;
      newState[action.payload.id].name = action.payload.name;
      newState[action.payload.id].status = "isLoading";
      if ("isSaved" in action.payload)
        newState[action.payload.id].isSaved = action.payload.isSaved;
      newState[action.payload.id].fetchedData = {
        populationMap: null,
        erc: null,
        tableOne: null,
        tableTwo: null,
      };
      newState[action.payload.id].subcohortSize = null;
      return newState;
    case "UPDATE_SUBCOHORT_FETCHED_DATA":
      if (newState[action.payload.id]) {
        newState[action.payload.id].fetchedData[action.payload.chart] =
          action.payload.fetchedData;
        if (!includes(newState[action.payload.id].fetchedData, null))
          newState[action.payload.id].status = "isFullyLoaded";
      }
      return newState;
    case "UPDATE_SUBCOHORT_NAME":
      if (newState[action.payload.id]) {
        newState[action.payload.id].name = action.payload.name;
        if ("isSaved" in action.payload)
          newState[action.payload.id].isSaved = action.payload.isSaved;
      }
      return newState;
    case "UPDATE_SUBCOHORT_SIZE":
      if (newState[action.payload.id])
        newState[action.payload.id].subcohortSize = action.payload.size;
      return newState;
    case "UPDATE_SUBCOHORT_STATUS":
      if (newState[action.payload.id])
        newState[action.payload.id].status = action.payload.status;
      return newState;
    case "TOOGLE_VISIBILITY_SUBCOHORT":
      if (newState[action.payload])
        newState[action.payload].visible = !newState[action.payload].visible;
      return newState;
    case "HANDLE_COHORT_INDEX_CHANGE":
      colorPallet = ["#FD765D", "#3CC23A", "#0CA6C8"];
      idAvailableOptions = ["subcohort-a", "subcohort-b", "subcohort-c"];
      return {
        cohort: {
          name: action.payload.index_human_readable_name,
          filterList: {},
          color: "#6E5BC7",
          subcohortSize: null,
          visible: true,
          status: "isLoading",
          isSaved: true,
          fetchedData: {
            populationMap: null,
            erc: null,
            tableOne: null,
            tableTwo: null,
          },
        },
      };
    default:
      return state;
  }
};

const defaultSubcohort = {
  name: "",
  filterList: {},
  color: "",
  subcohortSize: null,
  visible: true,
  status: "isCreating",
  isSaved: false,
  fetchedData: {
    populationMap: null,
    erc: null,
    tableOne: null,
    tableTwo: null,
  },
};

const defalutState = {};

export function IdentifySubcohortListProvider({ children, cohortIndex }) {
  const [subcohortList, dispatch] = useReducer(
    IdentifySubcohortListReducer,
    defalutState
  );

  useEffect(() => {
    if (!isEmpty(cohortIndex))
      dispatch({ type: "HANDLE_COHORT_INDEX_CHANGE", payload: cohortIndex });
  }, [cohortIndex]);

  return (
    // put here the variables that component need to share
    <IdentifySubcohortListContext.Provider value={{ subcohortList, dispatch }}>
      {children}
    </IdentifySubcohortListContext.Provider>
  );
}

export function useIdentifySubcohortList() {
  const context = useContext(IdentifySubcohortListContext);

  return context;
}
