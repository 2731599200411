import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 600px;
  min-width: 500px;
  border-radius: 3px;
  border: 1px solid white;
  margin: 30px auto 0;
`;

export const Header = styled.div`
  padding: 12px 24px;
  background-color: white;
  border-bottom: 1px solid #e6e6e6;
  color: #26344d;
  align-items: center;
  display: flex;
  justify-content: space-between;

  h2 {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  max-height: 65%;
  background-color: #ffffff;
  padding: 24px;
  overflow: auto;

  p {
    margin-bottom: 10px;
    line-height: 1.6;
  }
`;

export const OutsideFAQ = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(38, 52, 77, 0.7);

  /* opacity: 0; */
  transition: opacity 0.4s;
`;

export const Question = styled.div`
  padding: 10px 18px;
  border: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  & + & {
    border-top: 0;
  }
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const Tab = styled.div`
  border-bottom: 2px solid transparent;
  padding: 2px 5px;
  text-transform: uppercase;
  color: #cccccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  ${(props) =>
    props.active &&
    css`
      border-color: var(--primary);
      color: var(--primary);
    `}
`;
