import React from "react";
import { Link } from "react-router-dom";
import { find, countBy } from "lodash";

import GuidelineHelper from "../../../helpers/guideline-helper.js";
import Table from "../../../components/tables/simple-table";
import FilteredTable from "../../../components/tables/filtered-table";
import Button from "../../../components/button";

import { useGuidelineRule } from "../../../hooks/useGuidelineRules.js";
import { useMedicationIntents } from "../../../hooks/useMedicationIntents.js";
import { useMedicationDrugs } from "../../../hooks/useMedicationDrugs.js";

export default function MedicationMechanismsTable(props) {
  const { medicationMechanisms, isFiltersOn = true } = props;

  const { medications } = useMedicationDrugs();
  const { guidelines } = useGuidelineRule();
  const { medicationIntents } = useMedicationIntents();

  const columns = [
    {
      Header: "#",
      accessor: "id",
      disableGlobalFilter: true,
    },
    {
      accessor: "id",
      id: "view-btn",
      disableSortBy: true,
      disableGlobalFilter: true,
      Cell: ({ cell: { value } }) => (
        <Button variant="outline">
          <Link to={`/medication-manager/medication-mechanisms/${value}`}>
            View
          </Link>
        </Button>
      ),
    },
    {
      Header: "Name",
      accessor: "human_readable_name",
      breakWord: true,
    },
    {
      Header: "Medication Intent",
      accessor: (row) =>
        find(medicationIntents, ["id", row.parent])
          ? find(medicationIntents, ["id", row.parent]).human_readable_name
          : "-",
      id: "medication-intent",
      disableGlobalFilter: true,
    },
    {
      Header: "# of Medication Drugs",
      accessor: (row) => {
        const usageCount = countBy(medications, function (medication) {
          return medication.parent;
        })[row.id];
        return usageCount || 0;
      },
      id: "medication-drug-usage",
      disableGlobalFilter: true,
    },
    {
      Header: "# of Rules",
      accessor: (row) =>
        GuidelineHelper.numberOfRulesWithAttribute(row.id, guidelines),
      id: "rule-usage",
      disableGlobalFilter: true,
    },
  ];

  if (isFiltersOn) {
    return (
      <FilteredTable columns={columns} data={medicationMechanisms} urlState />
    );
  }
  return <Table columns={columns} data={medicationMechanisms} />;
}
