import React, { forwardRef, useState, useEffect } from "react";

import { Container, Slider, RangeInputs, SliderContainer } from "./styles";
import Input from "../input";

const RangeSlider = forwardRef(
  (
    {
      min,
      max,
      onChange,
      name,
      defaultValue = [],
      value,
      showInputsMinMax = true,
      step = 1,
      marginBottom = 10,
      marginTop = 0,
      ...rest
    },
    ref
  ) => {
    const [rangeValue, setRangeValue] = useState([]);

    const handleChange = (value) => {
      setRangeValue(value);
      onChange ? onChange(name, [...value]) : null;
    };

    const handleInputChange = (value, type) => {
      const newRangeValue = rangeValue;
      newRangeValue[type == "min" ? 0 : 1] = Number(value);
      setRangeValue(newRangeValue);
      onChange ? onChange(name, [...newRangeValue]) : null;
    };

    useEffect(() => {
      if (value) setRangeValue(value);
    }, [value]);

    useEffect(() => {
      if (defaultValue) setRangeValue([...defaultValue]);
    }, []);

    const getRangeElements = () => {
      const elements = [];
      if (rangeValue[0] !== min)
        elements.push(
          <span
            style={{
              right: `${(100 * (max - rangeValue[0])) / (max - min) - 7}%`,
            }}
          >
            {rangeValue[0]}
          </span>
        );
      if (rangeValue[1] !== max)
        elements.push(
          <span
            style={{
              right: `${(100 * (max - rangeValue[1])) / (max - min) - 7}%`,
            }}
          >
            {rangeValue[1]}
          </span>
        );
    };

    const getRangeInputs = () => {
      return (
        <RangeInputs>
          <Input
            type="number"
            placeholder={min}
            onChange={(value) => handleInputChange(value, "min")}
            value={rangeValue[0]}
            step={step}
            min={min}
            max={max}
          />
          <div>
            <span />
          </div>
          <Input
            type="number"
            placeholder={max}
            onChange={(value) => handleInputChange(value, "max")}
            value={rangeValue[1]}
            step={step}
            min={min}
            max={max}
          />
        </RangeInputs>
      );
    };

    return (
      <Container marginBottom={marginBottom} marginTop={marginTop}>
        {showInputsMinMax ? getRangeInputs() : ""}
        <SliderContainer>
          <Slider
            {...rest}
            min={min}
            max={max}
            ref={ref}
            value={rangeValue}
            step={step}
            defaultValue={defaultValue}
            onChange={handleChange}
          />
          {showInputsMinMax ? "" : getRangeElements()}
        </SliderContainer>
      </Container>
    );
  }
);

export default RangeSlider;
