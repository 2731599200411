import styled, { css } from "styled-components";

export const Table = styled.table`
  background-color: transparent;

  width: 100%;
  max-width: 100%;
  margin: 0px 0px 15px 0px;
`;

export const Thead = styled.thead`
  background-color: white;

  ${(props) =>
    props.stickyHeader &&
    css`
      position: sticky;
      top: 0;
      z-index: 3;
    `}
`;
