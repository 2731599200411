import React from "react";
import { useLocation } from "react-router-dom";
import { isEqual } from "lodash";
import {
  mixpanelTrack,
  mixpanelIdentify,
} from "../../../../../../../../services/mixpanel-tracking";
import RangeSlider from "../../../../../../../../components/basic-inputs/range-slider-input ";

function Numerical({ filter, draftFilterList, setDraftFilterList }) {
  const [min, max] = filter.options;
  const marks = {};
  marks[min] = min;
  marks[max] = max;
  const step = filter.precision;
  const location = useLocation();

  const handleFilterChange = (attributeKey, value) => {
    const newFilterList = { ...draftFilterList };
    newFilterList[attributeKey] = value;
    if (isEqual([min, max], value)) delete newFilterList[attributeKey];

    mixpanelIdentify(localStorage.email);
    mixpanelTrack("Filter Change", {
      attributeFilter: attributeKey,
      valeu: newFilterList[attributeKey],
      urlPathname: location.pathname,
    });

    setDraftFilterList(newFilterList);
  };

  return (
    <RangeSlider
      min={min}
      max={max}
      marks={marks}
      defaultValue={
        draftFilterList[filter.key]
          ? draftFilterList[filter.key]
          : filter.defaultValue
      }
      onChange={handleFilterChange}
      name={filter.key}
      step={step}
    />
  );
}

export default Numerical;
