import React from "react";

import { Container, AttributesLabels } from "./styles";

function GuidelineDocumentsOverview({ document }) {
  return (
    <Container>
      <div className="row">
        <div className="col-sm-6 col-xs-12">
          <AttributesLabels>
            <label>Disease State</label>
            <span>{document.module}</span>
          </AttributesLabels>
        </div>
        <div className="col-sm-6 col-xs-12">
          <AttributesLabels>
            <label>Publication Date</label>
            <span>{document.publication_date}</span>
          </AttributesLabels>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <AttributesLabels>
            <label>Guideline Source</label>
            <span>{document.source}</span>
          </AttributesLabels>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <AttributesLabels>
            <label>Guideline Title</label>
            <span>{document.title}</span>
          </AttributesLabels>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <AttributesLabels>
            <label>Guideline Subtitle</label>
            <span>{document.subtitle ? document.subtitle : "-"}</span>
          </AttributesLabels>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <AttributesLabels>
            <label>Download Url</label>
            <span>{document.download_url ? document.download_url : "-"}</span>
          </AttributesLabels>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-xs-12">
          <AttributesLabels>
            <label>DOI Number</label>
            <span>{document.doi_number ? document.doi_number : "-"}</span>
          </AttributesLabels>
        </div>
        <div className="col-sm-6 col-xs-12">
          <AttributesLabels>
            <label>ISSN Number</label>
            <span>{document.issn_number ? document.issn_number : "-"}</span>
          </AttributesLabels>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <AttributesLabels>
            <label>Authors</label>
            <span>{document.authors}</span>
          </AttributesLabels>
        </div>
      </div>
    </Container>
  );
}

export default GuidelineDocumentsOverview;
