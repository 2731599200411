import React, { useState, useMemo, forwardRef, useEffect } from "react";
import { Select, Creatable } from "./styles";
import { components } from "react-select";
import { MdKeyboardArrowDown } from "react-icons/md";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <MdKeyboardArrowDown color="#26344D" size={16} />
    </components.DropdownIndicator>
  );
};

const ReactSelect = forwardRef(
  (
    {
      onChange,
      className,
      invalid,
      options = [],
      creatable = false,
      disabled,
      marginBottom = 10,
      marginTop = 0,
      ...rest
    },
    ref
  ) => {
    function handleChange(SelectedValue) {
      if (onChange) onChange(SelectedValue);
    }

    const select = creatable ? (
      <Creatable
        ref={ref}
        components={{ DropdownIndicator }}
        invalid={invalid}
        className={className}
        onChange={handleChange}
        options={options}
        isDisabled={disabled}
        marginBottom={marginBottom}
        marginTop={marginTop}
        {...rest}
      />
    ) : (
      <Select
        ref={ref}
        components={{ DropdownIndicator }}
        invalid={invalid}
        className={className}
        onChange={handleChange}
        options={options}
        isDisabled={disabled}
        marginBottom={marginBottom}
        marginTop={marginTop}
        isSearchable={false}
        classNamePrefix="clint-select"
        {...rest}
      />
    );

    return select;
  }
);
export default ReactSelect;
