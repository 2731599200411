import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  grid-area: aside;
  width: ${(props) =>
    !props.collapsible ? "16vw" : props.open ? "16vw" : "12px"};
  padding-right: ${(props) => (!props.collapsible ? "0px" : "12px")};
  margin-right: ${(props) => (!props.collapsible ? "0px" : "-12px")};
  position: relative;
  /* overflow-y: hidden; */
  min-width: ${(props) => (!props.open ? "0px" : "253px")};
  // transition: 0.4s width;
  /* max-height: calc(100vh - 40px); */
  overflow: auto;
`;

export const Content = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  height: 100%;
  padding: 0px;
  border-right: 1px solid #cccccc;
  overflow: auto;
`;

export const OpenToogle = styled.div`
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 1rem;
  width: 12px;
  height: 42px;
  z-index: 1;
  border-radius: 0 3px 3px 0;
  cursor: pointer;

  box-shadow: 0px 2px 2px 0px rgba(87, 147, 219, 0.2);
  // border: 1px solid #CCCCCC;
  border-left: 0;

  background: #fff;
  vertical-align: middle;

  display: flex;
  align-items: center;
`;
