import React from "react";
import { Link } from "react-router-dom";

import { usePatientAttribute } from "../../../../hooks/usePatientAttributes";

import LoadingHOC from "../../../../components/loadingHoc";
import Button from "../../../../components/button";
import AppPageTitle from "../../../../components/app-page-title";
import PatientAttributeTable from "../../c-patient-attributes-table";

import { Container } from "./styles";

function PatientAttributeList() {
  const { attributes } = usePatientAttribute();

  return (
    <>
      <AppPageTitle
        title="Patient Attributes"
        actions={
          <Button variant="confirm">
            <Link to="/content-manager/patient-attributes/add">
              New Patient Attribute
            </Link>
          </Button>
        }
      />
      <Container>
        <PatientAttributeTable attributes={attributes} />
      </Container>
    </>
  );
}

export default LoadingHOC()(PatientAttributeList);
