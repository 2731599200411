import { isNumber } from "lodash";
import React, { useMemo } from "react";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";

import { Small } from "../../components/typography";

import { Container, Pages, Page } from "./styles";

function PageControl({
  currentPage = 1,
  onCurrentPageChange,
  numberOfItensTotal,
  numberOfItensPerPage,
  itemType = "itens",
}) {
  const totalPages = useMemo(() => {
    if (numberOfItensTotal && numberOfItensPerPage)
      return numberOfItensTotal / numberOfItensPerPage;
    return 0;
  }, [numberOfItensTotal, numberOfItensPerPage]);

  const getPageNumbers = () => {
    let pages = [];

    // Always show the first page
    pages.push(1);

    // If there are more than 5 pages, add ellipsis
    if (totalPages > 5) {
      // If current page is not in the first two pages, add ellipsis
      if (currentPage > 2) {
        pages.push("...");
      }

      // Calculate the range of pages to show around the current page
      let start = Math.max(currentPage - 2, 2);
      let end = Math.min(currentPage + 2, totalPages - 1);

      // Add the range of pages
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      // If current page is not in the last two pages, add ellipsis
      if (currentPage < totalPages - 1) {
        pages.push("...");
      }
    } else {
      // Add all pages if there are 5 or fewer pages
      for (let i = 2; i <= totalPages - 1; i++) {
        pages.push(i);
      }
    }

    // Always show the last page
    pages.push(totalPages);

    return pages.map((page) =>
      isNumber(page) ? (
        <Page onClick={() => onCurrentPageChange(page)}>
          <Small fontWeight={page == currentPage ? 700 : 400}>{page}</Small>
        </Page>
      ) : (
        <div>
          <Small>{page}</Small>
        </div>
      )
    );
  };

  return (
    <Container>
      <Small>
        Showing {currentPage * numberOfItensPerPage - numberOfItensPerPage + 1}-
        {currentPage * numberOfItensPerPage} of {numberOfItensTotal}
        {` ${itemType}`}
      </Small>
      <Pages>{getPageNumbers()}</Pages>
      <Pages>
        <Page
          disabled={currentPage == 1}
          onClick={() => {
            if (currentPage > 1) onCurrentPageChange(currentPage - 1);
          }}
        >
          <MdOutlineKeyboardArrowLeft size={18} />
        </Page>
        <Page
          disabled={currentPage == totalPages}
          onClick={() => {
            if (currentPage < totalPages) onCurrentPageChange(currentPage + 1);
          }}
        >
          <MdOutlineKeyboardArrowRight size={18} />
        </Page>
      </Pages>
    </Container>
  );
}

export default PageControl;
