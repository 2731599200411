import React, { useEffect, useMemo } from "react";
import { BsExclamation, BsCheck } from "react-icons/bs";
import { XSmall } from "../typography";

import { Container, IconWrapper } from "./styles";

const warningColor = "#FFA775";
const successColor = "#0ADF6C";

function MessageAlert({ children, variant, timeout }) {
  const [showMessage, setShowMessage] = React.useState(true);

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        setShowMessage(false);
      }, timeout);
    }
  }, []);

  const icon = useMemo(() => {
    switch (variant) {
      case "warning":
        return <BsExclamation color={warningColor} fontSize={15} />;
      case "success":
        return <BsCheck color={successColor} fontSize={15} />;
      default:
        return "";
    }
  }, [variant]);

  const color = useMemo(() => {
    switch (variant) {
      case "warning":
        return warningColor;
      case "success":
        return successColor;
      default:
        return "";
    }
  }, [variant]);

  return showMessage ? (
    <Container color={color}>
      <IconWrapper color={color}>{icon}</IconWrapper>
      <XSmall color="--darkGray">{children}</XSmall>
    </Container>
  ) : (
    ""
  );
}

export default MessageAlert;
