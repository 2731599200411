import SessionService from "../../services/session-service.js";
import { api } from "../../services/api.js";

const Session = (state = {}, action) => {
  const defaultState = {
    isAuthenticated: undefined,
    isLoading: false,
    errorMessages: undefined,
    first_name: undefined,
    last_name: undefined,
    roles: undefined,
    email: undefined,
    access_token: undefined,
  };

  if (action.type == "USER_LOGIN") {
    const newState = state;
    newState.isLoading = true;
    newState.errorMessages = undefined;
    return newState;
  }

  if (action.type == "USER_LOGIN_RECEIVED") {
    let newState = state;
    const sessionData = action.data;
    SessionService.createSession(sessionData);
    newState = sessionData;
    newState.isAuthenticated = true;
    newState.isLoading = false;

    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${newState.access_token}`;

    return newState;
  }

  if (action.type == "USER_LOGIN_ERROR") {
    const newState = state;
    if (action.errorMessages) {
      newState.errorMessages = action.errorMessages;
    } else {
      newState.errorMessages =
        "Login failed. You have either entered an incorrect email or password.";
    }
    newState.isLoading = false;

    return newState;
  }

  if (action.type == "REFRESH_SESSION_STATE") {
    const newState = state;
    newState.isAuthenticated = true;
    newState.first_name = localStorage.firstName;
    newState.last_name = localStorage.lastName;
    newState.roles = localStorage.roles;
    newState.access_token = localStorage.accessToken;

    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${newState.access_token}`;

    return newState;
  }

  if (action.type == "USER_LOGOUT") {
    const newState = defaultState;
    newState.isAuthenticated = false;
    SessionService.destroySession();
    delete api.defaults.headers.common["Authorization"];

    return newState;
  }

  if (Object.keys(state) == 0) {
    state = defaultState;
  }

  return state;
};

export default Session;
