import React from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";

import LoadingHOC from "../../../../components/loadingHoc";

import GuidelineDocumentsForm from "../../c-guideline-documents-form";

import { Container, BackLink, Header } from "./styles";

function DocumentAdd() {
  return (
    <Container>
      <Header>
        <BackLink to="/content-manager/guideline-documents/">
          <RiArrowDropLeftLine size={18} />
          back to guideline documents
        </BackLink>
        <h3>Create New Guideline Document</h3>
      </Header>
      <GuidelineDocumentsForm />
    </Container>
  );
}

export default LoadingHOC()(DocumentAdd);
