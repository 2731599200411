import React, { useEffect, useRef } from "react";
import { MdDownload } from "react-icons/md";

import KebabMenuItem from "../kebab-menu-item";

// time to wait before releasing object URL
const REVOKE_DELAY = 150;

function DownloadCsvMenuItem({
  icon = <MdDownload size={14} color="26344D" />,
  label,
  filename,
  csvString,
}) {
  const anchorRef = useRef(null);

  const blob = new Blob([csvString], { type: "text/csv" });
  const downloadUrl = URL.createObjectURL(blob);

  // release reference to object URL on component unmount
  useEffect(() => {
    return () => {
      setTimeout(() => {
        URL.revokeObjectURL(downloadUrl);
      }, REVOKE_DELAY);
    };
  }, []);

  /**
   * Triggers file download on all elements inside KebabMenuItem
   *
   * To allow all elements inside KebabMenuItem to trigger the file download,
   * a click event listener is registered on the capture phase.
   * If the target element is the anchor element, the default event behavior is
   * allowed. This will result in a file download.
   * If the target element is any other elements, the current event default
   * behavior is prevent and its propagation stopped. Afterwards, a new click
   * event is triggered using the anchor element as target. This enabled all
   * elements to trigger the file download while preventing the triggering more
   * than once.
   *
   * @param {SyntheticEvent} event
   * @returns
   */
  function onClickCaptureHandler(event) {
    if (event.target.tagName === "A") {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    anchorRef.current.click();
  }

  return (
    <KebabMenuItem onClickCapture={onClickCaptureHandler}>
      {icon}
      <a ref={anchorRef} href={downloadUrl} download={filename}>
        <span>{label}</span>
      </a>
    </KebabMenuItem>
  );
}

export default DownloadCsvMenuItem;
