import React, { useMemo, Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { find } from "lodash";

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { RiArrowDropLeftLine } from "react-icons/ri";

import MedicationDrugOverview from "../../c-medication-drug-overview";
import MedicationForm from "../../c-medication-drug-form";
import GuidelineHelper from "../../../../helpers/guideline-helper";
import GuidelineRulesTable from "../../../content-manager/c-guidelines-rules-table";
import Button from "../../../../components/button";
import Tabs from "../../../../components/tabs";
import Tab from "../../../../components/tabs/tab";
import LoadingHOC from "../../../../components/loadingHoc";

import { useGuidelineRule } from "../../../../hooks/useGuidelineRules";
import { useMedicationDrugs } from "../../../../hooks/useMedicationDrugs";

import { Container, Header, BackLink, HeaderRow } from "./styles";

function MedicationView() {
  const { medicationId } = useParams();
  const [isEditing, setIsEditing] = useState(false);

  const { medications, DeleteMedication } = useMedicationDrugs();
  const { guidelines } = useGuidelineRule();

  const history = useHistory();

  const medication = useMemo(() => {
    const medication = find(medications, ["id", parseInt(medicationId)]);
    if (medication) {
      return medication;
    }

    history.push("/medication-manager/medications/");
    return {};
  }, [medicationId, medications]);

  const guidelinesAttachedToMedication = useMemo(() => {
    return GuidelineHelper.getListOfRulesWithAttribute(
      medicationId,
      guidelines
    );
  }, [medicationId, guidelines]);

  const cancelEditingForm = () => {
    setIsEditing(false);
  };

  return (
    <Container>
      <Header>
        <BackLink to="/medication-manager/medications/">
          <RiArrowDropLeftLine size={18} />
          back to medications
        </BackLink>
        <HeaderRow>
          <div>
            <h3>{medication.human_readable_name}</h3>
            <span>{medication.name}</span>
          </div>
          {isEditing ? (
            ""
          ) : (
            <Button
              variant="outline"
              type="button"
              leftIcon={<FaEdit />}
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
          )}
        </HeaderRow>
      </Header>
      {isEditing ? (
        <MedicationForm
          cancelFormAction={cancelEditingForm}
          medication={medication}
          usageNumber={guidelinesAttachedToMedication.length}
        />
      ) : (
        <Tabs>
          <Tab label="Overview">
            <MedicationDrugOverview medication={medication} />
          </Tab>
          {guidelinesAttachedToMedication.length == 0 ? null : (
            <Tab
              label={`Guidelines (${guidelinesAttachedToMedication.length})`}
            >
              <GuidelineRulesTable
                isFiltersOn={false}
                guidelines={guidelinesAttachedToMedication}
              />
            </Tab>
          )}
        </Tabs>
      )}
    </Container>
  );
}

export default LoadingHOC()(MedicationView);
