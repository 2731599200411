import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;

  a {
    color: var(--secundary);
    text-decoration: none;
  }
`;
