import React, { useState, useRef } from "react";
import { Heading3 } from "../typography";
import { Container, Header, ActionsContainer, TitleContainer } from "./styles";

export default function Panel({ children, title, actions }) {
  return (
    <Container>
      {title || actions ? (
        <Header>
          <TitleContainer>
            {title ? <Heading3>{title}</Heading3> : ""}
            {actions ? <ActionsContainer>{actions}</ActionsContainer> : ""}
          </TitleContainer>
        </Header>
      ) : (
        ""
      )}
      {children}
    </Container>
  );
}
