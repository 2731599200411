import React from "react";
import ReactDOM from "react-dom";
import * as d3 from "d3";
import { event as currentEvent } from "d3";
import { cloneDeep, find, unionBy, uniqBy } from "lodash";
import DashboardHelper from "../../../../../helpers/dashboard-helper";
import { Small } from "../../../../typography";

class D3BarGapPlotLayer extends React.Component {
  constructor(props) {
    super(props);

    this.gWrapperRef = null;
    this.setGWrapperRef = (e) => {
      this.gWrapperRef = e;
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Ensures that the component doesn't get re-rendered without reason

    // Currently, updates every time the width of height changes
    // TODO: add check for data as well
    if (
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width ||
      this.props.data !== nextProps.data
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    if (this.props.data && this.props.height !== 0 && this.props.width !== 0) {
      this.renderChartD3Wrapper();
    }
  }

  componentDidUpdate() {
    d3.select(this.gWrapperRef).select("g").remove();
    if (this.props.data) {
      this.renderChartD3Wrapper();
    }
  }

  handleMouseOver(e) {
    /*
    Sets the style of the hovered bar and calls the parent function responsible for showing the
    tooltip. Once we're using the Redux store, this should probably be handled in the layout state.

    D3 mouse events are, by default, bound to the element where the action occurs (i.e, `this`
    refers to the svg element that triggered the event). To keep the React Class context for the
    `this` keyword, we bind the event handler to the `this` of the class, and to still have acess
    to the triggered mouse event, we use the D3 `currentEvent` (imported in the beginning of this
    file).
    */
    if (this.props.config.showTooltip) {
      d3.select(currentEvent.target).style("fill-opacity", "1");
      const d3Data = e;
      const category = currentEvent.target.getAttribute("data-category");

      const xLabel = this.props.meta.axesTitle.x;
      const yLabel = category || this.props.meta.axesTitle.y;

      const content = (
        <table>
          <tbody>
            <tr key="key">
              <td key={xLabel}>
                <Small>{`${xLabel}: `}</Small>
              </td>
              <td key={d3Data.key}>
                <Small>
                  <b>
                    {d3Data.key}
                    {d3Data.key_range ? ` - ${d3Data.key_range}` : ""}
                  </b>
                </Small>
              </td>
            </tr>
            <tr key="value">
              <td key={yLabel}>
                <Small>{`${yLabel}: `}</Small>
              </td>
              <td key={d3Data.value}>
                <Small>
                  <b>
                    {this.props.normalizedData
                      ? `${d3Data.value.toFixed(2)}%`
                      : d3Data.value}
                  </b>
                </Small>
              </td>
            </tr>
          </tbody>
        </table>
      );

      this.props.mouseOverFunc(content);
    }
  }

  handleMouseOut() {
    /*
    Re-sets the style of the bar and calls the parent function responsible for hiding the tooltip.
    Once we're using the Redux store, this should probably be handled in the layout state.
    */
    if (this.props.config.showTooltip) {
      d3.select(currentEvent.target).style("fill-opacity", "0.7");
      this.props.mouseOutFunc();
    }
  }

  renderChartD3Wrapper() {
    const NUM_SEGMENTS = 2;
    const MIN_BAR_SEGMENT_WIDTH = 45;
    const MIN_BAR_WIDTH = MIN_BAR_SEGMENT_WIDTH * NUM_SEGMENTS;
    const TEXT_LEFT_PADDING = 4;
    const MAX_PLOT_BARS_NUMBER = 5;
    const { hideLegends } = this.props.config;
    const { hideVerticalAxis } = this.props.config;
    const { hideHorizontalAxis } = this.props.config;

    const { width } = this.props;
    const { height } = this.props;
    const barPaddingRatio = this.props.config.spacing;
    const { colorPalette } = this.props.config;
    const { labelColorPalette } = this.props.config;
    let data = cloneDeep(this.props.data);

    data = data.map((d) => {
      d.values = d.values.slice(0, MAX_PLOT_BARS_NUMBER);
      return d;
    });

    data = data.map((d, index) => {
      d.values.map((item) => {
        if (index == 0) {
          item.value0 = 0;
        } else {
          item.value0 = find(data[index - 1].values, function (o) {
            return o.key == item.key;
          }).value;
        }
      });
      return d;
    });

    let Range;
    let Domain;

    Range = [
      d3.min(data, function (d) {
        return d3.min(d.values, function (b) {
          return b.value + b.value0;
        });
      }),
      d3.max(data, function (d) {
        return d3.max(d.values, function (b) {
          return b.value + b.value0;
        });
      }),
    ];

    data.map((d) => {
      Domain = unionBy(
        Domain,
        d.values.map((b) => {
          return b.key;
        })
      );
    });
    Domain = uniqBy(Domain);

    // The `margin` prop defines the "padding" inside the svg container. For some categorical
    // charts, we want to rotate the labels. This requires more space at the bottom
    const margin = cloneDeep(this.props.marginDefs);
    const axisMargin = cloneDeep(this.props.axisMarginDefs);
    // if (rotateXAxisLabels) margin.bottom += margin.bottomPadding;
    const horizontalMargin = axisMargin.horizontal;
    const verticalMargin = axisMargin.vertical;
    const legendSpace =
      !hideLegends && data.length >= 2 ? width * margin.withLegend.vertical : 0;

    const innerWidth =
      width - margin.left - margin.right - horizontalMargin - legendSpace;
    const innerHeight = height - margin.top - margin.bottom - verticalMargin;

    // D3 selects the parent group tag using the React ref and attach nested groups to it.
    // When the component is updated, children of this node are removed and re-added.
    const parentG = d3.select(this.gWrapperRef);

    const g = parentG
      .append("g")
      .attr("transform", `translate(${margin.right},${margin.top})`);

    // Define scales. The xScale's domain is the list of "categories", and the yScale's domain
    // goes from 0 to the max value.
    const yScale = d3.scale
      .ordinal()
      .domain(Domain)
      .rangeBands([0, innerHeight], barPaddingRatio);

    const xScale = d3.scale
      .linear()
      .domain([0, Range[1]])
      .range([0, innerWidth]);

    const rowTotal = [];
    data.map((bars) => {
      bars.values.map((value) => {
        const row = rowTotal.find((t) => t.key == value.key);
        if (row) {
          row.value += value.value;
        } else {
          rowTotal.push({ key: value.key, value: value.value });
        }
      });
    });

    data.map((bars, index) => {
      const bar = g.selectAll("bar").data(bars.values).enter();
      bar
        .append("rect")
        .attr("data-category", bars.name)
        .on("mouseover", this.handleMouseOver.bind(this))
        .on("mouseout", this.handleMouseOut.bind(this))
        .attr("x", function (d) {
          return xScale(d.value0);
        })
        .attr("height", yScale.rangeBand())
        .attr("y", function (d) {
          return yScale(d.key);
        })
        .attr("width", function (d) {
          return xScale(d.value);
        })
        .style("fill", function (d) {
          if (colorPalette) {
            return colorPalette[index];
          }
          return "#1F61A2";
        })
        .style("fill-opacity", "0.7");
      bar
        .append("text")
        .attr("class", "text-population")
        .text((d) => {
          if (xScale(d.value) > MIN_BAR_SEGMENT_WIDTH)
            return DashboardHelper.roundUpNumber(d.value, 1);
        }) // DashboardHelper.roundUpNumber(d.value - d.not_gaps_count, 1)
        .attr("x", function (d, i) {
          if (xScale(d.value) > MIN_BAR_SEGMENT_WIDTH)
            return xScale(d.value0 + d.value) - TEXT_LEFT_PADDING;
        })
        .attr("y", function (d) {
          if (xScale(d.value) > MIN_BAR_SEGMENT_WIDTH)
            return yScale(d.key) + yScale.rangeBand() / 2 + 1;
        })
        .style("fill", function (d) {
          if (colorPalette) {
            return labelColorPalette[index];
          }
          return "#fff";
        })
        .style("fill-opacity", "1");
    });

    // TREATMENT BAR
    const g_static = g.selectAll(".bar").data(data[0].values).enter();
    // TREATMENT BAR title - name of medication, treatment, etc
    g_static
      .append("text")
      .attr("class", "text-category-name")
      .text((d) => {
        return DashboardHelper.printAttributeHumanReadable(d.key);
      })
      .attr("x", 0)
      .attr("y", function (d) {
        return yScale(d.key) - 5;
      });
  }

  render() {
    return <g ref={this.setGWrapperRef} />;
  }
}

export default D3BarGapPlotLayer;
