import React, { useState } from "react";
import { BsInfoCircle, BsDiagram3 } from "react-icons/bs";
import { isEmpty } from "lodash";

import Modal from "../../../../../components/modal";
import Loader from "../../../../../components/loader";
import Collapsible from "../../../../../components/collapsible";
import { Heading4, Small, XSmall } from "../../../../../components/typography";

import {
  DetailsContainer,
  ConsortDiagramContainer,
  StatementsContainer,
  StatementsCaption,
  StatementsList,
  StatementComments,
  StepsDisclaimer,
  Tabs,
  Tab,
  LoadingWrapper,
  EmptyStateWrapper,
} from "./styles";

import ConsortDiagram from "./consort-diagram";
import CohortSelect from "./cohort-select";

import { useIdentifyCohortConfigs } from "../../../../../hooks/useIdentifyCohortConfig";

const DETAILS_KEYS = ["trial", "data_source", "data_date_range"];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function Statement({ statement }) {
  return (
    <li>
      <Small>{statement.name}</Small>
      {statement.children && (
        <StatementsList>
          {statement.children.map((child, idx) => (
            <Statement statement={child} key={idx} />
          ))}
        </StatementsList>
      )}
    </li>
  );
}

function CohortDetails({ consortDiagram }) {
  if (consortDiagram === null)
    return (
      <LoadingWrapper>
        <Loader />
      </LoadingWrapper>
    );

  const [activeTab, setActiveTab] = useState("details");

  if (isEmpty(consortDiagram)) {
    return (
      <>
        <Tabs>
          <Tab active={activeTab === "details"}>
            <BsInfoCircle />
            <XSmall
              fontWeight={700}
              textTransform="uppercase"
              color={activeTab === "details" ? "--primary" : "#cccccc"}
            >
              Details
            </XSmall>
          </Tab>
        </Tabs>

        <EmptyStateWrapper>
          <Small color="#666666">
            Cohort details will be updated soon. We apologize for the
            inconvenience.
          </Small>
        </EmptyStateWrapper>
      </>
    );
  }

  const { steps, steps_disclaimer } = consortDiagram;

  const details = new Map();
  if ("details" in consortDiagram) {
    for (const detail of consortDiagram.details) {
      details.set(detail.name, detail.value);
    }
  } else {
    for (const key of DETAILS_KEYS) {
      if (key in consortDiagram) {
        details.set(capitalizeFirstLetter(key), consortDiagram[key]);
      }
    }
  }

  let statements;
  if ("statements" in consortDiagram) {
    statements = consortDiagram.statements;
  } else {
    statements = [
      {
        children: consortDiagram.disclaimers.map((disclaimer) => ({
          name: disclaimer,
        })),
      },
    ];
  }
  return (
    <>
      <Tabs>
        <Tab
          active={activeTab === "details"}
          onClick={() => setActiveTab("details")}
        >
          <BsInfoCircle />
          <XSmall
            fontWeight={700}
            textTransform="uppercase"
            color={activeTab === "details" ? "--primary" : "#cccccc"}
          >
            Details
          </XSmall>
        </Tab>
        {steps ? (
          <Tab
            active={activeTab === "consortDiagram"}
            onClick={() => setActiveTab("consortDiagram")}
          >
            <BsDiagram3 />
            <XSmall
              fontWeight={700}
              textTransform="uppercase"
              color={activeTab === "consortDiagram" ? "--primary" : "#cccccc"}
            >
              Consort Diagram
            </XSmall>
          </Tab>
        ) : (
          ""
        )}
      </Tabs>

      <div>
        {activeTab === "details" ? (
          <>
            <DetailsContainer>
              {Array.from(details.entries()).map(([key, value]) => {
                return (
                  <div key={key}>
                    <Small color="#666666" marginBottom={3}>
                      {key
                        .split("_")
                        .map((s) => capitalizeFirstLetter(s))
                        .join(" ")}
                    </Small>
                    <Heading4 color="--primary">{value}</Heading4>
                  </div>
                );
              })}
            </DetailsContainer>
            {statements &&
              statements.map((statement, idx) => {
                const childrenStatements =
                  statement.children &&
                  statement.children.map((child, idx) => (
                    <Statement statement={child} key={idx} />
                  ));

                // wrap statements in collapsible if there's a statement name set
                if (statement.name) {
                  return (
                    <Collapsible
                      title={<Heading4>{statement.name}</Heading4>}
                      border="1px solid #e6e6e6"
                      padding="10px"
                      key={idx}
                    >
                      {statement.caption && (
                        <StatementsCaption>
                          <Small>{statement.caption}</Small>
                        </StatementsCaption>
                      )}
                      <StatementsList paddingLeft="16px">
                        {childrenStatements}
                      </StatementsList>
                      {statement.comments && (
                        <StatementComments>
                          {statement.comments.map((comment, idx) => (
                            <p key={idx}>
                              <Small>{comment}</Small>
                            </p>
                          ))}
                        </StatementComments>
                      )}
                    </Collapsible>
                  );
                }

                return (
                  <StatementsContainer key={idx}>
                    <div>
                      <Small color="#666666" marginBottom={7}>
                        Analysis details
                      </Small>
                    </div>
                    <ul>{childrenStatements}</ul>
                  </StatementsContainer>
                );
              })}
          </>
        ) : (
          ""
        )}
        {activeTab === "consortDiagram" ? (
          <ConsortDiagramContainer>
            <ConsortDiagram steps={steps} />
            {steps_disclaimer && (
              <StepsDisclaimer>{steps_disclaimer}</StepsDisclaimer>
            )}
          </ConsortDiagramContainer>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

function ConsortDiagramModal({ toggleModal, isShowingModal }) {
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { consortDiagram } = cohortConfigs;

  return (
    <div>
      <Modal title="Cohort" isShowing={isShowingModal} hide={toggleModal}>
        <CohortSelect />
        <CohortDetails consortDiagram={consortDiagram} />
      </Modal>
    </div>
  );
}

export default ConsortDiagramModal;
