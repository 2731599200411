import styled, { css } from "styled-components";

export const CollapsiblesContainer = styled.div`
  padding-top: 16px;
`;

export const PanelTab = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 6px 2px 6px;
  cursor: pointer;
  color: ${(props) => (props.selected ? "#26344d" : "#b0b0b0")};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;

  ${(props) =>
    props.selected &&
    css`
      border-bottom: 2px #26344d solid;
    `}
`;

export const TabContainer = styled.div`
  display: flex;
  gap: 20px;
  padding-top: 15px;
  margin-bottom: -15px;
`;

export const ChartContainer = styled.div`
  display: flex;
  max-height: 300px;
  > .chart-wrapper {
    border: 1px solid #c1c8d4;
    border-right: 0;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c1c8d4;
`;
export const LegendWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #c1c8d4;
  padding: 10px;

  &:last-child {
    border-bottom: 0;
  }
`;

export const LegendHeader = styled.div`
  text-align: center;
  border-bottom: 1px solid var(--secundary);
  background-color: #c1c8d4;
`;

export const LoaderWrapper = styled.div`
  height: 300px;
`;
