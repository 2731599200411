var config = {
  debug_mode: true,
  api_address: process.env.API_ADDRESS || "http://localhost:8000/api/",
  mixpanel_token: process.env.MIXPANEL_TOKEN,
  app_version: "2.1.3",
};

if (config.debug_mode) {
  if (process.env.API_ADDRESS) {
    console.warn(
      "You are running the application with this API Address: " +
        process.env.API_ADDRESS
    );
  } else {
    console.warn(
      "You are running the application with this API Address: http://localhost:6543/api/"
    );
  }
} else {
  if (
    !process.env.API_ADDRESS ||
    process.env.API_ADDRESS == "http://localhost:6543/api/"
  ) {
    console.warn("You are running the application with your local API Address");
  }
}

export default config;
