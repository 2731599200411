import React, { Fragment, useRef, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Scope } from "@unform/core";
import { Form } from "@unform/web";

import { MdDelete } from "react-icons/md";

import { CodeGroup, ActionButtons } from "./styles";

import Input from "../../../components/unform-inputs/input";
import Select from "../../../components/unform-inputs/select";
import Button from "../../../components/button";
import GuidelineHelper from "../../../helpers/guideline-helper";

import { useMedicationIntents } from "../../../hooks/useMedicationIntents";
import { useMedicationMechanisms } from "../../../hooks/useMedicationMechanisms";

export default function MedicationMechanismForm({
  medicationMechanism,
  cancelFormAction,
  usageNumber = 0,
}) {
  const history = useHistory();
  const formRef = useRef(null);
  const [defaultForm, setDefaultForm] = useState({});

  const { medicationIntents } = useMedicationIntents();
  const {
    AddMedicationMechanism,
    EditMedicationMechanism,
    DeleteMedicationMechanism,
  } = useMedicationMechanisms();
  const [errorMessage, setErrorMessage] = useState(null);

  const intentsOptions = useMemo(() => {
    const medicationIntentOptions = [];
    medicationIntents.map((intent, index) => {
      medicationIntentOptions.push({
        value: intent.id,
        label: intent.human_readable_name,
      });
    });
    return medicationIntentOptions;
  }, [medicationIntents]);

  useEffect(() => {
    async function defaultValues() {
      if (medicationMechanism) {
        await formRef.current.setData(medicationMechanism);
        setDefaultForm(formRef.current.getData());
      }
    }
    defaultValues();
  }, [medicationMechanism]);

  async function submit(data) {
    setErrorMessage(null);
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required("This is a required field"),
        human_readable_name: Yup.string().required("This is a required field"),
        parent: Yup.number()
          .required("This is a required field")
          .positive()
          .integer()
          .typeError("This is a required field"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      data.id = medicationMechanism ? medicationMechanism.id : null;
      data = GuidelineHelper.removeEmptyStrings(data);

      if (medicationMechanism) {
        EditMedicationMechanism(data, data.id, successSave, errorSave);
      } else {
        AddMedicationMechanism(data, successSave, errorSave);
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const successSave = function () {
    cancelForm();
  };

  const errorSave = function (errorMessages) {
    setErrorMessage(errorMessages);
  };

  const deleteMedicationMechanism = () => {
    const response = confirm(
      `Do you really want to delete ${medicationMechanism.human_readable_name} ?`
    );
    if (response == true) {
      DeleteMedicationMechanism(medicationMechanism.id, deleteSuccess);
    }
  };

  const deleteSuccess = () => {
    history.push("/medication-manager/medication-mechanisms/");
  };

  const cancelForm = () => {
    formRef.current.setData(defaultForm);
    if (cancelFormAction) {
      cancelFormAction();
    } else {
      history.goBack();
    }
  };

  const handleNameChange = function () {
    // funcion to handle specifically changes on the `name` field, to update the `variable` field based on the `name`
    // it is called only when the Name field is changed, which means it's possible to change the Variable field afterwards
    const nameString = formRef.current.getFieldValue("human_readable_name");
    let newAttributeString = nameString.replace(
      new RegExp(/[^a-zA-Z0-9]+/gi, "g"),
      "_"
    ); // symple regex to replace non-alphanumeric characters with underscores;
    newAttributeString = newAttributeString.toLowerCase();
    formRef.current.setFieldValue("name", newAttributeString);
  };

  return (
    <>
      {errorMessage ? (
        <div className="patient-status__alert-message alert-message__warning">
          <p className="alert-message__paragraph u-color-denim-40">
            <b>Alert:</b> {errorMessage}
          </p>
        </div>
      ) : (
        " "
      )}
      <Form ref={formRef} onSubmit={submit} className="form-box">
        <div className="row">
          <div className="col-sm-6 col-xs-12">
            <Input
              name="human_readable_name"
              required
              label="Name"
              onChange={() => handleNameChange()}
            />
          </div>
          <div className="col-sm-6 col-xs-12">
            <Input name="name" required label="Variable" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Select
              name="parent"
              required
              options={intentsOptions}
              label="Medication Intent"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <CodeGroup>
              <label>Codes</label>
              <div>
                <Scope path="codes">
                  <Input name="SNOMED_CT" label="SNOMED CT" />
                  <Input name="RX_NORM" label="RX NORM" />
                  <Input name="CUSTOM_ACC" label="CUSTOM ACC" />
                </Scope>
              </div>
            </CodeGroup>
          </div>
        </div>

        <ActionButtons>
          <div>
            {medicationMechanism ? (
              <Button
                variant="clear"
                disabled={usageNumber > 0}
                leftIcon={<MdDelete />}
                type="button"
                onClick={() => deleteMedicationMechanism()}
              >
                Delete medication mechanism
              </Button>
            ) : (
              ""
            )}
          </div>
          <div>
            <Button
              variant="outline"
              type="button"
              onClick={() => cancelForm()}
            >
              Cancel
            </Button>
            <Button variant="confirm" type="submit">
              {medicationMechanism
                ? "Edit Medication Mechanism"
                : "Create Medication Mechanism"}
            </Button>
          </div>
        </ActionButtons>
      </Form>
    </>
  );
}
