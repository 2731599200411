import React, { forwardRef } from "react";

import { isEqual, isNil, isEmpty, cloneDeep } from "lodash";
import { withResizeDetector } from "react-resize-detector";

import D3BarPlotLayer from "./chart-type/bar";
import D3BarGroupPlotLayer from "./chart-type/bar-group";
import D3ScatterPlotLayer from "./chart-type/scatterplot";
import D3BarGapPlotLayer from "./chart-type/bar-gap";
import D3LinePlotLayer from "./chart-type/line";
import D3LineCIPlotLayer from "./chart-type/line-ci";

import Loader from "../../loader";
import D3LegendLayer from "./chart-element/legends";
import D3StatsLayer from "./chart-element/stats";
import AxesLayer from "./chart-element/axes";

const MARGIN_DEFS = {
  left: 5,
  top: 10,
  right: 10,
  bottom: 10,
  withLegend: { vertical: 0.2, horizontal: 35 },
};
const INCREASE_Y_MAX_VAlUE = 1.05;

const H_AXIS_MAX_HEIGHT = 30;
const H_AXIS_TICK_NOTES_LINE_HEIGHT = 12;
const H_AXIS_TICK_NOTES_MARGIN_TOP = 10;
const H_AXIS_TITLE_MAX_HEIGHT = 17;

class ChartWrapper extends React.Component {
  // START COMPONENT LIFECYCLE
  constructor(props) {
    super(props);

    this.state = {
      wrapperHeight: 0,
      wrapperWidth: 0,
      tooltip: {
        show: false,
        content: "",
        x: 0,
        y: 0,
      },
    };

    // Set React refs for chart and tooltip
    this.chartWrapperRef = null;
    this.chartTooltipRef = null;
    this.setChartWrapperRef = (e) => {
      this.chartWrapperRef = e;
    };
    this.setChartTooltipRef = (e) => {
      this.chartTooltipRef = e;
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps, this.props)) this.updateWrapperSize();
  }

  componentDidMount() {
    // Add listener and update size for the first time. This event listener is based on the browser
    // window. The size of the container can potentially be changed without a resize event (e.g, by
    // collapsing the filter left bar).
    // TODO: check size for other events.
    window.addEventListener("resize", this.updateWrapperSize.bind(this));
    this.updateWrapperSize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWrapperSize.bind(this));
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Ensures that the component doesn't get re-rendered without reason
    // Lodash `isEqual` might not be the most performing. TODO: find better approach
    if (isEqual(this.props, nextProps) && isEqual(this.state, nextState))
      return false;
    return true;
  }
  // END COMPONENT LIFECYCLE

  // START TOOLTIP Functions related to render anc control tooltips
  handleMouseOver(tooltipContent) {
    /*
      This handler receives the data from the hovered d3 element (e.g, a hovered bar).
      The data comes as a dictionary with {key: '', value: ''}
      */
    const newState = cloneDeep(this.state);
    newState.tooltip.show = true;
    newState.tooltip.content = tooltipContent;

    this.setState(newState); // Move to the Redux store!
  }

  handleMouseOut() {
    /*
      Move to the Redux store!
      */
    const newState = cloneDeep(this.state);
    newState.tooltip.show = false;
    newState.tooltip.content = "";

    this.setState(newState); // Move to the Redux store!
  }

  handleMouseMove(e) {
    /*
      Constantly called to update the position of the tooltip.
      Only updates when the tooltip is shown.
      */
    const Y_POS_OFFSET = 15;

    if (this.state.tooltip.show) {
      // Only calculate when tooltip is shown
      const newState = cloneDeep(this.state);
      const chartWrapperBounds = this.chartWrapperRef.getBoundingClientRect();
      const viewportWidth = document.documentElement.clientWidth;
      const tooltipBounds = this.chartTooltipRef.getBoundingClientRect();

      let x = e.clientX - chartWrapperBounds.left - tooltipBounds.width / 2;
      const y =
        e.clientY -
        chartWrapperBounds.top -
        tooltipBounds.height -
        Y_POS_OFFSET;

      // // Ensures the tooltip stays within the browser window
      if (e.clientX + tooltipBounds.width / 2 > chartWrapperBounds.right) {
        // Avoid overflow to the right.
        x =
          chartWrapperBounds.right -
          chartWrapperBounds.left -
          tooltipBounds.width;
      } else if (
        e.clientX - tooltipBounds.width / 2 <
        chartWrapperBounds.left
      ) {
        // Avoid overflow to the left.
        x = 0;
      }

      newState.tooltip.x = x;
      newState.tooltip.y = y;

      this.setState(newState); // Move to the Redux store!
    }
  }

  renderTooltipLayer() {
    /*
      Consider creating a component just for the tooltip
      */
    return (
      <div className="charts-tooltip-layer">
        {this.state.tooltip.show ? (
          <div
            className="charts-tooltip"
            ref={this.setChartTooltipRef}
            style={{ top: this.state.tooltip.y, left: this.state.tooltip.x }}
          >
            {this.state.tooltip.content}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
  // END TOOLTIP

  updateWrapperSize() {
    if (this.props.width && this.props.height) {
      this.setState({
        wrapperWidth: this.props.width,
        wrapperHeight: this.props.height,
      });
    }
  }

  getChartOption(AXIS_MARGIN_DEFS) {
    const chartType = this.props.chart.config.type;
    switch (chartType) {
      case "bar":
        return (
          <D3BarPlotLayer
            width={this.state.wrapperWidth}
            height={this.state.wrapperHeight}
            config={this.props.chart.config}
            meta={this.props.chart.meta}
            data={this.props.chart.data ? this.props.chart.data : []}
            mouseOverFunc={this.handleMouseOver.bind(this)}
            mouseOutFunc={this.handleMouseOut.bind(this)}
            normalizedData={this.props.normalized}
            marginDefs={MARGIN_DEFS}
            axisMarginDefs={AXIS_MARGIN_DEFS}
            increaseYMaxValue={INCREASE_Y_MAX_VAlUE}
            chart={this.props.chart}
          />
        );
        break;
      case "bar-group":
        return (
          <D3BarGroupPlotLayer
            width={this.state.wrapperWidth}
            height={this.state.wrapperHeight}
            config={this.props.chart.config}
            meta={this.props.chart.meta}
            data={this.props.chart.data ? this.props.chart.data : []}
            mouseOverFunc={this.handleMouseOver.bind(this)}
            mouseOutFunc={this.handleMouseOut.bind(this)}
            normalizedData={this.props.normalized}
            marginDefs={MARGIN_DEFS}
            axisMarginDefs={AXIS_MARGIN_DEFS}
            tooltipContent={this.props.tooltipContent}
            increaseYMaxValue={INCREASE_Y_MAX_VAlUE}
            chart={this.props.chart}
          />
        );
        break;
      case "gap-bar":
        return (
          <D3BarGapPlotLayer
            width={this.state.wrapperWidth}
            height={this.state.wrapperHeight}
            meta={this.props.chart.meta}
            config={this.props.chart.config}
            mouseOverFunc={this.handleMouseOver.bind(this)}
            mouseOutFunc={this.handleMouseOut.bind(this)}
            data={this.props.chart.data ? this.props.chart.data : []}
            marginDefs={MARGIN_DEFS}
            axisMarginDefs={AXIS_MARGIN_DEFS}
            increaseYMaxValue={INCREASE_Y_MAX_VAlUE}
            chart={this.props.chart}
          />
        );
        break;
      case "scatterplot":
        return (
          <D3ScatterPlotLayer
            width={this.state.wrapperWidth}
            height={this.state.wrapperHeight}
            meta={this.props.chart.meta}
            config={this.props.chart.config}
            mouseOverFunc={this.handleMouseOver.bind(this)}
            mouseOutFunc={this.handleMouseOut.bind(this)}
            data={this.props.chart.data ? this.props.chart.data : []}
            marginDefs={MARGIN_DEFS}
            axisMarginDefs={AXIS_MARGIN_DEFS}
            chart={this.props.chart}
            increaseYMaxValue={INCREASE_Y_MAX_VAlUE}
          />
        );
        break;
      case "line":
        return (
          <D3LinePlotLayer
            width={this.state.wrapperWidth}
            height={this.state.wrapperHeight}
            meta={this.props.chart.meta}
            config={this.props.chart.config}
            mouseOverFunc={this.handleMouseOver.bind(this)}
            mouseOutFunc={this.handleMouseOut.bind(this)}
            data={this.props.chart.data ? this.props.chart.data : []}
            marginDefs={MARGIN_DEFS}
            axisMarginDefs={AXIS_MARGIN_DEFS}
            tooltipContent={this.props.tooltipContent}
            increaseYMaxValue={INCREASE_Y_MAX_VAlUE}
            chart={this.props.chart}
          />
        );
        break;
      case "line-ci":
        return (
          <D3LineCIPlotLayer
            width={this.state.wrapperWidth}
            height={this.state.wrapperHeight}
            meta={this.props.chart.meta}
            config={this.props.chart.config}
            mouseOverFunc={this.handleMouseOver.bind(this)}
            mouseOutFunc={this.handleMouseOut.bind(this)}
            data={this.props.chart.data ? this.props.chart.data : []}
            marginDefs={MARGIN_DEFS}
            axisMarginDefs={AXIS_MARGIN_DEFS}
            tooltipContent={this.props.tooltipContent}
            increaseYMaxValue={INCREASE_Y_MAX_VAlUE}
            chart={this.props.chart}
          />
        );
        break;
      default:
        console.error("Invalid chart type");
        break;
    }
  }

  plotChartElememts() {
    const chartData = this.props.chart.data;
    const chartConfig = this.props.chart.config;
    const chartMeta = this.props.chart.meta;
    const charttats = this.props.chart.stats;

    if (chartData && chartData[0].values.length > 0) {
      // Verify if data has any values to be displayed
      const AXIS_MARGIN_DEFS = {
        vertical: chartConfig.hideHorizontalAxis ? 0 : H_AXIS_MAX_HEIGHT,
        horizontal: chartConfig.hideVerticalAxis ? 0 : 45,
      };

      if (
        (chartConfig.flipAxes && !isNil(chartMeta.axesTitle.y)) ||
        (!chartConfig.flipAxes && !isNil(chartMeta.axesTitle.x))
      )
        AXIS_MARGIN_DEFS.vertical += H_AXIS_TITLE_MAX_HEIGHT;

      if (chartConfig.flipAxes) {
        if (chartConfig.yAxisTicksNotes)
          AXIS_MARGIN_DEFS.vertical +=
            chartConfig.yAxisTicksNotes[0].notes.length *
              H_AXIS_TICK_NOTES_LINE_HEIGHT +
            H_AXIS_TICK_NOTES_MARGIN_TOP +
            20;
      } else if (chartConfig.xAxisTicksNotes)
        AXIS_MARGIN_DEFS.vertical +=
          chartConfig.xAxisTicksNotes[0].notes.length *
            H_AXIS_TICK_NOTES_LINE_HEIGHT +
          H_AXIS_TICK_NOTES_MARGIN_TOP;
      return (
        <>
          {this.getChartOption(AXIS_MARGIN_DEFS)}
          {!chartConfig.hideAxes ? (
            <AxesLayer
              width={this.state.wrapperWidth}
              height={this.state.wrapperHeight}
              config={chartConfig}
              data={chartData}
              meta={chartMeta}
              mouseOverFunc={this.handleMouseOver.bind(this)}
              mouseOutFunc={this.handleMouseOut.bind(this)}
              normalizedData={this.props.normalized}
              marginDefs={MARGIN_DEFS}
              axisMarginDefs={AXIS_MARGIN_DEFS}
              increaseYMaxValue={INCREASE_Y_MAX_VAlUE}
            />
          ) : (
            ""
          )}
          {!chartConfig.hideLegends ? (
            <D3LegendLayer
              width={this.state.wrapperWidth}
              height={this.state.wrapperHeight}
              config={chartConfig}
              meta={chartMeta}
              data={chartData}
              marginDefs={MARGIN_DEFS}
              axisMarginDefs={AXIS_MARGIN_DEFS}
            />
          ) : (
            ""
          )}

          {chartConfig.showStats ? (
            <D3StatsLayer
              width={this.state.wrapperWidth}
              height={this.state.wrapperHeight}
              config={chartConfig}
              stats={charttats}
              marginDefs={MARGIN_DEFS}
              axisMarginDefs={AXIS_MARGIN_DEFS}
            />
          ) : (
            ""
          )}
        </>
      );
    }
  }

  renderNotEnoughData() {
    const chartData = this.props.chart.data;
    const noDataMessage = this.props.noDataMessage ? (
      this.props.noDataMessage
    ) : (
      <>
        <h3>NOT ENOUGH DATA</h3>
        <p>Please select a broader cohort.</p>
      </>
    );

    if (chartData && chartData[0].values.length === 0) {
      // Verify if data has any values to be displayed
      return (
        <div className="chart-no-data-message-container">
          <div className="chart-no-data-message">{noDataMessage}</div>
        </div>
      );
    }
  }

  render() {
    const { chart, visible } = this.props;
    const className =
      visible !== false ? "chart-wrapper" : "chart-wrapper-hidden";
    return (
      <div
        className={className}
        ref={this.setChartWrapperRef}
        onMouseMove={this.handleMouseMove.bind(this)}
      >
        {chart ? (
          <svg
            className="chart-d3-wrapper"
            width={this.state.wrapperWidth}
            height={this.state.wrapperHeight}
            ref={this.props.svgRef}
          >
            {this.plotChartElememts()}
          </svg>
        ) : (
          <div
            style={{
              height: "300px",
            }}
          >
            <Loader />
          </div>
        )}

        {!isEmpty(chart) ? this.renderTooltipLayer() : ""}
        {!isEmpty(chart) ? this.renderNotEnoughData() : ""}
      </div>
    );
  }
}

const ChartWrapperWithResizeDetector = withResizeDetector(ChartWrapper);

export default forwardRef((props, ref) => {
  return (
    <ChartWrapperWithResizeDetector
      svgRef={ref}
      {...props}
    ></ChartWrapperWithResizeDetector>
  );
});

export { ChartWrapper };
