import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 3;
  display: block;
  width: 100%;
`;

export const Select = styled.div`
  width: 100%;
  border-radius: 3px;
  background: ${(props) => (props.active ? "#F5F5F5" : "#FFFFFF")};
  height: 30px;
  cursor: pointer;
  border: solid 1px #cccccc;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: relative;

  display: flex;
  align-items: center;

  span {
    text-transform: capitalize;
    padding-left: 10px;
  }
`;

export const OptionsList = styled.div`
  background: #ffffff;
  border: solid 1px #cccccc;
  margin-top: 5px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  position: absolute;
  min-width: 100%;
  padding: 5px;

  a {
    min-height: 20px;
    color: #26344d;
    text-decoration: underline;
    margin-bottom: 5px;
    margin-left: 5px;
    display: block;
  }
`;

export const WrapOptions = styled.div`
  max-height: 150px;
  overflow-y: scroll;

  label {
    margin-bottom: 5px !important;
  }
`;

export const NoOptionsFound = styled.div`
  margin-left: 5px;
`;
