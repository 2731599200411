import { darken, lighten } from "polished";
import React, { useEffect, useMemo, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { isEmpty } from "lodash";

import Choropleth from "../../../../../../components/dashboard-choropleth-wrapper/choropleth";

import { useMapsTopography } from "../../../../../../hooks/useMapTopography";
import { useIdentifyCohortConfigs } from "../../../../../../hooks/useIdentifyCohortConfig";

import { Container } from "./styles";

import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../../../services/mixpanel-tracking";

import { getPopulationChoroplethData } from "../../../../../../services/insights-data-service";

export default function SiteGeomapDetails({
  sites,
  activeSite,
  setActiveSite,
  selectedCohort,
  setValidCharts,
  setErrorMsg,
}) {
  const { mapsTopography } = useMapsTopography();
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { cohortIndex } = cohortConfigs;

  const [map, setMap] = useState({});

  function getMapsConfig(mapName, data, color) {
    const config = {};
    config.title = "Population Map";
    config.name = mapName;
    config.layers = data;
    config.colorPalette = [lighten(0.25, color), color, darken(0.15, color)];
    return config;
  }

  const handleActiveSite = (site) => {
    setActiveSite(site.id);

    //Delete some attributes before sending it to mixpanel
    delete site.marker;
    delete site.onClick;
    mixpanelIdentify(localStorage.email);
    mixpanelTrack("Select Site - Active Site from Map", {
      site: site,
    });
  };

  const siteMarkers = useMemo(() => {
    if (sites) {
      const markers = sites.map((site) => {
        if (site.latitude && site.longitude) {
          const markerColor = site.id === activeSite ? "#26344D" : "#6AA3C2";
          const zIndex = site.id === activeSite ? 999 : 900;
          return {
            ...site,
            position: [site.latitude, site.longitude],
            type: "marker",
            onClick: (siteElement) => handleActiveSite(siteElement),
            marker: (
              <MdLocationPin
                size={25}
                color={markerColor}
                stroke="#4A7C97"
                strokeWidth={1}
                className="stroke-react-icon"
              />
            ),
            zIndexOffset: zIndex,
            config: { iconSize: [25, 25], iconAnchor: [12.5, 25] },
          };
        }

        return [];
      });

      return [
        {
          name: "Potential clinical trial sites",
          elementList: markers,
          checked: true,
        },
      ];
    }
    return [];
  }, [sites, activeSite]);

  useEffect(async () => {
    setMap({});

    if (cohortIndex?.index_name && !isEmpty(selectedCohort)) {
      const response = await getPopulationChoroplethData(
        cohortIndex.index_name,
        selectedCohort.query
      );

      if (response.data.valid) {
        const mapConfig = getMapsConfig(
          "population",
          response.data.data.result.population,
          selectedCohort.color
        );
        setMap(mapConfig);
      } else {
        setValidCharts(false);
        setErrorMsg(response.data.messages.join());
      }
    }
  }, [cohortIndex.index_name, selectedCohort?.query]);

  return (
    <Container>
      <Choropleth
        topography={mapsTopography}
        map={map}
        defaultLayer={1}
        overlayLayers={siteMarkers}
      />
    </Container>
  );
}
