import React from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";

import LoadingHOC from "../../../../components/loadingHoc";

import MedicationForm from "../../c-medication-drug-form";

import { Container, BackLink, Header } from "./styles";

function MedicationAdd() {
  return (
    <Container>
      <Header>
        <BackLink to="/medication-manager/medications">
          <RiArrowDropLeftLine size={18} />
          back to medications
        </BackLink>
        <h3>Create New Medication Drug</h3>
      </Header>
      <MedicationForm />
    </Container>
  );
}

export default LoadingHOC()(MedicationAdd);
