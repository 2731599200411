import styled from "styled-components";

export const Container = styled.div`
  width: 600px;
  min-width: 500px;
  margin: 30px auto 0;
  border-radius: 3px;
  border: 1px solid white;
`;

export const Header = styled.div`
  padding: 12px 24px;
  background-color: white;
  border-bottom: 1px solid #e6e6e6;
  color: #26344d;

  h3 {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  max-height: 80%;
  background-color: #ffffff;
  padding: 24px;
  overflow: auto;
`;

export const Footer = styled.div`
  padding: 10px;
  text-align: right;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const OutsideModal = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(38, 52, 77, 0.7);

  /* opacity: 0; */
  transition: opacity 0.4s;
`;
