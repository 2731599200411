import { includes, isEmpty, remove } from "lodash";
import React from "react";
import { useLocation } from "react-router-dom";
import {
  mixpanelTrack,
  mixpanelIdentify,
} from "../../../../../../../../services/mixpanel-tracking";
import Checkbox from "../../../../../../../../components/basic-inputs/checkbox";

import { CategoricalGrid } from "../styles";

function Categorical({ filter, draftFilterList, setDraftFilterList }) {
  const location = useLocation();

  const handleFilterChange = (attributeKey, value) => {
    const newFilterList = { ...draftFilterList };

    let newFilterArray;
    if (newFilterList[attributeKey]) {
      newFilterArray = [...newFilterList[attributeKey]];
      includes(newFilterArray, value)
        ? remove(newFilterArray, (n) => n === value)
        : newFilterArray.push(value);
    } else {
      newFilterArray = [value];
    }

    if (isEmpty(newFilterArray)) delete newFilterList[attributeKey];
    else newFilterList[attributeKey] = [...newFilterArray];

    mixpanelIdentify(localStorage.email);
    mixpanelTrack("Filter Change", {
      attributeFilter: attributeKey,
      valeu: newFilterList[attributeKey],
      urlPathname: location.pathname,
    });

    setDraftFilterList(newFilterList);
  };

  return (
    <CategoricalGrid>
      {filter.options.map((option) => {
        return (
          <Checkbox
            key={option}
            value={option}
            label={option.charAt(0).toUpperCase() + option.slice(1)}
            name={filter.key}
            checked={
              draftFilterList && filter.key in draftFilterList
                ? includes(draftFilterList[filter.key], option)
                : false
            }
            onChange={handleFilterChange}
          />
        );
      })}
    </CategoricalGrid>
  );
}

export default Categorical;
