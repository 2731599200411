import React from "react";
import PropTypes from "prop-types";

export default function auth({ children }) {
  return <div>{children}</div>;
}

auth.propTypes = {
  children: PropTypes.element.isRequired,
};
