import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  position: relative;
`;

export const CloseOpenIconContainer = styled.div`
  margin-left: 5px;
`;

export const IconContainer = styled.div`
  max-height: 18px;
  & + span {
    margin-left: 10px;
  }
  & img {
    max-height: 18px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--white);
  text-transform: capitalize;
`;

export const DropdownContainer = styled.div`
  background-color: #f2f2f2;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
  z-index: 9999;
  position: absolute;
  top: 40px;
  display: block;
  left: ${(props) => (props.align === "left" ? 0 : "unset")};
  right: ${(props) => (props.align === "left" ? "unset" : 0)};
  width: 100%;
  min-width: 150px;
  color: #404040;
`;

export const DropdownList = styled.div`
  display: flex;
  flex-direction: column;

  & > a,
  & > span {
    font-size: 0.8rem;

    margin-bottom: 2px;
    padding: 7px 10px 7px 15px;

    display: block;
    text-decoration: none;
    font-weight: 400;
  }

  & > a:hover,
  & > span:hover {
    background-color: #3d6287;
    color: #fff;
    cursor: pointer;
  }
`;
