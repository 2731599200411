import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Container, Content, OpenToogle } from "./styles";

function Aside({ children, startOpen = true, collapsible }) {
  const [isOpen, setIsOpen] = useState(startOpen);

  return (
    <Container open={isOpen} collapsible={collapsible}>
      <Content open={isOpen}>{children}</Content>
      {!collapsible ? (
        ""
      ) : (
        <OpenToogle onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <FaChevronLeft size={10} /> : <FaChevronRight size={10} />}
        </OpenToogle>
      )}
    </Container>
  );
}

export default Aside;
