import mixpanel from "mixpanel-browser";
import config from "../config.jsx";

const mixpanelToken = config.mixpanel_token;
mixpanel.init(mixpanelToken);

// Mixpanel methods
export const mixpanelTrack = (event_name, properties, callback) => {
  mixpanel.track(event_name, properties, callback);
};
export const mixpanelIdentify = (unique_id) => {
  mixpanel.identify(unique_id);
};
// const people_set = (prop,to,callback) => { mixpanel.people.set(prop,to,callback); }
// const people_set_once = (prop,to,callback) => { mixpanel.people.set_once(prop,to,callback); }
// const people_increment = (prop,by,callback) => { mixpanel.people.increment(prop,by,callback); }
// const time_event = (event_name) => { mixpanel.time_event(event_name); }

// const MixpanelTracking = store => dispatch => action => {

//   const id = localStorage.id;
//   const email = localStorage.email;
//   const first_name = localStorage.firstName;
//   const last_name = localStorage.lastName;
//   const role = localStorage.role;
//   const customUniqueId = role + '-' + id; // create a unique ID for Mixpanel using the user's Role and ID

//   dispatch(action);

//   // Events
//   switch (action.type) {
//     // START Sign In
//     case 'SUCCESSFULLY_SIGNED_IN':
//       identify(customUniqueId);
//       people_set_once({
//         '$created': new Date(),
//         '$first_name': first_name,
//         '$last_name': last_name,
//         '$email': email,
//         'Role': role
//       });
//       track('Sign in', {
//         'Screen': 'Sign in',
//         'Action Type': 'Sign in'
//       });
//       break
//     // END Sign In

//     // START Patient Selection
//     case 'SELECT_PATIENT_PROFILE':
//       identify(customUniqueId);
//       time_event('Select Patient - Duration'); // start timer for time spent in a patient screen
//       track('Select Patient', {
//         'Origin': action.origin,
//       });
//       break
//     case 'UNSELECT_PATIENT':
//       identify(customUniqueId);
//       track('Select Patient - Duration'); // stop timer for time spent in a patient screen
//       break
//     // END Patient Selection

//     // START Main Screens timers
//     // Here we track when the user changes the screen and how long them spent on each screen
//     // The Main screens are the high level ones (currently on the side menu)
//     case 'CHANGE_ROUTE':
//       identify(customUniqueId);
//       let selected = action.selectedRoute;
//       let previous = action.previous;
//       if(previous!=selected){
//         if (previous)
//           track(previous + ' Screen - Duration'); // stop timer for time spent in the previous screen
//         time_event(selected + ' Screen - Duration'); // start timer for the selected screen

//         track('Change Screen', {
//           'Menu Item': action.selectedRoute,
//           'Origin': action.previous
//         });

//         if(selected=='Treatment Plan'){
//           time_event('Create Treatment Plan - Duration'); // start timer for Treatment Plan creation
//         }
//       }
//       break
//     case 'OPEN_POPULATION_REPORT':
//       identify(customUniqueId);
//       track('Open Population Report',{'Option': action.option});
//       break
//     // END Main Screens timers

//     // START Treatment Plan (recommendations)
//     case 'TRACK_TREATMENT_CREATION':
//       identify(customUniqueId);
//       people_increment({
//         'Total Presented Recommendations': action.presented,
//         'Total Selected Recommendations': action.selected,
//         'Total Treatments Created': 1,
//       });
//       track('Save Treatment Plan', {
//         'Presented Recommendations': action.presented,
//         'Selected Recommendations': action.selected,
//       });
//       track('Create Treatment Plan - Duration'); // stop timer for Treatment Plan creation
//       break
//     // END Treatment Plan (recommendations)

//     default:
//       break
//   }

// }

// export default MixpanelTracking
