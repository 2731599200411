import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { rgba } from "polished";

import DashboardHelper from "../../../../helpers/dashboard-helper";
import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";

import { getSimulationData } from "../../../../services/insights-data-service";
import { useIdentifySimulation } from "../../../../hooks/useIdentifySimulation";
import CumulativeEventRateWrapper from "./cumulative-event-rate-wrapper";
import ChartWrapper from "../../../../components/dashboard-charts-wrapper/charts";

export default function SimulationCharts({
  setValidCharts,
  setErrorMsg,
  selectedEventType,
}) {
  const { simulation } = useIdentifySimulation();
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { cohortIndex, filtersConfig, simulationsConfig } = cohortConfigs;
  const [responseList, setResponseList] = useState({});
  const [chartsConfigs, setChartsConfigs] = useState({});

  async function getData(
    cohortQuery,
    baseAdoptionRate,
    simulationAdoptionRate
  ) {
    const response = await getSimulationData(
      cohortIndex.index_name,
      DashboardHelper.buildQueryFromFilters(cohortQuery, filtersConfig),
      simulationsConfig[selectedEventType].event_rate_curves,
      simulationsConfig[selectedEventType].table2_config,
      baseAdoptionRate / 100,
      simulationAdoptionRate / 100
    );
    if (response.data?.valid) {
      return response.data.data.result;
    }

    setValidCharts(false);
    setErrorMsg(response.data.messages.join());
    return {};
  }

  function parseEventRateCurveResponse(response) {
    const chartsOptions = Object.keys(
      response.simulation?.event_rate_curve || {}
    ).sort();

    const dataList = {};
    chartsOptions.forEach((chart) => {
      const data = [];
      Object.keys(response).forEach((subcohortID) => {
        if (
          response[subcohortID]?.event_rate_curve &&
          chart in response[subcohortID].event_rate_curve
        ) {
          const lineData =
            response[subcohortID].event_rate_curve[chart].data[0];
          const newData = {
            name: subcohortID.toUpperCase(),
            values: lineData.values.map((d) => ({
              ...d,
              value: d.value * 100,
            })),
            confidence_intervals: lineData.confidence_intervals.map((d) => ({
              ...d,
              lower_val: d.lower_val * 100,
              upper_val: d.upper_val * 100,
            })),
          };
          data.push(newData);
        }
      });
      dataList[chart] = data;
    });

    return dataList;
  }

  function getEventRateCurveConfig(chart, data) {
    const config = {};
    const colorPalette = [simulation.selectedCohort.color, "#0CA6C8"];
    config.name = chart;
    config.data = data;
    config.config = {
      type: "line-ci",
      layout: "linear",
      rotateXAxisLabels: false,
      alphanumSort: true,
      axesXType: "continuous",
      normalizeToogle: false,
      hideLegends: true,
      showTooltip: true,
      colorPalette: colorPalette,
    };
    config.meta = {
      axesTitle: {
        x: "Days since index date",
        y: "Cumulative Event Rate (%)",
      },
      chartTitle: "Cumulative Event Rate",
    };
    return config;
  }

  function parseEventRateBarsResponse(response) {
    const features = Object.keys(response.simulation?.bar_graphs || {}).sort();

    const dataList = [];

    Object.keys(response).forEach((subcohortID) => {
      if (response[subcohortID]?.bar_graphs) {
        const dataBar = [];
        const dataCI = [];
        features.forEach((feature) => {
          if (feature in response[subcohortID].bar_graphs) {
            const barData = response[subcohortID].bar_graphs[feature].data[0];

            dataBar.push({
              key: feature,
              value: barData.values[0].value * 100,
            });
            dataCI.push({
              key: feature,
              lower_val: barData.confidence_intervals[0].lower_val * 100,
              upper_val: barData.confidence_intervals[0].upper_val * 100,
            });
          }
        });
        dataList.push({
          name: subcohortID.toUpperCase(),
          values: dataBar,
          confidence_intervals: dataCI,
        });
      }
    });

    return dataList;
  }

  function getEventRateBarsConfig(data) {
    const config = {};
    const colorPalette = [simulation.selectedCohort.color, "#0CA6C8"];
    config.name = "random name";
    config.data = data;
    config.config = {
      type: "bar-group",
      axesXType: "ordinal",
      alphanumSort: false,
      spacing: 0.2,
      showTooltip: true,
      normalizeToogle: false,
      hideLegends: true,
      colorPalette: colorPalette,
    };
    config.meta = {
      axesTitle: {
        x: null,
        y: "Event Rate (%)",
      },
      chartTitle: "random name",
    };
    return config;
  }

  function getTables2Config(response) {
    if (!response.simulation.table2) return {};
    const outcomes = Object.keys(response.simulation.table2);
    if (!outcomes || !outcomes.length) {
      return {};
    }

    outcomes.sort();

    const years = response.simulation.table2[outcomes[0]].data[0].values.map(
      (d) => d.key
    );

    function getYearsHeaders(year) {
      const numYear = Math.trunc(year / 365);
      const yearHeader = {
        Header: `${numYear} Year`,
        accessor: numYear.toString(),
      };
      return yearHeader;
    }

    function getColumns(years) {
      const columns = [
        {
          Header: "Outcomes",
          accessor: "outcome",
          enableRowSpan: true,
        },
        {
          Header: "Cohort",
          accessor: "cohort",
          hiddenColumn: true,
        },
      ];

      years.forEach((year) => {
        columns.push(getYearsHeaders(year));
      });

      return columns;
    }

    function getRows(outcomes) {
      const newRows = [];

      Object.keys(response).forEach((id) => {
        outcomes.forEach((outcome) => {
          const newRow = {};
          newRow.cohort = id;
          newRow.outcome = outcome;
          response[id].table2[outcome].data[0].values.forEach((d) => {
            newRow[Math.trunc(d.key / 365).toString()] = (
              d.value * 100
            ).toFixed(2);
          });
          newRows.push(newRow);
        });
      });

      return newRows;
    }

    function getConditionalRowStyles() {
      const styles = [];
      Object.keys(response).forEach((id) => {
        styles.push({
          when: (row) => row.original.cohort === id,
          styles: {
            backgroundColor: rgba(
              id === "simulation" ? "#0CA6C8" : simulation.selectedCohort.color,
              0.1
            ),
          },
        });
      });
      return styles;
    }

    function getConditionalCellStyles() {
      const styles = [];
      styles.push({
        when: (cell) => cell.column.id === "outcome",
        styles: {
          backgroundColor: rgba("#fff", 0.1),
        },
      });
      return styles;
    }

    const columns = getColumns(years);
    const rows = getRows(outcomes);
    const conditionalRowStyles = getConditionalRowStyles();
    const conditionalCellStyles = getConditionalCellStyles();

    return { columns, rows, conditionalRowStyles, conditionalCellStyles };
  }

  function getLegendsConfig(response) {
    const features = Object.keys(response.simulation?.legend || {}).sort();

    const dataList = [];

    features.forEach((feature) => {
      dataList.push(response.simulation.legend[feature]);
    });

    return dataList;
  }

  function getConfigs(data) {
    const config = {};
    if (isEmpty(data)) return config;

    //ERB
    const erbDataList = parseEventRateBarsResponse(data);
    config["erbConfig"] = getEventRateBarsConfig(erbDataList);

    //ERC
    const ercDataList = parseEventRateCurveResponse(data);
    const newERCConfigs = [];
    for (const key of Object.keys(ercDataList)) {
      newERCConfigs.push(getEventRateCurveConfig(key, ercDataList[key]));
    }
    config["ercConfig"] = newERCConfigs;

    //Table2
    config["table2Config"] = getTables2Config(data);

    //Legends
    config["legend"] = getLegendsConfig(data);

    return config;
  }

  useEffect(async () => {
    setChartsConfigs({});
    const newResponseList = {};
    if (simulation.baseAdoptionRate) {
      let response = await getData(
        simulation.selectedCohort.filterList,
        simulation.baseAdoptionRate,
        simulation.baseAdoptionRate
      );
      newResponseList[simulation.selectedCohort.id] = response;

      response = await getData(
        simulation.selectedCohort.filterList,
        simulation.baseAdoptionRate,
        simulation.simulationAdoptionRate
      );
      newResponseList["simulation"] = response;
    }
    setResponseList(newResponseList);
  }, [
    selectedEventType,
    simulation.feature,
    simulation.simulationAdoptionRate,
    simulation.baseAdoptionRate,
  ]);

  // When any response changes trigger a build of the maps config structure
  useEffect(() => {
    const configs = getConfigs(responseList);
    setChartsConfigs(configs);
  }, [responseList.simulation]);

  return (
    <>
      <div className="charts-container">
        <ChartWrapper
          visible={true}
          chart={chartsConfigs["erbConfig"] ? chartsConfigs["erbConfig"] : {}}
        />
      </div>
      <CumulativeEventRateWrapper
        charts={chartsConfigs["ercConfig"] ? chartsConfigs["ercConfig"] : {}}
        table={
          chartsConfigs["table2Config"] ? chartsConfigs["table2Config"] : {}
        }
        legend={chartsConfigs["legend"] ? chartsConfigs["legend"] : {}}
      />
    </>
  );
}
