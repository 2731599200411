import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  padding: 15px 15px 15px 25px;
  border-left: 1px solid #e6e6e6;
`;

export const Header = styled.div`
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  small {
    display: block;
  }
`;

export const Content = styled.div``;

export const EligiblePatients = styled.div`
  display: flex;
  border-bottom: 1px solid #6b7c99;
  gap: 8px;
  padding-bottom: 5px;

  & > b {
    font-size: 20px;
  }
  margin-bottom: 20px;
`;

export const BreakdownWrapper = styled.div`
  display: grid;
  gap: 15px;
  overflow-y: scroll;
  height: 180px;

  @media (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Breakdown = styled.div``;

export const BreakdownContent = styled.div`
  border: 1px solid #c1c8d4;
  border-bottom: 0;

  & > div {
    border-bottom: 1px solid #c1c8d4;
    padding: 8px;
    display: flex;
    & > h4 + h4 {
      margin-left: 5px;
    }
  }
`;
export const ErrorMessageWrapper = styled.div`
  color: #666;
`;
