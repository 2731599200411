import styled, { css } from "styled-components";

export const Container = styled.div`
  ${(props) => {
    if ("border" in props && props.border) {
      return css`
        border: ${props.border};
      `;
    }

    return css`
      border-bottom: 1px solid ${props.borderColor};
    `;
  }}

  ${(props) => {
    if ("borderTop" in props && props.borderTop !== null) {
      return css`
        border-top: ${props.borderTop};
      `;
    }
  }}

  ${(props) => {
    if ("padding" in props && props.padding) {
      return css`
        padding: ${props.padding};
      `;
    }

    return css`
      padding: 0.5rem 5px;
    `;
  }}

  background-color: ${(props) => props.backgroundColor};
  transition: height 2s;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 3px;

  > span {
    flex: 1;
  }
`;

export const Content = styled.div`
  padding: 0.8rem 0 0.3rem;
`;
