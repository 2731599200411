import React, { forwardRef } from "react";

import { Container, Input } from "./styles";

const Ratiobox = forwardRef(
  ({ label, value, onChange, name, ratioPosition = "left", ...rest }, ref) => {
    return (
      <Container htmlFor={name + value} key={value}>
        {ratioPosition === "right" ? label : ""}
        <Input
          ratioPosition={ratioPosition}
          ref={ref}
          value={value}
          id={name + value}
          onChange={() =>
            onChange
              ? onChange(name, event.target.value, event.target.checked)
              : null
          }
          {...rest}
        />
        {ratioPosition === "left" ? label : ""}
      </Container>
    );
  }
);

export default Ratiobox;
