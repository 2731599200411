import React from "react";
import * as ReactDOMServer from "react-dom/server";
import {
  LayersControl,
  Marker,
  Popup,
  Tooltip,
  LayerGroup,
} from "react-leaflet";
import { divIcon } from "leaflet";

import { MdLocationPin } from "react-icons/md";

import { TooltipWrapper } from "./styles";

const { Overlay } = LayersControl;

function OverlayLayer({ layer }) {
  const getMarkerIcon = (marker, config) => {
    return divIcon({
      html: ReactDOMServer.renderToString(marker),
      className: "ResetClass", // this is just to remove leaflet default styles
      ...config,
    });
  };

  const handleOnClickEvent = (layerElement) => {
    if (layerElement.onClick) layerElement.onClick(layerElement);
  };

  const getElement = (layerElement, index) => {
    const { TooltipContent, PopupContent } = layerElement;
    switch (layerElement.type) {
      case "marker":
        const MarkerIcon = getMarkerIcon(
          layerElement.marker ? (
            layerElement.marker
          ) : (
            <MdLocationPin size={25} />
          ),
          layerElement.config
            ? layerElement.config
            : { iconSize: [25, 25], iconAnchor: [12.5, 25] }
        );
        return (
          <Marker
            key={`${index}${layerElement.position.join("")}`}
            position={layerElement.position}
            icon={MarkerIcon}
            zIndexOffset={
              layerElement.zIndexOffset ? layerElement.zIndexOffset : null
            }
            eventHandlers={{
              click: () => handleOnClickEvent(layerElement),
            }}
          >
            {TooltipContent ? (
              <TooltipWrapper>
                <TooltipContent layerElement={layerElement} />
              </TooltipWrapper>
            ) : (
              ""
            )}
            {PopupContent ? (
              <Popup>
                <PopupContent layerElement={layerElement} />
              </Popup>
            ) : (
              ""
            )}
          </Marker>
        );
      default:
        return "";
    }
  };
  return (
    <Overlay key={layer.name} name={layer.name} checked={layer.checked}>
      <LayerGroup>
        {layer.elementList.map((layerElement, i) =>
          getElement(layerElement, i)
        )}
      </LayerGroup>
    </Overlay>
  );
}

export default OverlayLayer;
