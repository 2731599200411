import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const Title = styled.h3``;

export const Actions = styled.div`
  display: flex;

  > * {
    margin-bottom: 0;
  }
`;
