// Libs
import React, { useEffect, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { isEmpty } from "lodash";

// UI Components
import { TabsContext } from "../../components/app-tab-nav/context";
import ContentContainer from "../../components/app-content-container";

// Modules Component
import MedicationIntentPage from "./pages/medication-intent-list";
import MedicationMechanismPage from "./pages/medication-mechanism-list";
import MedicationPage from "./pages/medication-drug-list";

// Form Components
import MedicationIntentAdd from "./pages/medication-intent-add";
import MedicationIntentView from "./pages/medication-intent-view";
import MedicationMechanismAdd from "./pages/medication-mechanism-add";
import MedicationMechanismView from "./pages/medication-mechanism-view";
import MedicationAdd from "./pages/medication-drug-add";
import MedicationView from "./pages/medication-drug-view";

// Page Not Found
import PageNotFound from "../NotFound";

import { useGuidelineRule } from "../../hooks/useGuidelineRules";
import { useGuidelineDocument } from "../../hooks/useGuidelineDocuments";
import { usePatientAttribute } from "../../hooks/usePatientAttributes";
import { useMedicationIntents } from "../../hooks/useMedicationIntents";
import { useMedicationMechanisms } from "../../hooks/useMedicationMechanisms";
import { useMedicationDrugs } from "../../hooks/useMedicationDrugs";

export default function MedicationManager(props) {
  const { guidelines } = useGuidelineRule();
  const { documents } = useGuidelineDocument();
  const { attributes } = usePatientAttribute();
  const { medicationIntents } = useMedicationIntents();
  const { medicationMechanisms } = useMedicationMechanisms();
  const { medications } = useMedicationDrugs();

  const { setMainTabs } = useContext(TabsContext);

  useEffect(() => {
    setMainTabs([
      {
        name: "Medication Drugs",
        url: "/medication-manager/medications",
        displayTab: true,
      },
      {
        name: "Medication Mechanisms",
        url: "/medication-manager/medication-mechanisms",
        displayTab: true,
      },
      {
        name: "Medication Intents",
        url: "/medication-manager/medication-intents",
        displayTab: true,
      },
    ]);
  }, []);

  const displayLoading =
    isEmpty(guidelines) ||
    isEmpty(documents) ||
    isEmpty(attributes) ||
    isEmpty(medicationIntents) ||
    isEmpty(medicationMechanisms) ||
    isEmpty(medications);

  return (
    <ContentContainer>
      <Switch>
        <Route
          exact
          path="/medication-manager"
          render={() => <Redirect to="/medication-manager/medications" />}
        />

        <Route
          exact
          path="/medication-manager/medication-intents"
          render={(props) => (
            <MedicationIntentPage {...props} displayLoading={displayLoading} />
          )}
        />

        <Route
          exact
          path="/medication-manager/medication-intents/add"
          render={(props) => (
            <MedicationIntentAdd {...props} displayLoading={displayLoading} />
          )}
        />

        <Route
          exact
          path="/medication-manager/medication-intents/:medicationIntentId"
          render={(props) => (
            <MedicationIntentView {...props} displayLoading={displayLoading} />
          )}
        />

        <Route
          exact
          path="/medication-manager/medication-mechanisms"
          render={(props) => (
            <MedicationMechanismPage
              {...props}
              displayLoading={displayLoading}
            />
          )}
        />

        <Route
          exact
          path="/medication-manager/medication-mechanisms/add"
          render={(props) => (
            <MedicationMechanismAdd
              {...props}
              displayLoading={displayLoading}
            />
          )}
        />

        <Route
          exact
          path="/medication-manager/medication-mechanisms/:medicationMechanismId"
          render={(props) => (
            <MedicationMechanismView
              {...props}
              displayLoading={displayLoading}
            />
          )}
        />

        <Route
          exact
          path="/medication-manager/medications"
          render={(props) => (
            <MedicationPage {...props} displayLoading={displayLoading} />
          )}
        />

        <Route
          exact
          path="/medication-manager/medications/add"
          render={(props) => (
            <MedicationAdd {...props} displayLoading={displayLoading} />
          )}
        />

        <Route
          exact
          path="/medication-manager/medications/:medicationId"
          render={(props) => (
            <MedicationView {...props} displayLoading={displayLoading} />
          )}
        />

        <Route render={(props) => <PageNotFound containerFrame={false} />} />
      </Switch>
    </ContentContainer>
  );
}
