import React, { useMemo } from "react";
import { Container } from "./styles";

import SiteMap from "./sites-map";
import SiteDetails from "./site-details";
import { find } from "lodash";

export default function SiteGeomapDetails({
  sites,
  activeSite,
  setActiveSite,
  selectedCohort,
  setValidCharts,
  setErrorMsg,
}) {
  const site = useMemo(() => {
    const siteActive = find(sites, ["id", activeSite]);
    return siteActive ? siteActive : {};
  }, [activeSite]);

  return (
    <Container>
      <SiteMap
        sites={sites}
        activeSite={activeSite}
        setActiveSite={setActiveSite}
        selectedCohort={selectedCohort}
        setValidCharts={setValidCharts}
        setErrorMsg={setErrorMsg}
      />
      <SiteDetails site={site} />
    </Container>
  );
}
