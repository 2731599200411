import React from "react";
import { Link } from "react-router-dom";

import LoadingHOC from "../../../../components/loadingHoc";

import Button from "../../../../components/button";
import AppPageTitle from "../../../../components/app-page-title";
import MedicationIntentTable from "../../c-medication-intent-table";

import { useMedicationIntents } from "../../../../hooks/useMedicationIntents.js";

import { Container } from "./styles";

function MedicationIntentsList() {
  const { medicationIntents } = useMedicationIntents();

  return (
    <>
      <AppPageTitle
        title="Medication Intent"
        actions={
          <Button variant="confirm">
            <Link to="/medication-manager/medication-intents/add">
              New Medication Intent
            </Link>
          </Button>
        }
      />
      <Container>
        <MedicationIntentTable medicationIntents={medicationIntents} />
      </Container>
    </>
  );
}

export default LoadingHOC()(MedicationIntentsList);
