const Users = (state = [], action) => {
  const defaultState = {
    refreshData: false,
    displayLoading: false,
    loaded: false,
    errorMessages: undefined,
    openForm: false,
    users: [],
  };

  if (Object.keys(state).length == 0) {
    state = defaultState;
  }

  // Set flag to component known when to trigger data refresh
  if (action.type == "REFRESH_ALL_USERS") {
    const newState = state;
    newState.refreshData = true;
    return newState;
  }

  // Component have fired
  if (action.type == "GET_ALL_USERS") {
    const newState = state;
    newState.refreshData = false;
    newState.displayLoading = true;
    newState.loaded = false;
    return newState;
  }

  if (action.type == "GET_ALL_USERS_RECEIVED") {
    const newState = state;
    newState.users = action.data.data;
    newState.displayLoading = false;
    newState.loaded = true;
    return newState;
  }

  if (action.type == "USER_LOGOUT") {
    return defaultState;
  }

  return state;
};

export default Users;
