import React from "react";

import { Container, AttributesLabels } from "./styles";

function MedicationIntentOverview({ medicationIntent }) {
  return (
    <Container>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <AttributesLabels>
            <label>Name</label>
            <span>{medicationIntent.human_readable_name}</span>
          </AttributesLabels>
        </div>
        <div className="col-xs-12 col-sm-6">
          <AttributesLabels>
            <label>Variable</label>
            <span>{medicationIntent.name}</span>
          </AttributesLabels>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <AttributesLabels>
            <label>Codes</label>
          </AttributesLabels>
        </div>
      </div>
    </Container>
  );
}

export default MedicationIntentOverview;
