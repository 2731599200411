import styled from "styled-components";

export const Textarea = styled.textarea`
  padding: 5px 12px;
  resize: vertical;
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;

  color: #26344d;
  background-color: #fff;

  border: 1px solid;
  border-color: ${(props) => (props.invalid ? "#DD2542" : "#8F9CB3")};
  border-radius: 3px;

  -webkit-transition: border-color ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;

  &:focus {
    outline: none;
  }

  &[readonly] {
    cursor: not-allowed;
    border-color: #c1c8d4;
    background: #f2f2f2;
    opacity: 1;
    color: #666666;
    background: #f2f2f2;
  }

  &[disabled] {
    cursor: not-allowed;
    border-color: #c1c8d4;
    opacity: 1;
    color: #666666;
  }
`;
