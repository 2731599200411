import React from "react";
import { BsInfoCircle } from "react-icons/bs";

import KebabMenuItem from "../../../../../components/kebab-menu-item";

function DetailsMenuItem({ toggleModal }) {
  return (
    <KebabMenuItem
      onClick={() => {
        toggleModal();
      }}
    >
      <BsInfoCircle size={14} color="26344D" />
      <span>Details</span>
    </KebabMenuItem>
  );
}

export default DetailsMenuItem;
