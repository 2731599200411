import React, { createContext, useContext, useEffect, useState } from "react";
import { findIndex } from "lodash";
import { api } from "../services/api";

const MedicationDrugsContext = createContext({});

export function MedicationDrugsProvider(props) {
  const { children } = props;
  const [medications, setMedications] = useState([]);

  useEffect(() => {
    GetMedications();
  }, []);

  async function GetMedications() {
    const response = await api.get(`medications`, { withCredentials: true });
    const { data } = response.data;
    setMedications(data);
  }

  async function EditMedication(medication, id, successHandler, errorHandler) {
    const response = await api.put(`medications/${id}`, medication, {
      withCredentials: true,
    });
    if (response.data.valid) {
      const medicationIndex = findIndex(medications, ["id", id]);
      const newMedications = [...medications];
      newMedications.splice(medicationIndex, 1, response.data.data);
      setMedications(newMedications);
      successHandler(response.data.data);
    } else {
      errorHandler(response.data.messages);
    }
  }

  async function DeleteMedication(id, successHandler, errorHandler) {
    const response = await api.delete(`medications/${id}`, {
      withCredentials: true,
    });
    if (response.data.valid) {
      const medicationIndex = findIndex(medications, ["id", id]);
      const newMedications = [...medications];
      newMedications.splice(medicationIndex, 1);
      setMedications(newMedications);
      successHandler(id);
    } else {
      errorHandler(response.data.messages);
    }
  }

  async function AddMedication(medication, successHandler, errorHandler) {
    const response = await api.post(`medications`, medication, {
      withCredentials: true,
    });
    if (response.data.valid) {
      setMedications([...medications, response.data.data]);
      successHandler(response.data.data);
    } else {
      errorHandler(response.data.messages);
    }
  }

  return (
    <MedicationDrugsContext.Provider
      value={{
        medications,
        AddMedication,
        EditMedication,
        DeleteMedication,
        GetMedications,
      }}
    >
      {children}
    </MedicationDrugsContext.Provider>
  );
}

export function useMedicationDrugs() {
  const context = useContext(MedicationDrugsContext);

  return context;
}
