import React, { createContext, useContext, useEffect, useState } from "react";
import { findIndex } from "lodash";
import { api } from "../services/api";

const MedicationMechanismsContext = createContext({});

export function MedicationMechanismsProvider(props) {
  const { children } = props;
  const [medicationMechanisms, setMedicationsMechanisms] = useState([]);

  useEffect(() => {
    GetMedicationsMechanisms();
  }, []);

  async function GetMedicationsMechanisms() {
    const response = await api.get(`medication_mechanisms`, {
      withCredentials: true,
    });
    const { data } = response.data;
    setMedicationsMechanisms(data);
  }

  async function EditMedicationMechanism(
    medication,
    id,
    successHandler,
    errorHandler
  ) {
    const response = await api.put(`medication_mechanisms/${id}`, medication, {
      withCredentials: true,
    });
    if (response.data.valid) {
      const medicationMechanismIndex = findIndex(medicationMechanisms, [
        "id",
        id,
      ]);
      const newMedicationsMechanisms = [...medicationMechanisms];
      newMedicationsMechanisms.splice(
        medicationMechanismIndex,
        1,
        response.data.data
      );
      setMedicationsMechanisms(newMedicationsMechanisms);
      successHandler(response.data.data);
    } else {
      errorHandler(response.data.messages);
    }
  }

  async function DeleteMedicationMechanism(id, successHandler, errorHandler) {
    const response = await api.delete(`medication_mechanisms/${id}`, {
      withCredentials: true,
    });
    if (response.data.valid) {
      const medicationMechanismIndex = findIndex(medicationMechanisms, [
        "id",
        id,
      ]);
      const newMedicationsMechanisms = [...medicationMechanisms];
      newMedicationsMechanisms.splice(medicationMechanismIndex, 1);
      setMedicationsMechanisms(newMedicationsMechanisms);
      successHandler(id);
    } else {
      errorHandler(response.data.messages);
    }
  }

  async function AddMedicationMechanism(
    medication,
    successHandler,
    errorHandler
  ) {
    const response = await api.post(`medication_mechanisms`, medication, {
      withCredentials: true,
    });
    if (response.data.valid) {
      setMedicationsMechanisms([...medicationMechanisms, response.data.data]);
      successHandler(response.data.data);
    } else {
      errorHandler(response.data.messages);
    }
  }

  return (
    <MedicationMechanismsContext.Provider
      value={{
        medicationMechanisms,
        AddMedicationMechanism,
        EditMedicationMechanism,
        DeleteMedicationMechanism,
        GetMedicationsMechanisms,
      }}
    >
      {children}
    </MedicationMechanismsContext.Provider>
  );
}

export function useMedicationMechanisms() {
  const context = useContext(MedicationMechanismsContext);

  return context;
}
