import React, { useState, useEffect, useMemo } from "react";

// Components
import ErrorScreen from "../../error-screen";
import Loader from "../../../../components/loader";
import ContentContainer from "../../../../components/app-content-container";
import DashboardFilters from "../../components/identify-dashboard-filter";
import Aside from "../../../../components/app-aside";

// Charts
import AwareEventRateCurve from "../../components/aware-event-rate-curve";
import AwareRiskModelCurve from "../../components/aware-risk-model-curve";
import AwarePopulationGeomap from "../../components/aware-population-geomap";
import AwareTableOne from "../../components/aware-table-one";

import { Container } from "./styles";

import { useIdentifySubcohortList } from "../../../../hooks/useIdentifySubcohortList";
import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";

export default function Overview() {
  const [validCharts, setValidCharts] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const { subcohortList } = useIdentifySubcohortList();
  const { cohortConfigs } = useIdentifyCohortConfigs();

  useEffect(() => {
    setValidCharts(true);
    setErrorMsg("");
  }, [
    subcohortList.cohort?.query,
    subcohortList["subcohort-a"]?.query,
    subcohortList["subcohort-b"]?.query,
    subcohortList["subcohort-c"]?.query,
  ]);

  const { modules, enabledModulesCount } = useMemo(() => {
    if (cohortConfigs.settings) return cohortConfigs.settings;
    return { modules: null, enabledModulesCount: null };
  }, [cohortConfigs.settings]);

  const grid = useMemo(() => {
    if (enabledModulesCount <= 1) return [1, 1];
    return [2, 2];
  }, [enabledModulesCount]);

  const getCharts = () => {
    return (
      <Container grid={grid}>
        {modules.geomap_module_enabled ? (
          <AwarePopulationGeomap
            setValidCharts={setValidCharts}
            setErrorMsg={setErrorMsg}
          />
        ) : (
          ""
        )}

        {modules.table_1_module_enabled ? (
          <AwareTableOne
            setValidCharts={setValidCharts}
            setErrorMsg={setErrorMsg}
          />
        ) : (
          ""
        )}

        {modules.erc_module_enabled ? (
          <AwareEventRateCurve
            setValidCharts={setValidCharts}
            setErrorMsg={setErrorMsg}
          />
        ) : (
          ""
        )}

        {modules.risk_model_module_enabled ? (
          <AwareRiskModelCurve
            setValidCharts={setValidCharts}
            setErrorMsg={setErrorMsg}
          />
        ) : (
          ""
        )}
      </Container>
    );
  };

  const getContent = () => {
    let content = (
      <Container grid={[1, 1]}>
        <Loader />
      </Container>
    );
    if (cohortConfigs.settings) {
      if (validCharts) {
        content = getCharts();
      } else {
        content = <ErrorScreen message={errorMsg} />;
      }
    }
    return content;
  };

  return (
    <>
      <Aside collapsible>
        <DashboardFilters isOpen />
      </Aside>
      <ContentContainer>{getContent()}</ContentContainer>
    </>
  );
}
