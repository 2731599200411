//Libs
import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

// UI Components
import { TabsContext } from "../../components/app-tab-nav/context";
import ContentContainer from "../../components/app-content-container";

//Modules Component
import UsersForm from "./users-form.jsx";
import UsersPage from "./users.jsx";

//Page Not Found
import PageNotFound from "../NotFound";

export default function Administration(props) {
  const store = props.store;
  const Users = useSelector((state) => state.Administration.Users);
  const dispatch = useDispatch();
  const { setMainTabs } = useContext(TabsContext);

  useEffect(() => {
    setMainTabs([
      {
        name: "Users",
        url: "/administration/users",
        displayTab: true,
      },
    ]);
  }, []);

  useEffect(() => {
    if (Users.refreshData && !Users.displayLoading) {
      dispatch({ type: "GET_ALL_USERS" });
    }
  }, [Users.refreshData]);

  // This is to reset the scroll bar of the tabs
  // when the state is updated
  // componentDidUpdate: function(){
  //   if(ReactDOM.findDOMNode(this).children[1]){
  //     ReactDOM.findDOMNode(this).children[1].scrollTop = 0;
  //   }

  //   //initialize the state only when the component updates (avoid warnings)
  //   const state = store.getState()
  //   this.initializeState(state)
  // },
  return (
    <ContentContainer>
      <Switch>
        <Route
          exact
          path="/administration"
          render={() => <Redirect to="/administration/users" />}
        />

        <Route
          exact
          path="/administration/users"
          render={(props) => (
            <UsersPage
              {...props}
              isOpen={true}
              store={store}
              displayLoading={Users.displayLoading}
              errorMessage={Users.errorMessage}
              loaded={Users.loaded}
            />
          )}
        />

        <Route
          path="/administration/users/add"
          render={(props) => (
            <UsersForm {...props} store={store} isOpen={true} />
          )}
        />

        <Route
          path="/administration/users/edit/:userId"
          render={(props) => (
            <UsersForm {...props} store={store} isOpen={true} />
          )}
        />

        <Route render={(props) => <PageNotFound containerFrame={false} />} />
      </Switch>
    </ContentContainer>
  );
}
