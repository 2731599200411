import { lighten, rgba } from "polished";
import styled from "styled-components";

export const SubtableHeader = styled.thead`
  position: sticky;
  top: ${(props) => (props.stickyHeight ? props.stickyHeight : 0)}px;
  left: 0;
  z-index: 998;

  > tr {
    background: #fbfbfb;

    > th {
      padding: 2px 10px;
      background: #fbfbfb;
      border-bottom: 1px solid #666666;
    }
  }
`;

export const SubtableBody = styled.tbody`
  > tr {
    > td {
      padding: 5px 10px;
      line-height: normal;
      border-bottom: 1px solid #c1c8d4;
    }

    &:last-child {
      border-bottom: 0;
      > td {
        padding-bottom: 10px;
      }
    }
  }
`;

export const SubtableBodySpecialCell = styled.td`
  text-align: ${(props) => props.textAlign};
  min-width: 70px;
  padding: ${(props) =>
    props.textAlign == "left"
      ? "5px 10px 5px 2px"
      : "5px 2px 5px 10px"} !important;
  background: ${(props) => rgba(props.color, 0.07)};
`;

export const SubtableBodyRowFirstCell = styled.td`
  min-width: 250px;
  background-color: #fff;
  position: sticky;
  left: 0;
`;

export const TooltipContent = styled.div`
  padding: 3px;
  > span,
  small {
    display: block;
  }
`;

export const TooltipContentGroup = styled.div`
  & + & {
    border-top: 1px solid #cccccc;
    padding-top: 7px;
  }
`;
