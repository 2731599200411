import React, { useMemo, Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { find } from "lodash";

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { RiArrowDropLeftLine } from "react-icons/ri";

import MedicationIntentOverview from "../../c-medication-intent-overview";
import MedicationIntentForm from "../../c-medication-intent-form";
import GuidelineHelper from "../../../../helpers/guideline-helper";
import GuidelineRulesTable from "../../../content-manager/c-guidelines-rules-table";
import MedicationMechanismTable from "../../c-medication-mechanism-table";
import LoadingHOC from "../../../../components/loadingHoc";
import Button from "../../../../components/button";
import Tabs from "../../../../components/tabs";
import Tab from "../../../../components/tabs/tab";

import { useGuidelineRule } from "../../../../hooks/useGuidelineRules";
import { useMedicationIntents } from "../../../../hooks/useMedicationIntents";
import { useMedicationMechanisms } from "../../../../hooks/useMedicationMechanisms";

import { Container, HeaderRow, Header, BackLink } from "./styles";

function MedicationIntentView() {
  const { medicationIntentId } = useParams();
  const [isEditing, setIsEditing] = useState(false);

  const { medicationMechanisms } = useMedicationMechanisms();
  const { medicationIntents, DeleteMedicationIntent } = useMedicationIntents();
  const { guidelines } = useGuidelineRule();

  const history = useHistory();

  const medicationIntent = useMemo(() => {
    const medicationIntent = find(medicationIntents, [
      "id",
      parseInt(medicationIntentId),
    ]);
    if (medicationIntent) {
      return medicationIntent;
    }

    history.push("/medication-manager/medication-mechanisms/");
    return {};
  }, [medicationIntentId, medicationIntents]);

  const guidelinesAttachedToMedicationIntent = useMemo(() => {
    return GuidelineHelper.getListOfRulesWithAttribute(
      medicationIntentId,
      guidelines
    );
  }, [medicationIntentId, guidelines]);

  const medicationMechanismsAttachedToMedicationIntent = useMemo(() => {
    return GuidelineHelper.getListOfAttributeWithParent(
      medicationIntentId,
      medicationMechanisms
    );
  }, [medicationIntentId, medicationMechanisms]);

  const cancelEditingForm = () => {
    setIsEditing(false);
  };

  return (
    <Container>
      <Header>
        <BackLink to="/medication-manager/medication-mechanisms/">
          <RiArrowDropLeftLine size={18} />
          back to medication mechanisms
        </BackLink>
        <HeaderRow>
          <div>
            <h3>{medicationIntent.human_readable_name}</h3>
            <span>{medicationIntent.name}</span>
          </div>
          {isEditing ? (
            ""
          ) : (
            <Button
              variant="outline"
              type="button"
              leftIcon={<FaEdit />}
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
          )}
        </HeaderRow>
      </Header>
      {isEditing ? (
        <MedicationIntentForm
          cancelFormAction={cancelEditingForm}
          medicationIntent={medicationIntent}
          usageNumber={
            guidelinesAttachedToMedicationIntent +
            medicationMechanismsAttachedToMedicationIntent
          }
        />
      ) : (
        <Tabs>
          <Tab label="Overview">
            <MedicationIntentOverview medicationIntent={medicationIntent} />
          </Tab>

          {guidelinesAttachedToMedicationIntent.length == 0 ? null : (
            <Tab
              label={`Guidelines (${guidelinesAttachedToMedicationIntent.length})`}
            >
              <GuidelineRulesTable
                isFiltersOn={false}
                guidelines={guidelinesAttachedToMedicationIntent}
              />
            </Tab>
          )}
          {medicationMechanismsAttachedToMedicationIntent.length == 0 ? null : (
            <Tab
              label={`Medications Mechanisms (${medicationMechanismsAttachedToMedicationIntent.length})`}
            >
              <MedicationMechanismTable
                isFiltersOn={false}
                medicationMechanisms={
                  medicationMechanismsAttachedToMedicationIntent
                }
              />
            </Tab>
          )}
        </Tabs>
      )}
    </Container>
  );
}

export default LoadingHOC()(MedicationIntentView);
