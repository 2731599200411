import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import Modal from "../../../../components/modal";
import Select from "../../../../components/basic-inputs/select";
import { Small } from "../../../../components/typography";
import Slider from "../../../../components/basic-inputs/slider-input";
import Button from "../../../../components/button";

import { useIdentifySimulation } from "../../../../hooks/useIdentifySimulation";
import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../services/mixpanel-tracking";

import { Container, Label } from "./styles";

function SimulationSettingsModal({ toggleModal, isShowingModal }) {
  const { simulation, dispatch } = useIdentifySimulation();
  const location = useLocation();

  const [adoptionRate, setAdoptionRate] = useState(
    simulation.simulationAdoptionRate
  );

  const featureOptions = [
    {
      value: "genetic_test_cardio_or_unspecified_183d_before_or_183d_after",
      label:
        "Evidence of genetic testing, cardiovascular/unspecified, within 6mo of index date",
    },
  ];

  const marks = { 100: 100 };
  marks[simulation.baseAdoptionRate] = simulation.baseAdoptionRate;
  simulation.baseAdoptionRate >= 3 ? (marks[0] = 0) : (marks[0] = " ");

  function handleSliderChange(_, value) {
    if (value >= simulation.baseAdoptionRate) setAdoptionRate(value);
    else setAdoptionRate(simulation.baseAdoptionRate);
  }

  function handleApplySettings() {
    dispatch({
      type: "CHANGE_SIMULATION_ADOPTION_RATE",
      payload: adoptionRate,
    });
    mixpanelIdentify(localStorage.email);
    mixpanelTrack("Change % adoption", {
      selectedCohort: {
        feature: simulation.feature,
        adoptionRate: adoptionRate,
      },
      urlPathname: location.pathname,
    });

    toggleModal();
  }

  function getButtons() {
    const buttons = [];
    buttons.push(
      <Button variant="outline" onClick={toggleModal}>
        Cancel
      </Button>
    );
    buttons.push(
      <Button variant="confirm" onClick={() => handleApplySettings()}>
        Apply
      </Button>
    );
    return buttons;
  }

  const careGapRate = useMemo(() => {
    const baseRate = +simulation.baseAdoptionRate;
    const x = (adoptionRate - baseRate) * 100;
    const y = 100 - baseRate;
    return (x / y).toFixed(2);
  }, [adoptionRate]);

  return (
    <div>
      <Modal
        title="Simulation settings"
        isShowing={isShowingModal}
        hide={toggleModal}
        buttons={getButtons()}
      >
        <Label>Choose intervention</Label>
        <Select
          options={featureOptions}
          value={simulation.feature.value}
          onChange={() => {}}
          marginBottom={24}
        />
        <Label>Choose percentage of adoption to simulate</Label>
        <div>
          <Small>Simulated market adoption rate</Small>
        </div>
        <Slider
          min={0}
          max={100}
          defaultValue={+simulation.baseAdoptionRate}
          step={0.01}
          onChange={handleSliderChange}
          value={adoptionRate}
          marks={marks}
          name={"Simulation Adoption Rate"}
        />
        <Small>
          The current market adoption rate in the selected population is{" "}
          <b>{simulation.baseAdoptionRate}%</b>, so the <b>{adoptionRate}%</b>{" "}
          market adoption rate selection closes <b>{careGapRate}%</b> of care
          gaps.
        </Small>
      </Modal>
    </div>
  );
}

export default SimulationSettingsModal;
