import styled from "styled-components";
import { Range } from "rc-slider";

export const Container = styled.div`
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;
`;

export const SliderContainer = styled.div`
  height: 23px;
  display: flex;
  justify-content: flex-end;
  position: relative;

  > span {
    position: absolute;
    font-size: 11px;
    top: 10px;
  }
`;

export const Slider = styled(Range)`
  width: calc(100% - 7px);

  .rc-slider-track {
    background-color: #6b7c99;
  }

  .rc-slider-handle {
    margin-left: -2px;
    margin-top: -3px;
    width: 10px;
    height: 10px;
    border: none;
    background-color: #6b7c99;
  }

  .rc-slider-step {
    .rc-slider-dot {
      border: none;
      background-color: #e9e9e9;
      &.rc-slider-dot-active {
        background-color: #e9e9e9;
      }
    }
  }
  .rc-slider-mark {
    top: 10px !important;
    .rc-slider-mark-text {
      font-size: 11px;

      &:first-of-type {
        transform: translateX(-5px) !important;
      }
      &:last-of-type {
        transform: translateX(-100%) !important;
      }
    }
  }
`;

export const RangeInputs = styled.div`
  display: flex;
  margin-bottom: 5px;

  div {
    padding: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    > span {
      width: 100%;
      heigth: 1px;
      border-top: 1px solid;
      display: block;
    }
  }
  > * {
    flex: 1;
  }
`;
