import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  padding: 15px;
  border: 1px solid #dedfe2;
  margin-bottom: 10px;
`;

export const Header = styled.div`
  margin: -15px -15px 15px -15px;
  padding: 15px;
  background-color: #ffffff;

  z-index: 999;
  position: relative;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
