import styled, { css } from "styled-components";

export const Thead = styled.thead`
  background-color: #fbfbfb;

  ${(props) =>
    props.stickyHeader &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
    `}
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  margin: 10px 0 15px 0;

  > label {
    margin-right: 10px;
  }
`;

export const Filter = styled.div`
  margin: 0 0.5% 0px;
`;

export const TableContainer = styled.div`
  width: 100%;
  margin: 0 0 15px 0;

  ${(props) =>
    props.tableHeight &&
    css`
      height: ${props.tableHeight}px;
      overflow-y: scroll;
    `}

  border: 1px solid #c1c8d4;
  border-radius: 3px;

  & > table {
    margin: 0;
  }
`;

export const NoResultContainer = styled.div`
  width: 100%;

  ${(props) =>
    props.tableHeight &&
    css`
      height: ${props.tableHeight}px;
    `}
`;
