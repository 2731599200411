import { api } from "../services/api";

export async function getMapsTopography() {
  let topography;

  try {
    const response = await api.get("insights/geojson", {
      withCredentials: true,
    });

    topography = [
      {
        type: "base",
        geojson: response.data.data.us_state,
        name: "usa_state",
        center: [37.0902, -95.7129],
        zoom: 3,
        childLayer: "usa_zip3",
      },
      {
        type: "base",
        geojson: response.data.data.us_zip3,
        name: "usa_zip3",
        center: [37.0902, -95.7129],
        zoom: 3,
        childs: [],
      },
    ];
  } catch {
    topography = null;
  }

  return topography;
}

export async function getCohortIndexList() {
  let cohortIndexList = [];
  try {
    const response = await api.get("insights/cohort_index", {
      withCredentials: true,
    });
    if (response.data.data && response.data.data.cohort_index_list)
      cohortIndexList = response.data.data.cohort_index_list;
  } catch {
    cohortIndexList = null;
  }

  return cohortIndexList;
}

export async function getDatasetConfig(cohortIndex) {
  let configs = {};
  try {
    const response = await api.post(
      "insights/dataset_config",
      {
        dataset: [cohortIndex],
      },
      { withCredentials: true }
    );

    if (response.data.data) configs = response.data.data;
  } catch {
    configs = null;
  }

  return configs;
}

export async function getSavedSubcohort(cohortIndex) {
  let subcohorts = [];

  try {
    const response = await api.get("insights/saved_subcohorts", {
      withCredentials: true,
      params: {
        cohort_index_name: cohortIndex,
      },
    });
    if (response.data.data)
      subcohorts = response.data.data.saved_subcohort_list;
  } catch {
    subcohorts = null;
  }

  return subcohorts;
}

export async function saveNewSubcohort(cohortIndex, cohortQuery, name) {
  const response = await api.post(
    "insights/saved_subcohorts",
    {
      cohort_index_name: cohortIndex,
      query: cohortQuery,
      subcohort_name: name,
    },
    { withCredentials: true }
  );

  return response;
}

export async function deleteSavedSubcohort(cohortIndex, name) {
  const response = await api.delete(`insights/saved_subcohorts`, {
    withCredentials: true,
    data: {
      cohort_index_name: cohortIndex,
      subcohort_name: name,
    },
  });

  return response;
}

export async function getEventRateCurve(cohortIndex, cohortQuery, ercConfig) {
  const response = await api.post(
    "insights/event_rate_curve",
    {
      dataset: [cohortIndex],
      cohorts: cohortQuery,
      config: ercConfig,
    },
    { withCredentials: true }
  );

  return response;
}

export async function getRiskModelCurve(cohortIndex, cohortQuery, rmcConfig) {
  const response = await api.post(
    "insights/risk_model",
    {
      dataset: [cohortIndex],
      cohorts: cohortQuery,
      config: rmcConfig,
    },
    { withCredentials: true }
  );

  return response;
}

export async function getTable2Data(
  cohortIndex,
  cohortQuery,
  ercConfig,
  table2Config
) {
  const response = await api.post(
    "insights/table2",
    {
      dataset: [cohortIndex],
      cohorts: cohortQuery,
      config: {
        event_rate_curves: ercConfig,
        table2_config: table2Config,
      },
    },
    { withCredentials: true }
  );

  if (response.data.valid) {
    return response;
  }

  return {};
}

export async function getPopulationChoroplethData(cohortIndex, cohortQuery) {
  const response = await api.post(
    "insights/geo_population",
    {
      dataset: [cohortIndex],
      cohorts: cohortQuery,
    },
    { withCredentials: true }
  );

  return response;
}

export async function getTableOneData(cohortIndex, cohortQuery, table1Config) {
  const response = await api.post(
    "insights/table1",
    {
      dataset: [cohortIndex],
      cohorts: cohortQuery,
      config: table1Config,
    },
    { withCredentials: true }
  );

  return response;
}

export async function getSites(cohortIndex, cohortQuery) {
  const response = await api.post(
    "insights/sites",
    {
      dataset: cohortIndex,
      cohorts: cohortQuery,
    },
    { withCredentials: true }
  );

  return response;
}

export async function getFavoriteSites() {
  const response = await api.get("insights/favorite-sites", {
    withCredentials: true,
  });

  return response;
}

export async function postFavoriteSites(siteID) {
  const response = await api.post(
    "insights/favorite-sites",
    {
      id: siteID,
    },
    {
      withCredentials: true,
    }
  );

  return response;
}

export async function getBaseAdoptionRate(
  cohortIndex,
  cohortQuery,
  table1Config
) {
  const response = await api.post(
    "insights/simulations/base_adoption_rate",
    {
      dataset: [cohortIndex],
      cohorts: cohortQuery,
      config: table1Config,
    },
    { withCredentials: true }
  );

  return response;
}

export async function getSimulationData(
  cohortIndex,
  cohortQuery,
  ercConfig,
  table2Config,
  baseAdoptionRate,
  simulationAdoptionRate
) {
  const response = await api.post(
    "insights/simulations/simulation",
    {
      dataset: [cohortIndex],
      cohorts: cohortQuery,
      config: {
        event_rate_curves: ercConfig,
        table2_config: table2Config,
      },
      base_adoption_rate: baseAdoptionRate,
      final_adoption_rate: simulationAdoptionRate,
    },
    { withCredentials: true }
  );

  return response;
}
