import React, { useRef, useEffect } from "react";
import { useField } from "@unform/core";
import { find } from "lodash";

import { Container, Select } from "./styles";

function ReactSelect({
  name,
  label,
  inline = false,
  required = false,
  options,
  style,
  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    if (options.length > 0) {
      registerField({
        name: fieldName,
        ref: selectRef.current,
        getValue: (ref) => {
          if (rest.isMulti) {
            if (!ref.state.value) {
              return [];
            }
            return ref.state.value.map((option) => option.value);
          }
          if (!ref.state.value) {
            return "";
          }
          return ref.state.value.value;
        },
        setValue: (ref, value) => {
          if (rest.isMulti) {
            const values = value.map((v) => find(options, ["value", v]));
            if (rest.creatable) {
              ref.select.select.setValue(values);
            } else {
              ref.select.setValue(values);
            }
          } else {
            const option = find(options, ["value", value]);
            if (option) {
              if (rest.creatable) {
                ref.select.select.setValue(option);
              } else {
                ref.select.setValue(option);
              }
            }
          }
        },
      });
    }
  }, [fieldName, registerField, rest.isMulti, options]);
  return (
    <Container inline={inline} required={required} style={style}>
      {label && <label htmlFor={fieldName}>{label}</label>}

      <Select
        ref={selectRef}
        id={fieldName}
        defaultValue={defaultValue}
        invalid={!!error}
        inline={inline}
        options={options}
        marginBottom={0}
        {...rest}
      />

      {error && <span>{error}</span>}
    </Container>
  );
}
export default ReactSelect;
