import React from "react";
import * as d3 from "d3";
import { cloneDeep, find, unionBy, uniqBy, isNil, findIndex } from "lodash";
import DashboardHelper from "../../../../../helpers/dashboard-helper";

// TODO: find a better place for these constants
const X_AXIS_TITLE_PADDING = 40;
const X_AXIS_TICK_TOP_PADDING = 8;
// Density-based tickts. The highest the number of pixes per tick, the lower the number of ticks
const X_AXIS_PIXELS_PER_TICK = 50;
const Y_AXIS_PIXESL_PER_TICK = 80;

class ChartD3Wrapper extends React.Component {
  constructor(props) {
    super(props);

    this.gWrapperRef = null;
    this.setGWrapperRef = (e) => {
      this.gWrapperRef = e;
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Ensures that the component doesn't get re-rendered without reason
    // TODO: understand why this is being called so many times

    // Currently, updates every time the width of height changes/
    // TODO: add check for data
    if (
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width ||
      this.props.data !== nextProps.data ||
      this.props.normalizedData !== nextProps.normalizedData
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    d3.select(this.gWrapperRef).select("g").remove();
    if (this.props.data) {
      this.renderChartD3Wrapper();
    }
  }

  componentDidMount() {
    this.renderChartD3Wrapper();
  }

  tickFormatFunction() {
    const axesType = this.props.config.axesXType;
    const parseDataFormat = this.props.config.parseDataFormat
      ? this.props.config.parseDataFormat
      : "%m/%Y";
    const { axesTickFormat } = this.props.config;

    switch (axesType) {
      case "continuous":
        return function (d) {
          return DashboardHelper.roundUpNumber(d, 1);
        };
      case "ordinal":
        return function (d) {
          if (typeof d === "number") {
            return DashboardHelper.roundUpNumber(d, 1);
          } else {
            return d;
          }
        };
        break;
      case "time":
        return d3.time.format(axesTickFormat || parseDataFormat);
        break;
      default:
        console.error("Invalid Axes Type");
    }
  }

  renderChartD3Wrapper() {
    // Declare local variables from props
    const { props } = this;
    const { width } = this.props;
    const { height } = this.props;

    const barPaddingRatio = this.props.config.spacing
      ? this.props.config.spacing
      : 0;
    const chartLayout = this.props.config.layout;

    const { hideLegends } = this.props.config;
    const legendPosition = this.props.config.legendPosition
      ? this.props.config.legendPosition
      : "left"; // Top or left for now
    const { chartObservation } = this.props.meta;

    const axesType = this.props.config.axesXType;
    const { flipAxes } = this.props.config;
    const { hideVerticalAxis } = this.props.config;
    const { hideHorizontalAxis } = this.props.config;
    const parseDataFormat = this.props.config.parseDataFormat
      ? this.props.config.parseDataFormat
      : "%m/%Y";
    const alphanumSort =
      typeof this.props.config.alphanumSort == "boolean"
        ? this.props.config.alphanumSort
        : true;
    const { hideHorizontalBorderAxis } = this.props.config;
    const { hideVerticalBorderAxis } = this.props.config;

    const normalizeBy = this.props.config.normalizeBy
      ? this.props.config.normalizeBy
      : "keyTotal";
    const { normalizeToogle } = this.props.config;
    const normalizeData = this.props.normalizedData;
    const xAxisTitleText = this.props.meta.axesTitle.x;
    const yAxisTitleText = this.props.meta.axesTitle.y;
    const { xAxisTicksNotes } = this.props.config;
    const { yAxisTicksNotes } = this.props.config;
    const { xAxisTicksAlert } = this.props.config;

    let data = cloneDeep(this.props.data);

    if (normalizeData) {
      if (normalizeBy == "keyTotal") {
        const normalizationSum = [];
        data.map((d) => {
          d.values.map((item) => {
            const keyAlreadyIn = find(normalizationSum, function (o) {
              return o.key == item.key;
            });
            if (!keyAlreadyIn) {
              let sum = 0;
              data.map((b) => {
                sum =
                  find(b.values, function (o) {
                    return o.key == item.key;
                  }).value + sum;
              });
              normalizationSum.push({ key: item.key, sum: sum });
            }
          });
        });

        data = data.map((d, index) => {
          d.values.map((item) => {
            const demoninator = find(normalizationSum, function (o) {
              return o.key == item.key;
            }).sum;
            item.value = (item.value * 100) / demoninator;
            item.std = item.std ? (item.std * 100) / demoninator : 0;
          });
          return d;
        });
      } else {
        const demominatorValues = data.filter((d) => d.name == normalizeBy)[0]
          .values;
        data = data.map((d) => {
          d.values.map((item) => {
            const demoninator = demominatorValues.filter(
              (n) => n.key == item.key
            )[0].value;
            item.value = (item.value * 100) / demoninator;
            item.std = item.std ? (item.std * 100) / demoninator : 0;
          });
          return d;
        });
      }
    }

    data = data.map((d, index) => {
      d.values.map((item) => {
        if (index == 0 || chartLayout != "stacked") {
          item.value0 = 0;
        } else {
          item.value0 = find(data[index - 1].values, function (o) {
            return o.key == item.key;
          }).value;
        }
        item.std = item.std ? item.std : 0;
      });
      return d;
    });

    // The `margin` prop defines the "padding" inside the svg container. For some categorical
    // charts, we want to rotate the labels. This requires more space at the bottom
    const margin = cloneDeep(this.props.marginDefs);
    const axisMargin = cloneDeep(this.props.axisMarginDefs);

    const xAxisPadding = X_AXIS_TITLE_PADDING;

    // This constant is used to calculate innerWidht and innerHeigh based on the size of the div
    // containing the svg tag
    const horizontalMargin = axisMargin.horizontal;
    const verticalMargin = axisMargin.vertical;
    const legendVerticalSpace =
      (!hideLegends && legendPosition == "left" && data.length >= 2) ||
      chartObservation
        ? width * margin.withLegend.vertical
        : 0;
    const legendHorizontalSpace =
      !hideLegends && legendPosition == "top" && data.length >= 2
        ? margin.withLegend.horizontal
        : 0;

    const innerWidth =
      width -
      margin.left -
      margin.right -
      horizontalMargin -
      legendVerticalSpace;
    const innerHeight =
      height -
      margin.top -
      margin.bottom -
      verticalMargin -
      legendHorizontalSpace;
    const maxXAxisTicks = flipAxes
      ? innerHeight / X_AXIS_PIXELS_PER_TICK
      : innerWidth / X_AXIS_PIXELS_PER_TICK;

    // D3 selects the parent group tag using the React ref and attach nested groups to it.
    // When the component is updated, children of this node are removed and re-added.
    const parentG = d3.select(this.gWrapperRef);

    const g = parentG
      .append("g")
      .attr(
        "transform",
        "translate(" +
          (margin.left + horizontalMargin) +
          "," +
          (margin.top + legendHorizontalSpace) +
          ")"
      );

    // Create X/Y axes' svg groups
    const horizontalAxisG = g
      .append("g")
      .attr("class", "chart__axis__horizontal")
      .attr("transform", `translate(0,${innerHeight})`);

    const verticalAxisG = g.append("g").attr("class", "chart__axis__vertical");

    const yAxisG = flipAxes ? horizontalAxisG : verticalAxisG;
    const xAxisG = flipAxes ? verticalAxisG : horizontalAxisG;

    // Define X/Y axes' linear scales and ranges

    let xDomain;
    let yDomain;
    let xScale;
    let yScale;
    let xAxis;
    let yAxis;
    const increaseYMaxValue = this.props.increaseYMaxValue;
    const verticalRange = [innerHeight, 0];
    const horizontalRange = [0, innerWidth];

    yDomain = [
      d3.min(data, function (d) {
        return d3.min(d.values, function (b) {
          return b.value0;
        });
      }),
      d3.max(data, function (d) {
        return d3.max(d.values, function (b) {
          return b.value + b.value0 + b.std;
        });
      }) * increaseYMaxValue,
    ];

    yScale = d3.scale
      .linear()
      .domain(yDomain)
      .range(flipAxes ? horizontalRange : verticalRange);

    yAxis = d3.svg
      .axis()
      .scale(yScale)
      .orient(flipAxes ? "bottom" : "left")
      .tickSize(0)
      .tickFormat(function (d) {
        return DashboardHelper.roundUpNumber(d, 1);
      });

    yAxis.ticks(
      flipAxes
        ? innerWidth / Y_AXIS_PIXESL_PER_TICK
        : innerHeight / Y_AXIS_PIXESL_PER_TICK
    );

    if ((!flipAxes && !hideVerticalAxis) || (flipAxes && !hideHorizontalAxis)) {
      yAxisG.append("g").attr("class", "chart__axis chart__y-axis").call(yAxis);
    }

    // The if block below defines the desired type of X Axis (continuous or categorical/ordinal)
    if (axesType === "continuous") {
      // Specific features for a continuous X Axis
      xDomain = [
        d3.min(data, function (d) {
          return d3.min(d.values, function (b) {
            return b.key;
          });
        }),
        d3.max(data, function (d) {
          return d3.max(d.values, function (b) {
            return b.key;
          });
        }),
      ];

      xScale = d3.scale
        .linear()
        .domain(xDomain)
        .range(flipAxes ? verticalRange : horizontalRange);
    } else if (axesType === "ordinal") {
      // Specific features for a categorical X Axis.

      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      data.map((d) => {
        xDomain = unionBy(
          xDomain,
          d.values.map((b) => {
            return b.key;
          })
        );
      });
      xDomain = uniqBy(xDomain);

      if (alphanumSort) xDomain = xDomain.sort(collator.compare);

      xScale = d3.scale
        .ordinal()
        .domain(xDomain)
        .rangeBands(
          flipAxes ? verticalRange : horizontalRange,
          barPaddingRatio
        );
    } else if (axesType === "time") {
      // parse the date / time
      const parseTime = d3.time.format(parseDataFormat).parse;

      data = data.map((d) => {
        d.values.map((item) => {
          item.key = parseTime(item.key);
        });
        return d;
      });

      xDomain = [
        d3.min(data, function (d) {
          return d3.min(d.values, function (b) {
            return b.key;
          });
        }),
        d3.max(data, function (d) {
          return d3.max(d.values, function (b) {
            return b.key;
          });
        }),
      ];

      xScale = d3.time
        .scale()
        .domain(xDomain)
        .range(flipAxes ? verticalRange : horizontalRange);
    } else {
      console.error("Invalid Axes Type");
    }

    xAxis = d3.svg
      .axis()
      .scale(xScale)
      .orient(flipAxes ? "left" : "bottom")
      .tickPadding(X_AXIS_TICK_TOP_PADDING)
      .tickSize(0)
      .tickFormat(this.tickFormatFunction())
      .ticks(maxXAxisTicks);

    if ((flipAxes && !hideVerticalAxis) || (!flipAxes && !hideHorizontalAxis)) {
      xAxisG.append("g").attr("class", "chart__axis chart__x-axis").call(xAxis);

      // `extraTicksRatio` is based on the desired "tick density" we want. If it's > 0,
      // we hide one tick every `extraTicksRatio` ticks
      const extraTicksRatio = Math.ceil(xDomain.length / maxXAxisTicks);

      if (extraTicksRatio > 1) {
        xAxisG.selectAll("text").attr("opacity", function (d, i) {
          if (i % extraTicksRatio === 0) return "1";
          return "0";
        });
      } else {
        if (axesType !== "continuous")
          xAxisG.selectAll("text").call(wrap, xScale.rangeBand());
      }
    }

    const handleAlertMouseOver = function (alert) {
      const spanColor = isNil(alert.color) ? "#26344D" : "#fff";
      const content = (
        <div>
          <h6 className="chart-axis-alert-title" key="alert-title">
            <b
              style={{
                background: isNil(alert.color) ? "transparent" : alert.color,
                color: spanColor,
              }}
            >
              {alert.key}
            </b>
          </h6>
          <div style={{ maxWidth: 200 }} key={"alert-text"}>
            {alert.text}
          </div>
        </div>
      );
      props.mouseOverFunc(content);
    };

    const handleAlertMouseOut = function () {
      props.mouseOutFunc();
    };

    // make this better
    d3.selectAll(".tick").each(function (d, i) {
      let tick = d3.select(this);
      var text = tick.select("text");
      var bBox = text.node().getBBox();
      const alertIndex = findIndex(xAxisTicksAlert, function (a) {
        return a.key == d;
      });
      if (alertIndex >= 0) {
        if (!isNil(xAxisTicksAlert[alertIndex].color)) {
          const circle = tick
            .insert("circle")
            .attr("cx", bBox.x + bBox.width + 8 + 5)
            .attr("cy", bBox.height / 2 + 8)
            .attr("r", 8)
            .style("fill", xAxisTicksAlert[alertIndex].color);

          const circleBBox = circle.node().getBBox();

          tick
            .append("text")
            .attr("x", circleBBox.x + 6.5)
            .attr("y", circleBBox.y + 12.5)
            .style("fill", "white")
            .style("font-size", "12px")
            .style("font-weight", "700")
            .text("!")
            .on("mouseover", () =>
              handleAlertMouseOver(xAxisTicksAlert[alertIndex])
            )
            .on("mouseout", () => handleAlertMouseOut());

          circle
            .on("mouseover", () =>
              handleAlertMouseOver(xAxisTicksAlert[alertIndex])
            )
            .on("mouseout", () => handleAlertMouseOut());
        } else {
          tick
            .on("mouseover", () =>
              handleAlertMouseOver(xAxisTicksAlert[alertIndex])
            )
            .on("mouseout", () => handleAlertMouseOut());
        }
      }
    });

    // horizontalAxisG.select(".domain").remove();
    const hTicksLabelsHeight = hideHorizontalAxis
      ? 5
      : horizontalAxisG.select(".chart__axis").node().getBBox().height;
    const horizontalTicksNotesG = horizontalAxisG
      .append("g")
      .attr("class", "chart__axis_ticks_note_group")
      .attr("transform", "translate(0," + hTicksLabelsHeight + ")");

    if (!flipAxes && xAxisTicksNotes) {
      const notesG = horizontalTicksNotesG
        .selectAll("g")
        .data(xAxisTicksNotes)
        .enter()
        .append("g")
        .attr("transform", function (d) {
          return "translate(" + xScale(d.key) + ",10)";
        });

      const note = notesG
        .selectAll("chart__axis_ticks_notes")
        .data(function (b) {
          return b.notes;
        })
        .enter()
        .append("text")
        .attr("class", "chart__axis__ticks-note")
        .style("text-anchor", "middle")
        .attr("x", (d) => xScale.rangeBand() / 2)
        .attr("y", (d, i) => i * 15)
        .attr("dy", "0.71em")
        .text((d) => d)
        .call(wrap, xScale.rangeBand());
    }

    if (flipAxes && yAxisTicksNotes) {
      const noteTitleG = horizontalTicksNotesG
        .append("g")
        .attr("class", "chart__axis chart__axis_note-title_group");

      const noteTitle = noteTitleG
        .selectAll("chart__axis_note-title")
        .data(yAxisTicksNotes)
        .enter()
        .append("text")
        .attr("class", "chart__axis_note-title")
        .style("text-anchor", "middle")
        .attr("x", (b) => innerWidth / 2)
        .attr("y", (b, i) => i * 15)
        .attr("dy", "0.71em")
        .text((b) => b.key)
        .call(wrap, innerWidth);

      const notesG = horizontalTicksNotesG
        .selectAll(".chart__axis__ticks-note-group")
        .data(yAxisTicksNotes)
        .enter()
        .append("g")
        .attr("class", "chart__axis__ticks-note-group")
        .attr("transform", "translate(0, 20)");

      const note = notesG
        .selectAll("chart__axis_ticks_notes")
        .data(function (b) {
          return b.notes;
        })
        .enter()
        .append("text")
        .attr("class", "chart__axis__ticks-note")
        .style("text-anchor", "middle")
        .attr("x", (d) => innerWidth / 2)
        .attr("y", (d, i) => i * 15)
        .attr("dy", "0.71em")
        .text((d) => d)
        .call(wrap, innerWidth);
    }

    if (hideHorizontalBorderAxis) horizontalAxisG.select(".domain").remove(); // delete just the line of the horizontal axis
    if (hideVerticalBorderAxis) verticalAxisG.select(".domain").remove(); // delete just the line of the vertical axis

    const yUnitLabel = !normalizeToogle
      ? ""
      : normalizeData
      ? " Percentage (%)"
      : " Count";
    const verticalAxisTitle = flipAxes
      ? xAxisTitleText
      : yAxisTitleText + yUnitLabel;
    const horizontalAxisTitle = flipAxes
      ? yAxisTitleText + yUnitLabel
      : xAxisTitleText;
    // Append X and Y axes titles. They are completely independend from the axis element.

    if (!hideHorizontalAxis) {
      const hTicksNotesHeight = horizontalTicksNotesG.node().getBBox().height;
      const horizontalAxisTitleG = horizontalAxisG
        .append("g")
        .attr("class", "chart__axis_title")
        .attr(
          "transform",
          "translate(0," + (hTicksLabelsHeight + hTicksNotesHeight) + ")"
        );

      horizontalAxisTitleG
        .append("text")
        .style("text-anchor", "middle")
        .attr("x", innerWidth / 2)
        .attr("y", 15)
        .text(horizontalAxisTitle);
    }
    if (!hideVerticalAxis) {
      const vTicksLabelsWidth = verticalAxisG
        .select(".chart__axis")
        .node()
        .getBBox().width;
      verticalAxisG
        .append("text")
        .attr("class", "chart__axis_title")
        .style("text-anchor", "middle")
        .style("dominant-baseline", "hanging")
        .attr("transform", "rotate(-90)")
        .attr("x", 0 - innerHeight / 2)
        .attr("y", 0 - vTicksLabelsWidth - 15)
        .text(verticalAxisTitle);
    }
  }

  render() {
    return <g ref={this.setGWrapperRef}></g>;
  }
}

function wrap(text, width) {
  const MAX_NUMBER_LINES = 2;
  const MAX_NUMBER_CHAR = 6;
  text.each(function () {
    let text = d3.select(this);
    var words = text.text().split(/\s+/).reverse();
    var word;
    var line = [];
    var lineNumber = 1;
    var lineHeight = 1.1; // ems
    var x = text.attr("x");
    var y = text.attr("y");
    var dy = parseFloat(text.attr("dy"));
    var tspan = text
      .text(null)
      .append("tspan")
      .attr("x", x)
      .attr("y", y)
      .attr("dy", `${dy}em`);
    if (words.length == 1) {
      let label = words.join(" ");
      tspan.text(label);
      if (tspan.node().getComputedTextLength() > width) {
        label = `${label.substring(0, MAX_NUMBER_CHAR)}...`;
        tspan.text(label);
      }
    } else {
      while ((word = words.pop())) {
        if (lineNumber <= MAX_NUMBER_LINES) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            let lineLabel = line.join(" ");
            if (lineNumber == MAX_NUMBER_LINES) {
              lineLabel += "...";
            }
            tspan.text(lineLabel);
            ++lineNumber;
            if (lineNumber <= MAX_NUMBER_LINES) {
              line = [word];
              tspan = text
                .append("tspan")
                .attr("x", x)
                .attr("y", y)
                .attr("dy", lineNumber - 1 * lineHeight + dy + "em")
                .text(word);
            }
          }
        }
      }
    }
  });
}

export default ChartD3Wrapper;
