import React from 'react';
import ComponentHelper from '../../helpers/component-helper.jsx'
import LoadingHOC from '../core-app/loading-hoc.jsx'
import {Switch, Route, Link} from 'react-router-dom'
import SearchSelect from 'react-select';
import SelectInput from '../../components/basic-inputs/select'
import createReactClass from 'create-react-class'
import {find} from 'lodash'

import Table from '../../components/tables/filtered-table'
import Button from '../../components/button'
import AppPageTitle from '../../components/app-page-title'

const Users = createReactClass({

  deleteUser: function(id){
    const state = this.props.store.getState()
    const users = state.Administration.Users.users
    const user = find(users, ['id', id])
    let response = confirm("Do you really want to delete "+ user.first_name+' '+user.last_name+" ?");
    if (response == true) {
      this.props.store.dispatch({
        'type': 'DELETE_USER',
        'payload': {},
        'userId': user.id,
        'successHandler': this.refreshUsers
      })
    }
  },

  refreshDemoUsers: function(){
    this.props.store.dispatch({
      'type':'GET_ALL_DEMO_USERS'
    });
  },

  submit: async function (data){
    data.id = demoUserId
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        first_name: Yup.string().required(),
        last_name: Yup.string().required(),
        email: Yup.string().email().required(),
        password: Yup.string().min(6).required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      let event = demoUserId ? 'EDIT_DEMO_USER' : 'CREATE_DEMO_USER'
      dispatch({
        'type': event,
        'payload': data,
        'demoUserId': demoUserId,
        'successHandler': successSave
      })

    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  },

  render: function(){
    const state = this.props.store.getState()
    const users = state.Administration.Users.users
    const columns = [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter:true,
      },
      {
        Header: "Name",
        accessor: (row)=>row.first_name + ' ' + row.last_name,
        sortType: (a, b) => {
          let paramA = a.original.first_name + ' ' + a.original.last_name
          let paramB = b.original.first_name + ' ' + b.original.last_name
          if (paramA < paramB) return 1;
          if (paramA > paramB) return -1;
          if (paramA == paramB) return 0;
        }
      },
      {
        Header: "Email",
        accessor: "email",
        disableGlobalFilter:true,
      },
      {
        Header: "Last Login",
        disableGlobalFilter:true,
        disableSortBy:true,
        accessor:(row)=>row,
        Cell: ({ cell: { value } })=>{
          return (<span className={value.last_successful_login_attempt==value.last_login_attempt ? ' ': 'u-color-red-alert'}>
            {value .last_login_attempt ? printDateFormat(value.last_login_attempt) : 'Did not login yet'}
          </span>)
        }
      },
      {
        accessor:"id",
        id:"delete-btn",
        disableGlobalFilter:true,
        disableSortBy:true,
        Cell: ({ cell: { value } })=>(
          <a className="table-link table-link--danger icon-cancel" onClick={this.deleteUser.bind(this, value)}></a>
        )
      },
      {
        accessor:"id",
        id:"view-btn",
        disableGlobalFilter:true,
        disableSortBy:true,
        Cell: ({ cell: { value } })=>(
          <Link className="table-link icon-edit" to={'/administration/users/edit/'+value}></Link>
        )
      }
    ];

    return(
      <React.Fragment>
        <AppPageTitle
          title={'Users'}
          actions={
            <Button variant="confirm"><Link to={'/administration/users/add'}>New User</Link></Button>
          }
        />
        <div className='row'>
          <div className='col-sm-12'>
            <Table 
              columns={columns} data={users} urlState={true}
              actionButtons={<Link to="/administration/users/add"><Button variant="confirm">New Account</Button></Link>}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
});

// print in a more nice way the date recived
const printDateFormat = function (date){
  let str = date.split('-')
  return str[0]+'/'+str[1]+'/'+str[2]+' at '+str[3]+':'+str[4]
}

export default LoadingHOC()(Users)
