import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  grid-area: trial;

  padding: 0 15px;

  color: #0ca6c8;
  background: #c8e7f2;

  height: 25px;
`;
