import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-left: ${(props) => (props.labelOffset ? "9rem" : "0")};
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: 5px;
    margin-left: 5px;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }

  > button,
  a {
    margin-bottom: 15px;
  }
`;

export const Header = styled.label`
  padding: ${(props) => (props.inline ? "0 10px 0 0" : "0 0 2px 0")};
  color: #495f83;
  display: ${(props) => (props.inline ? "unset" : "block")};

  &::after {
    ${(props) => (props.required ? "content:' (required)'" : "")};
  }

  flex-basis: ${(props) => (props.inline ? "9rem" : "unset")};
  flex-shrink: ${(props) => (props.inline ? "0" : "unset")};
  flex-grow: ${(props) => (props.inline ? "0" : "unset")};
  text-align: ${(props) => (props.inline ? "right" : "unset")};

  font-weight: ${(props) => (props.boldTitle ? "bold" : "initial")};
`;
