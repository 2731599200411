import React, { useState, useEffect } from "react";
import { BsTable } from "react-icons/bs";
import { VscGraph } from "react-icons/vsc";
import Select from "../../../../../components/basic-inputs/select";

import ChartWrapper from "../../../../../components/dashboard-charts-wrapper/charts";
import Loader from "../../../../../components/loader";
import InternalPanel from "../../../../../components/panel/internal";
import { Heading3, Small } from "../../../../../components/typography";
import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../../services/mixpanel-tracking";

import {
  PanelTab,
  TabContainer,
  ChartContainer,
  LegendContainer,
  LegendWrapper,
  LegendHeader,
  LoaderWrapper,
} from "./styles";
import TableWrapper from "./table-wrapper";

const PANEL_TITLE = "Cumulative Event Rate";
const VIEWS = {
  chart: {
    label: "Chart",
    icon: () => <VscGraph size={13} />,
  },
  table: {
    label: "Table",
    icon: () => <BsTable size={13} />,
  },
};

function getPanelTitle(charts) {
  if (!charts || !charts.length) {
    return "";
  }
  return PANEL_TITLE;
}

function getPanelTabs(selectedView, changeSelectedView) {
  const tabs = Object.entries(VIEWS).map(([_, view]) => (
    <PanelTab
      key={view.label}
      selected={selectedView === view.label}
      onClick={() => {
        changeSelectedView(view.label);
      }}
    >
      {view.icon()}
      {view.label}
    </PanelTab>
  ));

  return <TabContainer>{tabs}</TabContainer>;
}

function getPanelActions(
  charts,
  selectedDropdown,
  handleDropdownChange,
  selectedView
) {
  if (!charts || !charts.length) {
    return null;
  }

  const actions = [];

  const options = charts.map(({ name }, index) => ({
    value: index,
    label: name,
  }));

  actions.push(
    <Select
      key="dropdown"
      options={options}
      value={selectedDropdown}
      onChange={(value) => {
        handleDropdownChange(value);
      }}
      marginBottom={0}
      disabled={selectedView === VIEWS.table.label}
    />
  );

  return actions;
}

function Legend({ legend }) {
  const confidenceInterval = legend["confidence_interval"].split("-");
  return (
    <LegendContainer>
      <LegendHeader>
        <Small>At 1 Year</Small>
      </LegendHeader>
      <LegendWrapper>
        <span style={{ color: "#666666" }}>P-value</span>
        <Heading3 marginTop={5}>
          {legend["p_value"] ? Number(legend["p_value"]).toFixed(2) : "N/A"}
        </Heading3>
      </LegendWrapper>
      <LegendWrapper>
        <span style={{ color: "#666666" }}>Confidence Interval</span>
        <Heading3 marginTop={5}>
          {legend["confidence_interval"]
            ? `${(Number(confidenceInterval[0]) * 100).toFixed(2)} -${(
                Number(confidenceInterval[1]) * 100
              ).toFixed(2)}`
            : "N/A"}
        </Heading3>
      </LegendWrapper>
      <LegendWrapper>
        <span style={{ color: "#666666" }}>Relative Risk Reduction (%)</span>
        <Heading3 marginTop={5}>
          {legend["relative_risk_change"]
            ? (Number(legend["relative_risk_change"]) * 100).toFixed(2)
            : "N/A"}
        </Heading3>
      </LegendWrapper>
      <LegendWrapper>
        <span style={{ color: "#666666" }}>NNT</span>
        <span style={{ color: "#666666" }}>(Number Needed to Treat)</span>
        <Heading3 marginTop={5}>
          {legend["nnt"] ? Math.ceil(Number(legend["nnt"])) : "N/A"}
        </Heading3>
      </LegendWrapper>
    </LegendContainer>
  );
}

function CumulativeEventRateWrapper({ charts, table, legend }) {
  const [selectedDropdown, setSelectedDropdown] = useState(0);
  const [selectedView, setSelectedView] = useState(VIEWS.chart.label);

  const selectedChart = charts[selectedDropdown];
  const selectedLegend = legend[selectedDropdown];

  // set selectedView to chart if there's no table object
  useEffect(() => {
    if (!table || !Object.keys(table).length) {
      setSelectedView(VIEWS.chart.label);
    }
  }, [table]);

  // set selectedDropdown to first item when the charts change
  useEffect(() => {
    setSelectedDropdown(0);
  }, [charts]);

  function handleDropdownChange(value) {
    mixpanelIdentify(localStorage.email);
    mixpanelTrack(`${PANEL_TITLE} - Change Chart`, {
      selectedChart: value,
      urlPathname: location.pathname,
    });

    setSelectedDropdown(value);
  }

  function changeSelectedView(newSelectedView) {
    mixpanelIdentify(localStorage.email);
    mixpanelTrack(`${PANEL_TITLE} - Change View`, {
      selectedView: newSelectedView,
      urlPathname: location.pathname,
    });

    setSelectedView(newSelectedView);
  }

  const panelTitle = getPanelTitle(charts);
  const panelActions = getPanelActions(
    charts,
    selectedDropdown,
    handleDropdownChange,
    selectedView
  );

  const panelTabs = getPanelTabs(selectedView, changeSelectedView);

  const chartsEmpty = !charts.length;
  const tableEmpty = !Object.keys(table).length;
  const isLoading = tableEmpty || chartsEmpty;

  return (
    <InternalPanel
      title={isLoading ? "" : panelTitle}
      actions={isLoading ? null : panelActions}
      bottomActions={isLoading ? null : panelTabs}
    >
      {isLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          {selectedView === VIEWS.chart.label && (
            <ChartContainer>
              <ChartWrapper
                key={VIEWS.chart.label}
                chart={selectedChart}
                visible={true}
              />
              <Legend legend={selectedLegend} />
            </ChartContainer>
          )}
          {selectedView === VIEWS.table.label && (
            <TableWrapper key={VIEWS.table.label} table={table} />
          )}
        </>
      )}
    </InternalPanel>
  );
}

export default CumulativeEventRateWrapper;
