import React from "react";
import { BsTable } from "react-icons/bs";
import { VscGraph } from "react-icons/vsc";
import ChartWrapper from "../../../../../components/dashboard-charts-wrapper/charts";
import TableWrapper from "../table-wrapper";
import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../../services/mixpanel-tracking";
import { Container, PanelTab } from "./styles";

const VIEWS = {
  chart: {
    label: "Chart",
    icon: () => <VscGraph size={13} />,
  },
  table: {
    label: "AUC Values",
    icon: () => <BsTable size={13} />,
  },
};

export function TabsViewContent({ selectedTab, chart, table }) {
  return (
    <>
      {selectedTab === VIEWS.chart.label && (
        <div className="charts-container">
          <ChartWrapper key={VIEWS.chart.label} chart={chart} visible={true} />
        </div>
      )}
      {selectedTab === VIEWS.table.label && <TableWrapper table={table} />}
    </>
  );
}

export function Tabs({ selectedTab, setSelectedTab }) {
  function changeSelectedView(newSelectedView) {
    mixpanelIdentify(localStorage.email);
    mixpanelTrack(`Risk Models - Change View`, {
      selectedTabView: newSelectedView,
      urlPathname: location.pathname,
    });

    setSelectedTab(newSelectedView);
  }

  const tabs = Object.entries(VIEWS).map(([_, view]) => (
    <PanelTab
      key={view.label}
      selected={selectedTab === view.label}
      onClick={() => {
        changeSelectedView(view.label);
      }}
    >
      {view.icon()}
      {view.label}
    </PanelTab>
  ));

  return <Container>{tabs}</Container>;
}
