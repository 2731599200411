import React from "react";
import * as d3 from "d3";
import { cloneDeep } from "lodash";

const LEGEND_SQUARE_SIZE = 12;
const LEGEND_SPACING = 8;
const LEGEND_LINE_HEIGHT = LEGEND_SQUARE_SIZE + LEGEND_SPACING;
const SQUARE_TEXT_SPACING = LEGEND_SQUARE_SIZE / 2;

class D3StatsLayer extends React.Component {
  constructor(props) {
    super(props);

    this.gWrapperRef = null;
    this.setGWrapperRef = (e) => {
      this.gWrapperRef = e;
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Ensures that the component doesn't get re-rendered without reason
    // TODO: understand why this is being called so many times

    // Currently, updates every time the width of height changes/
    // TODO: add check for data
    if (
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width ||
      this.props.stats !== nextProps.stats
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    d3.select(this.gWrapperRef).select("g").remove();
    if (this.props.stats) {
      this.renderStatsD3Wrapper();
    }
  }

  componentDidMount() {
    if (this.props.stats && this.props.height !== 0 && this.props.width !== 0) {
      this.renderStatsD3Wrapper();
    }
  }

  renderStatsD3Wrapper() {
    // Declare local variables from props
    const { width } = this.props;
    const { height } = this.props;
    const stats = cloneDeep(this.props.stats);

    const margin = cloneDeep(this.props.marginDefs);
    const maxLegendWidth = this.props.width * margin.withLegend.vertical;

    const statsStrings = [];
    Object.keys(stats).map(function (key) {
      const valueLabel =
        stats[key] % 1 === 0 ? stats[key] : stats[key].toFixed(2);
      statsStrings.push(`${symbols[key]}: ${valueLabel}`);
    });

    // D3 selects the parent group tag using the React ref and attach nested groups to it.
    // When the component is updated, children of this node are removed and re-added.
    const parentG = d3.select(this.gWrapperRef);

    const g = parentG
      .append("g")
      .attr("transform", `translate(-${maxLegendWidth},${margin.top})`);

    // Draw legend
    const stat = g
      .selectAll(".chart__stats")
      .data(statsStrings)
      .enter()
      .append("g")
      .attr("class", "chart__stats")
      .attr("transform", function (d, i) {
        return `translate(0,${i * LEGEND_LINE_HEIGHT})`;
      });
    stat
      .append("text")
      .attr("x", width)
      .attr("y", LEGEND_SQUARE_SIZE / 2)
      .attr("dy", ".35em")
      .style("text-anchor", "start")
      .text(function (d) {
        return d;
      });
  }

  render() {
    return <g ref={this.setGWrapperRef} />;
  }
}

let symbols = {
  mean: "\u00B5",
  std_deviation: "\u03C3",
  population: "N",
};

function wrap(text, width) {
  text.each(function () {
    const text = d3.select(this);
    const words = text.text().split(/\s+/).reverse();
    let word;
    let line = [];
    let lineNumber = 0;
    const lineHeight = 1.1; // ems
    const x = text.attr("x");
    const y = text.attr("y");
    const dy = parseFloat(text.attr("dy"));
    let tspan = text
      .text(null)
      .append("tspan")
      .attr("x", x)
      .attr("y", y)
      .attr("dy", `${dy}em`);
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", `${++lineNumber * lineHeight + dy}em`)
          .text(word);
      }
    }
  });
}

export default D3StatsLayer;
