import styled from "styled-components";

export const Container = styled.div``;

export const AddSimulationButton = styled.div`
  flex: none;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 9px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 9px;
  border-radius: 3px;

  ${(props) =>
    props.disabled &&
    css`
      color: #cccccc;
      cursor: not-allowed;
    `}
`;
