import React from "react";

import { Container, Title, Actions } from "./styles";

function AppPageTitle({ title, actions }) {
  return (
    <Container>
      <Title>{title}</Title>
      {actions ? <Actions>{actions}</Actions> : ""}
    </Container>
  );
}

export default AppPageTitle;
