import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import { MedicalContentProvider } from "../hooks/useMedicalContent";

// MCM
import ContentManager from "../workspaces/content-manager/content-manager.jsx";

// Medication Manager
import MedicationManager from "../workspaces/medication/medication-manager.jsx";

// Administration
import Administration from "../workspaces/administration/administration.jsx";

// Clint Identify
import CLINTIdentify from "../workspaces/identify";

// Page Not Found
import PageNotFound from "../workspaces/NotFound";

// Sign In
import SignIn from "../workspaces/core-app/signIn.jsx";

function ContentManagerComponent() {
  return (
    <MedicalContentProvider>
      <ContentManager />
    </MedicalContentProvider>
  );
}

function MedicationManagerComponent() {
  return (
    <MedicalContentProvider>
      <MedicationManager />
    </MedicalContentProvider>
  );
}

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route
        path="/content-manager"
        component={ContentManagerComponent}
        isPrivate
        authRoles={["mcm.manager", "mcm.viewer"]}
      />
      <Route
        path="/medication-manager"
        component={MedicationManagerComponent}
        isPrivate
        authRoles={["mcm.manager", "mcm.viewer"]}
      />
      <Route
        path="/administration"
        component={Administration}
        isPrivate
        authRoles={["admin", "healthpals"]}
      />
      <Route
        path="/clint-identify"
        component={CLINTIdentify}
        isPrivate
        authRoles={["identify.manager", "identify.viewer"]}
      />
      <Route containerFrame component={PageNotFound} isPrivate />
    </Switch>
  );
}
