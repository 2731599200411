import React from "react";
import Collapsible from "../../../../../../../components/collapsible";
import Boolean from "./boolean";
import Categorical from "./categorical";
import Numerical from "./numerical";

import { FilterLabel } from "./styles";

function Filter({ filter, subcohort, draftFilterList, setDraftFilterList }) {
  const getFilterElement = () => {
    let filterInput = null;
    switch (filter.type) {
      case "boolean":
        filterInput = (
          <Boolean
            filter={filter}
            draftFilterList={draftFilterList}
            setDraftFilterList={setDraftFilterList}
          />
        );
        break;
      case "numerical":
        filterInput = (
          <Numerical
            filter={filter}
            draftFilterList={draftFilterList}
            setDraftFilterList={setDraftFilterList}
          />
        );
        break;
      case "categorical":
        filterInput = (
          <Categorical
            filter={filter}
            draftFilterList={draftFilterList}
            setDraftFilterList={setDraftFilterList}
          />
        );
        break;
      default:
        break;
    }

    // \u2022 Bullet Symbol code
    const bullet = `\n\u2022`;
    const filterUnit = filter.units;
    const isApplied = subcohort.filterList && subcohort.filterList[filter.key];
    const filterLabel = (
      <FilterLabel isApplied={isApplied}>
        {`${isApplied ? bullet : ""} ${filter.label}${
          filterUnit ? `, ${filterUnit}` : ""
        }`}
      </FilterLabel>
    );

    return (
      <Collapsible key={filter.key} title={filterLabel}>
        {filterInput}
      </Collapsible>
    );
  };

  return getFilterElement();
}

export default Filter;
