import styled from "styled-components";

import { Container as Panel } from "../../../../components/panel/internal/styles";

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    `repeat(${props.grid[0]}, minmax(0, 1fr))`};
  grid-template-rows: ${(props) => `repeat(${props.grid[1]}, minmax(0, 1fr))`};
  grid-column-gap: 14px;
  grid-row-gap: 12px;

  > ${Panel} {
    margin-bottom: 0;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
