import styled, { css } from "styled-components";

export const Footer = styled.div`
  padding: 9px;
  border-top: 1px solid #e6e6e6;

  flex: none;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  button {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  padding: 0 9px;
  flex: 1 1 auto;
  overflow-y: auto;
`;

export const SearchWrapper = styled.div`
  padding: 0 9px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  > div {
    flex: 1;
  }
`;
