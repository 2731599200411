import React, { useState } from "react";
import * as ReactDOM from "react-dom";

import { Container, TooltipText, TooltipBox } from "./styles";

const BODYCONTAINER = document.body;

function tooltip({ children, tooltipContent }) {
  const [isShowing, setIsShowing] = useState(false);
  const [coordinates, setCoordinates] = useState({ left: 0, top: 0 });

  const handleMouseMove = (event) => {
    // 👇 Get mouse position relative to element
    const localX = event.clientX;
    const localY = event.clientY;

    setCoordinates({ left: localX, top: localY });
  };

  const showTip = () => {
    setIsShowing(true);
  };

  const hideTip = () => {
    setIsShowing(false);
  };

  return (
    <Container>
      <TooltipText
        onMouseMove={handleMouseMove}
        onMouseOver={showTip}
        onMouseOut={hideTip}
      >
        {children}
      </TooltipText>
      {isShowing
        ? ReactDOM.createPortal(
            <TooltipBox coordinates={coordinates}>{tooltipContent}</TooltipBox>,
            BODYCONTAINER
          )
        : ""}
    </Container>
  );
}

export default tooltip;
