import React, { Fragment, useRef, useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { uniqBy } from "lodash";

import { MdDelete } from "react-icons/md";

import { useGuidelineDocument } from "../../../hooks/useGuidelineDocuments";

import { ActionButtons } from "./styles";

import Input from "../../../components/unform-inputs/input";
import Textarea from "../../../components/unform-inputs/textarea";
import ReactSelect from "../../../components/unform-inputs/react-select";
import Button from "../../../components/button";
import GuidelineHelper from "../../../helpers/guideline-helper";

export default function GuidelineDocumentsForm({
  document,
  cancelFormAction,
  usageNumber = 0,
}) {
  const history = useHistory();
  const formRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultForm, setDefaultForm] = useState({});

  const { documents, AddDocument, EditDocument, DeleteDocument } =
    useGuidelineDocument();

  const moduleOptions = useMemo(() => {
    const options = [];
    for (const document of documents) {
      options.push({ value: document.module, label: document.module });
    }
    return uniqBy(options, "value");
  }, [documents]);

  useEffect(() => {
    async function defaultValues() {
      if (document) {
        await formRef.current.setData(document);
        setDefaultForm(formRef.current.getData());
      }
    }
    defaultValues();
  }, [document]);

  async function submit(data) {
    setErrorMessage(null);
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        module: Yup.string().required("This is a required field"),
        source: Yup.string().required("This is a required field"),
        title: Yup.string().required("This is a required field"),
        authors: Yup.string().required("This is a required field"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      data.id = document ? document.id : null;
      data = GuidelineHelper.removeEmptyStrings(data);

      if (document) {
        EditDocument(data, data.id, successSave, errorSave);
      } else {
        AddDocument(data, successSave, errorSave);
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const successSave = function () {
    cancelForm();
  };

  const errorSave = function (errorMessages) {
    setErrorMessage(errorMessages);
  };

  const cancelForm = () => {
    formRef.current.setData(defaultForm);
    if (cancelFormAction) {
      cancelFormAction();
    } else {
      history.goBack();
    }
  };

  const deleteGuidelineDocument = () => {
    const response = confirm(
      `Do you really want to delete ${document.title} ?`
    );
    if (response == true) {
      DeleteDocument(document.id, deleteSuccess);
    }
  };

  const deleteSuccess = () => {
    history.push("/content-manager/guideline-documents/");
  };

  return (
    <>
      {errorMessage ? (
        <div className="patient-status__alert-message alert-message__warning">
          <p className="alert-message__paragraph u-color-denim-40">
            <b>Alert:</b> {errorMessage}
          </p>
        </div>
      ) : (
        ""
      )}
      <Form ref={formRef} onSubmit={submit} className="form-box">
        <div className="row">
          <div className="col-sm-6 col-xs-12">
            <ReactSelect
              name="module"
              options={moduleOptions}
              creatable
              required
              label="Disease State"
            />
          </div>
          <div className="col-sm-6 col-xs-12">
            <Input name="publication_date" label="Publication Date" />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Input name="source" required label="Guideline Source" />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Textarea rows="2" name="title" required label="Guideline Title" />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Textarea rows="2" name="subtitle" label="Guideline Subtitle" />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Input name="download_url" label="Download Url" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-xs-12">
            <Input name="doi_number" label="DOI Number" />
          </div>
          <div className="col-sm-6 col-xs-12">
            <Input name="issn_number" label="ISSN Number" />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Textarea rows="3" required name="authors" label="Authors" />
          </div>
        </div>

        <ActionButtons>
          <div>
            {document ? (
              <Button
                variant="clear"
                disabled={usageNumber > 0}
                leftIcon={<MdDelete />}
                type="button"
                onClick={() => deleteGuidelineDocument()}
              >
                Delete guideline document
              </Button>
            ) : (
              ""
            )}
          </div>
          <div>
            <Button
              variant="outline"
              type="button"
              onClick={() => cancelForm()}
            >
              Cancel
            </Button>
            <Button variant="confirm" type="submit">
              {document ? "Edit Document" : "Create Document"}
            </Button>
          </div>
        </ActionButtons>
      </Form>
    </>
  );
}
