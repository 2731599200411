import { cloneDeep, isEmpty, merge } from "lodash";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import {
  getCohortIndexList,
  getDatasetConfig,
  getSavedSubcohort,
} from "../services/insights-data-service";

const IdentifyCohortConfigsContext = createContext({});

// TODO: once all indices have a settings object, remove this default
const DEFAULT_SETTINGS = {
  table_1_export_enabled: true,
  erc_export_enabled: true,
  risk_model_export_enabled: true,
  modules: {
    table_1_module_enabled: true,
    erc_module_enabled: true,
    geomap_module_enabled: true,
    risk_model_module_enabled: false,
  },
  enabledModulesCount: 3,
};

function countEnabledModules(modules) {
  return Object.values(modules).reduce((a, module) => a + module, 0);
}

const IdentifyCohortConfigsReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_COHORT_INDEX_LIST":
      return {
        ...state,
        cohortIndexList: action.payload,
        cohortIndex: action.payload[0],
      };
    case "CHANGE_SELECTED_COHORT_INDEX":
      return {
        ...state,
        cohortIndex: action.payload,
        consortDiagram: null,
        table1Config: null,
        riskModelConfig: null,
        ercConfig: null,
        sitesConfig: null,
        table2Config: null,
        filtersConfig: null,
        simulationsConfig: null,
        settings: null,
      };
    case "CHANGE_CONFIGS":
      const settings = merge(
        cloneDeep(DEFAULT_SETTINGS),
        cloneDeep(action.payload?.settings)
      );
      settings.enabledModulesCount = countEnabledModules(settings.modules);
      return {
        ...state,
        consortDiagram: action.payload?.consort_diagram || {},
        table1Config: action.payload?.table1_config || null,
        riskModelConfig: action.payload?.risk_models || null,
        ercConfig: action.payload?.event_rate_curves || null,
        sitesConfig: action.payload?.sites || null,
        table2Config: action.payload?.table2_config || null,
        simulationsConfig: action.payload?.simulations || null,
        savedSubcohorts: action.payload?.saved_subcohorts || [],
        filtersConfig: action.payload?.filter_list
          ? parseAttributeFilters(action.payload.filter_list)
          : {},
        settings: settings,
      };
    case "CHANGE_COHORT_SAVED_SUBCOHORT_LIST":
      return {
        ...state,
        savedSubcohorts: action.payload || [],
      };
    default:
      return state;
  }
};

const defaultState = {
  cohortIndexList: [],
  cohortIndex: {},
  savedSubcohorts: [],
  consortDiagram: null,
  table1Config: null,
  riskModelConfig: null,
  ercConfig: null,
  sitesConfig: null,
  table2Config: null,
  filtersConfig: null,
  simulationsConfig: null,
  settings: null,
};

export function IdentifyCohortConfigsProvider({ children }) {
  const [cohortConfigs, dispatch] = useReducer(
    IdentifyCohortConfigsReducer,
    defaultState
  );

  useEffect(async () => {
    const cohortIndexList = await getCohortIndexList();
    dispatch({ type: "CHANGE_COHORT_INDEX_LIST", payload: cohortIndexList });
  }, []);

  useEffect(async () => {
    if (!isEmpty(cohortConfigs.cohortIndex)) {
      const cohortIndexConfig = await getDatasetConfig(
        cohortConfigs.cohortIndex.index_name
      );

      const cohortIndexSavedSubcohorts = await getSavedSubcohort(
        cohortConfigs.cohortIndex.index_name
      );

      dispatch({
        type: "CHANGE_CONFIGS",
        payload: {
          ...cohortIndexConfig,
          saved_subcohorts: cohortIndexSavedSubcohorts,
        },
      });
    }
  }, [cohortConfigs.cohortIndex?.index_name]);

  return (
    // put here the variables that component need to share
    <IdentifyCohortConfigsContext.Provider value={{ cohortConfigs, dispatch }}>
      {children}
    </IdentifyCohortConfigsContext.Provider>
  );
}

export function useIdentifyCohortConfigs() {
  const context = useContext(IdentifyCohortConfigsContext);

  return context;
}

function parseAttributeFilters(attributeList) {
  const newAttributeFilterList = {};
  attributeList.forEach((attribute) => {
    newAttributeFilterList[attribute.key] = {
      ...attribute,
      defaultValue:
        attribute.type === "numerical" ? [...attribute.options] : [],
    };
  });
  return newAttributeFilterList;
}
