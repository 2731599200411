import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  display: inline-block;
`;

export const ChildrenElement = styled.div``;

export const TooltipContainer = styled.div`
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;

  text-rendering: optimizeLegibility;
  overflow-wrap: normal;
  box-sizing: content-box;
  border: unset;

  z-index: 9999;
  display: inline-block;

  position: absolute;
  left: ${(props) => props.coordinates.left};
  top: ${(props) => props.coordinates.top};

  ${(props) =>
    props.position === "bottom" &&
    css`
      transform: translateY(5px) translateX(-50%);
    `}

  ${(props) =>
    props.position === "top" &&
    css`
      transform: translateY(calc(-100% - 5px)) translateX(-50%);
    `}
`;

export const TooltipContent = styled.div`
  width: 150px; // TODO see how can you make this generic
  padding: 3px 5px;
  color: white;
  background-color: var(--tertiary);
  border-radius: 3px;
`;
