import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: white;
`;

export const SubcohortsWrapper = styled.div`
  flex: 1;
`;

export const Card = styled.div`
  border: 1px solid #e6e6e6;
  margin: 9px;
  border-radius: 3px;
`;

export const CardTitle = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;

  > button {
    margin: 0;
  }
`;
export const CardContent = styled.div`
  border-left: 5px solid ${(props) => props.color};
  padding: 10px;

  & + & {
    border-top: 1px solid #e6e6e6;
  }
`;

export const CenterRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const SpaceBetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
