import React, { useRef, useMemo, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../../components/unform-inputs/input";
import Loader from "../../components/loader";
import Button from "../../components/button";

import Actions from "../../actions/actions.js";

import { useLottie } from "lottie-react";
import * as ClintLogoAnimationData from "../../images/clint-logo-animation.json";

const lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: ClintLogoAnimationData,
};
const lottieStyle = {
  height: 92,
  width: 220,
};

export default function SignIn(props) {
  const store = props.store;
  const { roleType } = useParams();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const Session = useSelector((state) => state.Session);
  const { View } = useLottie(lottieOptions, lottieStyle);

  const currentYear = new Date().getFullYear();

  // Handle login logic
  const login = async function (data) {
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email("This is an invalid email")
          .required("This is a required field"),
        password: Yup.string().required("This is a required field"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      dispatch({
        type: "USER_LOGIN",
        payload: data,
        successHandler: initializeApp,
      });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const initializeApp = function () {
    Actions.initializeAppState(store);
    dispatch({ type: "SUCCESSFULLY_SIGNED_IN" });
  };

  let errorMsg = useMemo(() => {
    if (Session.errorMessages) {
      return (
        <div className="alert alert-danger alert-dismissible" role="alert">
          {Session.errorMessages.map((message) => (
            <p>{message}</p>
          ))}
        </div>
      );
    } else {
      return <Fragment />;
    }
  }, [Session.errorMessages]);

  const getButtonContent = function () {
    if (Session.isLoading) {
      return <Loader size="xs" />;
    } else {
      return (
        <span className="u-color-white u-font-size-small u-text-allcaps">
          Login
        </span>
      );
    }
  };

  return (
    <div className="signin__container">
      {View}

      <div className="signin__form__container">
        {/* <span className='u-text-capitalized'>{roleType} Login</span> */}

        <Form ref={formRef} onSubmit={login} className="signin__form form">
          <Input
            name="email"
            autoFocus
            type="email"
            placeholder="Email"
            inline={false}
          />
          <Input
            name="password"
            type="password"
            placeholder="Password"
            inline={false}
          />
          <Button type="submit" variant={"confirm"} marginBottom={10}>
            {getButtonContent()}
          </Button>
        </Form>

        {errorMsg}
      </div>

      <div className="signin-footer">
        {/* <p className='signin-terms'>By clicking Login, I accept the <a>Privacy Policy</a> and <a>HealthPals CLINT terms of use</a></p> */}
        <div className="signin-copyright">
          <span className="signin__logo">
            <img src="/images/healthpals-logo-10.svg"></img>
          </span>
          <span className="small">
            © 2005 - {currentYear} HealthPals, Inc. All rights reserved
          </span>
        </div>
      </div>
    </div>
  );
}
