import styled from "styled-components";

export const Container = styled.div`
  ul {
    list-style-type: disc;
    list-style-position: outside;
    padding: 0 0 0 24px;
    line-height: 163%;
  }

  li {
    color: #666666;
    line-height: 163%;
  }

  div {
    color: #666666;
  }

  p {
    color: #666666;
    padding: 8px 0;
    line-height: 163%;
  }

  p:first-of-type {
    padding-top: 0;
  }

  p:last-of-type {
    padding-bottom: 0;
  }

  p strong {
    color: var(--primary);
  }
`;
