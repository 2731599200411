import React, { useRef, useState, useEffect } from "react";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { useLocation } from "react-router-dom";
import { find, isEqual } from "lodash";

import Modal from "../../../../../../components/modal";
import Alert from "../../../../../../components/message-alert";

import { Footer } from "./styles";
import Button from "../../../../../../components/button";
import Input from "../../../../../../components/unform-inputs/input";
import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../../../services/mixpanel-tracking";
import { useIdentifyCohortConfigs } from "../../../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySubcohortList } from "../../../../../../hooks/useIdentifySubcohortList";

import {
  getSavedSubcohort,
  saveNewSubcohort,
} from "../../../../../../services/insights-data-service";

function SaveSubcohort({
  toggleModal,
  isShowingModal,
  subcohortID,
  draftFilterList,
  subcohort,
}) {
  const formRef = useRef(null);
  const timerRef = useRef(null);
  const location = useLocation();

  const { cohortConfigs, dispatch: cohortConfigsDispatch } =
    useIdentifyCohortConfigs();
  const { cohortIndex } = cohortConfigs;
  const { dispatch: subcohortListDispatch } = useIdentifySubcohortList();

  const [messageAlertConfig, setMessageAlertConfig] = useState({});

  useEffect(() => {
    if (messageAlertConfig.timeout)
      setTimeout(() => {
        setMessageAlertConfig({});
      }, messageAlertConfig.timeout);
  }, [messageAlertConfig]);

  function handleHide() {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    setMessageAlertConfig({});
    toggleModal();
  }

  async function submit(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await saveNewSubcohort(
        cohortIndex.index_name,
        draftFilterList,
        data.name
      );

      if (response.data.valid) {
        if (isEqual(draftFilterList, subcohort.filterList)) {
          subcohortListDispatch({
            type: "UPDATE_SUBCOHORT_NAME",
            payload: {
              id: subcohortID,
              name: data.name,
              isSaved: true,
            },
          });
        } else {
          subcohortListDispatch({
            type: "APPLY_SUBCOHORT_FILTERS",
            payload: {
              id: subcohortID,
              name: data.name,
              filterList: draftFilterList,
              isSaved: true,
            },
          });
        }

        mixpanelIdentify(localStorage.email);
        mixpanelTrack("Save Subcohort", {
          cohort: cohortIndex.index_name,
          subcohort: data.name,
          query: draftFilterList,
          urlPathname: location.pathname,
        });

        const newSavedSubcohorts = await getSavedSubcohort(
          cohortIndex.index_name
        );

        cohortConfigsDispatch({
          type: "CHANGE_COHORT_SAVED_SUBCOHORT_LIST",
          payload: newSavedSubcohorts,
        });

        setMessageAlertConfig({
          message: `Subcohort "${data.name}" saved`,
          variant: "success",
        });

        timerRef.current = setTimeout(() => {
          handleHide();
        }, 1000);
      } else {
        let message = response.data.messages.join();
        message = message.includes("Tried to save duplicate unique keys")
          ? `"${data.name}" already exists under the cohort ${cohortIndex.index_human_readable_name}. Please try a new name.`
          : message;
        setMessageAlertConfig({
          timeout: 5000,
          message,
          variant: "warning",
        });
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <div>
      <Modal
        title="Save subcohort"
        isShowing={isShowingModal}
        hide={handleHide}
      >
        {messageAlertConfig.message ? (
          <Alert
            variant={messageAlertConfig.variant}
            timeout={messageAlertConfig.timeout}
          >
            {messageAlertConfig.message}
          </Alert>
        ) : (
          ""
        )}
        {messageAlertConfig.message &&
        messageAlertConfig.variant == "success" ? (
          ""
        ) : (
          <Form ref={formRef} onSubmit={submit}>
            <Input name="name" label="Save as:" placeholder="Name subcohort" />
            <Footer>
              <Button variant="confirm" type="submit">
                {isEqual(draftFilterList, subcohort.filterList) ? (
                  <span>Save</span>
                ) : (
                  <span>Save &amp; Apply</span>
                )}
              </Button>
            </Footer>
          </Form>
        )}
      </Modal>
    </div>
  );
}

export default SaveSubcohort;
