import React, { Fragment, useRef, useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";

import { MdDelete } from "react-icons/md";
import { FaTimes } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { BiPlusCircle } from "react-icons/bi";

import { concat, find, isEmpty } from "lodash";

import {
  TriggerCombinationBtn,
  FormRow,
  TriggerGroup,
  OutputGroup,
  ActionButtons,
} from "./styles";

import Input from "../../../components/unform-inputs/input";
import Select from "../../../components/unform-inputs/select";
import ReactSelect from "../../../components/unform-inputs/react-select";
import Textarea from "../../../components/unform-inputs/textarea";
import Scope from "../../../components/unform-inputs/scope";
import LogicExpressionSelect from "../../../components/unform-inputs/logic-expression-select";
import Button from "../../../components/button";
import GuidelineHelper from "../../../helpers/guideline-helper";

import { useGuidelineDocument } from "../../../hooks/useGuidelineDocuments";
import { useGuidelineRule } from "../../../hooks/useGuidelineRules";
import { usePatientAttribute } from "../../../hooks/usePatientAttributes";
import { useMedicationMechanisms } from "../../../hooks/useMedicationMechanisms";
import { useMedicationIntents } from "../../../hooks/useMedicationIntents";
import { useMedicationDrugs } from "../../../hooks/useMedicationDrugs";

export default function GuidelineForm({ guideline, cancelFormAction }) {
  const history = useHistory();
  const formRef = useRef(null);

  const { AddGuideline, EditGuideline, DeleteGuideline } = useGuidelineRule();

  const [outputScope, setOutputScope] = useState([""]);
  const [triggerScope, setTriggerScope] = useState([{}]);
  const [triggerTypeSimple, setTriggerTypeSimple] = useState("AND");
  const [compoundTriggersToogle, setCompoundTriggersToggle] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultForm, setDefaultForm] = useState({});

  // trigger and Output options
  const { attributes } = usePatientAttribute();
  const { medicationIntents } = useMedicationIntents();
  const { medicationMechanisms } = useMedicationMechanisms();
  const { medications } = useMedicationDrugs();

  const triggers = useMemo(() => {
    return concat(
      attributes,
      medicationIntents,
      medicationMechanisms,
      medications
    );
  }, [attributes, medicationIntents, medicationMechanisms, medications]);

  const triggerAndOutputOptions = useMemo(() => {
    const options = [];
    for (const option of triggers) {
      options.push({ value: option.id, label: option.human_readable_name });
    }
    return options;
  }, [triggers]);

  // create the documents options
  const { documents } = useGuidelineDocument();
  const documentsOptions = useMemo(() => {
    const options = [];
    for (const option of documents) {
      const optionName = `${option.module}-${option.source}-${option.publication_date}: ${option.title}`;
      options.push({ value: option.id, label: optionName });
    }
    return options;
  }, [documents]);

  const editGuideline = useMemo(() => {
    if (!isEmpty(guideline)) {
      setTriggerScope(
        guideline.triggers.map((trigger) => {
          const attribute = find(triggers, ["id", parseInt(trigger.attribute)]);
          trigger.data_type = attribute.data_type;
          trigger.available_values = attribute.available_values;
          return trigger;
        })
      );
      setOutputScope(guideline.outputs);
      if (
        guideline.trigger_combination_type != "AND" &&
        guideline.trigger_combination_type != "OR"
      ) {
        setCompoundTriggersToggle(true);
      } else {
        setTriggerTypeSimple(guideline.trigger_combination_type);
      }

      return guideline;
    }
  }, [guideline]);

  useEffect(() => {
    async function defaultValues() {
      if (
        editGuideline &&
        documentsOptions.length > 0 &&
        triggerAndOutputOptions.length > 0
      ) {
        await formRef.current.setData(editGuideline);
        setDefaultForm(formRef.current.getData());
      }
    }
    defaultValues();
  }, [editGuideline, documentsOptions, triggerAndOutputOptions]);

  const schema = Yup.object().shape({
    rule_type: Yup.string()
      .required("Rule Type is required")
      .oneOf(
        ["tx", "dx", "iv"],
        "The Rule type needs to be one of this elements: Treatment, Diagnosis or Intermediate Var."
      ),
    status: Yup.string()
      .required("Status is required")
      .oneOf(
        ["draft", "published"],
        "The Status should be Draft or Published."
      ),
    guideline_document: Yup.number("Guideline Document is required").required(
      "Guideline Document is required"
    ),
    healthpals_rule_grade: Yup.string()
      .required("HealthPals Grade is required")
      .oneOf(
        ["strong", "moderate", "optional", "expert_opinion", "unassessed"],
        "HealthPals Grade needs to be one of this elements: Strong, Moderate, Optional, Expert Opinion or Unassessed"
      ),
    rule_value: Yup.string().required("Rule description is required"),
    evidence_level: Yup.string().required("Rule Grade is required"),
    name: Yup.string().required("Title is required"),
    triggers: TriggerSchema,
    outputs: Yup.array()
      .of(Yup.number("Output is required"))
      .min(1, "Must have at least one Output"),
  });

  const TriggerSchema = Yup.array()
    .of(
      Yup.object().shape({
        attribute: Yup.number().required("Trigger Attribute is required"),
        comparator: Yup.string()
          .required("Comparator is required")
          .oneOf(
            ["==", "<", ">", "<=", ">=", "==", "!="],
            "The Comparator is invalid"
          ),
        compare_value: Yup.mixed()
          .when(["attribute"], {
            is: (attribute) =>
              find(triggers, ["id", parseInt(attribute)]).data_type ==
              "numerical",
            then: Yup.number().required("Compare Value is Required"),
          })
          .when(["attribute"], {
            is: (attribute) =>
              find(triggers, ["id", parseInt(attribute)]).data_type ==
              "categorical",
            then: Yup.string().required("Compare Value is Required"),
          })
          .when(["attribute"], {
            is: (attribute) =>
              find(triggers, ["id", parseInt(attribute)]).data_type ==
              "boolean",
            then: Yup.boolean().required("Compare Value is Required"),
          }),
      })
    )
    .min(1, "Must have at least one Trigger");

  async function submit(data) {
    setErrorMessage(null);

    try {
      // Remove all previous errors
      formRef.current.setErrors({});

      await schema.validate(data, {
        abortEarly: false,
      });

      // TODO find away to fix the need convert triggers compare_value and have trigger_combination_type set to AND as default not null
      data.triggers = data.triggers.map((trigger) => {
        const attribute = find(triggers, ["id", trigger.attribute]);
        if (attribute.data_type === "numerical") {
          trigger.compare_value = parseFloat(trigger.compare_value);
        } else if (attribute.data_type === "boolean") {
          trigger.compare_value = trigger.compare_value === "true";
        }
        return trigger;
      });
      data.trigger_combination_type = data.trigger_combination_type
        ? data.trigger_combination_type
        : "AND";

      data.id = guideline ? guideline.id : null;
      data = GuidelineHelper.removeEmptyStrings(data);

      if (guideline) {
        EditGuideline(data, data.id, successSave, errorSave);
      } else {
        AddGuideline(data, successSave, errorSave);
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const successSave = function () {
    cancelForm();
  };

  const errorSave = function (errorMessages) {
    setErrorMessage(errorMessages);
  };

  const cancelForm = () => {
    formRef.current.setData(defaultForm);
    if (guideline) {
      setTriggerScope(
        guideline.triggers.map((trigger) => {
          const attribute = find(triggers, ["id", parseInt(trigger.attribute)]);
          trigger.data_type = attribute.data_type;
          trigger.available_values = attribute.available_values;
          return trigger;
        })
      );
      setOutputScope(guideline.outputs);
      if (
        guideline.trigger_combination_type != "AND" &&
        guideline.trigger_combination_type != "OR"
      ) {
        setCompoundTriggersToggle(true);
      } else {
        setTriggerTypeSimple(guideline.trigger_combination_type);
      }
    }
    if (cancelFormAction) {
      cancelFormAction();
    } else {
      history.goBack();
    }
  };

  const deleteGuideline = () => {
    const response = confirm(
      `Do you really want to delete ${guideline.name} ?`
    );
    if (response == true) {
      DeleteGuideline(guideline.id, deleteSuccess);
    }
  };

  const deleteSuccess = () => {
    history.push("/content-manager/rules/");
  };

  const deleteOutput = function (index) {
    formRef.current.setFieldValue(
      `outputs[${index}]`,
      formRef.current.getFieldValue(`outputs[${index + 1}]`)
    );
    const newOutput = [...outputScope];
    newOutput.splice(index, 1);
    setOutputScope(newOutput);
  };

  const getInputsByType = function (selectedTrigger, index) {
    switch (selectedTrigger.data_type) {
      case "numerical":
        return (
          <>
            <Select
              style={{ flex: "1" }}
              name="comparator"
              setDefaultValue="=="
              options={numericalComparatorOptions}
              onChange={(value) =>
                handleTriggerChange(index, value, "comparator")
              }
            />
            <Input
              style={{ flex: "1" }}
              name="compare_value"
              type="number"
              step="0.0001"
              onChange={(value) =>
                handleTriggerChange(index, value, "compare_value")
              }
            />
          </>
        );
      case "boolean":
        return (
          <>
            <Select
              style={{ flex: "1" }}
              name="comparator"
              setDefaultValue="=="
              options={booleanComparatorOptions}
              onChange={(value) =>
                handleTriggerChange(index, value, "comparator")
              }
            />
            <Select
              style={{ flex: "1" }}
              name="compare_value"
              setDefaultValue="true"
              options={booleanValueOptions}
              onChange={(value) =>
                handleTriggerChange(index, value, "compare_value")
              }
            />
          </>
        );
      case "categorical":
        return (
          <>
            <Select
              style={{ flex: "1" }}
              name="comparator"
              setDefaultValue="=="
              options={categoricalComparatorOptions}
              onChange={(value) =>
                handleTriggerChange(index, value, "comparator")
              }
            />
            <Select
              style={{ flex: "1" }}
              name="compare_value"
              options={selectedTrigger.available_values}
              onChange={(value) =>
                handleTriggerChange(index, value, "compare_value")
              }
            />
          </>
        );
      default:
        return (
          <>
            <Select
              style={{ flex: "1" }}
              name="comparator"
              disabled
              readOnly
              options={booleanComparatorOptions}
            />
            <Select
              style={{ flex: "1" }}
              name="compare_value"
              disabled
              readOnly
              options={booleanValueOptions}
            />
          </>
        );
    }
  };

  const handleTriggerChange = function (index, value, name) {
    const newTriggersScope = [...triggerScope];
    if (name == "attribute") {
      newTriggersScope[index][name] = value.value;
      const attribute = find(triggers, ["id", parseInt(value.value)]);
      newTriggersScope[index].data_type = attribute.data_type;
      newTriggersScope[index].available_values = attribute.available_values;
    } else {
      newTriggersScope[index][name] = value;
    }
    setTriggerScope(newTriggersScope);
  };

  const changeTriggerType = function () {
    let triggerType = formRef.current.getFieldValue("trigger_combination_type");
    if (!triggerType) triggerType = "AND";
    formRef.current.setFieldValue(
      "trigger_combination_type",
      triggerType == "AND" ? "OR" : "AND"
    );
    setTriggerTypeSimple(triggerType == "AND" ? "OR" : "AND");
  };

  const deleteTrigger = function (index) {
    formRef.current.setFieldValue(
      `triggers[${index}].attribute`,
      formRef.current.getFieldValue(`triggers[${index + 1}].attribute`)
    );
    formRef.current.setFieldValue(
      `triggers[${index}].comparator`,
      formRef.current.getFieldValue(`triggers[${index + 1}].comparator`)
    );
    formRef.current.setFieldValue(
      `triggers[${index}].compare_value`,
      formRef.current.getFieldValue(`triggers[${index + 1}].compare_value`)
    );
    const newTriggers = [...triggerScope];
    newTriggers.splice(index, 1);
    setTriggerScope(newTriggers);
  };

  return (
    <>
      {errorMessage ? (
        <div className="patient-status__alert-message alert-message__warning">
          <p className="alert-message__paragraph u-color-denim-40">
            <b>Alert:</b> {errorMessage}
          </p>
        </div>
      ) : (
        ""
      )}

      <Form ref={formRef} onSubmit={submit} className="form-box">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <Select
              name="rule_type"
              setDefaultValue="tx"
              options={ruleTypesOptions}
              required
              label="Rule Type"
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Select
              name="status"
              setDefaultValue="draft"
              options={statusOptions}
              required
              label="Status"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <ReactSelect
              name="guideline_document"
              options={documentsOptions}
              required
              label="Guideline Document"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <Input name="name" required type="text" label="Rule Title" />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <Input
              name="evidence_level"
              required
              type="text"
              label="Rule Grade"
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Select
              name="healthpals_rule_grade"
              options={hpGradeOptions}
              required
              label="HealthPals Grade"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <TriggerGroup>
              <label>Triggers</label>
              {triggerScope.map((o, i) => {
                return (
                  <Scope key={i} path={`triggers[${i}]`}>
                    <ReactSelect
                      style={{ flex: "1" }}
                      name="attribute"
                      options={triggerAndOutputOptions}
                      onChange={(value) =>
                        handleTriggerChange(i, value, "attribute")
                      }
                    />
                    {getInputsByType(o, i)}
                    {triggerScope.length > 1 && !compoundTriggersToogle ? (
                      <TriggerCombinationBtn
                        onClick={() => changeTriggerType()}
                      >
                        {triggerTypeSimple}
                      </TriggerCombinationBtn>
                    ) : (
                      ""
                    )}
                    {triggerScope.length > 1 ? (
                      <Button
                        type="button"
                        variant="clear"
                        onClick={() => deleteTrigger(i)}
                      >
                        <CgClose size={18} />
                      </Button>
                    ) : (
                      ""
                    )}
                  </Scope>
                );
              })}
            </TriggerGroup>
            {!compoundTriggersToogle ? (
              <Input
                name="trigger_combination_type"
                value={triggerTypeSimple}
                type="hidden"
                style={{ marginBottom: 0 }}
              />
            ) : (
              <LogicExpressionSelect
                name="trigger_combination_type"
                label="Compound Triggers"
                statments={triggerScope}
                statmentsInfo={triggerAndOutputOptions}
              />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <FormRow>
              <Button
                variant="clear"
                type="button"
                onClick={() => setTriggerScope([...triggerScope, {}])}
              >
                + Add Trigger
              </Button>
              <Button
                variant="clear"
                type="button"
                disabled={triggerScope.length < 2}
                onClick={() =>
                  setCompoundTriggersToggle(!compoundTriggersToogle)
                }
              >
                {compoundTriggersToogle
                  ? "Hide Compound Triggers"
                  : "Compound Triggers"}
              </Button>
            </FormRow>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <Textarea
              name="rule_value"
              required
              type="text"
              rows="5"
              label="Rule Description"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <OutputGroup>
              <label>Outputs</label>
              {outputScope.map((o, i) => (
                <div key={i}>
                  <div>
                    <ReactSelect
                      name={`outputs[${i}]`}
                      options={triggerAndOutputOptions}
                    />
                  </div>
                  {outputScope.length > 1 ? (
                    <Button variant="clear" onClick={() => deleteOutput(i)}>
                      <CgClose size={18} />
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </OutputGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <FormRow>
              <Button
                type="button"
                variant="clear"
                onClick={() => setOutputScope([...outputScope, ""])}
              >
                + Add Output
              </Button>
            </FormRow>
          </div>
        </div>

        <ActionButtons>
          <div>
            {guideline ? (
              <Button
                variant="clear"
                leftIcon={<MdDelete />}
                type="button"
                onClick={() => deleteGuideline()}
              >
                Delete guideline
              </Button>
            ) : (
              ""
            )}
          </div>
          <div>
            <Button
              variant="outline"
              type="button"
              onClick={() => cancelForm()}
            >
              Cancel
            </Button>
            <Button variant="confirm" type="submit">
              {guideline ? "Edit Guideline" : "Create Guideline"}
            </Button>
          </div>
        </ActionButtons>
      </Form>
    </>
  );
}

const ruleTypesOptions = [
  { value: "tx", label: "Treatment" },
  { value: "dx", label: "Diagnosis" },
  { value: "iv", label: "Intermediate Var." },
];

const statusOptions = [
  { value: "draft", label: "Draft" },
  { value: "published", label: "Published" },
];

const hpGradeOptions = [
  { value: "strong", label: "Strong" },
  { value: "moderate", label: "Moderate" },
  { value: "optional", label: "Optional" },
  { value: "expert_opinion", label: "Expert Opinion" },
  // {value:"──────", label:"──────", attributes:['disabled']},
  { value: "unassessed", label: "Unassessed" },
];

const booleanValueOptions = [
  { value: true, label: "True" },
  { value: false, label: "False" },
];
const booleanComparatorOptions = [{ value: "==", label: "is" }];
const numericalComparatorOptions = [
  { value: "==", label: "Equal" },
  { value: "<", label: "Less than" },
  { value: ">", label: "Greater than" },
  { value: "<=", label: "Less than or equal to" },
  { value: ">=", label: "Greater than or equal to" },
];
const categoricalComparatorOptions = [
  { value: "==", label: "Equal" },
  { value: "!=", label: "Different than" },
];
