import React, { useMemo } from "react";
import { isEmpty } from "lodash";

import { TileLayer, LayersControl, ZoomControl } from "react-leaflet";

import Loader from "../../loader";

import { Map } from "./styles";

import BaseLayer from "./base-layer";
import OverlayLayer from "./overlay-layer";

const TileURL =
  "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png";

function Choropleth({
  map = {},
  topography = [],
  overlayLayers = [],
  onFeatureClick,
  defaultLayer = 0,
}) {
  const baseLayers = useMemo(
    () => topography.filter((l) => l.type === "base"),
    [topography]
  );

  const renderMap = () => {
    const selectedMap = map;
    if (!isEmpty(baseLayers) && selectedMap) {
      return (
        <Map
          center={baseLayers[0].center}
          zoom={baseLayers[0].zoom}
          scrollWheelZoom={false}
          zoomControl={false}
          zoomSnap={0.5}
        >
          <TileLayer url={TileURL} />
          <ZoomControl position="bottomleft" />
          <LayersControl position="bottomleft">
            {overlayLayers.map((layer, i) => (
              <OverlayLayer layer={layer} key={layer.name} />
            ))}
            {baseLayers.map((layer, i) => (
              <BaseLayer
                mapTitle={map.title ? map.title : map.name}
                key={layer.name}
                layer={layer}
                colorPalette={selectedMap.colorPalette}
                data={selectedMap.layers[layer.name]}
                getTooltipContent={selectedMap.getTooltip}
                onFeatureClick={onFeatureClick}
                checked={i === defaultLayer}
              />
            ))}
          </LayersControl>
        </Map>
      );
    }
    return "";
  };

  if (isEmpty(map) || isEmpty(topography)) {
    return <Loader />;
  }

  return renderMap();
}

export default Choropleth;
