import React, { useEffect, useState } from "react";
import { rgba } from "polished";

import DashboardHelper from "../../../../helpers/dashboard-helper";

import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySubcohortList } from "../../../../hooks/useIdentifySubcohortList";
import { getRiskModelCurve } from "../../../../services/insights-data-service";
import RiskModelCurveWrapper from "../risk-model-curve-wrapper";
import { isEmpty } from "lodash";

function RiskModelCurve({ setErrorMsg, setValidCharts }) {
  const { subcohortList, dispatch: subcohortListDispatch } =
    useIdentifySubcohortList();
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { cohortIndex, filtersConfig, riskModelConfig } = cohortConfigs;
  const [responseList, setResponseList] = useState({});
  const [rocCharts, setROCCharts] = useState([]);
  const [precisionCharts, setPrecisionCharts] = useState([]);
  const [rocTable, setROCTable] = useState({});
  const [precisionTable, setPrecisionTable] = useState({});

  async function getChartData(cohortQuery) {
    const newAPIResponse = {};
    await Promise.all(
      riskModelConfig.map(async (model) => {
        const response = await getRiskModelCurve(
          cohortIndex.index_name,
          DashboardHelper.buildQueryFromFilters(cohortQuery, filtersConfig),
          [{ field_name: model.field_name }]
        );

        if (response.data.valid) {
          newAPIResponse[model.display_name] = response.data.data.result;
        }
      })
    );

    if (!isEmpty(newAPIResponse)) return newAPIResponse;

    setValidCharts(false);
    setErrorMsg("");
    return {};
  }

  function getChartConfig(name, data, title, xTitle, yTitle) {
    const config = {};
    const colorPalette = [];
    Object.keys(subcohortList).forEach((id) => {
      if (id in subcohortList && subcohortList[id].visible)
        colorPalette.push(subcohortList[id].color);
    });
    config.name = name;
    config.data = data;
    config.config = {
      type: "line",
      layout: "linear",
      rotateXAxisLabels: false,
      axesXType: "continuous",
      normalizeToogle: false,
      hideLegends: true,
      showTooltip: true,
      colorPalette: colorPalette,
    };
    config.meta = {
      axesTitle: {
        x: xTitle,
        y: yTitle,
      },
      chartTitle: title,
    };
    return config;
  }

  useEffect(async () => {
    const newResponseList = { ...responseList };
    let fetchedID;

    // Trigger the get chart data for the subcohort that was changed or added

    for (const id in subcohortList) {
      if (
        !subcohortList[id].fetchedData.rmc &&
        subcohortList[id].status !== "isCreating"
      ) {
        setROCCharts([]);
        setPrecisionCharts([]);
        if (riskModelConfig) {
          const response = await getChartData(subcohortList[id].filterList);
          newResponseList[id] = response;
          fetchedID = id;
        }
      } else if (subcohortList[id].fetchedData.rmc) {
        newResponseList[id] = subcohortList[id].fetchedData.rmc;
      }
    }

    //Treat the remove subcohorts, if removed from subcohortList will be removed from response list
    for (const id in newResponseList) {
      if (!(id in subcohortList)) delete newResponseList[id];
    }

    setResponseList(newResponseList);

    //Change the subcohort that were edited or added to loaded
    if (fetchedID)
      subcohortListDispatch({
        type: "UPDATE_SUBCOHORT_FETCHED_DATA",
        payload: {
          chart: "rmc",
          fetchedData: newResponseList[fetchedID],
          id: fetchedID,
        },
      });
  }, [
    subcohortList.cohort?.status,
    subcohortList["subcohort-a"]?.status,
    subcohortList["subcohort-b"]?.status,
    subcohortList["subcohort-c"]?.status,
    riskModelConfig,
  ]);

  function parseResponse() {
    if (!responseList.cohort) return {};

    const models = Object.keys(responseList.cohort);
    if (!models || !models.length) {
      return {};
    }

    const precisionCallChart = {};
    const rocCurveChart = {};

    models.map((model) => {
      const rocData = [];
      const precisionData = [];
      Object.keys(responseList).forEach((subcohortID) => {
        if (
          subcohortID in subcohortList &&
          subcohortList[subcohortID].visible &&
          responseList[subcohortID]
        ) {
          rocData.push({
            name: subcohortList[subcohortID].name,
            values: responseList[subcohortID][model][0].values
              .map((a) => ({ key: a.key * 100, value: a.value * 100 }))
              .sort((a, b) => a.key - b.key),
          });
          precisionData.push({
            name: subcohortList[subcohortID].name,
            values: responseList[subcohortID][model][1].values.sort(
              (a, b) => a.key - b.key
            ),
          });
        }
      });
      precisionCallChart[model] = {
        data: precisionData,
        axes: responseList["cohort"][model][1].axes,
        name: responseList["cohort"][model][1].name,
      };
      rocCurveChart[model] = {
        data: rocData,
        axes: responseList["cohort"][model][0].axes,
        name: responseList["cohort"][model][0].name,
      };
    });

    return { precisionCallChart, rocCurveChart };
  }

  function parseTablesResponse() {
    if (!responseList.cohort) return {};
    const models = Object.keys(responseList.cohort);
    if (!models || !models.length) {
      return {};
    }

    models.sort();

    function getColumns(models) {
      const columns = [
        {
          Header: "Models",
          accessor: "model",
          id: "model",
          enableRowSpan: true,
        },
      ];

      Object.keys(responseList).forEach((subcohortID) => {
        if (subcohortID in subcohortList && subcohortList[subcohortID].visible)
          columns.push({
            Header: `${subcohortList[subcohortID].name}`,
            accessor: subcohortID,
          });
      });

      return columns;
    }

    function getRows(models) {
      const newROCRows = [];
      const newPrecisionRows = [];

      models.forEach((model) => {
        const newROCRow = {};
        newROCRow.model = model;
        const newPrecisionRow = {};
        newPrecisionRow.model = model;
        Object.keys(responseList).forEach((subcohortID) => {
          if (
            subcohortID in subcohortList &&
            subcohortList[subcohortID].visible
          ) {
            newROCRow[subcohortID] =
              responseList[subcohortID][model][0].auc.toFixed(3);
            newPrecisionRow[subcohortID] =
              responseList[subcohortID][model][1].auc.toFixed(3);
          }
        });
        newROCRows.push(newROCRow);
        newPrecisionRows.push(newPrecisionRow);
      });

      return { rocRows: newROCRows, precisionRows: newPrecisionRows };
    }

    function getConditionalCellStyles() {
      const styles = [];
      Object.keys(responseList).forEach((id) => {
        if (id in subcohortList && subcohortList[id].visible) {
          styles.push({
            when: (cell) => cell.column.id === id,
            styles: {
              backgroundColor: rgba(subcohortList[id].color, 0.1),
            },
          });
        }
      });
      return styles;
    }

    const columns = getColumns(models);
    const { rocRows, precisionRows } = getRows(models);
    const conditionalCellStyles = getConditionalCellStyles();

    return {
      columns,
      rocRows,
      precisionRows,
      conditionalCellStyles,
    };
  }

  // Trigger build of the charts config structure
  useEffect(() => {
    const newROCCharts = [];
    const newPrecisionCharts = [];
    const { precisionCallChart, rocCurveChart } = parseResponse();
    if (precisionCallChart) {
      for (const key of Object.keys(precisionCallChart)) {
        newPrecisionCharts.push(
          getChartConfig(
            key,
            precisionCallChart[key].data,
            precisionCallChart[key].name,
            precisionCallChart[key].axes.x,
            precisionCallChart[key].axes.y
          )
        );
      }
    }
    if (rocCurveChart) {
      for (const key of Object.keys(rocCurveChart)) {
        newROCCharts.push(
          getChartConfig(
            key,
            rocCurveChart[key].data,
            rocCurveChart[key].name,
            rocCurveChart[key].axes.x,
            rocCurveChart[key].axes.y
          )
        );
      }
    }
    setROCCharts(newROCCharts);
    setPrecisionCharts(newPrecisionCharts);

    const { columns, rocRows, precisionRows, conditionalCellStyles } =
      parseTablesResponse();
    if (rocRows) {
      setROCTable({
        columns,
        conditionalCellStyles,
        rows: rocRows,
      });
    }
    if (precisionRows) {
      setPrecisionTable({
        columns,
        conditionalCellStyles,
        rows: precisionRows,
      });
    }
  }, [
    responseList.cohort,
    responseList["subcohort-a"],
    responseList["subcohort-b"],
    responseList["subcohort-c"],
    subcohortList?.cohort?.visible,
  ]);

  return (
    <RiskModelCurveWrapper
      rocCharts={rocCharts}
      precisionCharts={precisionCharts}
      rocTable={rocTable}
      precisionTable={precisionTable}
    />
  );
}

export default RiskModelCurve;
