import styled from "styled-components";

export const Nav = styled.nav`
  box-sizing: border-box;

  grid-area: toph;
  height: 40px;
  border-bottom: 1px solid #000;
  color: #fff;
  background-color: #26344d;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px 0 0;
`;

export const SignOutContainer = styled.div`
  padding: 0px 10px 10px;

  button {
    margin: 0;
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const IconLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 10px;
  cursor: pointer;
`;
