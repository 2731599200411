import React from "react";
import ReactDOM from "react-dom";
import { Heading3, Small } from "../../typography";
import { BiExpand, BiCollapse } from "react-icons/bi";
import {
  OutsidePanel,
  Container,
  Header,
  ActionsContainer,
  TitleContainer,
  BottomHeader,
} from "./styles";

export default function InternalPanel({
  children,
  title,
  subtitle,
  actions, //Todo change this to topActions to be more explicit on where they go
  bottomActions,
  isOpen = false,
  setIsOpen,
  ...rest
}) {
  function clickOutsideHide(event) {
    const element = event.currentTarget.dataset.id;
    if (element === "panel") {
      // click inside the content of the modal. call stopPropagation to avoid closing
      event.stopPropagation();
    } else if (element === "overlay") {
      setIsOpen(false);
    }
  }

  function renderFullPanel() {
    return isOpen
      ? ReactDOM.createPortal(
          <OutsidePanel onClick={clickOutsideHide} data-id="overlay">
            <Container {...rest} onClick={clickOutsideHide} data-id="panel">
              {title || actions || bottomActions ? (
                <Header>
                  <TitleContainer>
                    <div>
                      {title ? <Heading3>{title}</Heading3> : ""}
                      {subtitle ? (
                        <Small color="#666666">{subtitle}</Small>
                      ) : (
                        ""
                      )}
                    </div>
                    {actions ? (
                      <ActionsContainer>{actions}</ActionsContainer>
                    ) : (
                      ""
                    )}
                  </TitleContainer>
                  <BottomHeader>{bottomActions || null}</BottomHeader>
                </Header>
              ) : (
                ""
              )}

              {children}
            </Container>
          </OutsidePanel>,
          document.body
        )
      : "";
  }
  return (
    <>
      <div
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {isOpen ? (
          <BiCollapse size={24} color="4D5F80" style={{ cursor: "pointer" }} />
        ) : (
          <BiExpand size={24} color="4D5F80" style={{ cursor: "pointer" }} />
        )}
      </div>
      {renderFullPanel()}
    </>
  );
}
