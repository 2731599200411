const SessionService = class {
  static createSession(session) {
    localStorage.firstName = session.first_name;
    localStorage.lastName = session.last_name;
    localStorage.roles = session.roles;
    localStorage.email = session.email;
    localStorage.accessToken = session.access_token;
  }

  static sessionIsAuthenticated() {
    const requiredSessionAttributes = [
      "firstName",
      "lastName",
      "roles",
      "accessToken",
    ];

    for (const sessionAttribute of requiredSessionAttributes) {
      if (!localStorage[sessionAttribute]) {
        return false;
      }
    }

    // check if access token is expired
    const token = localStorage.accessToken;
    const payload = JSON.parse(window.atob(token.split(".")[1]));
    const now = new Date().getTime() / 1000;
    if (payload.exp < now) {
      return false;
    }

    return true;
  }

  static getRole() {
    return localStorage.roles ? localStorage.roles.split(",") : [];
  }

  static getAccessibleWorkspaces() {
    const roles = localStorage.roles ? localStorage.roles.split(",") : [];
    const workspaces = [];

    for (const index in roles) {
      const [workspace, _] = roles[index].split(".");
      workspaces.push(workspace);
    }

    return workspaces;
  }

  static destroySession() {
    localStorage.clear();
  }
};
export default SessionService;
