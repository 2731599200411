import React, { useCallback, useRef } from "react";
import { useResizeDetector } from "react-resize-detector";

import * as echarts from "echarts";

import { Container, Chart, PADDING } from "./styles";

/**
 * Renders an event rate curves chart.
 *
 * @param {Object} props
 * @param {Object} props.chartOption - the chart option
 *
 * @returns {React.Component}
 *
 * @example
 * <ErcChart chartOption={chartOption} />
 *
 * @see {@link https://echarts.apache.org/en/index.html|ECharts}
 * @see {@link https://echarts.apache.org/en/option.html|ECharts Option}
 */
function ErcChart({ chartOption }) {
  // render nothing if there is no chart option
  if (!chartOption) {
    return null;
  }

  // create a ref to store the ECharts instance
  const echartRef = useRef(null);

  // resize the ECharts instance when the container is resized
  const onResize = useCallback((width, height) => {
    if (echartRef.current) {
      echartRef.current.resize({
        height: height - 2 * PADDING,
        width,
      });
    }
  }, []);

  // use the resize detector hook to run the onResize callback on container resize
  const { ref } = useResizeDetector({
    onResize,
  });

  // initialize the ECharts instance when the chart node element is mounted
  // https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const measuredRef = useCallback(
    (node) => {
      // do nothing if the node is not mounted
      if (node === null) {
        return;
      }

      // dispose the previous ECharts instance
      if (echartRef.current) {
        echartRef.current.dispose();
      }

      // create a new ECharts instance and set the chart option
      echartRef.current = echarts.init(node);
      echartRef.current.setOption(chartOption);

      // toggle the confidence bands on legend click
      echartRef.current.on("legendselectchanged", function (params) {
        const name = params.name;
        const selected = params.selected[name];

        for (const post of ["L", "U"]) {
          const legendName = `${name} - ${post}`;
          echartRef.current.dispatchAction({
            type: selected ? "legendSelect" : "legendUnSelect",
            name: legendName,
          });
        }
      });
    },
    [chartOption]
  );

  return (
    <Container ref={ref}>
      <Chart ref={measuredRef} />
    </Container>
  );
}

export default ErcChart;
