import React from "react";
import { Link } from "react-router-dom";
import LoadingHOC from "../../../../components/loadingHoc";

import Button from "../../../../components/button";
import AppPageTitle from "../../../../components/app-page-title";
import GuidelineTable from "../../c-guidelines-rules-table";

import { useGuidelineRule } from "../../../../hooks/useGuidelineRules.js";

import { Container } from "./styles";

function Rules(props) {
  const { guidelines } = useGuidelineRule();

  return (
    <>
      <AppPageTitle
        title="Guideline Rules"
        actions={
          <Button variant="confirm">
            <Link to="/content-manager/rules/add">New Guideline Rule</Link>
          </Button>
        }
      />
      <Container>
        <GuidelineTable guidelines={guidelines} />
      </Container>
    </>
  );
}

export default LoadingHOC()(Rules);
