import React from "react";
import { Link } from "react-router-dom";

import LoadingHOC from "../../../../components/loadingHoc";

import { useGuidelineDocument } from "../../../../hooks/useGuidelineDocuments.js";

import Button from "../../../../components/button";
import AppPageTitle from "../../../../components/app-page-title";
import GuidelineDocumentsTable from "../../c-guideline-documents-table";

import { Container } from "./styles";

function Documents() {
  const { documents } = useGuidelineDocument();

  return (
    <>
      <AppPageTitle
        title="Guideline Document"
        actions={
          <Button variant="confirm">
            <Link to="/content-manager/guideline-documents/add">
              New Guideline Document
            </Link>
          </Button>
        }
      />
      <Container>
        <GuidelineDocumentsTable documents={documents} />
      </Container>
    </>
  );
}

export default LoadingHOC()(Documents);
