/*
 * Re-usable methods for guidelines
 */
import { find } from "lodash";

const logicExpressionHelper = class {
  static updateSelectValues(expressionWithIDs, optionsData, attributesOptions) {
    let expression = [];
    // set up the trigger_advanced variable to the appropriate values.
    const arrayValues = expressionWithIDs.split(" "); // .map((expression)=>expression.value);

    const leftParenthesis = { value: "(", label: "(" };
    const rightParenthesis = { value: ")", label: ")" };
    const andOperator = { value: "AND", label: "AND" };
    const orOperator = { value: "OR", label: "OR" };

    for (let index = 0; index < arrayValues.length; index++) {
      if (arrayValues[index] == "(") {
        expression = expression.concat(leftParenthesis);
      } else if (arrayValues[index] == ")") {
        expression = expression.concat(rightParenthesis);
      } else if (arrayValues[index] == "AND") {
        expression = expression.concat(andOperator);
      } else if (arrayValues[index] == "OR") {
        expression = expression.concat(orOperator);
      } else {
        // otherwise, it is an attribute id number.
        const trigger = optionsData[parseInt(arrayValues[index])];
        const attribute = find(attributesOptions, [
          "value",
          parseInt(trigger.attribute),
        ]);
        let currentLabel;
        let value;
        currentLabel = attribute.label;
        if (attribute.data_type == "boolean") {
          value =
            trigger.compare_value != undefined ? "true" : trigger.compare_value;
          currentLabel = `${currentLabel} is ${value}`;
        } else {
          let { comparator } = trigger;
          if (comparator == "!=") {
            comparator = " is not ";
          }
          if (comparator == "==") {
            comparator = " is ";
          }
          value = trigger.compare_value ? trigger.compare_value : "";
          currentLabel = currentLabel + comparator + trigger.compare_value;
        }
        expression = expression.concat({
          value: parseInt(arrayValues[index]),
          label: currentLabel,
        });
      }
    }

    return expression;
  }

  static getSelectOptions(matters, box_value, attributesOptions) {
    let mattersLength = matters.length;
    let selectableOptions = [];

    if (mattersLength == null) {
      mattersLength = 0;
    }

    const leftParenthesis = { value: "(", label: "(" };
    const rightParenthesis = { value: ")", label: ")" };
    const andOperator = { value: "AND", label: "AND" };
    const orOperator = { value: "OR", label: "OR" };
    const numbers = [];
    for (let index = 0; index < mattersLength; index++) {
      let currentLabel;
      let value;
      const attribute = find(attributesOptions, [
        "value",
        parseInt(matters[index].attribute),
      ]);
      if (attribute) {
        currentLabel = attribute.label;
        if (matters[index].data_type == "boolean") {
          value =
            matters[index].compare_value == undefined
              ? "true"
              : matters[index].compare_value;
          currentLabel = `${currentLabel} is ${value}`;
        } else {
          let comparator = matters[index].comparator
            ? matters[index].comparator
            : "==";
          if (comparator == "!=") {
            comparator = " is not ";
          }
          if (comparator == "==") {
            comparator = " is ";
          }
          value = matters[index].compare_value
            ? matters[index].compare_value
            : "";
          currentLabel = currentLabel + comparator + value;
        }

        numbers.push({ value: index, label: currentLabel });
      }
    }

    const last_item = box_value[box_value.length - 1];
    // if null
    if (box_value[0] == null) {
      selectableOptions = selectableOptions
        .concat(numbers)
        .concat(leftParenthesis);
    }
    // if AND or OR or (
    else if (
      last_item.value == "AND" ||
      last_item.value == "OR" ||
      last_item.value == "("
    ) {
      selectableOptions = selectableOptions
        .concat(numbers)
        .concat(leftParenthesis);
    } else {
      // otherwise, it is a number. Return and, or and right parenthsis if valid.

      // CHECK VALIDITY, num open and num close parantehsis
      let countLeft = 0;
      let countRight = 0;
      for (const item in box_value) {
        if (box_value[item].value == "(") {
          countLeft += 1;
        }
        if (box_value[item].value == ")") {
          countRight += 1;
        }
      }

      // only show right paranthesis if there are more open paranthesis.

      if (countLeft > countRight) {
        selectableOptions = selectableOptions
          .concat(andOperator)
          .concat(orOperator)
          .concat(rightParenthesis);
      } else {
        selectableOptions = selectableOptions
          .concat(andOperator)
          .concat(orOperator);
      }
    }

    return selectableOptions;
  }
};

export default logicExpressionHelper;
