import React from "react";
import { Heading3, Small } from "../../typography";
import {
  Container,
  Header,
  ActionsContainer,
  TitleContainer,
  BottomHeader,
} from "./styles";

export default function InternalPanel({
  children,
  title,
  subtitle,
  actions, //Todo change this to topActions to be more explicit on where they go
  bottomActions,
  ...rest
}) {
  return (
    <Container {...rest}>
      {title || actions || bottomActions ? (
        <Header>
          <TitleContainer>
            <div>
              {title ? <Heading3>{title}</Heading3> : ""}
              {subtitle ? <Small color="#666666">{subtitle}</Small> : ""}
            </div>
            {actions ? <ActionsContainer>{actions}</ActionsContainer> : ""}
          </TitleContainer>
          <BottomHeader>{bottomActions || null}</BottomHeader>
        </Header>
      ) : (
        ""
      )}

      {children}
    </Container>
  );
}
