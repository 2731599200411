import styled, { css } from "styled-components";

export const CollapsiblesContainer = styled.div`
  padding-top: 16px;
`;

export const PanelTab = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 6px 2px 6px;
  cursor: pointer;
  color: ${(props) => (props.selected ? "#26344d" : "#b0b0b0")};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;

  ${(props) =>
    props.selected &&
    css`
      border-bottom: 2px #26344d solid;
    `}
`;

export const TabContainer = styled.div`
  display: flex;
  gap: 20px;
  padding-top: 15px;
  margin-bottom: -15px;
`;
