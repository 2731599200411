import styled from "styled-components";

export const TooltipText = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const TooltipBox = styled.div`
  position: absolute;
  left: ${(props) => props.coordinates.left};
  top: ${(props) => props.coordinates.top};
  transform: translateY(15px) translateX(-50%);

  text-align: left;
  width: auto;
  height: auto;
  padding: 11px;
  border-radius: 3px;
  border: 1px solid #c1c8d4;
  background: white;
  pointer-events: none;
  z-index: 999;
  -webkit-box-shadow: 1px 2px 3px 2px rgba(87, 147, 219, 0.2);
  -moz-box-shadow: 1px 2px 3px 2px rgba(87, 147, 219, 0.2);
  box-shadow: 1px 2px 3px 2px rgba(87, 147, 219, 0.2);
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
`;
