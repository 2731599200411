import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 3;
  display: block;
  width: 100%;
  margin-bottom: 15px;
`;

export const SelectBox = styled.div`
  padding: 10px 16px;
  border: 1px solid #c1c8d4;
  border-radius: 3px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CohortSelectList = styled.div`
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #c1c8d4;
  overflow-y: scroll;
  max-height: 250px;

  div {
    padding: 5px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      visibility: hidden;
    }

    &:hover {
      background: #dadee5;
      button {
        visibility: visible;
      }
    }
  }
`;
