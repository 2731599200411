import React from "react";
import * as d3 from "d3";
import { cloneDeep } from "lodash";

const LEGEND_SQUARE_SIZE = 12;
const LEGEND_SPACING = 10;
const LEGEND_LINE_HEIGHT = LEGEND_SQUARE_SIZE + LEGEND_SPACING;
const SQUARE_TEXT_SPACING = LEGEND_SQUARE_SIZE / 2;

class D3LegendsLayer extends React.Component {
  constructor(props) {
    super(props);

    this.gWrapperRef = null;
    this.setGWrapperRef = (e) => {
      this.gWrapperRef = e;
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Ensures that the component doesn't get re-rendered without reason
    // TODO: understand why this is being called so many times

    // Currently, updates every time the width of height changes/
    // TODO: add check for data
    if (
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width ||
      this.props.data !== nextProps.data
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    d3.select(this.gWrapperRef).select("g").remove();
    if (this.props.data) {
      this.renderLegendsD3Wrapper();
    }
  }

  componentDidMount() {
    if (this.props.data && this.props.height !== 0 && this.props.width !== 0) {
      this.renderLegendsD3Wrapper();
    }
  }

  renderLegendsD3Wrapper() {
    // Declare local variables from props
    const { width } = this.props;
    const { height } = this.props;

    const { colorPalette } = this.props.config;

    const legendPosition = this.props.config.legendPosition
      ? this.props.config.legendPosition
      : "left"; // Top or left for now

    const { chartObservation } = this.props.meta;
    const data = cloneDeep(this.props.data);

    const margin = cloneDeep(this.props.marginDefs);
    const legendVerticalSpace =
      (legendPosition == "left" && data.length >= 2) || chartObservation
        ? width * margin.withLegend.vertical
        : 0;
    const legendHorizontalSpace =
      legendPosition == "top" && data.length >= 2
        ? margin.withLegend.horizontal
        : 0;

    // Set the color range
    const categories = [];
    data.map(function (d) {
      categories.push(d.name);
    });
    const color = d3.scale
      .ordinal()
      .range(colorPalette || ["#1F61A2"])
      .domain(categories);

    // D3 selects the parent group tag using the React ref and attach nested groups to it.
    // When the component is updated, children of this node are removed and re-added.
    const parentG = d3.select(this.gWrapperRef);

    let xValueofSquare;
    if (legendPosition == "left") {
      xValueofSquare = width;
    } else {
      xValueofSquare = 0;
    }

    const g = parentG
      .append("g")
      .attr("transform", `translate(-${legendVerticalSpace},${margin.top})`);

    // Draw legend
    const legend = g
      .selectAll(".chart__legend")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "chart__legend")
      .attr("transform", function (d, i) {
        return `translate(0,${i * LEGEND_LINE_HEIGHT})`;
      });

    legend
      .append("rect")
      .attr("x", xValueofSquare)
      .attr("width", LEGEND_SQUARE_SIZE)
      .attr("height", LEGEND_SQUARE_SIZE)
      .style("fill", function (d) {
        return color(d.name);
      })
      .style("stroke", "#25334E")
      .style("stroke-width", "1px");

    legend
      .append("text")
      .attr("x", xValueofSquare + LEGEND_SQUARE_SIZE + SQUARE_TEXT_SPACING)
      .attr("y", LEGEND_SQUARE_SIZE / 2)
      .attr("dy", ".35em")
      .style("text-anchor", "start")
      .text(function (d) {
        return d.name;
      });

    if (legendPosition == "top") {
      g.attr("transform", function () {
        return `translate(${margin.left},${margin.top})`;
      });

      const nodeWidth = (d) => d.getBBox().width;
      let offset = 0;
      legend.attr("transform", function (d, i) {
        const x = offset;
        offset += nodeWidth(this) + 20;
        return `translate(${x},0)`;
      });
    }

    if (chartObservation) {
      let topTranslate;
      let leftTranslate;
      if (legendPosition == "top") {
        topTranslate = LEGEND_LINE_HEIGHT;
        leftTranslate = -legendVerticalSpace;
      } else {
        topTranslate = categories.length * LEGEND_LINE_HEIGHT + LEGEND_SPACING;
        leftTranslate = 0;
      }

      const observation = g
        .append("g")
        .attr("class", "chart__observation")
        .attr("transform", function (d, i) {
          return `translate(${leftTranslate},${topTranslate})`;
        })
        .append("text")
        .attr("x", width)
        .attr("y", 9)
        .attr("dy", ".35em")
        .style("text-anchor", "start")
        .text(chartObservation)
        .call(wrap, legendVerticalSpace - 5);
    }
  }

  render() {
    return <g ref={this.setGWrapperRef} />;
  }
}

function wrap(text, width) {
  text.each(function () {
    const text = d3.select(this);
    const words = text.text().split(/\s+/).reverse();
    let word;
    let line = [];
    let lineNumber = 0;
    const lineHeight = 1.1; // ems
    const x = text.attr("x");
    const y = text.attr("y");
    const dy = parseFloat(text.attr("dy"));
    let tspan = text
      .text(null)
      .append("tspan")
      .attr("x", x)
      .attr("y", y)
      .attr("dy", `${dy}em`);
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", `${++lineNumber * lineHeight + dy}em`)
          .text(word);
      }
    }
  });
}

export default D3LegendsLayer;
