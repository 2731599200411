import React, { useState, forwardRef, useEffect } from "react";
import { Textarea } from "./styles";

const BasicTextarea = forwardRef(
  (
    { onChange, className, invalid, marginBottom = 10, marginTop = 0, ...rest },
    ref
  ) => {
    function handleChange(event) {
      if (onChange) onChange(event.target.value);
    }

    return (
      <Textarea
        ref={ref}
        className={className}
        onChange={handleChange}
        marginBottom={marginBottom}
        marginTop={marginTop}
        {...rest}
      />
    );
  }
);

export default BasicTextarea;
