import React from "react";

import { Container } from "./styles";

export default function Tab({ label, isActive, onClick }) {
  return (
    <Container active={isActive}>
      <a onClick={onClick}>{label}</a>
    </Container>
  );
}
