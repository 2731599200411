/*
 * Re-usable methods for guidelines
 */
import React from "react";
import { countBy, find } from "lodash";

const GuidelineHelper = class {
  static removeEmptyStrings(obj) {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        obj[key] = this.removeEmptyStrings(obj[key]);
      } else if (obj[key] === "") {
        obj[key] = null;
      }
    });
    return obj;
  }

  static printStatusTag(statusString) {
    if (typeof statusString === "undefined") {
      return "";
    }
    if (statusString == "published") {
      return <span className="label label-success">{statusString}</span>;
    }
    return <span className="label label-warning">{statusString}</span>;
  }

  static printGuidelineSource(row, guidelineDocumentsOptions) {
    let source = "-";
    if (row.guideline_document) {
      const documents = guidelineDocumentsOptions;
      for (const doc of documents) {
        if (doc.id == row.guideline_document) {
          source = doc.source;
        }
      }
    } else {
      source = row.source;
    }
    return source;
  }

  static numberOfRulesWithDocument(documentId, guidelines) {
    const countGuideline = countBy(guidelines, function (guideline) {
      return guideline.guideline_document;
    });

    const countGuidelineDocument = countGuideline[documentId]
      ? countGuideline[documentId]
      : 0;

    return countGuidelineDocument;
  }

  static getListOfRulesWithDocument(documentId, guidelines) {
    const list = guidelines.filter((guideline) => {
      return guideline.guideline_document === parseInt(documentId);
    });
    return list;
  }

  static numberOfRulesWithAttribute(attributeId, guidelines) {
    let count = 0;
    guidelines.forEach((guideline) => {
      const triggersCount = guideline.triggers.find(
        (trigger) => trigger.attribute === parseInt(attributeId)
      )
        ? 1
        : 0;
      const outputsCount = guideline.outputs.find(
        (output) => output === parseInt(attributeId)
      )
        ? 1
        : 0;
      if (outputsCount || triggersCount) {
        count += 1;
      }
    });
    return count;
  }

  static getListOfRulesWithAttribute(attributeId, guidelines) {
    const list = guidelines.filter((guideline) => {
      return (
        guideline.triggers.find(
          (trigger) => trigger.attribute === parseInt(attributeId)
        ) ||
        guideline.outputs.find((output) => output === parseInt(attributeId))
      );
    });
    return list;
  }

  static getListOfAttributeWithParent(attributeId, attributes) {
    const list = attributes.filter((attribute) => {
      return attribute.parent === parseInt(attributeId);
    });
    return list;
  }

  static printTriggerExpression(combinationType, triggers, attributesOptions) {
    const parsedCombination = combinationType.split(" ");
    const finalArray = [];
    if (
      parsedCombination.length == 1 &&
      (parsedCombination[0] == "AND" || parsedCombination[0] == "OR")
    ) {
      for (const item in triggers) {
        if (item != triggers.length) {
          finalArray.push(this.parseTrigger(triggers[item], attributesOptions));
        }
        if (item != triggers.length - 1) {
          // finalArray.push(<span key={parsedCombination[0]+'-'+item}  className="label label-default">{parsedCombination[0]}</span>)
          finalArray.push(
            <b key={`${parsedCombination[0]}-${item}`}>
              {" "}
              {parsedCombination[0]}{" "}
            </b>
          );
        }
        finalArray.push(<div key={item} />);
      }
      return finalArray;
    }

    for (const item in parsedCombination) {
      if (
        parsedCombination[item] == "(" ||
        parsedCombination[item] == ")" ||
        parsedCombination[item] == "AND" ||
        parsedCombination[item] == "OR"
      ) {
        // finalArray.push(<span key={parsedCombination[item]+item} className="label label-default" >{parsedCombination[item]}</span>)
        finalArray.push(
          <b key={parsedCombination[item] + item}>
            {" "}
            {parsedCombination[item]}{" "}
          </b>
        );
        if (
          parsedCombination[item] == "AND" ||
          parsedCombination[item] == "OR"
        ) {
          finalArray.push(<div key={item} />);
        }
        // finalArray.push(parsedCombination[item])
      } else {
        finalArray.push(
          this.parseTrigger(
            triggers[parseInt(parsedCombination[item])],
            attributesOptions
          )
        );
        // its a number. parse accordingly
        // finalArray.push(parseInt(parsedCombination[item]));
      }
    }

    return finalArray;
  }

  static parseTrigger(trigger, attributesOptions) {
    let triggerSentence = "has";
    const attribute = find(attributesOptions, function (o) {
      return trigger.attribute == o.id;
    });
    if (attribute.data_type == "boolean") {
      if (!trigger.compare_value)
        triggerSentence = `${triggerSentence} ` + `no`;
      triggerSentence = `${triggerSentence} ${attribute.human_readable_name}`;
    } else if (
      attribute.data_type == "numerical" ||
      attribute.data_type == "categorical"
    ) {
      triggerSentence = `${triggerSentence} ${attribute.human_readable_name} ${
        comparatorNote[trigger.comparator]
      } ${trigger.compare_value}`;
    }
    return triggerSentence;
  }

  static printableRuleType(type) {
    switch (type) {
      case "tx":
        return "Treatment";
        break;
      case "dx":
        return "Discovored Diagnosis";
        break;
      case "iv":
        return "Intermediate Variable";
        break;
      default:
        return type;
    }
  }

  static printableCategory(category) {
    // TODO: improve logic.
    // Below there's a hacky workaround to account for MongoDB field keys not accepting dot notation
    // E.g.: workup.labs vs workup-labs

    switch (category) {
      case "medication":
        return "Medication";
        break;

      case "workup.screening":
        return "Work up/Screening";
        break;
      case "workup.imaging":
        return "Work up/Imaging";
        break;
      case "workup.labs":
        return "Work up/Labs";
        break;
      case "lifestyle.diet":
        return "Lifestyle/Diet";
        break;
      case "lifestyle.exercise":
        return "Lifestyle/Exercise";
        break;
      case "lifestyle.smoking":
        return "Lifestyle/Smoking";
        break;
      case "lifestyle.other":
        return "Lifestyle/Other";
        break;

      case "workup-screening":
        return "Work up/Screening";
        break;
      case "workup-imaging":
        return "Work up/Imaging";
        break;
      case "workup-labs":
        return "Work up/Labs";
        break;
      case "lifestyle-diet":
        return "Lifestyle/Diet";
        break;
      case "lifestyle-exercise":
        return "Lifestyle/Exercise";
        break;
      case "lifestyle-smoking":
        return "Lifestyle/Smoking";
        break;
      case "lifestyle-other":
        return "Lifestyle/Other";
        break;

      case "procedure":
        return "Procedure";
        break;
      case "monitor":
        return "Monitor";
        break;
      case "follow_up":
        return "Follow up";
        break;
      case "target":
        return "Target";
        break;
      case "referral_to_specialist":
        return "Referral to specialist";
        break;
      case "other":
        return "Other";
        break;
      case "empty":
        return "Empty";
        break;
      default:
        return category;
    }
  }

  static printCategoryTag(category, filled, divided, key) {
    let abreviation = "";
    let tagClass = "mini-tooltip-hover treatment-tag ";

    if (filled) {
      tagClass += "treatment-tag--filled ";
    } else if (divided) {
      tagClass += "treatment-tag--divided ";
    } else {
      tagClass += "treatment-tag--outline ";
    }

    switch (category) {
      // TODO: improve logic.
      // Below there's a hacky workaround to account for MongoDB field keys not accepting dot notation
      // E.g.: workup.labs vs workup-labs

      case "medication":
        abreviation = "Rx";
        tagClass += "treatment-tag--yellow";
        break;

      case "workup.labs":
        abreviation = "W/U";
        tagClass += "treatment-tag--blue";
        break;
      case "workup-labs":
        abreviation = "W/U";
        tagClass += "treatment-tag--blue";
        break;

      case "follow_up":
        abreviation = "F/U";
        tagClass += "treatment-tag--puke";
        break;

      case "workup.imaging":
        abreviation = "W/U";
        tagClass += "treatment-tag--blue";
        break;
      case "workup-imaging":
        abreviation = "W/U";
        tagClass += "treatment-tag--blue";
        break;

      case "lifestyle.smoking":
        abreviation = "LS";
        tagClass += "treatment-tag--green";
        break;
      case "lifestyle-smoking":
        abreviation = "LS";
        tagClass += "treatment-tag--green";
        break;

      case "lifestyle.diet":
        abreviation = "LS";
        tagClass += "treatment-tag--green";
        break;
      case "lifestyle-diet":
        abreviation = "LS";
        tagClass += "treatment-tag--green";
        break;

      case "lifestyle.exercise":
        abreviation = "LS";
        tagClass += "treatment-tag--green";
        break;
      case "lifestyle-exercise":
        abreviation = "LS";
        tagClass += "treatment-tag--green";
        break;

      case "procedure":
        abreviation = "PCR";
        tagClass += "treatment-tag--aegean";
        break;
      case "workup.screening":
        abreviation = "W/U";
        tagClass += "treatment-tag--blue";
        break;
      case "workup-screening":
        abreviation = "W/U";
        tagClass += "treatment-tag--blue";
        break;

      case "monitor":
        abreviation = "MO";
        tagClass += "treatment-tag--orange";
        break;
      case "target":
        abreviation = "TRG";
        tagClass += "treatment-tag--purple";
        break;
      case "referral_to_specialist":
        abreviation = "REF";
        tagClass += "treatment-tag--absinto";
        break;

      case "lifestyle.other":
        abreviation = "LS";
        tagClass += "treatment-tag--green";
        break;
      case "lifestyle-other":
        abreviation = "LS";
        tagClass += "treatment-tag--green";
        break;

      case "other":
        abreviation = "---";
        break;
      default:
        abreviation = "?";
    }
    return (
      <span key={key} className={tagClass}>
        {abreviation}
        <div className="mini-tooltip">{this.printableCategory(category)}</div>
      </span>
    );
  }

  static getColorCategory(category) {
    let color;
    switch (category) {
      case "medication":
        color = "yellow";
        break;
      case "workup.labs":
        color = "blue";
        break;
      case "follow_up":
        color = "puke";
        break;
      case "workup.imaging":
        color = "blue";
        break;
      case "workup-imaging":
        color = "blue";
        break;
      case "lifestyle.smoking":
        color = "green";
        break;
      case "lifestyle-smoking":
        color = "green";
        break;
      case "lifestyle.diet":
        color = "green";
        break;
      case "lifestyle-diet":
        color = "green";
        break;
      case "lifestyle.exercise":
        color = "green";
        break;
      case "lifestyle-exercise":
        color = "green";
        break;
      case "procedure":
        color = "aegean";
        break;
      case "workup.screening":
        color = "blue";
        break;
      case "workup-screening":
        color = "blue";
        break;
      case "monitor":
        color = "orange";
        break;
      case "target":
        color = "purple";
        break;
      case "referral_to_specialist":
        color = "absinto";
        break;
      case "lifestyle.other":
        color = "green";
        break;
      case "lifestyle-other":
        color = "green";
        break;
      default:
        color = "blue";
    }
    return color;
  }
};

const comparatorNote = {
  "==": "equal to",
  "!=": "not equal to",
  ">=": "greater than or equal to",
  "<": "Less than",
  ">": "Greater than",
  "<=": "Less than or equal to",
};

export default GuidelineHelper;
