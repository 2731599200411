import React, { useState, useEffect } from "react";
import { includes, isEmpty, cloneDeep } from "lodash";
import { MdClose, MdEdit, MdRemoveCircleOutline } from "react-icons/md";
import { BsPersonFill, BsPerson } from "react-icons/bs";
import { BiSave } from "react-icons/bi";

import {
  CenterRow,
  Card,
  CardTitle,
  CardContent,
  SubcohortTitle,
  Tabs,
  Tab,
  HeaderActions,
} from "./styles";

import SaveSubcohort from "./save-subcohort";
import SavedSubcohortList from "./saved-subcohort-list";
import NewSubcohort from "./new-subcohort";
import AppliedFilters from "./applied-filters";
import { useIdentifySubcohortList } from "../../../../../hooks/useIdentifySubcohortList";

import Tooltip from "../../../../../components/tooltip";
import { useModal } from "../../../../../components/modal";
import { Heading4 } from "../../../../../components/typography";
import InfoTooltip from "../../../../../components/tooltip-info-icon";
import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../../services/mixpanel-tracking";

function SubcohortBuilder({
  selectedCohort,
  setSelectedCohort,
  subcohort,
  subcohortID,
  isSomeSubcohortOpen,
  index,
}) {
  const [activeTab, setActiveTab] = useState("new");
  const [isEditing, setIsEditing] = useState(false);
  const { dispatch } = useIdentifySubcohortList();

  const [draftFilterList, setDraftFilterList] = useState({});

  useEffect(() => {
    setDraftFilterList({ ...subcohort.filterList });
  }, [subcohort.filterList]);

  const {
    toggleModal: toggleSaveSubcohortModal,
    isShowingModal: isShowingSaveSubcohortModal,
  } = useModal();

  useEffect(() => {
    if (!isEmpty(subcohort.filterList)) setIsEditing(false);
  }, [subcohort.filterList]);

  const getSubCohortIcon = () => {
    let icon = <BsPersonFill size={18} />;
    if (
      selectedCohort !== undefined &&
      selectedCohort !== subcohortID &&
      subcohort.status !== "isCreating"
    )
      icon = <BsPerson size={18} />;
    return icon;
  };

  const selectSubcohort = () => {
    if (
      setSelectedCohort &&
      subcohort.status !== "isCreating" &&
      subcohort.status !== "isEditing"
    )
      setSelectedCohort(subcohortID);
  };

  function getContent() {
    const TabView = (
      <>
        <Tabs>
          <Tab active={activeTab === "new"} onClick={() => setActiveTab("new")}>
            New
          </Tab>
          <Tab
            active={activeTab === "saved"}
            onClick={() => setActiveTab("saved")}
          >
            Saved
          </Tab>
        </Tabs>

        {activeTab === "new" ? (
          <NewSubcohort
            selectedCohort={selectedCohort}
            setSelectedCohort={setSelectedCohort}
            subcohort={subcohort}
            subcohortID={subcohortID}
            isEditing={isEditing}
            draftFilterList={draftFilterList}
            setDraftFilterList={setDraftFilterList}
          />
        ) : (
          ""
        )}

        {activeTab === "saved" ? (
          <SavedSubcohortList
            selectedCohort={selectedCohort}
            setSelectedCohort={setSelectedCohort}
            subcohortID={subcohortID}
            isEditing={isEditing}
            subcohort={subcohort}
          />
        ) : (
          ""
        )}
      </>
    );

    const AppliedMinifyView = (
      <CardContent color={subcohort.color}>
        <SubcohortTitle>
          <Heading4 title={subcohort.name} marginBottom={5}>
            {subcohort.name}
          </Heading4>
        </SubcohortTitle>
        {!(
          isSomeSubcohortOpen &&
          !(
            subcohort.status === "isCreating" ||
            subcohort.status === "isEditing"
          )
        ) ? (
          <AppliedFilters subcohort={subcohort} disabled={isEditing} />
        ) : (
          ""
        )}
      </CardContent>
    );

    if (isEmpty(subcohort.filterList)) return TabView;

    return (
      <>
        {AppliedMinifyView}
        {isEditing ? TabView : ""}
      </>
    );
  }

  function removeSubcohortFromDashboard(needConfirmation) {
    let removeSubcohort = true;
    if (needConfirmation) {
      removeSubcohort = window.confirm(
        `Do you really want to remove subcohort: ${subcohort.name} ?`
      );
    }

    if (removeSubcohort === true) {
      dispatch({
        type: "REMOVE_SUBCOHORT",
        payload: {
          id: subcohortID,
        },
      });

      if (selectedCohort && selectedCohort === subcohortID)
        setSelectedCohort("cohort");

      mixpanelIdentify(localStorage.email);
      mixpanelTrack("Remove Subcohort", {
        id: subcohortID,
        name: subcohort.name,
        filters: subcohort.filterList,
      });
    }
  }

  function handleEditingToogle(value) {
    const status = value
      ? "isEditing"
      : includes(subcohort.fetchedData, null)
      ? "isLoading"
      : "isFullyLoaded";

    setIsEditing(value);
    dispatch({
      type: "UPDATE_SUBCOHORT_STATUS",
      payload: {
        id: subcohortID,
        status: status,
      },
    });
  }

  function getHeaderActionsIcons() {
    const iconsActions = [];
    if (!isEmpty(draftFilterList) && !subcohort.isSaved) {
      iconsActions.push(
        <Tooltip key={"saveSubcohort"} description="Save subcohort">
          <BiSave
            color="#6B7C99"
            onClick={(e) => {
              e.stopPropagation();
              toggleSaveSubcohortModal();
            }}
            size={16}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
      );
    }

    if (!isEmpty(subcohort.filterList)) {
      iconsActions.push(
        <Tooltip key={"removeSubcohort"} description="Remove subcohort">
          <MdRemoveCircleOutline
            color="#6B7C99"
            onClick={(e) => {
              e.stopPropagation();
              removeSubcohortFromDashboard(true);
            }}
            size={16}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
      );

      if (isEditing) {
        iconsActions.push(
          <Tooltip key={"cancelEdit"} description="Cancel edit">
            <MdClose
              color="#6B7C99"
              onClick={(e) => {
                e.stopPropagation();
                handleEditingToogle(false);
              }}
              size={16}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        );
      } else {
        iconsActions.push(
          <Tooltip key={"editSubcohort"} description="Edit subcohort">
            <MdEdit
              color="#6B7C99"
              onClick={(e) => {
                e.stopPropagation();
                handleEditingToogle(true);
              }}
              size={16}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        );
      }
    }

    if (isEmpty(subcohort.filterList)) {
      iconsActions.push(
        <Tooltip key={"cancelAddSubcohort"} description="Cancel add subcohort">
          <MdClose
            color="#6B7C99"
            onClick={(e) => {
              e.stopPropagation();
              removeSubcohortFromDashboard(false);
            }}
            size={16}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
      );
    }
    return iconsActions;
  }

  const getCardOpacity = () => {
    if (
      !selectedCohort ||
      subcohort.status === "isCreating" ||
      subcohort.status === "isEditing" ||
      isSomeSubcohortOpen
    )
      return 1;
    if (selectedCohort !== subcohortID) return 0.4;
    return 1;
  };

  return (
    <>
      <Card
        id="subcohortHeader"
        cardIndex={index}
        stackCard={
          isSomeSubcohortOpen &&
          !(
            subcohort.status === "isCreating" ||
            subcohort.status === "isEditing"
          )
        }
        stretch={
          subcohort.status === "isCreating" || subcohort.status === "isEditing"
        }
        pointerCursor={selectedCohort && true}
        onClick={selectSubcohort}
        opacity={getCardOpacity()}
      >
        <CardTitle>
          <CenterRow>
            {getSubCohortIcon()}
            <InfoTooltip
              description={
                "A subcohort is a subset of patients within the cohort."
              }
            >
              Subcohort
            </InfoTooltip>
          </CenterRow>
          <HeaderActions hideIcons={!isEmpty(subcohort.filterList)}>
            {getHeaderActionsIcons()}
          </HeaderActions>
        </CardTitle>
        {getContent()}
      </Card>
      <SaveSubcohort
        toggleModal={toggleSaveSubcohortModal}
        isShowingModal={isShowingSaveSubcohortModal}
        draftFilterList={draftFilterList}
        subcohort={subcohort}
        subcohortID={subcohortID}
      />
    </>
  );
}

export default SubcohortBuilder;
