import styled from "styled-components";

export const PADDING = 24;

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

export const Chart = styled.div`
  box-sizing: border-box;
  padding: ${PADDING}px 0;
  width: 100%;
  height: 100%;
`;
