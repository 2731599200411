import React, { useRef, useEffect } from "react";
import { useField } from "@unform/core";

import { Container, Select } from "./styles";

function LogicExpressionSelect({
  name,
  label,
  inline = false,
  required = false,
  style,
  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.setValue(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container inline={inline} required={required} style={style}>
      {label && <label htmlFor={fieldName}>{label}</label>}

      <Select
        ref={selectRef}
        id={fieldName}
        invalid={!!error}
        inline={inline}
        marginBottom={0}
        {...rest}
      />

      {error && <span>{error}</span>}
    </Container>
  );
}
export default LogicExpressionSelect;
