import React, { useState, useMemo } from "react";
import ReactDOM from "react-dom";
import { filter } from "lodash";

import { FaTimes } from "react-icons/fa";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

import {
  Container,
  Content,
  Header,
  OutsideFAQ,
  Question,
  Tabs,
  Tab,
} from "./styles";
import Button from "../button";
import SearchInput from "../basic-inputs/input/search-input";
import { Heading1, Heading2, Heading4, XSmall } from "../typography";

const FAQ = ({ isShowing, hide, tabs }) => {
  const [activeQuestion, setActiveQuestion] = useState(-1);
  const [searchQuestion, setSearchQuestion] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  function clickOutsideHide(event) {
    const element = event.currentTarget.dataset.id;
    if (element === "faq") {
      // click inside the content of the faq. call stopPropagation to avoid closing
      event.stopPropagation();
    } else if (element === "overlay") {
      closeFAQ();
    }
  }

  function closeFAQ() {
    setActiveQuestion(-1);
    hide();
  }

  const filteredQuestions = useMemo(() => {
    if (searchQuestion.length === 0) {
      return tabs[activeTab].questions;
    }

    let newFilteredQuestions = [];
    tabs.forEach((tab) => {
      newFilteredQuestions = [
        ...newFilteredQuestions,
        ...filter(
          tab.questions,
          (q) => q.title.search(new RegExp(searchQuestion, "i")) > -1
        ),
      ];
    });

    return newFilteredQuestions;
  }, [searchQuestion, activeTab]);

  const noResultFoundText = () => {
    return (
      <>
        <Heading1 marginTop={25}>
          Your search didn’t match any content.
        </Heading1>
        <br />
        <p>
          <b>Some suggestions:</b>
        </p>
        <ul className="u-bullet-list">
          <li>Make sure all words are spelled correctly</li>
          <li>Try different filters combinations</li>
          <li>Try less specific words</li>
        </ul>
      </>
    );
  };

  function getQuestionList() {
    return (
      <>
        <SearchInput
          value={searchQuestion}
          onChange={(value) => setSearchQuestion(value)}
          resetSearch={() => setSearchQuestion("")}
        />
        {searchQuestion.length === 0 && tabs.length > 0 ? (
          <Tabs>
            {tabs.map((tab, index) => (
              <Tab
                active={activeTab === index}
                onClick={() => setActiveTab(index)}
              >
                <XSmall
                  fontWeight={700}
                  textTransform="uppercase"
                  color={activeTab === index ? "--primary" : "#cccccc"}
                >
                  {tab.name}
                </XSmall>
              </Tab>
            ))}
          </Tabs>
        ) : (
          ""
        )}
        {filteredQuestions.length > 0
          ? filteredQuestions.map((question, index) => {
              return (
                <Question key={index} onClick={() => setActiveQuestion(index)}>
                  <Heading4>{question.title}</Heading4>
                  <MdKeyboardArrowRight size={20} />
                </Question>
              );
            })
          : noResultFoundText()}
      </>
    );
  }
  function getQuestionItem() {
    const question = filteredQuestions[activeQuestion];
    return (
      <>
        <Heading4 marginBottom={10}>{question.title}</Heading4>
        {question.description}
      </>
    );
  }

  return isShowing
    ? ReactDOM.createPortal(
        <OutsideFAQ onClick={clickOutsideHide} data-id="overlay">
          <Container onClick={clickOutsideHide} data-id="faq">
            <Header>
              <Heading2 onClick={() => setActiveQuestion(-1)}>
                {activeQuestion === -1 ? "" : <MdKeyboardArrowLeft size={18} />}
                Frequently Asked Questions (FAQs)
              </Heading2>
              <Button variant="clear" onClick={closeFAQ} margin={0} padding={0}>
                <FaTimes size={18} />
              </Button>
            </Header>
            <Content>
              {activeQuestion === -1 ? getQuestionList() : getQuestionItem()}
            </Content>
          </Container>
        </OutsideFAQ>,
        document.body
      )
    : "";
};

export const useFAQ = () => {
  const [isShowingFAQ, setIsShowingFAQ] = useState(false);

  function toggleFAQ() {
    setIsShowingFAQ(!isShowingFAQ);
  }

  return {
    isShowingFAQ,
    toggleFAQ,
  };
};

export default FAQ;
