import React from "react";
import { Switch, Route, Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { BsPersonCircle, BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineHelpOutline } from "react-icons/md";
import RouteHelper from "../../helpers/route-helper";
import SessionService from "../../services/session-service";

import NavItem, { DropdownMenu } from "../nav-item";
import TabsNav from "../app-tab-nav";
import Button from "../button";
import FAQModal, { useFAQ } from "../app-faq";

import { Nav, SignOutContainer, Row, IconLink } from "./styles.js";

export default function AppTopHeader() {
  const Session = useSelector((state) => state.Session);
  const dispatch = useDispatch();
  const history = useHistory();
  const userWorkspaces = SessionService.getAccessibleWorkspaces();
  const { isShowingFAQ, toggleFAQ } = useFAQ();

  const getCLINTView = () => {
    return (
      <Switch>
        <Route path="/content-manager">Content Manager</Route>
        <Route path="/medication-manager">Medication Manager</Route>
        <Route path="/administration">User Administration</Route>
        <Route path="/care-management">Care Management</Route>
        <Route path="/population-analytics">Population Analytics</Route>
        <Route path="/external-control">RWD Trial Replication</Route>
        <Route path="/clint-identify">Clint Identify</Route>
        <Route>404 Error</Route>
      </Switch>
    );
  };

  function signOut() {
    dispatch({ type: "USER_LOGOUT" });
    history.push({ pathname: "/" });
  }

  return (
    <Nav>
      <div>
        <NavItem
          title={getCLINTView()}
          icon={<img src="/images/clint-symbol-white.png" />}
        >
          <DropdownMenu>
            {RouteHelper.getAvailableRoutesURLs(userWorkspaces).map((l, i) => (
              <Link key={i} to={l.url}>
                {l.label}
              </Link>
            ))}
          </DropdownMenu>
        </NavItem>
      </div>

      <TabsNav />

      <Row>
        <IconLink onClick={() => toggleFAQ()} title="FAQs">
          <MdOutlineHelpOutline size={20} />
        </IconLink>
        <NavItem icon={<BsPersonCircle size={18} />} showCloseOpenIcon={false}>
          <DropdownMenu align="right">
            <span>{`${Session.first_name} ${Session.last_name}`}</span>
            {/* <a className="u-cursor-not-allowed">Notifications</a>
              <a className="u-cursor-not-allowed">Settings</a> */}
            <SignOutContainer>
              <Button
                variant="outline"
                className="u-font-size-small u-text-allcaps"
                onClick={() => signOut()}
              >
                Sign out
              </Button>
            </SignOutContainer>
          </DropdownMenu>
        </NavItem>
        <FAQModal
          isShowing={isShowingFAQ}
          hide={toggleFAQ}
          tabs={faqQuestions}
        />
      </Row>
    </Nav>
  );
}

const faqQuestions = [
  {
    name: "General Questions",
    questions: [
      {
        title: "What is real-world data?",
        description: (
          <p>
            Real-world data (RWD) are the data relating to patient health status
            and/or the delivery of health care routinely collected from a
            variety of sources. RWD that powers the CLINT engine comes from
            electronic health records (EHR) and claims records. Real-world
            evidence (RWE) is the analysis of RWD, which is the output of the
            CLINT engine.
          </p>
        ),
      },
      {
        title: "How can I interpret this real-world evidence?",
        description: (
          <>
            <p>
              This answer depends on the context in which the user plans to use
              the data. Because this RWD is heavily dependent on how clinical
              events were documented in patients’ EHR records, it is important
              to use the relative difference between the main cohort and
              subcohort when extracting data from the dashboard.
            </p>
            <p>
              For example, if the event rate in your subcohort is 3.8% and that
              of your main cohort is 1.3%, it is expected that, on average, the
              subcohort will experience gross higher event rates. An incorrect
              interpretation would be that the subcohort always experiences a
              2.5% higher event rate than the main cohort, as this answer is
              biased towards how the event was documented in the EHR.
            </p>
          </>
        ),
      },
      {
        title: "Where and how have these methodologies been validated?",
        description: (
          <p>
            CLINT’s RWE methods have informed a number of published research
            studies. The patented technology has been validated by the FDA and
            ACC; the machine learning models and data analysis methods used by
            CLINT may be reviewed in the following studies completed in
            collaboration with the FDA, Stanford, and Janssen:{" "}
            <a href="https://doi.org/10.1007/s12325-021-01777-9">
              https://doi.org/10.1007/s12325-021-01777-9
            </a>
            ,{" "}
            <a href="https://doi.org/10.1016/j.hroo.2022.11.004">
              https://doi.org/10.1016/j.hroo.2022.11.004
            </a>
            ,{" "}
            <a href="https://doi.org/10.1371/journal.pone.0261786">
              https://doi.org/10.1371/journal.pone.0261786
            </a>
          </p>
        ),
      },
      {
        title: "What is a CLINT Risk Score?",
        description: (
          <p>
            The CLINT Risk score is calculated using machine learning (ML)
            models trained on the main cohort. CLINT’s ML models experience both
            greater sensitivity and selectivity than historical risk models. To
            see this validation, please see the following:{" "}
            <a href=" https://doi.org/10.1371/journal.pone.0261786">
              https://doi.org/10.1371/journal.pone.0261786
            </a>
          </p>
        ),
      },
      {
        title:
          "When I apply a filter, does that filter out past or future patient's characteristics?",
        description: (
          <p>
            All filters and table 1 characteristics relate to variables captured{" "}
            <b>prior to and on</b> the patients index date.
          </p>
        ),
      },
      {
        title: "What is an index date?",
        description: (
          <p>
            All patients included in the cohort have an index date, which is the
            date used to assess inclusion/exclusion criteria for eligibility in
            the cohort. Depending on the cohort generation, per Sponsor’s
            discretion, this varies. For example, for diagnosis specific
            conditions, the index date is the first date the diagnosis appears
            in the patient’s records, once data quality measures are met. For
            general prevention cohorts, the index date is the first date the
            patient appears in the data, once data quality measures are met.
            Please contact your administrator or{" "}
            <a href="support@clinthealth.com">support@clinthealth.com</a> for
            further clarification on your index date selection.
          </p>
        ),
      },
      {
        title: "How is an event, medication, or other clinical value captured?",
        description: (
          <p>
            An event, medication, or other clinical value is captured using
            ICD-10 codes, CPT codes, or string text found in structured notes. A
            patient is counted as on a medication if they filled a prescription
            within the 6 months prior to the patient’s index date. Labs and
            Clinical measurements are the last known values prior to the
            patient’s index date. For all other characteristics, a patient is
            counted as having that characteristic if there is any documentation
            of that characteristic at any time prior to the patient’s index
            date. Each value is stringently reviewed by a member of the
            HealthPals clinical team to ensure proper context is applied when
            capturing the value.
          </p>
        ),
      },
      {
        title: "How was my cohort created?",
        description: (
          <p>
            Cohorts are created by first applying data quality filters, ie:
            making sure each patient has enough data, then applying broad
            inclusion and exclusion criteria to the EHR records, ie: condition,
            age, or presence of certain lab values, depending on the Sponsor.
            You can view your cohort generation by selecting the{" "}
            <BsThreeDotsVertical size={18} color="495F83" /> button on the top
            left of your screen{" "}
            <img
              src="/images/faq_infoicon_dashboard.png"
              style={{ maxWidth: "100%", marginTop: 15 }}
            />
            .
          </p>
        ),
      },
      {
        title:
          "What is the difference between the main cohort and the subcohort?",
        description: (
          <p>
            The main cohort does not have any filters applied, whereas the
            subcohort applies the user generated, additional exclusion and/or
            exclusion criteria to the main cohort.
          </p>
        ),
      },
      {
        title: "Why aren’t my filters being applied to the dashboard?",
        description: (
          <p>
            When selecting a filter, be sure to click the “Apply Filters”
            button– these filters do not apply automatically! If the problem
            persists, please contact{" "}
            <a href="support@clinthealth.com">support@clinthealth.com</a> and a
            member of the support team will contact you.
          </p>
        ),
      },
      {
        title:
          "I have a feature idea, but it’s not in the dashboard. How can I request a feature activation?",
        description: (
          <p>
            We love hearing about ways we can improve the CLINT engine’s user
            experience, so please let us know if there’s something you want to
            see by contacting{" "}
            <a href="support@clinthealth.com">support@clinthealth.com</a>.
          </p>
        ),
      },
      {
        title: "Can I save my results in the dashboard?",
        description: (
          <p>
            Yes! To save a subcohort, press the “Save” button in the bottom left
            of your screen. To re-open a saved subcohort, select the “Saved”
            button next to the subcohort section.
          </p>
        ),
      },
      {
        title: "Can I export my results?",
        description: (
          <p>
            If you’d like to export your results, please contact{" "}
            <a href="support@clinthealth.com">support@clinthealth.com</a> for
            assistance.
          </p>
        ),
      },
    ],
  },
  {
    name: "Definitions",
    questions: [
      {
        title: "Population Map",
        description: (
          <p>
            Highlighted areas on the map show the ZIP3 location where patients
            within the selected cohort receive their care. A darker color
            indicates a greater number of patients in that area.
          </p>
        ),
      },
      {
        title: "Table 1 - Patient characteristics",
        description: (
          <>
            <p>Each row in Table 1 corresponds to a patient characteristic.</p>
            <p>
              Characteristics which are binary (either present or not present)
              are reported as “N (%)”, which correspond to the number and
              percent of individuals which have the characteristic.
            </p>
            <p>
              Characteristics which are numerical (e.g. labs, clinical
              measurements) are reported as "Median (IQR)", which correspond to
              the median value and interquartile range (25th and 75th
              percentile) of the characteristic among patients who have a
              documented value for that characteristic.
            </p>
            <p>
              Look-back timeframes:
              <ul style={{ listStyle: "disc", padding: "5px 20px" }}>
                <li>
                  A patient is counted as on a medication if they filled a
                  prescription within the 6 months prior to the patient’s index
                  date.
                </li>
                <li>
                  Labs and Clinical measurements are the last known values prior
                  to the patient’s index date.
                </li>
                <li>
                  For all other characteristics, a patient is counted as having
                  that characteristic if there is any documentation of that
                  characteristic at any time prior to the patient’s index date.
                </li>
              </ul>
            </p>
          </>
        ),
      },
      {
        title: "Cumulative Event Rate Curve",
        description: (
          <p>
            The cumulative event rate curve compares the percentage of patients
            in the cohort and sub-cohort who have had a documented new event or
            new diagnosis within a given timeframe. The shaded areas show the
            95% confidence intervals.
          </p>
        ),
      },
      {
        title: "Risk model performance - ROC Curve",
        description: (
          <p>
            An ROC curve (receiver operating characteristic curve) is a graph
            showing the performance of a classification model at all
            classification thresholds. This curve plots two parameters:
            sensitivity and specificity. ROC curves show how well a risk
            prediction model discriminates between patients with and without a
            condition.
          </p>
        ),
      },
      {
        title: "Risk model performance - Precision Recall",
        description: (
          <p>
            The Precision Recall curve shows the tradeoff between precision and
            recall for different thresholds. A high area under the curve
            represents both high recall and high precision, where high precision
            relates to a low false positive rate, and high recall relates to a
            low false negative rate. Precision shows how often the machine
            learning model is correct when predicting the target class. Recall
            shows whether the machine learning model can find all objects of the
            target class.
          </p>
        ),
      },
      {
        title: "Risk model performance - Feature Importances",
        description: (
          <p>
            Feature importance refers to techniques that calculate a score for
            all the input features for a given model. The scores represent the
            “importance” of each feature. A higher score means that the specific
            feature will have a larger effect on the model that is being used to
            predict a certain variable.
          </p>
        ),
      },
    ],
  },
];
