import styled from "styled-components";

import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";

export const Creatable = styled(CreatableSelect)`
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;

  //Normal Class
  > div.css-bg1rzq-control,
  div.css-1szy77t-control {
    background-color: #fff;
    border: 1px solid;
    border-color: #8f9cb3;
    min-height: 30px;
    border-radius: 3px;
    color: #26344d;

    > div:first-child {
      padding: 1px 8px;
    }

    > div:nth-child(2) {
      div {
        padding: 0 6px;
        color: #26344d;
      }
    }
  }

  //Disabled Class
  > div.css-yk16xz-control {
    min-height: 30px;
    border-radius: 3px;

    > div:first-child {
      padding: 1px 8px;

      .css-ue83k8-singleValue {
        color: #666666 !important;
      }
    }

    > div:nth-child(2) {
      div {
        padding: 0 6px;
      }
    }
  }

  > div.css-kj6f9i-menu {
    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`;

export const Select = styled(ReactSelect)`
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;

  //Normal Class
  .clint-select__control,
  .clint-select__control--isfocus {
    width: 100%;
    min-width: 150px;
    min-height: auto;

    background-color: #fff;
    border-color: #c1c8d4;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;

    cursor: default;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    box-shadow: none !important;

    outline: 0 !important;
    position: relative;

    transition: all 100ms;

    box-sizing: border-box;

    &:hover {
      border-color: #c1c8d4;
    }
  }

  .clint-select__value-container {
    padding: 6px 8px;
  }

  .clint-select__indicator-separator {
    display: none;
  }

  .clint-select__indicator {
    padding: 6px;
  }

  //Disabled Class
  > div.css-14ojxsb-control {
    min-height: 30px;
    border-radius: 3px;

    > div:first-child {
      padding: 1px 8px;

      .css-ue83k8-singleValue {
        color: #666666 !important;
      }
    }

    > div:nth-child(2) {
      div {
        padding: 0 6px;
      }
    }
  }

  > div.clint-select__menu {
    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`;
