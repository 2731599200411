import React, { createContext, useContext } from "react";

import {
  usePatientAttribute,
  PatientAttributeProvider,
} from "./usePatientAttributes";
import {
  useGuidelineDocument,
  GuidelineDocumentProvider,
} from "./useGuidelineDocuments";
import { useGuidelineRule, GuidelineRuleProvider } from "./useGuidelineRules";
import {
  useMedicationDrugs,
  MedicationDrugsProvider,
} from "./useMedicationDrugs";
import {
  useMedicationIntents,
  MedicationIntentsProvider,
} from "./useMedicationIntents";
import {
  useMedicationMechanisms,
  MedicationMechanismsProvider,
} from "./useMedicationMechanisms";

const MedicalContentContext = createContext({});

export function MedicalContentProvider(props) {
  const { children } = props;

  return (
    <GuidelineRuleProvider>
      <GuidelineDocumentProvider>
        <PatientAttributeProvider>
          <MedicationDrugsProvider>
            <MedicationIntentsProvider>
              <MedicationMechanismsProvider>
                <MedicalContentContext.Provider value={null}>
                  {children}
                </MedicalContentContext.Provider>
              </MedicationMechanismsProvider>
            </MedicationIntentsProvider>
          </MedicationDrugsProvider>
        </PatientAttributeProvider>
      </GuidelineDocumentProvider>
    </GuidelineRuleProvider>
  );
}

export function useMedicalContent() {
  const context = useContext(MedicalContentContext);

  return context;
}
