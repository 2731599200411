import React, { useState, useRef } from "react";
import * as ReactDOM from "react-dom";
import { Small } from "../typography";
import {
  Container,
  ChildrenElement,
  TooltipContainer,
  TooltipContent,
} from "./styles";

const BODYCONTAINER = document.body;

export default function Tooltip({
  children,
  description,
  delay = 400,
  position = "bottom",
  key,
}) {
  const [isShowing, setIsShowing] = useState(false); // tooltip show/hide state
  const timeoutRef = useRef(null); // timeout reference
  const childrenRef = useRef(null);

  function getCoordinates(el) {
    const coordinates = { left: 0, top: 0 };
    if (!el) return coordinates;
    const rect = el.getBoundingClientRect();

    switch (position) {
      case "bottom":
        coordinates.left = rect.right + window.scrollX;
        coordinates.top = rect.bottom + window.scrollY;
        break;
      case "top":
        coordinates.left = rect.left + window.scrollX;
        coordinates.top = rect.top + window.scrollY;
        break;
    }
    return coordinates;
  }

  const showTip = () => {
    timeoutRef.current = setTimeout(() => {
      setIsShowing(true);
    }, delay);
  };

  const hideTip = () => {
    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
      timeoutRef.current = null;
    }

    setIsShowing(false);
  };

  const clickHandler = () => {
    hideTip();
  };

  return (
    <Container key={key}>
      <ChildrenElement
        ref={childrenRef}
        onMouseOver={showTip}
        onMouseOut={hideTip}
        onClick={clickHandler}
      >
        {children}
      </ChildrenElement>
      {isShowing
        ? ReactDOM.createPortal(
            <TooltipContainer
              position={position}
              coordinates={getCoordinates(childrenRef.current)}
            >
              <TooltipContent>
                <Small color="#fff">{description}</Small>
              </TooltipContent>
            </TooltipContainer>,
            BODYCONTAINER
          )
        : ""}
    </Container>
  );
}
