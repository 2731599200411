import React from "react";
import { MdOutlineWarning } from "react-icons/md";

import { Container } from "./styles";

function TrialAlert({ showTrialAlert }) {
  if (showTrialAlert)
    return (
      <Container>
        <MdOutlineWarning />
        This is a demo account. The current license has expired.
      </Container>
    );

  return "";
}

export default TrialAlert;
