import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineHome, MdArrowForwardIos } from "react-icons/md";

import { Heading4 } from "../typography";

import { Container } from "./styles";

function Breadcrumbs({ pathList }) {
  return (
    <Container>
      <MdOutlineHome size={18} color={"#4D5F80"} />
      {pathList.map((link, i) => {
        if (i === 0) return <Link to={link.url}>{link.label}</Link>;
        if (i === pathList.length - 1)
          return (
            <>
              <MdArrowForwardIos size={12} color={"#4D5F80"} />
              <Heading4>{link.label}</Heading4>
            </>
          );
        return (
          <>
            <MdArrowForwardIos size={12} color={"#4D5F80"} />
            <Link to={link.url}>{link.label}</Link>
          </>
        );
      })}
    </Container>
  );
}

export default Breadcrumbs;
