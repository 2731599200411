import React from 'react';
import Loader from'../../components/loader'

const LoadingHOC = () => (WrappedComponent) => {
  const LoadingHOCComponent = (props) => {
    const {isOpen, displayLoading, errorMessage} = props
    const loaded = props.loaded == undefined? true : props.loaded;//Change this

    // Do not display messages if component is closed
    //remove this
    if (!isOpen || !loaded){
      return <div></div>
    }

    // Error Handler
    if (errorMessage){
      return(
        <div className='select-patient__error'>
          <p className='h1'>We are sorry.</p>
          <p>{errorMessage}</p>
          <p>Our engineering team was already notified and we are working quickly to solve the issue.</p>
        </div>
      )
    }

    // Loading otherwise load original component
    if (displayLoading) {
      return (
        <Loader/>
      )
    } else {
      return <WrappedComponent {...props} />
    }
  
  }
  return LoadingHOCComponent
}


export default LoadingHOC;
