import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { Canvg, presets } from "canvg";

import KebabMenuItem from "../../../../../../components/kebab-menu-item";
import ChartWrapper from "../../../../../../components/dashboard-charts-wrapper/charts";

import { useIdentifySubcohortList } from "../../../../../../hooks/useIdentifySubcohortList";
import { useIdentifyCohortConfigs } from "../../../../../../hooks/useIdentifyCohortConfig";

import DashboardHelper from "../../../../../../helpers/dashboard-helper";

import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../../../services/mixpanel-tracking";

const EXPORT_GRAPH_WIDTH = 1024;
const EXPORT_GRAPH_HEIGHT = 768;

function DownloadChart({ chart }) {
  const { subcohortList } = useIdentifySubcohortList();
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { filtersConfig } = cohortConfigs;
  const subcohortIDs = Object.keys(subcohortList);
  const downloadSvgRef = useRef(null);

  const hiddenSelectedChart = Object.assign(
    { config: {}, meta: {} },
    JSON.parse(JSON.stringify(chart ?? {}))
  );
  hiddenSelectedChart.config.hideLegends = false;
  if (filtersConfig)
    hiddenSelectedChart.meta.chartObservation = getChartObservation(
      subcohortList,
      filtersConfig
    );

  function getChartObservation(subcohortList, attributeList) {
    const subcohortIDs = Object.keys(subcohortList);
    let chartObservation = "";
    if (subcohortIDs.length > 0) {
      chartObservation += `The chart shows the risk model curve for the cohort ${subcohortList.cohort.name}`;
      if (subcohortIDs.length > 1) chartObservation += ` and the subcohorts:`;
      for (const id in subcohortList) {
        if (id !== "cohort") {
          chartObservation += ` ${id.toUpperCase()}`;
          const appliedFilterExpression =
            DashboardHelper.getSubcohortDefaultName(
              subcohortList[id].filterList,
              attributeList
            );
          if (subcohortList[id].name !== appliedFilterExpression)
            chartObservation += ` named as ${subcohortList[id].name}`;
          chartObservation += ` with the following attributes: ${appliedFilterExpression}`;
        }
      }
      chartObservation += ".";
    }
    return chartObservation;
  }

  async function onDownloadGraphClick(event) {
    event.preventDefault();

    const svgTitle = chart.name;
    const svgTitleOffset = 20;
    const svgInnerHTML = downloadSvgRef.current.innerHTML;
    const svgStyles = `
      .title {
        font-size: 14px;
      }

      .chart__axis {
        font-size: 12px;
        fill: #26344d;
      }

      .chart__axis_title,
      .chart__observation,
      .chart__axis__ticks-note {
        font-size: 10px;
        fill: #4d5f80;
        shape-rendering: optimizespeed;
      }

      .chart__axis path {
        fill: none;
        stroke: #979797;
        stroke-width: 1px;
        shape-rendering: crispEdges;
      }

      .chart__axis line {
        visibility: hidden;
      }
    `;

    const svgOuterHTML = `<svg width='${EXPORT_GRAPH_WIDTH}' height='${
      EXPORT_GRAPH_HEIGHT + svgTitleOffset
    }'>
      <defs>
        <style>
          ${svgStyles}
        </style>
      </defs>
      <rect width="100%" height="100%" fill="white"/>
      <g transform="translate(0, ${svgTitleOffset})">
        <text dx="50%" class="title" dominant-baseline="middle" text-anchor="middle">${svgTitle}</text>
        ${svgInnerHTML}
      </g>
    </svg>`;

    presets.offscreen();
    const canvas = new OffscreenCanvas(EXPORT_GRAPH_WIDTH, EXPORT_GRAPH_HEIGHT);
    const ctx = canvas.getContext("2d");
    const v = await Canvg.from(ctx, svgOuterHTML);
    await v.render();

    const blob = await canvas.convertToBlob({ type: "image/jpeg" });
    const imageDataUrl = URL.createObjectURL(blob);

    const graphName = subcohortIDs
      .map((name) => name.toLowerCase().replaceAll(" ", "_"))
      .join("-");

    const downloadAnchor = document.createElement("a");
    downloadAnchor.href = imageDataUrl;
    downloadAnchor.download = `${graphName}.jpeg`;
    downloadAnchor.click();

    mixpanelIdentify(localStorage.email);
    mixpanelTrack(`Risk model module - Download Chart`, {
      urlPathname: location.pathname,
    });
  }

  return (
    <>
      <KebabMenuItem
        key="graph-jpg"
        onClick={(event) => onDownloadGraphClick(event)}
      >
        <span>Graph (.jpg)</span>
      </KebabMenuItem>
      {ReactDOM.createPortal(
        <div style={{ width: EXPORT_GRAPH_WIDTH, height: EXPORT_GRAPH_HEIGHT }}>
          <ChartWrapper
            key={"chart-export"}
            svgRef={downloadSvgRef}
            chart={hiddenSelectedChart}
            width={EXPORT_GRAPH_WIDTH}
            height={EXPORT_GRAPH_HEIGHT}
            visible={true}
          />
        </div>,
        document.body
      )}
    </>
  );
}

export default DownloadChart;
