import React, { useState, useMemo } from "react";

import Select from "./select-dropdown";
import Loader from "../../../../components/loader";

import InternalPanel from "../../../../components/panel/internal";

import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../services/mixpanel-tracking";

import PanelActions from "./panel-actions";

import { Tabs, TabsViewContent } from "./tabs-view";

const MODULEVIEWS = ["ROC Curve", "Precision Recall"];

function RiskModelCurveWrapper({
  rocCharts,
  precisionCharts,
  rocTable,
  precisionTable,
}) {
  const [selectedModuleView, setSelectedModuleView] = useState(0);
  const [selectedTab, setSelectedTab] = useState("Chart");
  const [selectedChart, setSelectedChart] = useState(0);

  const isLoading =
    !rocCharts ||
    !rocCharts.length ||
    !precisionCharts ||
    !precisionCharts.length;

  const { moduleViewCharts, moduleViewTable } = useMemo(() => {
    let moduleViewCharts = [];
    let moduleViewTable = {};
    switch (selectedModuleView) {
      case 1:
        moduleViewCharts = precisionCharts;
        moduleViewTable = precisionTable;
        break;
      default:
        moduleViewCharts = rocCharts;
        moduleViewTable = rocTable;
        break;
    }
    return { moduleViewCharts, moduleViewTable };
  }, [selectedModuleView, rocCharts, precisionCharts]);

  const options = MODULEVIEWS.map((l, i) => ({ label: l, value: i }));
  const title = (
    <Select
      key="dropdown"
      options={options}
      value={selectedModuleView}
      onChange={(value) => {
        setSelectedModuleView(Number(value));
        mixpanelIdentify(localStorage.email);
        mixpanelTrack(`Risk model module - Change View`, {
          selectedView: options[value].label,
          urlPathname: location.pathname,
        });
      }}
      marginBottom={0}
    />
  );
  return (
    <InternalPanel
      title={isLoading ? "" : title}
      bottomActions={
        isLoading ? null : (
          <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        )
      }
      actions={
        isLoading ? null : (
          <PanelActions
            charts={moduleViewCharts}
            table={moduleViewTable}
            setSelectedChart={setSelectedChart}
            selectedChart={selectedChart}
            disabledChartSelect={selectedTab == "AUC Values"}
            view={selectedTab}
          />
        )
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <TabsViewContent
          selectedTab={selectedTab}
          chart={moduleViewCharts[selectedChart]}
          table={moduleViewTable}
        />
      )}
    </InternalPanel>
  );
}

export default RiskModelCurveWrapper;
