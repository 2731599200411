import styled from "styled-components";

import { NavLink } from "react-router-dom";

export const TabsBar = styled.div`
  box-sizing: border-box;

  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  overflow-x: auto;
  white-space: nowrap;

  flex: 1;
  margin: 0 20px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TabLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: 10px;
  padding: 0px 15px 7px;

  color: #fff;
  cursor: pointer;
  text-decoration: none;

  font-weight: 400;
  font-size: 1em;
  letter-spacing: 0.04em;

  border-bottom: 5px solid transparent;

  &.is-active {
    cursor: default;
    font-weight: bold;
    border-bottom: 5px solid #fff;
  }
`;

export const MoreTabLink = styled(TabLink)`
  &::after {
    content: "";
    margin-left: 10px;
    border-top: 5px solid #fff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
`;

export const DropdownContainer = styled.div`
  background-color: #f2f2f2;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
  z-index: 999;
  position: absolute;
  top: 40px;
  display: block;
`;

export const DropdownList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropdownLink = styled(NavLink)`
  font-size: 0.8rem;

  margin-bottom: 2px;
  padding: 7px 10px 7px 15px;

  display: block;
  text-decoration: none;

  &:hover {
    background-color: #3d6287;
    color: #fff;
    cursor: pointer;
  }
`;
