import styled from "styled-components";

export const Container = styled.div``;

export const Description = styled.div`
  color: #666666;
  margin-bottom: 10px;
`;

export const SubcohortOptionList = styled.div`
  display: flex;
  gap: 10px;
`;

export const SubcohortOption = styled.div`
  padding: 8px 5px;
  border: 1px solid #c1c8d4;
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 5px;

  label {
    margin: 0;
  }
`;
