import React, { useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { find } from "lodash";

import { FaEdit } from "react-icons/fa";
import { RiArrowDropLeftLine } from "react-icons/ri";

import GuidelineDocumentsOverview from "../../c-guideline-documents-overview";
import GuidelineDocumentsForm from "../../c-guideline-documents-form";
import GuidelineHelper from "../../../../helpers/guideline-helper";
import GuidelineRulesTable from "../../c-guidelines-rules-table";
import Button from "../../../../components/button";
import Tabs from "../../../../components/tabs";
import Tab from "../../../../components/tabs/tab";
import LoadingHOC from "../../../../components/loadingHoc";

import { useGuidelineDocument } from "../../../../hooks/useGuidelineDocuments";
import { useGuidelineRule } from "../../../../hooks/useGuidelineRules";

import { Container, HeaderRow, Header, BackLink } from "./styles";

function DocumentView() {
  const { documentId } = useParams();
  const [isEditing, setIsEditing] = useState(false);

  const { documents } = useGuidelineDocument();
  const { guidelines } = useGuidelineRule();

  const history = useHistory();

  const document = useMemo(() => {
    const document = find(documents, ["id", parseInt(documentId)]);
    if (document) {
      return document;
    }

    history.push("/content-manager/guideline-documents/");
    return {};
  }, [documentId, documents]);

  const guidelinesAttachedToDocument = useMemo(() => {
    return GuidelineHelper.getListOfRulesWithDocument(documentId, guidelines);
  }, [documentId, guidelines]);

  const cancelEditingForm = () => {
    setIsEditing(false);
  };

  return (
    <Container>
      <Header>
        <BackLink to="/content-manager/guideline-documents/">
          <RiArrowDropLeftLine size={18} />
          back to guideline documents
        </BackLink>
        <HeaderRow>
          <h3>{document.title}</h3>
          {isEditing ? (
            ""
          ) : (
            <Button
              variant="outline"
              type="button"
              leftIcon={<FaEdit />}
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
          )}
        </HeaderRow>
      </Header>
      {isEditing ? (
        <GuidelineDocumentsForm
          cancelFormAction={cancelEditingForm}
          document={document}
          usageNumber={guidelinesAttachedToDocument.length}
        />
      ) : (
        <Tabs>
          <Tab label="Overview">
            <GuidelineDocumentsOverview document={document} />
          </Tab>
          {guidelinesAttachedToDocument.length == 0 ? null : (
            <Tab label={`Guidelines (${guidelinesAttachedToDocument.length})`}>
              <GuidelineRulesTable
                isFiltersOn={false}
                guidelines={guidelinesAttachedToDocument}
              />
            </Tab>
          )}
        </Tabs>
      )}
    </Container>
  );
}

export default LoadingHOC()(DocumentView);
