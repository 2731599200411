import React from "react";
import { Link } from "react-router-dom";

import LoadingHOC from "../../../../components/loadingHoc";

import Button from "../../../../components/button";
import AppPageTitle from "../../../../components/app-page-title";
import MedicationTable from "../../c-medication-drug-table";

import { useMedicationDrugs } from "../../../../hooks/useMedicationDrugs.js";

import { Container } from "./styles";

function MedicationList() {
  const { medications } = useMedicationDrugs();

  return (
    <>
      <AppPageTitle
        title="Medication Drugs"
        actions={
          <Button variant="confirm">
            <Link to="/medication-manager/medications/add">New Medication</Link>
          </Button>
        }
      />
      <Container>
        <MedicationTable medications={medications} />
      </Container>
    </>
  );
}

export default LoadingHOC()(MedicationList);
