import React from "react";

import { Container, AttributesLabels } from "./styles";

function PatientAttributeOverview({ attribute }) {
  return (
    <Container>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <AttributesLabels>
            <label>Name</label>
            <span>{attribute.human_readable_name}</span>
          </AttributesLabels>
        </div>
        <div className="col-xs-12 col-sm-6">
          <AttributesLabels>
            <label>Variable</label>
            <span>{attribute.name}</span>
          </AttributesLabels>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <AttributesLabels>
            <label>Category</label>
            <span>{attribute.category}</span>
          </AttributesLabels>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <AttributesLabels>
            <label>Data Type</label>
            <span>{attribute.data_type}</span>
          </AttributesLabels>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <AttributesLabels>
            <label>Description</label>
            <span>{attribute.description ? attribute.description : "-"}</span>
          </AttributesLabels>
        </div>
      </div>
    </Container>
  );
}

export default PatientAttributeOverview;
