import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { concat } from "lodash";

import GuidelineHelper from "../../../helpers/guideline-helper.js";
import Table from "../../../components/tables/simple-table";
import FilteredTable from "../../../components/tables/filtered-table";
import Button from "../../../components/button";

import { useGuidelineDocument } from "../../../hooks/useGuidelineDocuments.js";
import { usePatientAttribute } from "../../../hooks/usePatientAttributes";
import { useMedicationMechanisms } from "../../../hooks/useMedicationMechanisms";
import { useMedicationIntents } from "../../../hooks/useMedicationIntents";
import { useMedicationDrugs } from "../../../hooks/useMedicationDrugs";

export default function GuidelineRulesTable(props) {
  const { guidelines, isFiltersOn = true } = props;

  const { attributes } = usePatientAttribute();
  const { medicationIntents } = useMedicationIntents();
  const { medicationMechanisms } = useMedicationMechanisms();
  const { medications } = useMedicationDrugs();

  const triggers = useMemo(() => {
    return concat(
      attributes,
      medicationIntents,
      medicationMechanisms,
      medications
    );
  }, [attributes, medicationIntents, medicationMechanisms, medications]);

  const { documents } = useGuidelineDocument();

  const columns = [
    {
      Header: "#",
      accessor: "id",
      disableGlobalFilter: true,
    },
    {
      accessor: "id",
      id: "view-btn",
      disableSortBy: true,
      disableGlobalFilter: true,
      Cell: ({ cell: { value } }) => (
        <Button variant="outline">
          <Link to={`/content-manager/rules/${value}`}>View</Link>
        </Button>
      ),
    },
    {
      Header: "Rule Type",
      accessor: (row) => GuidelineHelper.printableRuleType(row.rule_type),
      filterType: "SelectCheckbox",
      disableGlobalFilter: true,
      id: "rule_type",
    },
    {
      Header: "Recommendation Summary",
      accessor: "name",
    },
    {
      Header: "Triggers",
      accessor: (row) =>
        GuidelineHelper.printTriggerExpression(
          row.trigger_combination_type,
          row.triggers,
          triggers
        ),
      id: "triggers",
    },
    {
      Header: "Source",
      accessor: (row) => GuidelineHelper.printGuidelineSource(row, documents),
      breakWord: true,
      filterType: "SelectCheckbox",
      disableGlobalFilter: true,
      id: "source",
    },
    {
      Header: "Healthpals Grade",
      disableGlobalFilter: true,
      accessor: "healthpals_rule_grade",
    },
    {
      Header: "Status",
      accessor: "status",
      disableGlobalFilter: true,
      Cell: ({ cell: { value } }) => GuidelineHelper.printStatusTag(value),
      filterType: "SelectCheckbox",
    },
  ];

  if (isFiltersOn) {
    return <FilteredTable columns={columns} data={guidelines} urlState />;
  }
  return <Table columns={columns} data={guidelines} />;
}
