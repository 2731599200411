import React, { useMemo } from "react";
import { find } from "lodash";

import { Container, AttributesLabels } from "./styles";

import { useMedicationMechanisms } from "../../../hooks/useMedicationMechanisms";

function MedicationDrugOverview({ medication }) {
  const { medicationMechanisms } = useMedicationMechanisms();

  const parent = useMemo(() => {
    const medicationMechanism = find(medicationMechanisms, [
      "id",
      medication.parent,
    ]);
    return medicationMechanism || {};
  }, [medicationMechanisms]);

  return (
    <Container>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <AttributesLabels>
            <label>Name</label>
            <span>{medication.human_readable_name}</span>
          </AttributesLabels>
        </div>
        <div className="col-xs-12 col-sm-6">
          <AttributesLabels>
            <label>Variable</label>
            <span>{medication.name}</span>
          </AttributesLabels>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <AttributesLabels>
            <label>Medication Mechanism</label>
            <span>{parent.human_readable_name}</span>
          </AttributesLabels>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <AttributesLabels>
            <label>Codes</label>
          </AttributesLabels>
        </div>
      </div>
    </Container>
  );
}

export default MedicationDrugOverview;
