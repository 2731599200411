import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { find } from "lodash";

import GuidelineHelper from "../../../helpers/guideline-helper.js";
import Table from "../../../components/tables/simple-table";
import FilteredTable from "../../../components/tables/filtered-table";
import Button from "../../../components/button";

import { useGuidelineRule } from "../../../hooks/useGuidelineRules.js";
import { useMedicationMechanisms } from "../../../hooks/useMedicationMechanisms.js";

export default function MedicationsTable(props) {
  const { medications, isFiltersOn = true } = props;

  const { guidelines } = useGuidelineRule();
  const { medicationMechanisms } = useMedicationMechanisms();

  const columns = [
    {
      Header: "#",
      accessor: "id",
      disableGlobalFilter: true,
    },
    {
      accessor: "id",
      id: "view-btn",
      disableSortBy: true,
      disableGlobalFilter: true,
      Cell: ({ cell: { value } }) => (
        <Button variant="outline">
          <Link to={`/medication-manager/medications/${value}`}>View</Link>
        </Button>
      ),
    },
    {
      Header: "Name",
      accessor: "human_readable_name",
      breakWord: true,
    },
    {
      Header: "Medication Mechanism",
      accessor: (row) =>
        find(medicationMechanisms, ["id", row.parent])
          ? find(medicationMechanisms, ["id", row.parent]).human_readable_name
          : "-",
      id: "medication-mechanism",
      disableGlobalFilter: true,
    },
    {
      Header: "# of Rules",
      accessor: (row) =>
        GuidelineHelper.numberOfRulesWithAttribute(row.id, guidelines),
      id: "rule-usage",
      disableGlobalFilter: true,
    },
  ];

  if (isFiltersOn) {
    return <FilteredTable columns={columns} data={medications} urlState />;
  }
  return <Table columns={columns} data={medications} />;
}
