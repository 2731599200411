import React, { useMemo, useEffect } from "react";
import { useTable, useGlobalFilter, useSortBy, useFilters } from "react-table";
import { uniqBy, includes } from "lodash";

import SelectCheckbox from "../../select-checkbox";
import SelectSliderRangeInput from "../../select-slider-range";

import TableHeader from "../simple-table/header";
import TableRow from "../simple-table/row";

import { Table } from "../simple-table/styles";

import {
  Thead,
  Filters,
  Filter,
  TableContainer,
  NoResultContainer,
} from "./styles";

// This is a custom filter UI for selecting
// a multiple options from a list
function SelectCheckboxFilter({ column }) {
  const { filterValue, setFilter, preFilteredRows, id } = column;
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = [];
    preFilteredRows.forEach((row) => {
      if (row.values[id])
        options.push({ value: row.values[id], label: row.values[id] });
    });
    return uniqBy(options, "value");
  }, [id, preFilteredRows]);

  const getLabel = () => {
    if (column.filterLabel) return column.filterLabel;
    return column.render("Header");
  };

  // Render a multi-select box
  return (
    <SelectCheckbox
      value={filterValue}
      options={options}
      label={getLabel()}
      onChange={(value) => {
        setFilter(value);
      }}
    />
  );
}
// Define a custom filter function!
function SelectCheckboxFilterFn(rows, id, filterValue) {
  if (filterValue) {
    const arrayFilter = filterValue.split(",");
    return rows.filter(function (row) {
      return includes(arrayFilter, row.values[id]);
    });
  }
  return rows;
}
function SelectSliderRange({ column }) {
  const { filterValue, setFilter, preFilteredRows, id, step } = column;
  // TODO: Calculate the min and max for filtering
  // using the preFilteredRows
  const { min, max } = useMemo(() => {
    const availableValues = preFilteredRows.map((row) => {
      return row.values[id];
    });

    return {
      min: Math.min(...availableValues),
      max: Math.max(...availableValues),
    };
  }, [preFilteredRows]);

  const getLabel = () => {
    if (column.filterLabel) return column.filterLabel;
    return column.render("Header");
  };

  // Render a multi-select box
  return (
    <SelectSliderRangeInput
      label={getLabel()}
      min={0}
      max={100}
      defaultValue={[0, 100]}
      step={10}
      marks={{
        0: 0,
        10: 10,
        20: 20,
        30: 30,
        40: 40,
        50: 50,
        60: 60,
        70: 70,
        80: 80,
        90: 90,
        100: 100,
      }}
      value={filterValue}
      onChange={(value) => {
        setFilter(value);
      }}
    />
  );
}
function SelectSliderRangeFn(rows, id, filterValue) {
  if (filterValue) {
    return rows.filter(function (row) {
      return (
        filterValue[0] <= row.values[id] && row.values[id] <= filterValue[1]
      );
    });
  }
  return rows;
}

function SelectCheckboxRange({ column }) {
  const { filterValue, setFilter, preFilteredRows, id, options } = column;

  const getLabel = () => {
    if (column.filterLabel) return column.filterLabel;
    return column.render("Header");
  };

  // Render a multi-select box
  return (
    <SelectCheckbox
      value={filterValue}
      options={options}
      label={getLabel()}
      onChange={(value) => {
        setFilter(value);
      }}
    />
  );
}
function SelectCheckboxRangeFn(rows, id, filterValue) {
  if (filterValue) {
    const filterValueArray = filterValue.split(",");
    let newRow = [];
    filterValueArray.forEach((value) => {
      const arrayFilter = value.split("-");
      const filteredRows = rows.filter(function (row) {
        return (
          arrayFilter[0] <= row.values[id] && row.values[id] <= arrayFilter[1]
        );
      });
      newRow = [...newRow, ...filteredRows];
    });
    return newRow;
  }
  return rows;
}

export default function parseTableProps(props) {
  const columns = React.useMemo(() => {
    const columns = props.columns.map((column) => {
      switch (column.filterType) {
        case "SelectCheckbox":
          column.Filter = SelectCheckboxFilter;
          column.filter = SelectCheckboxFilterFn;
          break;
        case "SelectSliderRange":
          column.Filter = SelectSliderRange;
          column.filter = SelectSliderRangeFn;
          break;
        case "SelectCheckboxRange":
          column.Filter = SelectCheckboxRange;
          column.filter = SelectCheckboxRangeFn;
          break;
      }
      return column;
    });
    return columns;
  }, [props.columns]);
  return <FilteredTable columns={columns} {...props} />;
}

function FilteredTable({
  columns,
  data,
  defaultSort = [],
  globalFilterValue,
  conditionalRowStyles,
  conditionalCellStyles,
  tableHeight,
  stickyHeader,
  onFilterDataChange,
}) {
  const filterTypes = useMemo(
    () => ({
      selectCheckbox: SelectCheckboxFilterFn,
    }),
    []
  );

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setGlobalFilter, // use a global filter
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: defaultSort,
        hiddenColumns: columns.map((column) =>
          column.hiddenColumn ? column.accessor : null
        ),
      },
      filterTypes,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  // Update the state when input changes
  useEffect(() => {
    setGlobalFilter(globalFilterValue);
  }, [globalFilterValue]);

  useEffect(() => {
    if (onFilterDataChange)
      onFilterDataChange(rows.map((row) => ({ ...row.original })));
  }, [rows.length]);

  const getFiltersInput = () => {
    const filters = [];
    headerGroups.map((headerGroup) => {
      headerGroup.headers.map((column) => {
        if (column.Filter)
          filters.push(
            <Filter key={column.id}>{column.render("Filter")}</Filter>
          );
      });
    });
    return filters;
  };

  const getFilters = () => {
    const filters = getFiltersInput();

    if (filters.length > 0)
      return (
        <Filters>
          <label>Filter</label>
          {filters}
        </Filters>
      );
  };

  const noResultFoundText = () => {
    return (
      <NoResultContainer tableHeight={tableHeight}>
        <h1>Your search didn’t match any content.</h1>
        <br />
        <p>
          <b>Some suggestions:</b>
        </p>
        <ul className="u-bullet-list">
          <li>Make sure all words are spelled correctly</li>
          <li>Try different filters combinations</li>
          <li>Try less specific words</li>
        </ul>
      </NoResultContainer>
    );
  };

  rows.forEach((row) => prepareRow(row));

  const getTable = () => (
    <TableContainer tableHeight={tableHeight}>
      <Table {...getTableProps()}>
        <Thead stickyHeader={stickyHeader}>
          {headerGroups.map((headerGroup, i) => (
            <TableHeader key={i} headerGroup={headerGroup} />
          ))}
        </Thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => (
            <TableRow
              key={i}
              prepareRow={prepareRow}
              row={row}
              conditionalRowStyles={conditionalRowStyles}
              conditionalCellStyles={conditionalCellStyles}
            />
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      {getFilters()}
      {rows.length > 0 ? getTable() : noResultFoundText()}
    </>
  );
}
