import { combineReducers } from "redux";

import Session from "./core-app/session";

import Administration from "./administration/administration";

const ciApp = combineReducers({
  Session,
  Administration,
});

export default ciApp;
