import styled from "styled-components";
import { MapContainer } from "react-leaflet";

export const Map = styled(MapContainer)`
  position: absolute;
  z-index: 1;

	width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  > .leaflet-control-container{

    .leaflet-left .leaflet-control {
      margin-left: 15px;
    }

    .leaflet-control-layers-toggle {
      width: 30px;
      height: 30px;
      background-image: url(/images/layers-icon.svg);
      background-size: 20px 20px;
    }

    .leaflet-control-layers, .leaflet-control-zoom {
      border: 1px solid #3D6287;
    }

    .leaflet-bar a{
      color: #3D6287;
      border-bottom: 1px solid #3D6287;

      &:last-child{
        border-bottom: none;
      }
    }

  }

  .leaflet-marker-icon.leaflet-div-icon{
    background:transparent;
    border: 0;
    margin-left: -12px !important;
    margin-top: -30px !important;

  }

  }

`;
