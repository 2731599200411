import styled from "styled-components";

export const OutsidePanel = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(38, 52, 77, 0.7);

  /* opacity: 0; */
  transition: opacity 0.4s;
`;

export const Container = styled.div`
  background: #ffffff;
  padding: 15px;
  border: 1px solid #c1c8d4;
  width: 80%;
  margin: 30px auto;
`;

export const Header = styled.div`
  margin: -15px -15px 15px -15px;
  padding: 15px;
  background-color: #ffffff;
  border-bottom: 1px solid #c1c8d4;

  z-index: 999;
  position: relative;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BottomHeader = styled.div``;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
