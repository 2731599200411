import styled, { css } from "styled-components";
import { startsWith } from "lodash";

export const Heading1Container = styled.h1`
  color: ${(props) =>
    startsWith(props.color, "--") ? `var(${props.color})` : props.color};
  text-transform: ${(props) => props.textTransform};
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;
`;

export const Heading2Container = styled.h2`
  color: ${(props) =>
    startsWith(props.color, "--") ? `var(${props.color})` : props.color};
  text-transform: ${(props) => props.textTransform};
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;
`;

export const Heading3Container = styled.h3`
  color: ${(props) =>
    startsWith(props.color, "--") ? `var(${props.color})` : props.color};
  text-transform: ${(props) => props.textTransform};
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;
`;

export const Heading4Container = styled.h4`
  color: ${(props) =>
    startsWith(props.color, "--") ? `var(${props.color})` : props.color};
  text-transform: ${(props) => props.textTransform};
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;
`;

export const Heading5Container = styled.h5`
  color: ${(props) =>
    startsWith(props.color, "--") ? `var(${props.color})` : props.color};
  text-transform: ${(props) => props.textTransform};
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;
`;

export const SmallContainer = styled.small`
  color: ${(props) =>
    startsWith(props.color, "--") ? `var(${props.color})` : props.color};
  text-transform: ${(props) => props.textTransform};
  font-weight: ${(props) => props.fontWeight};
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;

  ${(props) =>
    (props.marginBottom > 0 || props.marginTop > 0) &&
    css`
      display: block;
    `}
`;

export const XSmallContainer = styled.small`
  color: ${(props) =>
    startsWith(props.color, "--") ? `var(${props.color})` : props.color};
  text-transform: ${(props) => props.textTransform};
  font-weight: ${(props) => props.fontWeight};
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;
  font-size: 0.785rem;

  ${(props) =>
    (props.marginBottom > 0 || props.marginTop > 0) &&
    css`
      display: block;
    `}
`;

export const LargeContainer = styled.span`
  color: ${(props) =>
    startsWith(props.color, "--") ? `var(${props.color})` : props.color};
  text-transform: ${(props) => props.textTransform};
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;
  display: block;
  font-weight: 400;
  font-size: 2.142rem;
  letter-spacing: -0.02em;
  line-height: 1.4;
`;
