import React, { Fragment, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";

import Input from "../../components/unform-inputs/input";
import Select from "../../components/unform-inputs/select";
import ReactSelect from "../../components/unform-inputs/react-select";
import Button from "../../components/button";

import { find, uniq, compact } from "lodash";

export default function DemoUsersForm(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef(null);
  const users = useSelector((state) => state.Administration.Users.users);
  const { userId } = useParams();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (userId) {
      const user = find(users, ["id", parseInt(userId)]);
      if (user) {
        let roles = [];
        let workspaces = [];
        user.roles.forEach((r) => {
          let [workspace, role] = r.split(".");
          roles.push(role);
          workspaces.push(workspace);
        });
        user.role = uniq(compact(roles))[0];
        user.workspaces = workspaces;
        user.password = null;
        formRef.current.setData(user);
      }
    }
  }, [users]);

  async function submit(data) {
    setErrorMessage(null);
    data.id = userId;
    try {
      // if(!data.password || data.password=='') delete data.password
      // Remove all previous errors

      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        first_name: Yup.string().required(),
        last_name: Yup.string().required(),
        email: Yup.string().email().required(),
        // password: Yup.when('$isEditing',{'is':false, 'then':s=>s.string().min(6).required(),  'otherwise': s=>s.string().min(6)},),
      });
      await schema.validate(data, {
        abortEarly: false,
        context: { isEditing: userId ? true : false },
      });

      data.roles = [];
      data.workspaces.forEach((w) => {
        if (w != "healthpals" && w != "admin ") {
          data.roles.push(`${w}.${data.role}`);
        } else {
          data.roles.push(w);
        }
      });
      delete data["role"];
      delete data["workspaces"];
      let event = userId ? "EDIT_USER" : "CREATE_USER";

      dispatch({
        type: event,
        payload: data,
        userId: userId,
        successHandler: successSave,
        errorHandler: errorSave,
      });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const successSave = function () {
    dispatch({ type: "REFRESH_ALL_USERS" });
    cancelForm();
  };

  const errorSave = function (errorMessages) {
    setErrorMessage(errorMessages);
  };

  const cancelForm = () => {
    history.push({ pathname: "/administration/users" });
  };

  return (
    <Fragment>
      <Form ref={formRef} onSubmit={submit} className="form-box">
        {errorMessage ? (
          <div
            className="patient-status__alert-message alert-message__warning"
            style={{ height: "36px", marginBottom: "12px" }}
          >
            <p className="alert-message__paragraph u-color-denim-40">
              <b>Alert:</b> {errorMessage}
            </p>
          </div>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-sm-7 col-sm-offset-2">
            <Input name="first_name" required label="First Name" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-7 col-sm-offset-2">
            <Input name="last_name" required label="Last Name" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-7 col-sm-offset-2">
            <Input name="email" required type="email" label="Email" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-7 col-sm-offset-2">
            <ReactSelect
              name="workspaces"
              label="Workspaces"
              options={workspacesOptions}
              isMulti
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-7 col-sm-offset-2">
            <Select name="role" label="Role" options={rolesOptions} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-7 col-sm-offset-2">
            <Input
              name="password"
              required={userId ? false : true}
              type="password"
              label="Password"
            />
            <p>Password policy:</p>
            <ul className="u-bullet-list">
              <li>Must be at least 12 digits long</li>
              <li>Must contain at least 1 uppercase letter</li>
              <li>Must contain at least 1 lowercase letter</li>
              <li>Must contain at least 1 number</li>
              <li>Must contain at least 1 symbol</li>
              <li>
                No sequences of the same character longer than 2 are allowed
              </li>
              <li>No sequences (i.e., 123) allowed</li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-7 col-sm-offset-2">
            <div className="form-box__footer">
              <Button
                type="button"
                variant="clear"
                onClick={() => cancelForm()}
              >
                Cancel
              </Button>
              <Button variant="confirm" type="submit">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Fragment>
  );
}

// TODO: certerlize this roles and workspaces name in one file
const workspacesOptions = [
  { value: "healthpals", label: "Healthpals User Administration" },
  { value: "admin", label: "User Administration" },
  { value: "mcm", label: "Content Manager" },
  { value: "identify", label: "Clint Identify" },
];
const rolesOptions = [
  { value: "viewer", label: "Viewer" },
  { value: "manager", label: "Manager" },
];
