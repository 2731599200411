import { isEmpty } from "lodash";
import React, {
  useState,
  useImperativeHandle,
  useMemo,
  useRef,
  forwardRef,
} from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Small } from "../../../../../../../components/typography";

import Filter from "../filter";

import { Container, Filters } from "./styles";

const FilterGroup = forwardRef(
  ({ filters, name, subcohort, draftFilterList, setDraftFilterList }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const collapsibleRef = useRef(null);

    useImperativeHandle(
      ref,
      () => ({
        open() {
          setIsOpen(true);
        },
        close() {
          setIsOpen(false);
        },
        element: collapsibleRef.current,
      }),
      [collapsibleRef.current]
    );

    const appliedFilterCount = useMemo(() => {
      let count = 0;
      if (!isEmpty(subcohort.filterList)) {
        filters.forEach((filter) => {
          if (subcohort.filterList[filter.key]) count++;
        });
      }

      return count;
    }, [subcohort.filterList]);

    return (
      <Container ref={collapsibleRef}>
        <Small
          color="#666666"
          textTransform="capitalize"
          onClick={() => setIsOpen(!isOpen)}
          fontWeight={appliedFilterCount > 0 ? 700 : 400}
        >
          {name}
          {appliedFilterCount > 0 ? ` (${appliedFilterCount})` : ""}
          {isOpen ? (
            <MdKeyboardArrowUp size={18} />
          ) : (
            <MdKeyboardArrowDown size={18} />
          )}
        </Small>
        {isOpen ? (
          <Filters>
            {filters.map((filter) => (
              <Filter
                key={filter.key}
                filter={filter}
                subcohort={subcohort}
                draftFilterList={draftFilterList}
                setDraftFilterList={setDraftFilterList}
              />
            ))}
          </Filters>
        ) : (
          ""
        )}
      </Container>
    );
  }
);

export default FilterGroup;
