import React, { useMemo } from "react";
import { concat, find } from "lodash";
import { Container, AttributesLabels } from "./styles";

import GuidelineHelper from "../../../helpers/guideline-helper.js";

import { useGuidelineDocument } from "../../../hooks/useGuidelineDocuments.js";
import { usePatientAttribute } from "../../../hooks/usePatientAttributes";
import { useMedicationMechanisms } from "../../../hooks/useMedicationMechanisms";
import { useMedicationIntents } from "../../../hooks/useMedicationIntents";
import { useMedicationDrugs } from "../../../hooks/useMedicationDrugs";

function GuidelineRuleOverview({ guideline }) {
  const { attributes } = usePatientAttribute();
  const { medicationIntents } = useMedicationIntents();
  const { medicationMechanisms } = useMedicationMechanisms();
  const { medications } = useMedicationDrugs();

  const triggers = useMemo(() => {
    return concat(
      attributes,
      medicationIntents,
      medicationMechanisms,
      medications
    );
  }, [attributes, medicationIntents, medicationMechanisms, medications]);

  const { documents } = useGuidelineDocument();

  const document = useMemo(() => {
    const document = find(documents, ["id", guideline.guideline_document]);
    return document || {};
  }, [documents]);

  return (
    <Container>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <AttributesLabels>
            <label>Rule Type</label>
            <span>
              {GuidelineHelper.printableRuleType(guideline.rule_type)}
            </span>
          </AttributesLabels>
        </div>
        <div className="col-xs-12 col-sm-6">
          <AttributesLabels>
            <label>Status</label>
            <span>{GuidelineHelper.printStatusTag(guideline.status)}</span>
          </AttributesLabels>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <AttributesLabels>
            <label>Guideline Document</label>
            <span>{document.title}</span>
          </AttributesLabels>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <AttributesLabels>
            <label>Rule Title</label>
            <span>{guideline.name}</span>
          </AttributesLabels>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <AttributesLabels>
            <label>Rule Grade</label>
            <span>{guideline.evidence_level}</span>
          </AttributesLabels>
        </div>
        <div className="col-xs-12 col-sm-6">
          <AttributesLabels>
            <label>HealthPals Grade</label>
            <span>{guideline.healthpals_rule_grade}</span>
          </AttributesLabels>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <AttributesLabels>
            <label>Triggers</label>
            <span>
              {GuidelineHelper.printTriggerExpression(
                guideline.trigger_combination_type,
                guideline.triggers,
                triggers
              )}
            </span>
          </AttributesLabels>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <AttributesLabels>
            <label>Rule Description</label>
            <span>{guideline.rule_value}</span>
          </AttributesLabels>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <AttributesLabels>
            <label>Outputs</label>
            <span>
              {guideline.outputs.map(
                (id) => `${find(triggers, ["id", id]).human_readable_name} `
              )}
            </span>
          </AttributesLabels>
        </div>
      </div>
    </Container>
  );
}

export default GuidelineRuleOverview;
