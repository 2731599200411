import { sortBy } from "lodash";
import React from "react";
import Table from "../../../../../../components/tables/simple-table";

import { Container } from "./styles";

function TableWrapper({ table }) {
  if (!table) {
    return null;
  }

  const { columns, rows, conditionalRowStyles, conditionalCellStyles } = table;
  if (!columns || !rows) {
    return null;
  }

  const data = sortBy(rows, ["outcome"]);

  return (
    <Container>
      <Table
        columns={columns}
        data={data}
        conditionalRowStyles={conditionalRowStyles}
        conditionalCellStyles={conditionalCellStyles}
        disableSortBy={true}
        stickyHeader={true}
      />
    </Container>
  );
}

export default TableWrapper;
