import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  z-index: 10;

  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;

  > svg {
    position: absolute;
    right: 5;
    top: 5;
    z-index: -1;
  }

  &[disabled] {
    cursor: not-allowed;
    border-color: #cccccc;
    opacity: 1;
    color: #cccccc;
  }
`;

export const Select = styled.select`
  padding: 5px 30px 5px 0px;
  height: 30px;
  width: 100%;
  margin: 0;

  font-size: 1.142rem;
  font-weight: 400;
  letter-spacing: -0.02em;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  color: #26344d;
  background-color: transparent;

  border: 1px solid;
  border-radius: 3px;

  border-color: transparent;

  -webkit-transition: border-color ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;

  cursor: pointer;

  &:focus {
    outline: none;
  }

  &[readonly] {
    cursor: not-allowed;
    border-color: #c1c8d4;
    background: #f2f2f2;
    opacity: 1;
    color: #666666;
    background: #f2f2f2;
  }

  &[disabled] {
    cursor: not-allowed;
    border-color: #cccccc;
    opacity: 1;
    color: #cccccc;
  }
`;
