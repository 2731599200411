import styled, { css } from "styled-components";

export const CenterRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderActions = styled.div`
  display: flex;
  gap: 5px;
  visibility: ${(props) => (props.hideIcons ? "hidden" : "visible")};
`;

export const Card = styled.div`
  border: 1px solid #e6e6e6;
  margin: 4px 9px;
  border-radius: 3px;
  background-color: #fff;
  cursor: ${(props) => (props.pointerCursor ? "pointer" : "default")};

  display: flex;
  flex-direction: column;
  z-index: ${(props) => 4 - props.cardIndex};
  transition: margin 0.5s;

  opacity: ${(props) => props.opacity};

  ${(props) =>
    props.stackCard &&
    css`
      margin-top: -68px;
    `}

  flex: ${(props) => (props.stretch ? "1 1 auto" : "none")};
  overflow: auto;

  &:hover {
    opacity: 1;
    ${HeaderActions} {
      visibility: visible;
    }
  }
`;

export const CardTitle = styled.div`
  padding: 6px;
  flex: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #e6e6e6;

  > button {
    margin: 0;
  }
`;

export const SubcohortTitle = styled.div`
  h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const CardContent = styled.div`
  padding: 9px;
  border-left: 5px solid;
  border-color: ${(props) => props.color};
  flex: none;
`;

export const Tabs = styled.div`
  flex: none;

  display: flex;

  text-align: center;
  border-radius: 3px;
  border: solid 1px #c1c8d4;
  margin: 9px 9px 10px 9px;
  padding: 3px;
`;

export const Tab = styled.div`
  padding: 6px 11px;
  max-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 3px;

  cursor: pointer;

  background-color: #fff;
  color: var(--primary);

  ${(props) =>
    props.active &&
    css`
      background-color: #495f83;
      color: #fff;
      cursor: default;
    `}
`;
