import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;

  /* flex: 1;
    align-self: stretch;
    margin-bottom: 0; */
`;

export const Control = styled.div`
  background-color: #fff;
  border: 1px solid;
  border-color: #8f9cb3;
  min-height: 30px;
  border-radius: 3px;
  color: #26344d;

  align-items: center;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      border-color: #c1c8d4;
      opacity: 1;
      color: #666666;
    `}
`;

export const Value = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 2px 8px;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
`;

export const Controls = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
`;

export const Separator = styled.span`
  align-self: stretch;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
`;
export const Indicator = styled.div`
  padding: 0 4px;
  color: ${(props) => (props.disabled ? "#666666" : "#26344D")};
  display: flex;
  transition: color 150ms;
  box-sizing: border-box;
  cursor: pointer;
`;

export const Tag = styled.div`
  border-radius: 2px;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;

  background-color: hsl(0, 0%, 90%);
  color: #26344d;

  ${(props) =>
    props.type == "(" &&
    css`
      background-color: #3d6287;
      color: #fff;
    `}

  ${(props) =>
    props.type == ")" &&
    css`
      background-color: #3d6287;
      color: #fff;
    `}
    
    ${(props) =>
    props.type == "AND" &&
    css`
      background-color: #547b3b;
      color: #fff;
    `}

    ${(props) =>
    props.type == "OR" &&
    css`
      background-color: #c25e48;
      color: #fff;
    `}


    > span {
    border-radius: 2px;
    font-size: 85%;
    overflow: hidden;
    padding: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
  }

  > a {
    align-items: center;
    border-radius: 2px;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    color: ${(props) =>
      props.type == "OR" ||
      props.type == "AND" ||
      props.type == "(" ||
      props.type == ")"
        ? "#fff"
        : "#26344D"};

    &:hover {
      background-color: #ffbdad;
      color: #de350b;
    }
  }
`;

export const Menu = styled.div`
  float: left;
  position: relative;
  width: 100%;
  display: block;

  ul {
    display: block;
    position: absolute;
    max-height: 200px;
    width: 100%;
    background: #fff;
    overflow-y: scroll;
    border-bottom: solid 1px #cccccc;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 99;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      padding: 8px 15px;
      font-size: 14px;
      border: solid 1px #cccccc;
      border-top: none;
      border-bottom: none;
      cursor: pointer;
      color: #26344d;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        font-size: 12px;
        color: #c1c8d4;
      }

      &:hover {
        background: #f0f0f0;
      }
    }
  }
`;
