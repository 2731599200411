import React, { useState, cloneElement } from "react";
import { Container, TabsList, Content } from "./styles";

export default function Tabs({ defaultActiveTab = 0, children }) {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const handleTabClick = (tabIndex) => {
    if (tabIndex !== activeTab) {
      setActiveTab(tabIndex);
    }
  };

  const cloneTabElement = (tab, index = 0) => {
    if (tab && tab !== "") {
      return cloneElement(tab, {
        onClick: () => handleTabClick(index),
        tabIndex: index,
        isActive: index === activeTab,
        key: index,
      });
    }
  };

  const renderChildrenTabs = () => {
    if (!Array.isArray(children)) {
      return cloneTabElement(children);
    }

    return children.map(cloneTabElement);
  };

  const renderActiveTabContent = () => {
    if (children[activeTab]) {
      return children[activeTab].props.children;
    }
    return children.props.children;
  };

  return (
    <Container>
      <TabsList>{renderChildrenTabs()}</TabsList>
      <Content>{renderActiveTabContent()}</Content>
    </Container>
  );
}
