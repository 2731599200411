import React, { memo } from "react";

import { Row, Cell } from "./styles";

const TableRow = memo(
  ({ row, conditionalRowStyles, conditionalCellStyles }) => {
    const getStyles = (row, cell) => {
      let styles = {};
      conditionalRowStyles &&
        conditionalRowStyles.forEach((element) => {
          if (element.when(row)) styles = { ...element.styles };
        });

      conditionalCellStyles &&
        conditionalCellStyles.forEach((element) => {
          if (element.when(cell)) styles = { ...element.styles };
        });
      return styles;
    };

    return (
      <Row {...row.getRowProps()}>
        {row.cells.map((cell, i) => {
          if (cell.isRowSpanned) return null;
          else
            return (
              <Cell
                key={i}
                style={getStyles(row, cell)}
                rowSpan={cell.rowSpan}
                {...cell.getCellProps({ breakWord: cell.column.breakWord })}
              >
                {cell.render("Cell")}
              </Cell>
            );
        })}
      </Row>
    );
  }
);

export default TableRow;
