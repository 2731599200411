import React from "react";
import * as d3 from "d3";
import { event as currentEvent } from "d3";
import { cloneDeep, unionBy, uniqBy } from "lodash";
import DashboardHelper from "../../../../../helpers/dashboard-helper";
import { Small } from "../../../../typography";

class D3LinePlotLayer extends React.Component {
  constructor(props) {
    super(props);

    this.gWrapperRef = null;
    this.setGWrapperRef = (e) => {
      this.gWrapperRef = e;
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Ensures that the component doesn't get re-rendered without reason

    // Currently, updates every time the width of height changes
    // TODO: add check for data as well
    if (
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width ||
      this.props.data !== nextProps.data
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    if (this.props.data && this.props.height !== 0 && this.props.width !== 0) {
      this.renderChartD3Wrapper();
    }
  }

  componentDidUpdate() {
    d3.select(this.gWrapperRef).select("g").remove();
    if (this.props.data) {
      this.renderChartD3Wrapper();
    }
  }

  handleMouseOver(tipBox, xScale, data, tooltipLine, innerWidth, innerHeight) {
    /*
    Sets the style of the hovered bar and calls the parent function responsible for showing the
    tooltip. Once we're using the Redux store, this should probably be handled in the layout state.

    D3 mouse events are, by default, bound to the element where the action occurs (i.e, `this`
    refers to the svg element that triggered the event). To keep the React Class context for the
    `this` keyword, we bind the event handler to the `this` of the class, and to still have acess
    to the triggered mouse event, we use the D3 `currentEvent` (imported in the beginning of this
    file).
    */

    if (this.props.config.showTooltip) {
      const axesType = this.props.config.axesXType;
      const parseDataFormat = this.props.config.parseDataFormat
        ? this.props.config.parseDataFormat
        : "%m/%Y";
      const parseTime = d3.time.format(parseDataFormat).parse;

      const { flipAxes } = this.props.config;

      const mouseIndex = flipAxes ? 1 : 0;
      const value = xScale.invert(d3.mouse(tipBox.node())[mouseIndex]);
      let xValue = axesType === "time" ? parseTime(value) : value;

      const selectedData = [];
      data.map((d) => {
        const closest = d.values.reduce(function (prev, curr) {
          return Math.abs(curr.key - xValue) < Math.abs(prev.key - xValue)
            ? curr
            : prev;
        });

        xValue = closest.key;
        selectedData.push({ category: d.name, value: closest.value });
        // d.values.map(value=>{
        //     if (value.key === xValue){
        //       selectedData.push({'category':d.name, 'value':value.value})
        //     }
        // })
      });

      // Add a line to chart when it is hover and activate tooltip
      tooltipLine
        .attr("stroke", "gray")
        .attr("x1", flipAxes ? 0 : xScale(xValue))
        .attr("x2", flipAxes ? innerWidth : xScale(xValue))
        .attr("y1", flipAxes ? xScale(xValue) : 0)
        .attr("y2", flipAxes ? xScale(xValue) : innerHeight);

      let content = "";
      if (
        this.props.tooltipContent &&
        typeof this.props.tooltipContent === "function"
      ) {
        content = this.props.tooltipContent(
          xValue,
          selectedData,
          this.props.chart
        );
      } else {
        const xLabel = this.props.meta.axesTitle.x;

        const tooltipRow = [];

        tooltipRow.push(
          <tr key={`${xLabel}key`}>
            <td key={xLabel}>
              <Small>{`${xLabel}: `}</Small>
            </td>
            <td key={xLabel + xValue}>
              <Small>
                <b>{xValue}</b>
              </Small>
            </td>
          </tr>
        );
        selectedData.map((d) => {
          const yLabel = d.category ? d.category : this.props.meta.axesTitle.y;
          tooltipRow.push(
            <tr key={`${yLabel}value`}>
              <td key={yLabel}>
                <Small>{`${yLabel}: `}</Small>
              </td>
              <td key={yLabel + d.value}>
                <Small>
                  <b>
                    {Number.isInteger(d.value) ? d.value : d.value.toFixed(2)}
                  </b>
                </Small>
              </td>
            </tr>
          );
        });

        content = (
          <table>
            <tbody>{tooltipRow}</tbody>
          </table>
        );
      }

      this.props.mouseOverFunc(content);
    }
  }

  handleMouseOut(tooltipLine) {
    /*
    Re-sets the style of the bar and calls the parent function responsible for hiding the tooltip.
    Once we're using the Redux store, this should probably be handled in the layout state.
    */
    if (this.props.config.showTooltip) {
      d3.select(currentEvent.target).style("fill-opacity", "0.7");
      tooltipLine.attr("stroke", "none");
      this.props.mouseOutFunc();
    }
  }

  renderChartD3Wrapper() {
    const { width } = this.props;
    const { height } = this.props;

    const { hideVerticalAxis } = this.props.config;
    const { hideHorizontalAxis } = this.props.config;
    const axesType = this.props.config.axesXType;
    const { flipAxes } = this.props.config;
    const parseDataFormat = this.props.config.parseDataFormat
      ? this.props.config.parseDataFormat
      : "%m/%Y";

    const { hideLegends } = this.props.config;
    const legendPosition = this.props.config.legendPosition
      ? this.props.config.legendPosition
      : "left"; // Top or left for now
    const { chartObservation } = this.props.meta;

    const chartLayout = this.props.config.layout
      ? this.props.config.layout
      : "linear";
    const { colorPalette } = this.props.config;
    let data = cloneDeep(this.props.data);

    // The `margin` prop defines the "padding" inside the svg container. For some categorical
    // charts, we want to rotate the labels. This requires more space at the bottom
    const margin = cloneDeep(this.props.marginDefs);
    const axisMargin = cloneDeep(this.props.axisMarginDefs);
    const horizontalMargin = axisMargin.horizontal;
    const verticalMargin = axisMargin.vertical;
    const legendVerticalSpace =
      (!hideLegends && legendPosition == "left" && data.length >= 2) ||
      chartObservation
        ? width * margin.withLegend.vertical
        : 0;
    const legendHorizontalSpace =
      !hideLegends && legendPosition == "top" && data.length >= 2
        ? margin.withLegend.horizontal
        : 0;

    const innerWidth =
      width -
      margin.left -
      margin.right -
      horizontalMargin -
      legendVerticalSpace;
    const innerHeight =
      height -
      margin.top -
      margin.bottom -
      verticalMargin -
      legendHorizontalSpace;

    let yDomain;
    let xDomain;
    const { increaseYMaxValue } = this.props;
    const verticalRange = [innerHeight, 0];
    const horizontalRange = [0, innerWidth];

    yDomain = [
      d3.min(data, function (d) {
        return d3.min(d.values, function (b) {
          return b.value;
        });
      }),
      d3.max(data, function (d) {
        return d3.max(d.values, function (b) {
          return b.value;
        });
      }) * increaseYMaxValue,
    ];

    if (axesType === "continuous") {
      xDomain = [
        d3.min(data, function (d) {
          return d3.min(d.values, function (b) {
            return b.key;
          });
        }),
        d3.max(data, function (d) {
          return d3.max(d.values, function (b) {
            return b.key;
          });
        }),
      ];
    } else if (axesType === "ordinal") {
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      data.map((d) => {
        xDomain = unionBy(
          xDomain,
          d.values.map((b) => {
            return b.key;
          })
        );
      });
      xDomain = uniqBy(xDomain).sort(collator.compare);
    } else if (axesType === "time") {
      // parse the date / time
      const parseTime = d3.time.format(parseDataFormat).parse;

      data = data.map((d) => {
        d.values.map((item) => {
          item.key = parseTime(item.key);
        });
        return d;
      });
      xDomain = [
        d3.min(data, function (d) {
          return d3.min(d.values, function (b) {
            return b.key;
          });
        }),
        d3.max(data, function (d) {
          return d3.max(d.values, function (b) {
            return b.key;
          });
        }),
      ];
    } else {
      console.error("Invalid Axes Type");
    }

    // D3 selects the parent group tag using the React ref and attach nested groups to it.
    // When the component is updated, children of this node are removed and re-added.
    const parentG = d3.select(this.gWrapperRef);

    const g = parentG
      .append("g")
      .attr(
        "transform",
        `translate(${margin.left + horizontalMargin},${
          margin.top + legendHorizontalSpace
        })`
      );

    // Define scales. The xScale's domain is the list of "categories", and the yScale's domain
    // goes from 0 to the max value.

    const yScale = d3.scale
      .linear()
      .domain(yDomain)
      .range(flipAxes ? horizontalRange : verticalRange);

    let xScale;
    if (axesType === "continuous") {
      xScale = d3.scale
        .linear()
        .domain(xDomain)
        .range(flipAxes ? verticalRange : horizontalRange);
    } else if (axesType === "ordinal") {
      xScale = d3.scale
        .ordinal()
        .domain(xDomain)
        .rangeBands(flipAxes ? verticalRange : horizontalRange, 0);
      // custom invert function
      xScale.invert = (function () {
        const domain = xScale.domain();
        const range = xScale.range();
        const scale = d3.scale.quantize().domain(range).range(domain);

        return function (x) {
          return scale(x);
        };
      })();
    } else if (axesType === "time") {
      xScale = d3.time
        .scale()
        .domain(xDomain)
        .range(flipAxes ? verticalRange : horizontalRange);
    }

    const tooltipLine = g.append("line"); // line that will appear when the tooltip is activated

    // Plot lines
    data.map((lines, index) => {
      g.append("path")
        .datum(lines.values)
        .attr("fill", "none")
        .attr("stroke", function (d) {
          if (colorPalette) {
            return colorPalette[index];
          }
          return "#1F61A2";
        })
        .attr("stroke-width", 2)
        .attr(
          "d",
          d3.svg
            .line()
            .interpolate(chartLayout)
            .x((d) => {
              if (flipAxes) {
                return yScale(d.value);
              }
              return xScale(d.key);
            })
            .y((d) => {
              if (flipAxes) {
                return xScale(d.key);
              }
              return yScale(d.value);
            })
        );
    });
    const tipBox = g
      .append("rect")
      .attr("width", innerWidth)
      .attr("height", innerHeight)
      .attr("opacity", 0);

    tipBox
      .on(
        "mousemove",
        this.handleMouseOver.bind(
          this,
          tipBox,
          xScale,
          data,
          tooltipLine,
          innerWidth,
          innerHeight
        )
      )
      .on("mouseout", this.handleMouseOut.bind(this, tooltipLine));
  }

  render() {
    return <g ref={this.setGWrapperRef} />;
  }
}

export default D3LinePlotLayer;
