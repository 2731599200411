import { fabric } from "fabric";
import React, { useRef, useEffect } from "react";

const CANVAS_WIDTH = 460;
const SPACER = 4;

const TEXTBOX_FILL = "black";
const TEXTBOX_FONT_SIZE = 14;
const TEXTBOX_FONT_FAMILY = "sans-serif";
const TEXTBOX_TEXT_ALIGN = "center";

const CONTAINER_RECT_WIDTH = 216;
const CONTAINER_RECT_MIN_HEIGHT = 80;
const CONTAINER_RECT_BORDER_RADIUS = 8;
const CONTAINER_RECT_BASE_FILL = "#8abbdd";
const CONTAINER_RECT_EXCLUSION_FILL = "#92dbf4";

export function renderCanvas(steps, element = null) {
  let left, top, previousHeight;
  const boxes = [];

  // create boxes elements based on steps
  for (const { description, number, exclusion } of steps) {
    // update left corner of box
    if (!left) {
      // start position
      left = SPACER;
    } else {
      // left position is different for exclusion boxes
      left = exclusion ? CANVAS_WIDTH - CONTAINER_RECT_WIDTH - SPACER : SPACER;
    }

    const descriptionText = `${exclusion ? "Excluded" : description}:`;
    const descriptionTextbox = new fabric.Textbox(descriptionText, {
      fill: TEXTBOX_FILL,
      fontSize: TEXTBOX_FONT_SIZE,
      fontFamily: TEXTBOX_FONT_FAMILY,
      width: CONTAINER_RECT_WIDTH - SPACER * 2,
      textAlign: TEXTBOX_TEXT_ALIGN,
      left: left + SPACER,
      lineHeight: 1,
    });

    const numberTextbox = new fabric.Textbox(`N=${number}`, {
      fill: TEXTBOX_FILL,
      fontSize: TEXTBOX_FONT_SIZE,
      fontWeight: "bold",
      fontFamily: TEXTBOX_FONT_FAMILY,
      width: CONTAINER_RECT_WIDTH - SPACER * 2,
      textAlign: TEXTBOX_TEXT_ALIGN,
      left: left + SPACER,
    });

    // calculate container height based on text height
    const textHeight =
      descriptionTextbox.height + SPACER + numberTextbox.height;
    const containerRectHeight =
      textHeight > CONTAINER_RECT_MIN_HEIGHT
        ? textHeight + 4 * SPACER
        : CONTAINER_RECT_MIN_HEIGHT;

    // update top corner of box
    if (!top) {
      // start position
      top = SPACER;
    } else {
      top = top + previousHeight / 2 + 2 * SPACER;
    }

    // save current container height as previous for the next iteration
    previousHeight = containerRectHeight;

    // adjust text boxes vertical positions based on their content
    descriptionTextbox.top = top + (containerRectHeight - textHeight) / 2;
    numberTextbox.top =
      descriptionTextbox.top + descriptionTextbox.height + SPACER;

    const containerRectFill = exclusion
      ? CONTAINER_RECT_EXCLUSION_FILL
      : CONTAINER_RECT_BASE_FILL;
    const containerRect = new fabric.Rect({
      width: CONTAINER_RECT_WIDTH,
      height: containerRectHeight,
      fill: containerRectFill,
      stroke: "#000000",
      rx: CONTAINER_RECT_BORDER_RADIUS,
      ry: CONTAINER_RECT_BORDER_RADIUS,
      left,
      top,
    });

    boxes.push({
      descriptionTextbox,
      numberTextbox,
      containerRect,
    });
  }

  // calculate canvas size after all boxes have been created
  const canvas = new fabric.StaticCanvas(element, {
    width: CANVAS_WIDTH,
    height: top + previousHeight + SPACER,
    backgroundColor: "#ffffff",
  });

  // add boxes to canvas
  for (const { descriptionTextbox, numberTextbox, containerRect } of boxes) {
    canvas.add(containerRect, descriptionTextbox, numberTextbox);
  }

  // draw lines connecting boxes
  const x1 = SPACER + CONTAINER_RECT_WIDTH / 2;
  const x2 = CANVAS_WIDTH - CONTAINER_RECT_WIDTH - SPACER;
  for (let i = 0; i < boxes.length - 2; i += 2) {
    const y1 = boxes[i].containerRect.top + boxes[i].containerRect.height;
    const y2 = y1 + SPACER * 2;
    const y3 = y2 + SPACER * 2;

    const verticalLine = new fabric.Line([x1, y1, x1, y3], {
      stroke: "#000000",
    });
    const horizontalLine = new fabric.Line([x1, y2, x2, y2], {
      stroke: "#000000",
    });

    canvas.add(verticalLine, horizontalLine);
  }

  canvas.renderAll();

  return canvas;
}

export default function ConsortDiagram({ steps }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const canvas = renderCanvas(steps, canvasRef.current);

    return () => {
      canvas.dispose();
    };
  }, []);

  return <canvas ref={canvasRef}></canvas>;
}
