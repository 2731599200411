const COHORT_HEADERS = ["Main Cohort", "Sub Cohort"];

const isUnique = (val, idx, arr) => arr.indexOf(val) === idx;

export function convertTableToCsvString(columns, rows) {
  if (!columns || !rows) {
    // eslint-disable-next-line
    console.error(
      "convertTableToCsvString: columns or rows parameters missing"
    );

    return "";
  }

  function convertRowsToCsvString(rows) {
    const strings = [];

    for (const row of rows) {
      strings.push(row.map((cell) => `"${cell}"`).join(","));
    }

    return strings.join("\n") + "\n";
  }

  function getRows(columns, rows) {
    const accessors = columns.map(({ accessor }) => accessor).filter(isUnique);

    const csvRows = [];

    // add header rows
    csvRows.push([
      ...accessors.map((accessor) => {
        const column = columns.find((column) => column.accessor === accessor);

        let label = column.Header;

        return label;
      }),
    ]);

    // add body rows
    for (const row of rows) {
      const newRow = [];

      for (const accessor of accessors) {
        const vals = [];

        // split accessor using space separator
        for (const key of accessor.split(" ")) {
          let newVal = row[key];

          // if column is numeric, either round to one decimal or output -
          // newVal =
          //   newVal === 0 || isNaN(newVal) ? "-" : Number(newVal).toFixed(1);

          if (!newVal) {
            newVal = "-";
          }

          vals.push(newVal);
        }

        newRow.push(vals.join(" "));
      }

      csvRows.push(newRow);
    }

    return csvRows;
  }

  const csvRows = [...getRows(columns, rows)];

  const csvString = convertRowsToCsvString(csvRows);

  return csvString;
}

export function convertGraphToCsvString(names, graph) {
  if (!names || !graph) {
    // eslint-disable-next-line
    console.error("convertGraphToCsvString: names or graph parameters missing");

    return "";
  }

  const rows = [];

  const outcomeHeaderRow = [];
  outcomeHeaderRow.push("Outcome");
  outcomeHeaderRow.push(graph.name);
  rows.push(outcomeHeaderRow);

  const cohortHeaderRow = [];
  for (let cohortIndex = 0; cohortIndex < graph.data.length; cohortIndex++) {
    cohortHeaderRow.push("Cohort");
    cohortHeaderRow.push(COHORT_HEADERS[cohortIndex]);
    cohortHeaderRow.push(names[cohortIndex]);
    cohortHeaderRow.push("");

    if (cohortIndex != graph.data.length - 1) {
      cohortHeaderRow.push("");
    }
  }
  rows.push(cohortHeaderRow);

  const axesHeaderRow = [];
  for (let cohortIndex = 0; cohortIndex < graph.data.length; cohortIndex++) {
    axesHeaderRow.push(graph.meta.axesTitle.x);
    axesHeaderRow.push(graph.meta.axesTitle.y);
    axesHeaderRow.push("Confidence Interval Lower Value");
    axesHeaderRow.push("Confidence Interval Upper Value");

    if (cohortIndex != graph.data.length - 1) {
      axesHeaderRow.push("");
    }
  }
  rows.push(axesHeaderRow);

  const maxKeyIndex = Math.max.apply(
    null,
    graph.data.map((data) => data.values.length)
  );
  for (let keyIndex = 0; keyIndex < maxKeyIndex; keyIndex++) {
    const dataRow = [];
    for (let cohortIndex = 0; cohortIndex < graph.data.length; cohortIndex++) {
      dataRow.push(graph.data[cohortIndex].values[keyIndex]?.key ?? "");
      dataRow.push(graph.data[cohortIndex].values[keyIndex]?.value ?? "");
      dataRow.push(
        graph.data[cohortIndex].confidence_intervals[keyIndex]?.lower_val ?? ""
      );
      dataRow.push(
        graph.data[cohortIndex].confidence_intervals[keyIndex]?.upper_val ?? ""
      );

      if (cohortIndex != graph.data.length - 1) {
        dataRow.push("");
      }
    }
    rows.push(dataRow);
  }

  const csvString =
    rows.map((row) => row.map((cell) => `"${cell}"`).join(",")).join("\n") +
    "\n";

  return csvString;
}
