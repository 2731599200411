import React, { useMemo } from "react";
import { find } from "lodash";

import { Container, AttributesLabels } from "./styles";

import { useMedicationIntents } from "../../../hooks/useMedicationIntents";

function MedicationMechanismOverview({ medicationMechanism }) {
  const { medicationIntents } = useMedicationIntents();

  const parent = useMemo(() => {
    const medicationIntent = find(medicationIntents, [
      "id",
      medicationMechanism.parent,
    ]);
    return medicationIntent || {};
  }, [medicationIntents]);

  return (
    <Container>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <AttributesLabels>
            <label>Name</label>
            <span>{medicationMechanism.human_readable_name}</span>
          </AttributesLabels>
        </div>
        <div className="col-xs-12 col-sm-6">
          <AttributesLabels>
            <label>Variable</label>
            <span>{medicationMechanism.name}</span>
          </AttributesLabels>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <AttributesLabels>
            <label>Medication Intent</label>
            <span>{parent.human_readable_name}</span>
          </AttributesLabels>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <AttributesLabels>
            <label>Codes</label>
          </AttributesLabels>
        </div>
      </div>
    </Container>
  );
}

export default MedicationMechanismOverview;
