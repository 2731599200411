import styled from "styled-components";

export const EmptyItem = styled.div`
  border-top: 1px solid #e6e6e6;
  padding: 16px 20px;
  color: #70757a;
`;

export const SubcohortItem = styled.div`
  border-bottom: 1px solid #e6e6e6;
  padding: 0.5rem 5px;
  & + & {
    border-top: 0;
  }
`;

export const TableContainer = styled.div`
  border: 1px solid #e6e6e6;
  border-bottom: 0;
`;

export const ContentPadding = styled.div`
  padding: 0 9px;
`;

export const Content = styled.div`
  padding: 0 9px;
  overflow: auto;
  flex: 1 1 auto;
`;

export const Footer = styled.div`
  padding: 9px;
  border-top: 1px solid #e6e6e6;

  flex: none;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  button {
    margin-left: 0;
  }
`;
