import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import { Container, Textarea } from "./styles";

export default function FormTextarea({
  name,
  label,
  inline = false,
  setDefaultValue = "",
  required = false,
  style,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue = setDefaultValue,
    registerField,
    error,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <Container inline={inline} required={required} style={style}>
      {label && <label htmlFor={fieldName}>{label}</label>}

      <Textarea
        ref={inputRef}
        id={fieldName}
        invalid={!!error}
        inline={inline}
        marginBottom={0}
        {...rest}
      />

      {error && <span>{error}</span>}
    </Container>
  );
}
