import React, { useRef, useMemo, useEffect, useState } from "react";
import d3 from "d3";
import { GeoJSON, LayersControl, FeatureGroup, useMap } from "react-leaflet";
import DashboardHelper from "../../../../helpers/dashboard-helper";
import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../services/mixpanel-tracking";

const { BaseLayer } = LayersControl;

function MyLayersControl({
  mapTitle,
  layer,
  checked,
  data = {},
  colorPalette = ["#BCD8FA", "#5F8BAF", "#003D67"],
  getTooltipContent,
  onFeatureClick,
}) {
  const map = useMap();
  const refFeatureGroup = useRef();
  const [updateCount, setUpdateCount] = useState(0);

  const baseLayerHandle = {
    click(event) {
      map.fitBounds(event.target.getBounds());
      const attribute =
        "STATE" in event.target.feature.properties ? "ptnt_zip3_cd" : "state"; // make this not hardcoded
      if (onFeatureClick)
        onFeatureClick(
          event.target,
          event.target.feature.properties,
          attribute
        );
    },
    mouseover(event) {
      const { properties } = event.target.feature;
      const featureData = data[properties.postal];

      // don't show popup when hovering over a postal code that doesn't have
      // data available
      if (!featureData) {
        return;
      }

      mixpanelIdentify(localStorage.email);
      mixpanelTrack(`${mapTitle} - Map Hover`, {
        layerName: layer.name,
        propertyName: properties.name,
        propertyPostal: properties.postal,
        populationSize: featureData.pop_size,
        urlPathname: location.pathname,
      });

      if (getTooltipContent) {
        event.target
          .bindTooltip(getTooltipContent(properties, featureData), {
            sticky: true,
            direction: "top",
          })
          .openTooltip();
      }
    },
  };

  const onEachFeature = (feature, layer) => {
    layer.on(baseLayerHandle);
  };

  const choroplethMinAvgMax = useMemo(() => {
    if (data) {
      const ratioList = Object.values(data).map((d) => {
        return d.ratio;
      });
      const max = Math.max(...ratioList.filter((n) => n != -1));
      const min = Math.min(...ratioList.filter((n) => n != -1));
      const avg = average(ratioList.filter((n) => n != -1));
      return { min, avg, max };
    }
    return { min: 0, avg: 0, max: 1 };
  }, [data]);

  useEffect(() => {
    setUpdateCount(updateCount + 1);
  }, [choroplethMinAvgMax]);

  const colorScale = useMemo(() => {
    return d3.scale
      .linear()
      .range(colorPalette)
      .domain([
        choroplethMinAvgMax.min,
        choroplethMinAvgMax.avg,
        choroplethMinAvgMax.max,
      ]);
  }, [colorPalette, choroplethMinAvgMax]);

  const styleFeature = (feature) => {
    let ratioValue;

    if (feature && feature.properties.postal in data) {
      ratioValue = data[feature.properties.postal].ratio;
    }

    return {
      fillColor: ratioValue ? colorScale(ratioValue) : "#ccc",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8,
      zIndex: 0,
      color: "#FFFFFF",
      smoothFactor: 0.5, // smoothFactor sets the smoothness of the polygons. The lower the value, the more precise the shape
    };
  };

  return (
    <BaseLayer
      key={layer.name}
      checked={checked}
      name={DashboardHelper.printAttributeHumanReadable(layer.name)}
    >
      <FeatureGroup ref={refFeatureGroup}>
        <GeoJSON
          key={layer.name + updateCount}
          data={layer.geojson}
          zIndex={0}
          style={styleFeature}
          onEachFeature={onEachFeature}
        />
      </FeatureGroup>
    </BaseLayer>
  );
}

export default MyLayersControl;

const average = (arr) => {
  let avg = 0;
  if (arr.length > 0) {
    const sum = arr.reduce((previous, current) => (current += previous));
    avg = sum / arr.length;
  }
  return avg;
};
