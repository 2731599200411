import React, { useMemo, forwardRef } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

import { Container, Select } from "./styles";

const BasicSelect = forwardRef(
  (
    {
      onChange,
      className,
      invalid,
      defaultValue,
      options = [],
      borderless = false,
      marginBottom = 10,
      marginTop = 0,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    const optionsField = useMemo(() => {
      const optionsField = [];
      options.map((o, i) => {
        optionsField.push(
          <option key={i} value={o.value}>
            {o.label}
          </option>
        );
      });
      return optionsField;
    }, [options]);

    function handleChange(event) {
      if (onChange) onChange(event.target.value);
    }

    return (
      <Container
        disabled={disabled}
        marginBottom={marginBottom}
        marginTop={marginTop}
      >
        <Select
          ref={ref}
          invalid={invalid}
          defaultValue={defaultValue}
          className={className}
          onChange={handleChange}
          disabled={disabled}
          borderless={borderless}
          {...rest}
        >
          {defaultValue == "" ? (
            <option key="default" value="" defaultValue />
          ) : (
            ""
          )}
          {optionsField}
        </Select>
        <MdKeyboardArrowDown size={20} />
      </Container>
    );
  }
);
export default BasicSelect;
