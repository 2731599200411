import styled from "styled-components";

export const Container = styled.div`
  padding: 5px 0 0 0;
  cursor: pointer;

  > small {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 5px;
  }
`;

export const Filters = styled.div`
  border: 1px solid #e6e6e6;
  border-bottom: 0;
`;
