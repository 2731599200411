/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from "react";
import { Container, LeftIcon, RightIcon } from "./styles";

function Button({
  size,
  variant,
  children = "Send",
  rightIcon,
  leftIcon,
  ...rest
}) {
  const [sizeState] = useState(size);
  const [variantState] = useState(variant);

  return (
    <Container size={sizeState} variant={variantState} {...rest}>
      {leftIcon ? <LeftIcon>{leftIcon}</LeftIcon> : ""}
      {children}
      {rightIcon ? <RightIcon>{rightIcon}</RightIcon> : ""}
    </Container>
  );
}
export default Button;
