import styled from "styled-components";

import { Link } from "react-router-dom";

export const Container = styled.div`
  max-width: 850px;
  margin: 0 auto;
  border: 1px solid #dedfe2;
  padding: 1rem;
  background: #fff;
  margin-bottom: 1rem;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  button {
    margin-left: 0;
    margin-bottom: 0;
  }
`;

export const BackLink = styled(Link)`
  display: block;
  margin-bottom: 20px;
  text-decoration: underline;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  margin-bottom: 25px;

  > span {
    font-weight: 300;
    display: block;
    color: #666666;
  }
`;
