import styled, { css } from "styled-components";

export const Row = styled.tr`
  td {
    button,
    a {
      opacity: 0;
      margin: 0;
    }
  }

  &:hover {
    background-color: #f3f3f3;
    td {
      color: #26344c !important;

      button,
      a {
        opacity: 1;
      }
    }
  }
`;

export const Cell = styled.td`
  /* All Cells */
  padding: 8px 8px 8px 12px;
  line-height: 1.42857143;
  border-bottom: 1px solid #cccccd;
  position: relative;

  color: #666666;
  vertical-align: middle;

  ${(props) =>
    props.breakWord &&
    css`
      word-break: break-word;
    `}
`;
