import React from "react";
import {
  BsThreeDotsVertical,
  BsFillPeopleFill,
  BsPersonFill,
} from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import {
  Container,
  SubcohortsWrapper,
  Card,
  CardTitle,
  CardContent,
  CenterRow,
  SpaceBetweenRow,
} from "./styles";

import AppliedFilters from "./applied-filters";

import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";
import { useIdentifySubcohortList } from "../../../../hooks/useIdentifySubcohortList";
import { useIdentifySimulation } from "../../../../hooks/useIdentifySimulation";

import { Heading4, Small } from "../../../../components/typography";
import { useModal } from "../../../../components/modal";
import InfoTooltip from "../../../../components/tooltip-info-icon";
import Tooltip from "../../../../components/tooltip";
import SimulationEditModal from "../simulation-settings-modal";

function DashboardFilters() {
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { cohortIndex } = cohortConfigs;
  const { subcohortList } = useIdentifySubcohortList();
  const { simulation } = useIdentifySimulation();
  const { toggleModal, isShowingModal } = useModal();

  const simulationCard = (
    <CardContent color="#0CA6C8">
      <SpaceBetweenRow>
        <Heading4>Simulation</Heading4>
        <Tooltip description="Edit simulation">
          <MdEdit
            color="#6B7C99"
            size={16}
            style={{ cursor: "pointer" }}
            onClick={toggleModal}
          />
        </Tooltip>
      </SpaceBetweenRow>
      <Small marginTop={5}>
        {simulation.simulationAdoptionRate}% adoption rate
      </Small>
    </CardContent>
  );

  return (
    <Container>
      <SubcohortsWrapper>
        <Card id="cohortSelection">
          <CardTitle>
            <CenterRow>
              <BsFillPeopleFill size={18} />
              <InfoTooltip
                description={
                  "A cohort is a primary predefined patient population."
                }
              >
                Cohort
              </InfoTooltip>
            </CenterRow>
            <BsThreeDotsVertical size={16} color="495F83" />
          </CardTitle>

          <CardContent color="#6450C3">
            <SpaceBetweenRow>
              <Heading4>{cohortIndex.index_human_readable_name}</Heading4>
              {subcohortList.cohort?.subcohortSize ? (
                <Small color="#666666">
                  N=
                  {subcohortList.cohort.subcohortSize?.toLocaleString("en-US")}
                </Small>
              ) : (
                ""
              )}
            </SpaceBetweenRow>
          </CardContent>

          {simulation.selectedCohort.id === "cohort" ? simulationCard : ""}
        </Card>
        {simulation.selectedCohort.id !== "cohort" ? (
          <Card id="subcohortHeader">
            <CardTitle>
              <CenterRow>
                <BsPersonFill size={18} />
                <InfoTooltip
                  description={
                    "A subcohort is a subset of patients within the cohort."
                  }
                >
                  Subcohort
                </InfoTooltip>
              </CenterRow>
            </CardTitle>
            <CardContent color={simulation.selectedCohort.color}>
              <Heading4 marginBottom={5}>
                {simulation.selectedCohort.name}
              </Heading4>
              <AppliedFilters subcohort={simulation.selectedCohort} />
            </CardContent>
            {simulationCard}
          </Card>
        ) : (
          ""
        )}
      </SubcohortsWrapper>
      <SimulationEditModal
        toggleModal={toggleModal}
        isShowingModal={isShowingModal}
      />
    </Container>
  );
}

export default DashboardFilters;
