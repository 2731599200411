import request from "superagent";
import config from "../config.jsx";

const apiUrl = config.api_address;

/*
 * The data service flow:
 * 1. Fire action with NAME and PARAMS.
 * 2. an HTTP request is made.
 *    If SUCCESS: a [NAME_OF_ACTION]_RECEIVED action is dispatched
 *    If API or TIMEOUT ERROR: a [NAME_OF_ACTION]_RECEIVED action is dispatched
 *
 * Conventions:
 * 1. Always use the related HTTP VERB before the action. (eg: GET_PATIENT)
 *    It makes simple to differentiate regular actions from API request actions
 * 2. QUERY PARAMS needs to be passed through action.payload
 *    It is a mandatory field for all actions but GET.
 * 3. URL PARAMS if not already available on action.payload, should be passed outside action.payload
 *
 */

const LOGIN_FAILED_MESSAGE =
  "Login failed. You have either entered an incorrect email or password.";

async function postLogin(dispatch, action) {
  const formData = new FormData();
  formData.append("username", action.payload.email);
  formData.append("password", action.payload.password);

  try {
    const loginResponse = await fetch(`${apiUrl}auth/login`, {
      method: "POST",
      body: formData,
    });

    if (!loginResponse.ok) {
      const { detail } = await loginResponse.json();
      throw new Error(detail || LOGIN_FAILED_MESSAGE);
    }

    const { access_token } = await loginResponse.json();
    const userResponse = await fetch(`${apiUrl}users/me`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    if (!userResponse.ok) {
      throw new Error(LOGIN_FAILED_MESSAGE);
    }

    const userData = await userResponse.json();

    const data = {
      ...{
        access_token,
      },
      ...userData,
    };
    dispatchRequestSuccess(dispatch, action, data);
  } catch (error) {
    dispatchRequestError(dispatch, action, [error.message]);
  }
}

const dataService = (store) => (dispatch) => (action) => {
  /*
  Pass all actions through by default
  */
  dispatch(action);
  const getApi = getApiGenerator(dispatch);
  const postApi = postApiGenerator(dispatch);
  const putApi = putApiGenerator(dispatch);
  const deleteApi = deleteApiGenerator(dispatch);
  let url;

  switch (action.type) {
    case "USER_LOGIN":
      postLogin(dispatch, action);
      break;

    case "USER_LOGOUT":
      deleteApi("user/auth", "USER_LOGOUT", action);
      break;

    case "GET_ALL_USERS":
      url = "users/";
      getApi(url, "GET_ALL_USERS", action);
      break;

    case "CREATE_USER":
      url = "users/";
      postApi(url, "CREATE_USER", action);
      break;

    case "EDIT_USER":
      url = `users/${action.userId}/`;
      putApi(url, "EDIT_USER", action);
      break;

    case "DELETE_USER":
      url = `users/${action.userId}/`;
      deleteApi(url, "DELETE_USER", action);
      break;

    /*
    Do nothing if the action does not interest us
    */
    default:
      break;
  }
};

const deleteApiGenerator = (dispatch) => (route, name, action) => {
  route = apiUrl + route;
  request
    .del(route)
    .withCredentials()
    .auth(localStorage.accessToken, { type: "bearer" })
    .end((err, res) => {
      processRequest(err, res, dispatch, name, action);
    });
};

const getApiGenerator = (dispatch) => (route, name, action) => {
  route = apiUrl + route;
  request
    .get(route)
    .withCredentials()
    .auth(localStorage.accessToken, { type: "bearer" })
    .end((err, res) => {
      processRequest(err, res, dispatch, name, action);
    });
};

// Action.payload is a mandatory field
const postApiGenerator = (dispatch) => (route, name, action) => {
  route = apiUrl + route;
  request
    .post(route)
    .send(action.payload)
    .withCredentials()
    .auth(localStorage.accessToken, { type: "bearer" })
    .end((err, res) => {
      processRequest(err, res, dispatch, name, action);
    });
};

const putApiGenerator = (dispatch) => (route, name, action) => {
  route = apiUrl + route;
  request
    .put(route)
    .send(action.payload)
    .withCredentials()
    .auth(localStorage.accessToken, { type: "bearer" })
    .end((err, res) => {
      processRequest(err, res, dispatch, name, action);
    });
};

const processRequest = (err, res, dispatch, name, action) => {
  // dispatch error handler
  if (err) {
    dispatchRequestError(dispatch, action, err);
  }
  // dispatch success handler if VALID==TRUE
  const data = JSON.parse(res.text);

  if (data.valid) {
    dispatchRequestSuccess(dispatch, action, data);
  } else {
    dispatchRequestError(dispatch, action, data.messages);
  }
};

// Success Handler
const dispatchRequestSuccess = (dispatch, action, data) => {
  dispatch({
    type: `${action.type}_RECEIVED`,
    data,
    action,
  });
  if (action.successHandler) {
    return action.successHandler();
  }
};

// Error Handler
const dispatchRequestError = (dispatch, action, errorMessages) => {
  dispatch({
    type: `${action.type}_ERROR`,
    errorMessages,
    action,
  });
  if (action.errorHandler) {
    return action.errorHandler(errorMessages);
  }
};

export default dataService;
