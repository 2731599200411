import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import GuidelineHelper from "../../../helpers/guideline-helper.js";
import Table from "../../../components/tables/filtered-table";
import Button from "../../../components/button";

import { useGuidelineRule } from "../../../hooks/useGuidelineRules.js";

function GuidelineDocumentsTable(props) {
  const { documents } = props;
  const { guidelines } = useGuidelineRule();

  const columns = [
    {
      Header: "#",
      accessor: "id",
      disableGlobalFilter: true,
    },
    {
      accessor: "id",
      id: "view-btn",
      disableSortBy: true,
      disableGlobalFilter: true,
      Cell: ({ cell: { value } }) => (
        <Button variant="outline">
          <Link to={`/content-manager/guideline-documents/${value}`}>View</Link>
        </Button>
      ),
    },
    {
      Header: "Document Title",
      accessor: "title",
      breakWord: true,
    },
    {
      Header: "Guideline For",
      accessor: "module",
      disableGlobalFilter: true,
      filterType: "SelectCheckbox",
      id: "Guideline For",
    },
    {
      Header: "Source",
      accessor: "source",
      disableGlobalFilter: true,
      breakWord: true,
      filterType: "SelectCheckbox",
    },
    {
      Header: "# of Rules",
      accessor: (row) =>
        GuidelineHelper.numberOfRulesWithDocument(row.id, guidelines, false),
      id: "#Rules",
    },
    {
      Header: "DOI Number",
      accessor: "doi_number",
      disableGlobalFilter: true,
      breakWord: true,
    },
    {
      Header: "Date",
      accessor: "publication_date",
      disableGlobalFilter: true,
      disableSortBy: true,
    },
    {
      Header: "Link",
      accessor: "download_url",
      disableGlobalFilter: true,
      Cell: ({ cell: { value } }) => {
        return value ? (
          <a target="_blank" href={value} rel="noreferrer">
            Link
          </a>
        ) : (
          "-"
        );
      },
    },
  ];

  return <Table columns={columns} data={documents} urlState />;
}

export default GuidelineDocumentsTable;
