import React, { useContext, useEffect, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import {
  IdentifyCohortConfigsProvider,
  useIdentifyCohortConfigs,
} from "../../hooks/useIdentifyCohortConfig";
import { IdentifySubcohortListProvider } from "../../hooks/useIdentifySubcohortList";
import { MapsTopographyProvider } from "../../hooks/useMapTopography";
import {
  IdentifySimulationProvider,
  useIdentifySimulation,
} from "../../hooks/useIdentifySimulation";

import { TabsContext } from "../../components/app-tab-nav/context";
import AppTips from "../../components/app-tips";
import TrialAlert from "../../components/app-trial-alert";

// UI Components

// Module components
import SummaryPage from "./pages/summary";
import SiteSelection from "./pages/site-selection";
import SimulationPage from "./pages/simulation";

//Page Not Found
import PageNotFound from "../NotFound";

const tipsSteps = [
  {
    target: "body",
    title: "Welcome to CLINT ",
    content: (
      <div>
        The Clint Identify dashboard allows you to view, filter, and customize
        real-word data cohorts in real-time.
        <b style={{ display: "block", marginTop: 25 }}>
          Please continue for an overview of your CLINT dashboard capabilities.
        </b>
      </div>
    ),
    placement: "center",
  },
  {
    target: "#cohortSelection",
    title: "Explore your cohorts",
    content:
      "Here, you can select your pre-built cohorts from the drop-down menu.",
    placement: "auto",
    placementBeacon: "right",
  },
  {
    target: "#subcohortHeader",
    title: "Explore your subcohort",
    content:
      "Here, you can create your subcohorts. Apply your desired inclusion/exclusion criteria and watch as your subcohort is instantly compared to your main cohort.",
    placement: "right-start",
    placementBeacon: "right",
  },
  {
    target: "#subcohortFilter",
    title: "Explore your subcohort",
    content:
      "To apply filters to your subcohort, use the search box to select your desired criteria on the side bar. When you have finished selecting, click the “Apply” button to save your changes to the subcohort.",
    placement: "right-start",
    placementBeacon: "right",
  },
  // {
  //   target: "#applySubcohort",
  //   title: "Explore your Subcohort",
  //   content: "Here, you can select and explore your predetermined Subcohort.",
  //   placement: "auto",
  //   placementBeacon: "right",
  // },
  {
    target: "#tableOne",
    title: "Table 1",
    content:
      "Understand your cohort and subcohort across a wide range of demographic and clinical variables. Scroll or use the search bar to find statistics for your variables of interest.",
    placement: "left",
    placementBeacon: "top",
  },
  {
    target: "#populationMap",
    title: "Population map",
    content:
      "Explore the distribution of where your selected cohort receives care. Use the controls on the left to toggle between the ZIP3 code regions (locations grouped by the first 3 digits of the zipcode) and the state-level view.",
    placement: "right",
    placementBeacon: "top",
  },
  {
    target: "#eventRateCurve",
    title: "Event rate curve",
    content:
      "This graph shows the percentage of the cohort who have had events over time (and for disease diagnoses, this graph shows the disease incidence, or the percentage of the cohort who have been newly diagnosed with the disease), along with 95% confidence intervals.",
    placement: "right",
    placementBeacon: "top",
  },
];

function IdentifyDashboard() {
  const { setMainTabs } = useContext(TabsContext);
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { cohortIndex, sitesConfig, simulationsConfig } = cohortConfigs;
  const { simulation } = useIdentifySimulation();

  useEffect(() => {
    if (sitesConfig) {
      setMainTabs([
        {
          name: "Verify",
          url: "/clint-identify/verify",
          displayTab: true,
        },
        {
          name: "Select",
          url: "/clint-identify/select",
          displayTab: true,
        },
      ]);
    } else {
      setMainTabs([]);
    }
  }, [sitesConfig]);

  return (
    <IdentifySubcohortListProvider cohortIndex={cohortIndex}>
      <TrialAlert showTrialAlert={cohortIndex?.is_demo_cohort} />
      <Switch>
        <Route
          exact
          path="/clint-identify"
          render={() => <Redirect to="/clint-identify/verify" />}
        />

        <Route
          path="/clint-identify/verify"
          render={(props) => <SummaryPage {...props} />}
        />

        {sitesConfig ? (
          <Route
            exact
            path="/clint-identify/select"
            render={(props) => <SiteSelection {...props} />}
          />
        ) : (
          <Route
            exact
            path="/clint-identify/select"
            render={() => <Redirect to="/clint-identify/verify" />}
          />
        )}

        {simulationsConfig ? (
          <Route
            exact
            path="/clint-identify/simulation"
            render={(props) => {
              if ("id" in simulation.selectedCohort) {
                return <SimulationPage {...props} />;
              } else {
                return <Redirect to="/clint-identify/verify" />;
              }
            }}
          />
        ) : (
          ""
        )}

        <Route render={(props) => <PageNotFound containerFrame={false} />} />
      </Switch>
      <AppTips steps={tipsSteps} run showProgress continuous />
    </IdentifySubcohortListProvider>
  );
}

export default function IdentifyDashboardWrapper() {
  return (
    <IdentifyCohortConfigsProvider>
      <MapsTopographyProvider>
        <IdentifySimulationProvider>
          <IdentifyDashboard />
        </IdentifySimulationProvider>
      </MapsTopographyProvider>
    </IdentifyCohortConfigsProvider>
  );
}
