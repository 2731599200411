import React from "react";
import { Link } from "react-router-dom";
import { Container, Title, PillLabel } from "./styles";
import Button from "../../components/button";

import ContentContainer from "../../components/app-content-container";

const PageNotFound = function () {
  const notFoundText = () => {
    return (
      <Container>
        <PillLabel>404 Error</PillLabel>
        <br />
        <Title>The page you are looking for can't be found</Title>
        <br />
        <p>
          Please, check if you entered the correct URL or click the button below
          to go back to your mainpage
        </p>
        <Link to="/">
          <Button variant="confirm">Back to Mainpage</Button>
        </Link>
      </Container>
    );
  };

  return <ContentContainer>{notFoundText()}</ContentContainer>;
};

export default PageNotFound;
