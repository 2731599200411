import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 21px;
    line-height: 33px;
  }

  a {
    text-decoration: none;
  }
`;

export const Title = styled.h1`
  font-size: 33px;
  line-height: 24px;
`;

export const PillLabel = styled.div`
  font-size: 14px;
  line-height: 24px;
  background: #e6ebf2;
  border-radius: 13px;
  padding: 3px 20px;
  text-transform: uppercase;
  color: #4d5f80;
  font-weight: bold;
`;
