import styled from "styled-components";
import { POSITIONS } from ".";

export const Container = styled.div`
  position: relative;
`;

export const OuterMenuContainer = styled.div`
  z-index: 999;
  position: absolute;
  display: block;
  padding-top: 7px;
  padding-bottom: 7px;
  width: ${(props) => parseInt(props.width) + 7};
  ${(props) =>
    props.position === POSITIONS.DOWN &&
    `top: 32px;
    right: 12px;`}
  ${(props) =>
    props.position === POSITIONS.RIGHT &&
    `top: 0;
    left: ${parseInt(props.leftOffset ?? 0)};`}
  ${(props) =>
    props.position === POSITIONS.LEFT &&
    `top: 0;
    left: -${parseInt(props.width) + 7};`}
`;

export const InnerMenuContainer = styled.div`
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  box-shadow: 1px 2px 3px 2px rgba(87, 147, 219, 0.2);
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 21px;
  padding-bottom: 7px;
  padding-top: 7px;
`;

export const TitleContainer = styled.div`
  color: #8c9cb5;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-left: 12px;
`;
