import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: white;
  overflow: auto;
`;

export const SubcohortsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const HeaderActions = styled.div`
  display: flex;
  gap: 5px;
  visibility: hidden;
`;

export const Card = styled.div`
  border: 1px solid #e6e6e6;
  margin: 9px 9px 4px 9px;
  border-radius: 3px;
  z-index: 4;
  background-color: #fff;
  flex: none;
  opacity: ${(props) => props.opacity};
  cursor: ${(props) => (props.pointerCursor ? "pointer" : "default")};

  &:hover {
    opacity: 1;
    ${HeaderActions} {
      visibility: visible;
    }
  }
`;

export const CardTitle = styled.div`
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;

  > button {
    margin: 0;
  }
`;

export const CardContent = styled.div`
  border-left: 5px solid ${(props) => props.color};
  padding: 9px;
`;

export const CenterRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const SpaceBetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AddSubcohortButton = styled.div`
  flex: none;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 9px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 9px;
  border-radius: 3px;

  ${(props) =>
    props.disabled &&
    css`
      color: #cccccc;
      cursor: not-allowed;
    `}
`;
