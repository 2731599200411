import styled, { css } from "styled-components";

import ReactSelect from "../../basic-inputs/react-select";

export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.inline ? "row" : "column")};
  justify-content: center;
  align-items: ${(props) => (props.inline ? "center" : "flex-start")};
  flex-wrap: wrap;

  margin-bottom: 15px;

  label {
    padding: ${(props) => (props.inline ? "0 10px 0 0" : "0 0 2px 0")};
    color: #495f83;

    &::after {
      ${(props) => (props.required ? "content:' (required)'" : "")};
    }

    ${(props) =>
      props.inline &&
      css`
        flex-basis: 9rem;
        flex-shrink: 0;
        flex-grow: 0;
        text-align: right;

        & + div {
          flex: 1;
        }
      `}
  }

  > span {
    color: #dd2542;
    text-align: left;

    width: ${(props) => (props.inline ? "100%" : "unset")};
    margin-left: ${(props) => (props.inline ? "9rem" : "unset")};
  }
`;

export const Select = styled(ReactSelect)`
  flex: 1;
  align-self: stretch;
`;
