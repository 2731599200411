import styled from "styled-components";

export const Container = styled.div`
  min-height: 300px;
  position: relative;
  flex: 1;

  > img {
    max-height: 100%;
  }
`;
