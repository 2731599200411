import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Small } from "../typography";

function PieChart(props) {
  const chartRef = useRef();
  const tooltipRef = useRef();
  const { data, outerRadius, innerRadius = 0, colorPalette } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const [contentTooltip, setContentTooltip] = useState("");
  const [locationTooltip, setLocationTooltip] = useState([0, 0]);
  // START TOOLTIP Functions related to render anc control tooltips
  const handleMouseOver = (hoveredData) => {
    setShowTooltip(true);
    const tooltipContent = (
      <table>
        <tbody>
          <tr key="key">
            <td key={hoveredData.data.key}>
              <Small>{`${hoveredData.data.key}: `}</Small>
            </td>
            <td key={hoveredData.data.value}>
              <Small>
                <b>{hoveredData.data.value}%</b>
              </Small>
            </td>
          </tr>
        </tbody>
      </table>
    );
    setContentTooltip(tooltipContent);
  };

  const handleMouseOut = () => {
    setShowTooltip(false);
    setContentTooltip("");
  };

  const handleMouseMove = (e) => {
    /*
      Constantly called to update the position of the tooltip.
      Only updates when the tooltip is shown.
      */
    const Y_POS_OFFSET = 15;

    if (showTooltip) {
      const chartWrapperBounds = chartRef.current.getBoundingClientRect();
      const viewportWidth = document.documentElement.clientWidth;
      const tooltipBounds = tooltipRef.current.getBoundingClientRect();

      let x = e.clientX - chartWrapperBounds.left - tooltipBounds.width / 2;
      const y =
        e.clientY -
        chartWrapperBounds.top -
        tooltipBounds.height -
        Y_POS_OFFSET;

      // // Ensures the tooltip stays within the browser window
      if (e.clientX + tooltipBounds.width / 2 > chartWrapperBounds.right) {
        // Avoid overflow to the right.
        x =
          chartWrapperBounds.right -
          chartWrapperBounds.left -
          tooltipBounds.width;
      } else if (
        e.clientX - tooltipBounds.width / 2 <
        chartWrapperBounds.left
      ) {
        // Avoid overflow to the left.
        x = 0;
      }

      setLocationTooltip([x, y]);
    }
  };

  const renderTooltipLayer = () => {
    /*
      Consider creating a component just for the tooltip
      */
    return (
      <div className="charts-tooltip-layer">
        {showTooltip ? (
          <div
            className="charts-tooltip"
            ref={tooltipRef}
            style={{ top: locationTooltip[1], left: locationTooltip[0] }}
          >
            {contentTooltip}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  // END TOOLTIP

  const margin = {
    top: 10,
    right: 40,
    bottom: 10,
    left: 40,
  };

  const width = 2 * outerRadius + margin.left + margin.right;
  const height = 2 * outerRadius + margin.top + margin.bottom;

  const colorScale = d3.scale.ordinal().range(colorPalette);

  useEffect(() => {
    drawChart();
  }, [data]);

  function drawChart() {
    // Remove the old svg

    d3.select(chartRef.current).select("svg").remove();

    // Create new svg
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    const pie = d3.layout
      .pie()
      .value(function (d) {
        return d.value;
      })
      .sort(null);

    const arc = d3.svg.arc().innerRadius(innerRadius).outerRadius(outerRadius);

    // Another arc that won't be drawn. Just for labels positioning
    const outerArc = d3.svg
      .arc()
      .innerRadius(outerRadius)
      .outerRadius(outerRadius);

    const path = svg
      .selectAll("path")
      .data(pie(data))
      .enter()
      .append("path")
      .attr({
        d: arc,
        fill: function (d, i) {
          return colorScale(i);
        },
      })
      .style({
        stroke: "#fff",
        "stroke-width": "2px",
      })
      .on("mouseover", function (d, i) {
        handleMouseOver(d);
      })
      .on("mouseout", function (d, i) {
        handleMouseOut(d);
      });

    // Add the polylines between chart and labels:
    svg
      .selectAll("allPolylines")
      .data(pie(data))
      .enter()
      .append("polyline")
      .attr("stroke", "black")
      .style("fill", "none")
      .attr("stroke-width", 1)
      .attr("points", function (d) {
        var posA = arc.centroid(d); // line insertion in the slice
        var posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
        var posC = outerArc.centroid(d); // Label position = almost the same as posB
        var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
        posC[0] = outerRadius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
        return [posA, posB, posC];
      });

    // Add the polylines between chart and labels:
    svg
      .selectAll("allLabels")
      .data(pie(data))
      .enter()
      .append("text")
      .text(function (d) {
        return `${d.data.value}%`;
      })
      .attr("transform", function (d) {
        var pos = outerArc.centroid(d);
        var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = outerRadius * 0.99 * (midangle < Math.PI ? 1 : -1);
        return "translate(" + pos + ")";
      })
      .style("font-size", "12px")
      .style("text-anchor", function (d) {
        var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? "start" : "end";
      });
  }

  return (
    <div
      ref={chartRef}
      onMouseMove={(e) => handleMouseMove(e)}
      style={{ position: "relative" }}
    >
      {renderTooltipLayer()}
    </div>
  );
}

export default PieChart;
