import { SITE_COLUMNS_LABELS } from ".";

const SITE_COLUMNS_EXPORT_BLACKLIST = ["id", "favorite"];
const SITE_COLUMNS_NUMERIC = ["patient_score", "estimated_enrollment_score"];

const isUnique = (val, idx, arr) => arr.indexOf(val) === idx;

function getSitesRows(columns, sites) {
  const accessors = columns
    .map(({ accessor }) => accessor)
    .filter(isUnique)
    .filter((id) => !SITE_COLUMNS_EXPORT_BLACKLIST.includes(id));
  const rows = [];

  // add header rows
  rows.push([
    ...accessors.map((accessor) => {
      let label =
        accessor in SITE_COLUMNS_LABELS
          ? SITE_COLUMNS_LABELS[accessor]
          : accessor;

      const column = columns.find((column) => column.accessor === accessor);
      if (column && "unit" in column) {
        label += ` (${column.unit})`;
      }

      return label;
    }),
  ]);

  // add body rows
  for (const site of sites) {
    const newRow = [];

    for (const accessor of accessors) {
      const vals = [];

      // split accessor using space separator
      // used for Site PI column, where accessor="pi_first pi_last"
      for (const key of accessor.split(" ")) {
        let newVal = site[key];

        // if column is numeric, either round to one decimal or output -
        if (SITE_COLUMNS_NUMERIC.includes(accessor)) {
          newVal =
            newVal === 0 || isNaN(newVal) ? "-" : Number(newVal).toFixed(1);
        }

        if (!newVal) {
          newVal = "-";
        }

        vals.push(newVal);
      }

      newRow.push(vals.join(" "));
    }

    rows.push(newRow);
  }

  return rows;
}

function convertRowsToCsvString(rows) {
  const strings = [];

  for (const row of rows) {
    strings.push(row.map((cell) => `"${cell}"`).join(","));
  }

  return strings.join("\n");
}

export function getSitesCsvString(
  name,
  columns,
  sites,
  favoriteSitesIdList = null
) {
  if (!columns || !columns.length || !sites || !sites.length) {
    return "";
  }

  const filteredSites = favoriteSitesIdList
    ? sites.filter(({ id }) => favoriteSitesIdList.includes(id))
    : sites;

  const rows = [
    ["", "Cohort Name", name],
    ...getSitesRows(columns, filteredSites),
  ];
  const csvString = convertRowsToCsvString(rows);

  return csvString;
}
