import React, { createContext, useContext, useEffect, useState } from "react";
import { findIndex } from "lodash";
import { api } from "../services/api";

const PatientAttributeContext = createContext({});

export function PatientAttributeProvider(props) {
  const { children } = props;
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    GetAttributes();
  }, []);

  async function GetAttributes() {
    const response = await api.get(`intelligence/guideline_attributes`, {
      withCredentials: true,
    });
    const data = response.data.data;
    setAttributes(data);
  }

  async function EditAttribute(attribute, id, successHandler, errorHandler) {
    const response = await api.put(
      `intelligence/guideline_attributes/${id}`,
      attribute,
      { withCredentials: true }
    );
    if (response.data.valid) {
      const attributeIndex = findIndex(attributes, ["id", id]);
      const newAttributes = [...attributes];
      newAttributes.splice(attributeIndex, 1, response.data.data);
      setAttributes(newAttributes);
      successHandler(response.data.data);
    } else {
      errorHandler(response.data.messages);
    }
  }

  async function DeleteAttribute(id, successHandler, errorHandler) {
    const response = await api.delete(
      `intelligence/guideline_attributes/${id}`,
      { withCredentials: true }
    );
    if (response.data.valid) {
      const attributeIndex = findIndex(attributes, ["id", id]);
      const newAttributes = [...attributes];
      newAttributes.splice(attributeIndex, 1);
      setAttributes(newAttributes);
      successHandler(id);
    } else {
      errorHandler(response.data.messages);
    }
  }

  async function AddAttribute(attribute, successHandler, errorHandler) {
    const response = await api.post(
      `intelligence/guideline_attributes`,
      attribute,
      { withCredentials: true }
    );
    if (response.data.valid) {
      setAttributes([...attributes, response.data.data]);
      successHandler(response.data.data);
    } else {
      errorHandler(response.data.messages);
    }
  }

  return (
    <PatientAttributeContext.Provider
      value={{
        attributes,
        AddAttribute,
        EditAttribute,
        DeleteAttribute,
        GetAttributes,
      }}
    >
      {children}
    </PatientAttributeContext.Provider>
  );
}

export function usePatientAttribute() {
  const context = useContext(PatientAttributeContext);

  return context;
}
