import styled from "styled-components";

export const FilterGroup = styled.div`
  padding: 10px 0;
  > small {
    display: block;
  }
`;

export const CategoricalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
  row-gap: 5px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
`;

export const FilterLabel = styled.span`
  font-weight: ${(props) => (props.isApplied ? 700 : 400)};
`;

export const DIToggle = styled.span`
  border: 1px solid #dedfe2;
  border-radius: 6px;
  padding: 1px 7px;
  cursor: pointer;
  margin: 5px 0;
  display: inline-block;
`;
