import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { intersection } from "lodash";

import AuthLayout from "../layouts/Auth";
import DeafaultLayout from "../layouts/Default";

import SessionService from "../services/session-service.js";
import RouteHelper from "../helpers/route-helper.js";

// Page Not Found
import PageNotFound from "../workspaces/NotFound";

import store from "../store";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  authRoles,
  location,
  ...rest
}) {
  const loggedin = SessionService.sessionIsAuthenticated();
  const userRoles = SessionService.getRole();
  const userWorkspaces = SessionService.getAccessibleWorkspaces();

  if (!loggedin && isPrivate) {
    return <Redirect to={{ pathname: "/", state: { from: location } }} />;
  }
  if (loggedin && !isPrivate) {
    const redirectRoute =
      location.state && location.state.from
        ? location.state.from
        : RouteHelper.getHomeURL(userWorkspaces);

    return <Redirect to={redirectRoute} />; //
  }

  const Layout = loggedin ? DeafaultLayout : AuthLayout;

  if (authRoles && authRoles.length > 0) {
    if (intersection(userRoles, authRoles).length <= 0)
      return (
        <Route
          {...rest}
          render={(props) => (
            <Layout>
              <PageNotFound {...props} store={store} />
            </Layout>
          )}
        />
      );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} store={store} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  authRoles: PropTypes.arrayOf(PropTypes.string),
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
