//Libs
import React, { useEffect, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { isEmpty } from "lodash";

// UI Components
import { TabsContext } from "../../components/app-tab-nav/context";
import ContentContainer from "../../components/app-content-container";

// Modules Component
import Rules from "./pages/guideline-list";
import GuidelineDocumentsPage from "./pages/document-list";
import PatientAttributes from "./pages/attributes-list";

// Form Components
import GuidelineAdd from "./pages/guideline-add";
import GuidelineView from "./pages/guideline-view";

import GuidelineAttributeAdd from "./pages/attribute-add";
import GuidelineAttributesView from "./pages/attribute-view";

import GuidelineDocumentView from "./pages/document-view";
import GuidelineDocumentAdd from "./pages/document-add";

// Page Not Found
import PageNotFound from "../NotFound";

import { useGuidelineRule } from "../../hooks/useGuidelineRules";
import { useGuidelineDocument } from "../../hooks/useGuidelineDocuments";
import { usePatientAttribute } from "../../hooks/usePatientAttributes";
import { useMedicationIntents } from "../../hooks/useMedicationIntents";
import { useMedicationMechanisms } from "../../hooks/useMedicationMechanisms";
import { useMedicationDrugs } from "../../hooks/useMedicationDrugs";

export default function ContentManager(props) {
  const { guidelines } = useGuidelineRule();
  const { documents } = useGuidelineDocument();
  const { attributes } = usePatientAttribute();
  const { medicationIntents } = useMedicationIntents();
  const { medicationMechanisms } = useMedicationMechanisms();
  const { medications } = useMedicationDrugs();

  const { setMainTabs } = useContext(TabsContext);

  useEffect(() => {
    setMainTabs([
      {
        name: "Rules",
        url: "/content-manager/rules",
        displayTab: true,
      },
      {
        name: "Guideline Documents",
        url: "/content-manager/guideline-documents",
        displayTab: true,
      },
      {
        name: "Patient Attributes",
        url: "/content-manager/patient-attributes",
        displayTab: true,
      },
    ]);
  }, []);

  const displayLoading =
    isEmpty(guidelines) ||
    isEmpty(documents) ||
    isEmpty(attributes) ||
    isEmpty(medicationIntents) ||
    isEmpty(medicationMechanisms) ||
    isEmpty(medications);

  return (
    <ContentContainer>
      <Switch>
        <Route
          exact
          path="/content-manager"
          render={() => <Redirect to="/content-manager/rules" />}
        />

        <Route
          exact
          path="/content-manager/patient-attributes"
          render={(props) => (
            <PatientAttributes {...props} displayLoading={displayLoading} />
          )}
        />

        <Route
          exact
          path="/content-manager/patient-attributes/add"
          render={(props) => (
            <GuidelineAttributeAdd {...props} displayLoading={displayLoading} />
          )}
        />

        <Route
          exact
          path="/content-manager/patient-attributes/:attributeId"
          render={(props) => (
            <GuidelineAttributesView
              {...props}
              displayLoading={displayLoading}
            />
          )}
        />

        <Route
          exact
          path="/content-manager/guideline-documents"
          render={(props) => (
            <GuidelineDocumentsPage
              {...props}
              displayLoading={displayLoading}
            />
          )}
        />

        <Route
          exact
          path="/content-manager/guideline-documents/add"
          render={(props) => (
            <GuidelineDocumentAdd {...props} displayLoading={displayLoading} />
          )}
        />

        <Route
          exact
          path="/content-manager/guideline-documents/:documentId"
          render={(props) => (
            <GuidelineDocumentView {...props} displayLoading={displayLoading} />
          )}
        />

        <Route
          exact
          path="/content-manager/rules"
          render={(props) => (
            <Rules {...props} displayLoading={displayLoading} />
          )}
        />

        <Route
          exact
          path="/content-manager/rules/add"
          render={(props) => (
            <GuidelineAdd {...props} displayLoading={displayLoading} />
          )}
        />

        <Route
          exact
          path="/content-manager/rules/:guidelineId?"
          render={(props) => (
            <GuidelineView {...props} displayLoading={displayLoading} />
          )}
        />

        <Route render={(props) => <PageNotFound containerFrame={false} />} />
      </Switch>
    </ContentContainer>
  );
}
