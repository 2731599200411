import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  grid-area: main;
  overflow-y: auto;
  position: relative;
  padding: 1.142rem;
  background: #f7f9fd;

  display: grid;
  grid-template-rows: 1fr;
  overflow: auto;
`;
