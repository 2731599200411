import styled from "styled-components";

export const Container = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
`;

export const Input = styled.input.attrs({ type: "checkbox" })`
  margin-right: 5px;
`;
