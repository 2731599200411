import React, { useMemo, Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { find } from "lodash";

import { FaEdit } from "react-icons/fa";
import { RiArrowDropLeftLine } from "react-icons/ri";

import MedicationMechanismOverview from "../../c-medication-mechanism-overview";
import MedicationMechanismForm from "../../c-medication-mechanism-form";
import MedicationTable from "../../c-medication-drug-table";
import GuidelineHelper from "../../../../helpers/guideline-helper";
import GuidelineRulesTable from "../../../content-manager/c-guidelines-rules-table";
import Button from "../../../../components/button";
import Tabs from "../../../../components/tabs";
import Tab from "../../../../components/tabs/tab";
import LoadingHOC from "../../../../components/loadingHoc";

import { useGuidelineRule } from "../../../../hooks/useGuidelineRules";
import { useMedicationMechanisms } from "../../../../hooks/useMedicationMechanisms";
import { useMedicationDrugs } from "../../../../hooks/useMedicationDrugs";

import { Container, HeaderRow, Header, BackLink } from "./styles";

function MedicationMechanismView() {
  const { medicationMechanismId } = useParams();
  const [isEditing, setIsEditing] = useState(false);

  const { medicationMechanisms, DeleteMedicationMechanism } =
    useMedicationMechanisms();
  const { guidelines } = useGuidelineRule();
  const { medications } = useMedicationDrugs();

  const history = useHistory();

  const medicationMechanism = useMemo(() => {
    const medicationMechanism = find(medicationMechanisms, [
      "id",
      parseInt(medicationMechanismId),
    ]);
    if (medicationMechanism) {
      return medicationMechanism;
    }

    history.push("/medication-manager/medication-mechanisms/");
    return {};
  }, [medicationMechanismId, medicationMechanisms]);

  const guidelinesAttachedToMedicationMechanism = useMemo(() => {
    return GuidelineHelper.getListOfRulesWithAttribute(
      medicationMechanismId,
      guidelines
    );
  }, [medicationMechanismId, guidelines]);

  const medicationAttachedToMedicationMechanism = useMemo(() => {
    return GuidelineHelper.getListOfAttributeWithParent(
      medicationMechanismId,
      medications
    );
  }, [medicationMechanismId, medications]);

  const cancelEditingForm = () => {
    setIsEditing(false);
  };

  if (medicationMechanism) {
    return (
      <Container>
        <Header>
          <BackLink to="/medication-manager/medication-mechanisms/">
            <RiArrowDropLeftLine size={18} />
            back to medication mechanisms
          </BackLink>
          <HeaderRow>
            <div>
              <h3>{medicationMechanism.human_readable_name}</h3>
              <span>{medicationMechanism.name}</span>
            </div>
            {isEditing ? (
              ""
            ) : (
              <Button
                variant="outline"
                type="button"
                leftIcon={<FaEdit />}
                onClick={() => setIsEditing(true)}
              >
                Edit
              </Button>
            )}
          </HeaderRow>
        </Header>
        {isEditing ? (
          <MedicationMechanismForm
            cancelFormAction={cancelEditingForm}
            medicationMechanism={medicationMechanism}
          />
        ) : (
          <Tabs>
            <Tab label="Overview">
              <MedicationMechanismOverview
                medicationMechanism={medicationMechanism}
              />
            </Tab>
            {guidelinesAttachedToMedicationMechanism.length == 0 ? null : (
              <Tab
                label={`Guidelines (${guidelinesAttachedToMedicationMechanism.length})`}
              >
                <GuidelineRulesTable
                  isFiltersOn={false}
                  guidelines={guidelinesAttachedToMedicationMechanism}
                />
              </Tab>
            )}
            {medicationAttachedToMedicationMechanism.length == 0 ? null : (
              <Tab
                label={`Medications (${medicationAttachedToMedicationMechanism.length})`}
              >
                <MedicationTable
                  isFiltersOn={false}
                  medications={medicationAttachedToMedicationMechanism}
                />
              </Tab>
            )}
          </Tabs>
        )}
      </Container>
    );
  }
  return "";
}

export default LoadingHOC()(MedicationMechanismView);
