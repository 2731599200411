import React, { useEffect } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";

import TableHeader from "./header";
import TableRow from "./row";

import { Table, Thead } from "./styles";

function useRowSpan(instance) {
  const { allColumns } = instance;

  let rowSpanHeaders = [];

  allColumns.forEach((column, i) => {
    const { id, enableRowSpan } = column;

    if (enableRowSpan !== undefined) {
      rowSpanHeaders = [
        ...rowSpanHeaders,
        { id, topCellValue: null, topCellIndex: 0 },
      ];
    }
  });

  Object.assign(instance, { rowSpanHeaders });
}

function TableComponent({
  columns,
  data,
  globalFilterValue,
  stickyHeader = false,
  defaultSort = [],
  conditionalRowStyles,
  conditionalCellStyles,
  disableSortBy = false,
}) {
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setGlobalFilter, // use a global filter
    rowSpanHeaders,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: defaultSort,
        hiddenColumns: columns.map((column) =>
          column.hiddenColumn ? column.accessor : null
        ),
      },
    },
    useGlobalFilter,
    useSortBy,
    (hooks) => {
      hooks.useInstance.push(useRowSpan);
    }
  );

  useEffect(() => {
    setGlobalFilter(globalFilterValue);
  }, [globalFilterValue]);

  //This is to prepare the table span cells
  rows.forEach((row, i) => {
    prepareRow(row);
    for (let j = 0; j < row.allCells.length; j++) {
      let cell = row.allCells[j];
      let rowSpanHeader = rowSpanHeaders.find((x) => x.id === cell.column.id);

      if (rowSpanHeader !== undefined) {
        if (
          rowSpanHeader.topCellValue === null ||
          rowSpanHeader.topCellValue !== cell.value
        ) {
          cell.isRowSpanned = false;
          rowSpanHeader.topCellValue = cell.value;
          rowSpanHeader.topCellIndex = i;
          cell.rowSpan = 1;
        } else {
          rows[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
          cell.isRowSpanned = true;
        }
      }
    }
    return null;
  });

  return (
    <Table {...getTableProps()}>
      <Thead stickyHeader={stickyHeader}>
        {headerGroups.map((headerGroup, i) => (
          <TableHeader
            key={i}
            headerGroup={headerGroup}
            disableSortBy={disableSortBy}
          />
        ))}
      </Thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => (
          <TableRow
            key={i}
            rows={rows}
            prepareRow={prepareRow}
            row={row}
            conditionalRowStyles={conditionalRowStyles}
            conditionalCellStyles={conditionalCellStyles}
          />
        ))}
      </tbody>
    </Table>
  );
}

export default TableComponent;
