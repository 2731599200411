import React from "react";

import { Container, Loader } from "./styles";

function loading({ size = "md" }) {
  return (
    <Container>
      <Loader size={size} />
    </Container>
  );
}

export default loading;
