import React, { Fragment, useMemo } from "react";
import DashboardHelper from "../../../../../helpers/dashboard-helper";

import {
  SubtableHeader,
  SubtableBody,
  SubtableBodySpecialCell,
  SubtableBodyRowFirstCell,
  TooltipContent,
  TooltipContentGroup,
} from "./styles";

import Tooltip from "../tooltip";
import { Small, XSmall } from "../../../../../components/typography";

const TABLE_ROW_ALLOWED_PRIORITY = [0, 1, 2];
const TABLE_HOVER_ALLOWED_PRIORITY = [3];
const DEFAULT_COLOR = "#E6F5FA";
function subtable({ table, tableIndex, cohorts, headerStickyHeight }) {
  const headers = useMemo(() => {
    return table.headers
      .filter((header) =>
        TABLE_ROW_ALLOWED_PRIORITY.includes(header.default_priority)
      )
      .sort((a, b) => {
        if (a.default_priority > b.default_priority) return 1;
        if (a.default_priority < b.default_priority) return -1;
        return 0;
      });
  }, [table]);

  const hovers = useMemo(() => {
    return table.headers
      .filter((header) =>
        TABLE_HOVER_ALLOWED_PRIORITY.includes(header.default_priority)
      )
      .sort((a, b) => {
        if (a.default_priority > b.default_priority) return 1;
        if (a.default_priority < b.default_priority) return -1;
        return 0;
      });
  }, [table]);

  const getTooltipContent = (row) => {
    const content = [];

    cohorts.forEach((cohort) => {
      const ContentRows = hovers.map((hover, i) => {
        if (row[cohort.name] && row[cohort.name][hover.column_name]) {
          let text;
          const cellType = typeof row[cohort.name][hover.column_name];
          switch (cellType) {
            case "string":
              text = DashboardHelper.printAttributeHumanReadable(
                row[cohort.name][hover.column_name]
              );
              break;
            case "object":
              text = row[cohort.name][hover.column_name]
                ? row[cohort.name][hover.column_name].join(" - ")
                : "-";
              break;
            default:
              text = row[cohort.name][hover.column_name]
                ? row[cohort.name][hover.column_name]
                : "-";
          }
          return (
            <Small marginBottom={7} key={cohort.name + i}>
              {hover.display_name}: <b>{text}</b>
            </Small>
          );
        }
      });
      content.push(
        <TooltipContentGroup key={`${cohort.name}Group`}>
          <Small
            key="title"
            textTransform="uppercase"
            color={cohort.color}
            key={cohort.name}
            marginBottom={7}
          >
            {cohort.label}
          </Small>
          {ContentRows}
        </TooltipContentGroup>
      );
    });
    return content;
  };

  const getCellPrioratyZero = (cell, row, cohort_name) => {
    const unit = DashboardHelper.printAttributeUnit(cell);
    let cellContent = row[cohort_name].display_name;
    if (unit) cellContent = `${cellContent}, ${unit}`;
    headers.forEach((header) => {
      switch (header.default_priority) {
        case 1:
          cellContent = `${cellContent}, ${header.display_name}`;
          break;
        case 2:
          cellContent = `${cellContent} (${header.display_name})`;
          break;
      }
    });

    if (hovers.length != 0) {
      const tooltipContent = getTooltipContent(row);

      return (
        <SubtableBodyRowFirstCell key={0}>
          <Tooltip
            tooltipContent={<TooltipContent>{tooltipContent}</TooltipContent>}
          >
            <Small>{cellContent}</Small>
          </Tooltip>
        </SubtableBodyRowFirstCell>
      );
    }
    return (
      <SubtableBodyRowFirstCell key={0}>
        <Small>{cellContent}</Small>
      </SubtableBodyRowFirstCell>
    );
  };

  const getCellOtherPrioraties = (cell, prioraty, color = DEFAULT_COLOR) => {
    let cellContent;
    const cellType = typeof cell;
    switch (cellType) {
      case "object":
        cellContent = cell ? cell.join(" - ") : "-";
        break;
      default:
        cellContent = cell || "-";
    }
    if (prioraty == 2) cellContent = `(${cellContent})`;
    return (
      <SubtableBodySpecialCell
        key={prioraty}
        textAlign={prioraty == 2 ? "left" : "right"}
        color={color}
      >
        <Small>{cellContent}</Small>
      </SubtableBodySpecialCell>
    );
  };

  const getRow = (row) => {
    const cells = cohorts.map((cohort, index) => {
      return headers.map((header) => {
        if (row[cohort.name]) {
          if (header.default_priority === 0) {
            if (index === 0)
              return getCellPrioratyZero(
                row[cohort.name][header.column_name],
                row,
                cohort.name
              );
          } else {
            return getCellOtherPrioraties(
              row[cohort.name][header.column_name],
              header.default_priority,
              cohort.color
            );
          }
        }
      });
    });
    return cells;
  };

  return (
    <Fragment key={tableIndex}>
      <SubtableHeader stickyHeight={headerStickyHeight}>
        <tr>
          <th colSpan={cohorts.length * 2 + 1}>
            <XSmall
              color={"#666"}
              textTransform="uppercase"
              style={{ position: "sticky", left: 10 }}
            >
              {DashboardHelper.printAttributeHumanReadable(table.subtable_name)}
            </XSmall>
          </th>
        </tr>
      </SubtableHeader>
      <SubtableBody>
        {table.rows.map((row, i) => {
          return <tr key={i}>{getRow(row)}</tr>;
        })}
      </SubtableBody>
    </Fragment>
  );
}

export default subtable;
