import React, { useState, forwardRef, useEffect } from "react";
import { Container } from "./styles";

const TextInput = forwardRef(
  (
    {
      type = "text",
      onChange,
      leftIcon,
      rightIcon: RightIcon,
      rightIconAction,
      className,
      invalid,
      disabled,
      readOnly,
      marginBottom = 10,
      marginTop = 0,
      ...rest
    },
    ref
  ) => {
    const [inputType] = useState(type);
    const [inputReadOnly] = useState(readOnly);

    function handleChange(event) {
      if (onChange) onChange(event.target.value);
    }

    function handleRightIconClick(event) {
      if (rightIconAction) rightIconAction();
    }

    if (inputType == "hidden") {
      // Find a better way of handling hidden inputs
      return <input ref={ref} type={inputType} />;
    }
    return (
      <Container
        invalid={invalid}
        className={className}
        disabled={disabled}
        readOnly={inputReadOnly}
        marginBottom={marginBottom}
        marginTop={marginTop}
      >
        {leftIcon || ""}
        <input
          ref={ref}
          type={inputType}
          disabled={disabled}
          readOnly={inputReadOnly}
          onChange={handleChange}
          {...rest}
        />
        {RightIcon ? (
          <RightIcon
            onClick={() => {
              handleRightIconClick();
            }}
          />
        ) : (
          ""
        )}
      </Container>
    );
  }
);
export default TextInput;
