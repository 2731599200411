import React from "react";
import { Link } from "react-router-dom";

import LoadingHOC from "../../../../components/loadingHoc";

import Button from "../../../../components/button";
import AppPageTitle from "../../../../components/app-page-title";
import MedicationMechanismTable from "../../c-medication-mechanism-table";

import { useMedicationMechanisms } from "../../../../hooks/useMedicationMechanisms.js";

import { Container } from "./styles";

function MedicationMechanismsList() {
  const { medicationMechanisms } = useMedicationMechanisms();

  return (
    <>
      <AppPageTitle
        title="Medication Mechanism"
        actions={
          <Button variant="confirm">
            <Link to="/medication-manager/medication-mechanisms/add">
              New Medication Mechanism
            </Link>
          </Button>
        }
      />
      <Container>
        <MedicationMechanismTable medicationMechanisms={medicationMechanisms} />
      </Container>
    </>
  );
}

export default LoadingHOC()(MedicationMechanismsList);
