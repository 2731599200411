import React, { createContext, useContext, useEffect, useState } from "react";
import { findIndex } from "lodash";
import { api } from "../services/api";

const MedicationIntentsContext = createContext({});

export function MedicationIntentsProvider(props) {
  const { children } = props;
  const [medicationIntents, setMedicationsIntents] = useState([]);

  useEffect(() => {
    GetMedicationsIntents();
  }, []);

  async function GetMedicationsIntents() {
    const response = await api.get(`medication_intents`, {
      withCredentials: true,
    });
    const { data } = response.data;
    setMedicationsIntents(data);
  }

  async function EditMedicationIntent(
    medication,
    id,
    successHandler,
    errorHandler
  ) {
    const response = await api.put(`medication_intents/${id}`, medication, {
      withCredentials: true,
    });
    if (response.data.valid) {
      const medicationIntentIndex = findIndex(medicationIntents, ["id", id]);
      const newMedicationsIntents = [...medicationIntents];
      newMedicationsIntents.splice(
        medicationIntentIndex,
        1,
        response.data.data
      );
      setMedicationsIntents(newMedicationsIntents);
      successHandler(response.data.data);
    } else {
      errorHandler(response.data.messages);
    }
  }

  async function DeleteMedicationIntent(id, successHandler, errorHandler) {
    const response = await api.delete(`medication_intents/${id}`, {
      withCredentials: true,
    });
    if (response.data.valid) {
      const medicationIntentIndex = findIndex(medicationIntents, ["id", id]);
      const newMedicationsIntents = [...medicationIntents];
      newMedicationsIntents.splice(medicationIntentIndex, 1);
      setMedicationsIntents(newMedicationsIntents);
      successHandler(id);
    } else {
      errorHandler(response.data.messages);
    }
  }

  async function AddMedicationIntent(medication, successHandler, errorHandler) {
    const response = await api.post(`medication_intents`, medication, {
      withCredentials: true,
    });
    if (response.data.valid) {
      setMedicationsIntents([...medicationIntents, response.data.data]);
      successHandler(response.data.data);
    } else {
      errorHandler(response.data.messages);
    }
  }

  return (
    <MedicationIntentsContext.Provider
      value={{
        medicationIntents,
        AddMedicationIntent,
        EditMedicationIntent,
        DeleteMedicationIntent,
        GetMedicationsIntents,
      }}
    >
      {children}
    </MedicationIntentsContext.Provider>
  );
}

export function useMedicationIntents() {
  const context = useContext(MedicationIntentsContext);

  return context;
}
