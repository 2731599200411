import styled from "styled-components";

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;
