import React, { Fragment } from "react";

import { Large } from "../../../components/typography";
import { Container } from "./styles";

function ErrorScreen({ message }) {
  const getMessage = () => {
    switch (message) {
      case "Not Enough Patients":
        return (
          <>
            <p>
              <Large>
                Your search resulted in <b>fewer than 10 patients</b>.
              </Large>
            </p>
            <p>
              <Large>
                To protect patient privacy, we are unable to display the
                results.
              </Large>
            </p>
            <p>
              <Large>
                Please try a different filter / combination of filters.
              </Large>
            </p>
          </>
        );
      default:
        return (
          <>
            <p>
              <Large>Something went wrong.</Large>
            </p>
            <p>
              <Large>We apologize for the inconvenience.</Large>
            </p>
            <p>
              <Large>
                If the error persists, please contact us at{" "}
                <a href="mailto: support@healthpalsinc.com">
                  support@healthpalsinc.com
                </a>
                .
              </Large>
            </p>
          </>
        );
    }
  };

  return (
    <Container>
      <img src="/images/clint-symbol-one-color-01.svg" />
      {getMessage()}
    </Container>
  );
}

export default ErrorScreen;
