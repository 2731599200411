import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Container, Header, Content } from "./styles";

const Collapsible = forwardRef(
  (
    {
      children,
      title,
      openDefault = false,
      backgroundColor = "transparent",
      borderColor = "#F2F2F2",
      border,
      borderTop,
      padding,
    },
    ref
  ) => {
    const [isOpen, SetIsOpen] = useState(openDefault);
    const collapsibleRef = useRef(null);
    useImperativeHandle(ref, () => ({
      open() {
        SetIsOpen(true);
      },
      close() {
        SetIsOpen(false);
      },
      element: collapsibleRef.current,
    }));

    return (
      <Container
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        ref={collapsibleRef}
        border={border}
        borderTop={borderTop}
        padding={padding}
      >
        <Header onClick={() => SetIsOpen(!isOpen)}>
          <span>{title}</span>
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </Header>
        {isOpen ? <Content>{children}</Content> : ""}
      </Container>
    );
  }
);

export default Collapsible;
