import React from "react";
import { BiInfoCircle } from "react-icons/bi";
import Tooltip from "../tooltip";

import { Container } from "./styles";

export default function InfoIconTooltip({
  description,
  size,
  children,
  position,
}) {
  return (
    <Container>
      {children}
      <Tooltip description={description} delay={0} position={position}>
        <BiInfoCircle
          size={size}
          color="#8C9CB5"
          style={{ cursor: "default" }}
        />
      </Tooltip>
    </Container>
  );
}
