import styled from "styled-components";

export const Container = styled.div`
  vertical-align: top;
  box-sizing: border-box;

  flex: 1;
  margin: -15px;
  padding: 15px;
  background: #fff;
`;

export const SelectLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;

export const Color = styled.span`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  border-radius: 3px;
  background-color: ${(props) => props.color};
`;
