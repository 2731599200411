import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// TODO define each size xs sm md lg and xl
const handleSize = (size) => {
  switch (size) {
    case "xs":
      return css`
        border: 2px solid #f3f3f3;
        border-top: 2px solid #ccc;
        width: 15px;
        height: 15px;
      `;
    case "md":
      return css`
        border: 5px solid #f3f3f3;
        border-top: 5px solid #ccc;
        width: 50px;
        height: 50px;
      `;
  }
};

export const Loader = styled.div`
  border-radius: 50%;

  ${(props) => handleSize(props.size)};

  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
