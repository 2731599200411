import React, { useState, useEffect, useRef } from "react";
import { BsTable } from "react-icons/bs";
import { VscGraph } from "react-icons/vsc";
import { MdDownload, MdKeyboardArrowRight } from "react-icons/md";
import { Canvg, presets } from "canvg";
import { isEmpty, sortBy } from "lodash";

import Select from "../../../../components/basic-inputs/select";
import Collapsible from "../../../../components/collapsible";
import ChartWrapper from "../../../../components/dashboard-charts-wrapper/charts";
import DownloadCsvMenuItem from "../../../../components/download-csv-menu-item";
import KebabMenu from "../../../../components/kebab-menu";
import KebabMenuItem from "../../../../components/kebab-menu-item";
import InfoModal from "../../../../components/info-modal";
import Loader from "../../../../components/loader";
import { useModal } from "../../../../components/modal";
import { Heading4 } from "../../../../components/typography";
import InternalPanel from "../../../../components/panel/internal";
import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../services/mixpanel-tracking";

import { CollapsiblesContainer, PanelTab, TabContainer } from "./styles";
import TableWrapper from "./table-wrapper";
import { ChartWrapper as InnerChartWrapper } from "../../../../components/dashboard-charts-wrapper/charts";

import { useIdentifySubcohortList } from "../../../../hooks/useIdentifySubcohortList";

import DetailsMenuItem from "./details-menu-item";

import {
  convertTableToCsvString,
  convertGraphToCsvString,
} from "./table-wrapper/convert-csv";

import DashboardHelper from "../../../../helpers/dashboard-helper";
import { useIdentifyCohortConfigs } from "../../../../hooks/useIdentifyCohortConfig";

const PANEL_TITLE = "Cumulative Event Rate";
const VIEWS = {
  chart: {
    label: "Chart",
    icon: () => <VscGraph size={13} />,
  },
  table: {
    label: "Table",
    icon: () => <BsTable size={13} />,
  },
};

const EXPORT_GRAPH_WIDTH = 1024;
const EXPORT_GRAPH_HEIGHT = 768;

function getDownloadMenuItems(
  selectedView,
  selectedChart,
  subcohortList,
  table,
  onDownloadGraphClick,
  exportEnabled
) {
  if (!exportEnabled) {
    return [];
  }

  const downloadMenuItems = [];
  if (selectedView === VIEWS.chart.label) {
    downloadMenuItems.push(
      <KebabMenuItem
        key="graph-jpg"
        onClick={(event) => onDownloadGraphClick(event, selectedChart)}
      >
        <span>Graph (.jpg)</span>
      </KebabMenuItem>
    );
    const names = Object.keys(subcohortList).map(
      (id) => subcohortList[id].name
    );
    downloadMenuItems.push(
      <DownloadCsvMenuItem
        key="graph-csv"
        label="Table (.csv)"
        filename="cumulative-event-rate.csv"
        csvString={convertGraphToCsvString(names, selectedChart)}
        icon={null}
      />
    );
  } else if (selectedView === VIEWS.table.label) {
    const { columns, rows } = table;
    downloadMenuItems.push(
      <DownloadCsvMenuItem
        key="table-2-csv"
        label="Download Table"
        filename="table-2.csv"
        csvString={convertTableToCsvString(columns, sortBy(rows, ["outcome"]))}
        icon={<MdDownload size={14} color="26344D" />}
      />
    );
  }

  return downloadMenuItems;
}

function getPanelTitle(charts) {
  if (!charts || !charts.length) {
    return "";
  }
  return PANEL_TITLE;
}

function getPanelActions(
  infoBox,
  toggleInfoBoxModal,
  charts,
  selectedChart,
  selectedDropdown,
  handleDropdownChange,
  selectedView,
  table,
  subcohortList,
  onDownloadGraphClick,
  exportEnabled
) {
  if (!charts || !charts.length) {
    return null;
  }

  const actions = [];

  const options = charts.map(({ name }, index) => ({
    value: index,
    label: name,
  }));
  actions.push(
    <Select
      key="dropdown"
      options={options}
      value={selectedDropdown}
      onChange={(value) => {
        handleDropdownChange(Number(value));
      }}
      marginBottom={0}
      disabled={selectedView === VIEWS.table.label}
    />
  );

  const kebabMenuItems = [];

  if (!isEmpty(infoBox)) {
    kebabMenuItems.push(
      <DetailsMenuItem
        key="details-menu-item"
        toggleModal={toggleInfoBoxModal}
      />
    );
  }

  const downloadMenuItems = getDownloadMenuItems(
    selectedView,
    selectedChart,
    subcohortList,
    table,
    onDownloadGraphClick,
    exportEnabled
  );

  if (downloadMenuItems.length === 1) {
    kebabMenuItems.push(downloadMenuItems[0]);
  } else if (downloadMenuItems.length > 1) {
    const downloadKebabMenu = (
      <KebabMenu
        key="download-kebab-menu"
        item={
          <KebabMenuItem>
            <MdDownload size={14} color="26344D" />
            <span>Download Chart</span>
            <MdKeyboardArrowRight size={14} color="26344D" />
          </KebabMenuItem>
        }
        position="right"
        activation="hover"
        leftOffset={132}
        title="Format"
      >
        {downloadMenuItems}
      </KebabMenu>
    );
    kebabMenuItems.push(downloadKebabMenu);
  }

  if (kebabMenuItems.length > 0)
    actions.push(
      <KebabMenu key="kebab-menu" width="160px">
        {kebabMenuItems}
      </KebabMenu>
    );

  return actions;
}

function getPanelTabs(showPanelTabs, selectedView, changeSelectedView) {
  if (!showPanelTabs) {
    return null;
  }

  const tabs = Object.entries(VIEWS).map(([_, view]) => (
    <PanelTab
      key={view.label}
      selected={selectedView === view.label}
      onClick={() => {
        changeSelectedView(view.label);
      }}
    >
      {view.icon()}
      {view.label}
    </PanelTab>
  ));

  return <TabContainer>{tabs}</TabContainer>;
}

function getInfoBoxModal(
  { title, description, compositeEndpoints },
  toggleModal,
  isShowingModal
) {
  return (
    <InfoModal
      key="infoModal"
      title={title}
      toggleModal={toggleModal}
      isShowingModal={isShowingModal}
    >
      {compositeEndpoints && Object.keys(compositeEndpoints).length !== 0 && (
        <CollapsiblesContainer>
          {Object.entries(compositeEndpoints).map(([title, description], i) => (
            <Collapsible
              key={title}
              title={<Heading4>{title}</Heading4>}
              border="1px solid #e6e6e6"
              borderTop={i === 0 ? null : 0}
              padding="10px"
            >
              {description}
            </Collapsible>
          ))}
        </CollapsiblesContainer>
      )}
    </InfoModal>
  );
}

function getChartObservation(subcohortList, attributeList) {
  const subcohortIDs = Object.keys(subcohortList);
  let chartObservation = "";
  if (subcohortIDs.length > 0) {
    chartObservation += `The chart shows the cumulative event rate for the cohort ${subcohortList.cohort.name}`;
    if (subcohortIDs.length > 1) chartObservation += ` and the subcohorts:`;
    for (const id in subcohortList) {
      if (id !== "cohort") {
        chartObservation += ` ${id.toUpperCase()}`;
        const appliedFilterExpression = DashboardHelper.getSubcohortDefaultName(
          subcohortList[id].filterList,
          attributeList
        );
        if (subcohortList[id].name !== appliedFilterExpression)
          chartObservation += ` named as ${subcohortList[id].name}`;
        chartObservation += ` with the following attributes: ${appliedFilterExpression}`;
      }
    }
    chartObservation += ".";
  }
  return chartObservation;
}

function CumulativeEventRateWrapper({
  infoBox,
  charts,
  table,
  showPanelTabs = false,
  exportEnabled = true,
}) {
  const { subcohortList } = useIdentifySubcohortList();
  const { cohortConfigs } = useIdentifyCohortConfigs();
  const { filtersConfig } = cohortConfigs;

  const downloadSvgRef = useRef(null);
  const [selectedDropdown, setSelectedDropdown] = useState(0);
  const [selectedView, setSelectedView] = useState(VIEWS.chart.label);
  const { toggleModal, isShowingModal } = useModal();
  const subcohortIDs = Object.keys(subcohortList);
  const selectedChart = charts[selectedDropdown];

  // set selectedView to chart if there's no table object
  useEffect(() => {
    if (!table || !Object.keys(table).length) {
      setSelectedView(VIEWS.chart.label);
    }
  }, [table]);

  // set selectedDropdown to first item when the charts change
  useEffect(() => {
    setSelectedDropdown(0);
  }, [charts]);

  function handleDropdownChange(value) {
    mixpanelIdentify(localStorage.email);
    mixpanelTrack(`${PANEL_TITLE} - Change Chart`, {
      selectedChart: value,
      urlPathname: location.pathname,
    });

    setSelectedDropdown(value);
  }

  function changeSelectedView(newSelectedView) {
    mixpanelIdentify(localStorage.email);
    mixpanelTrack(`${PANEL_TITLE} - Change View`, {
      selectedView: newSelectedView,
      urlPathname: location.pathname,
    });

    setSelectedView(newSelectedView);
  }

  function toggleInfoBoxModal() {
    const newIsShowingModal = !isShowingModal;

    mixpanelIdentify(localStorage.email);
    mixpanelTrack(`${PANEL_TITLE} - Toggle Info Box Modal`, {
      isShowingModal: newIsShowingModal,
      urlPathname: location.pathname,
    });

    toggleModal();
  }

  async function onDownloadGraphClick(event, selectedChart) {
    event.preventDefault();

    const svgTitle = selectedChart.name;
    const svgTitleOffset = 20;
    const svgInnerHTML = downloadSvgRef.current.innerHTML;
    const svgStyles = `
      .title {
        font-size: 14px;
      }

      .chart__axis {
        font-size: 12px;
        fill: #26344d;
      }

      .chart__axis_title,
      .chart__observation,
      .chart__axis__ticks-note {
        font-size: 10px;
        fill: #4d5f80;
        shape-rendering: optimizespeed;
      }

      .chart__axis path {
        fill: none;
        stroke: #979797;
        stroke-width: 1px;
        shape-rendering: crispEdges;
      }

      .chart__axis line {
        visibility: hidden;
      }
    `;

    const svgOuterHTML = `<svg width='${EXPORT_GRAPH_WIDTH}' height='${
      EXPORT_GRAPH_HEIGHT + svgTitleOffset
    }'>
      <defs>
        <style>
          ${svgStyles}
        </style>
      </defs>
      <rect width="100%" height="100%" fill="white"/>
      <g transform="translate(0, ${svgTitleOffset})">
        <text dx="50%" class="title" dominant-baseline="middle" text-anchor="middle">${svgTitle}</text>
        ${svgInnerHTML}
      </g>
    </svg>`;

    presets.offscreen();
    const canvas = new OffscreenCanvas(EXPORT_GRAPH_WIDTH, EXPORT_GRAPH_HEIGHT);
    const ctx = canvas.getContext("2d");
    const v = await Canvg.from(ctx, svgOuterHTML);
    await v.render();

    const blob = await canvas.convertToBlob({ type: "image/jpeg" });
    const imageDataUrl = URL.createObjectURL(blob);

    const graphName = subcohortIDs
      .map((name) => name.toLowerCase().replaceAll(" ", "_"))
      .join("-");

    const downloadAnchor = document.createElement("a");
    downloadAnchor.href = imageDataUrl;
    downloadAnchor.download = `${graphName}.jpeg`;
    downloadAnchor.click();
  }

  const panelTitle = getPanelTitle(charts);
  const panelActions = getPanelActions(
    infoBox,
    toggleInfoBoxModal,
    charts,
    selectedChart,
    selectedDropdown,
    handleDropdownChange,
    selectedView,
    table,
    subcohortList,
    onDownloadGraphClick,
    exportEnabled
  );
  const panelTabs = getPanelTabs(
    showPanelTabs,
    selectedView,
    changeSelectedView
  );
  const infoBoxModal = getInfoBoxModal(
    infoBox,
    toggleInfoBoxModal,
    isShowingModal
  );

  const chartsEmpty = !charts.length;
  const tableEmpty = !Object.keys(table).length;
  const isLoading = showPanelTabs ? tableEmpty || chartsEmpty : chartsEmpty;

  const hiddenSelectedChart = Object.assign(
    { config: {}, meta: {} },
    JSON.parse(JSON.stringify(selectedChart ?? {}))
  );
  hiddenSelectedChart.config.hideLegends = false;
  if (filtersConfig)
    hiddenSelectedChart.meta.chartObservation = getChartObservation(
      subcohortList,
      filtersConfig
    );

  return (
    <InternalPanel
      title={isLoading ? "" : panelTitle}
      actions={isLoading ? null : panelActions}
      bottomActions={isLoading ? null : panelTabs}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {selectedView === VIEWS.chart.label && (
            <div className="charts-container">
              <ChartWrapper
                key={VIEWS.chart.label}
                chart={selectedChart}
                visible={true}
              />
              <InnerChartWrapper
                key={VIEWS.chart.label + "-hidden"}
                svgRef={downloadSvgRef}
                chart={hiddenSelectedChart}
                width={EXPORT_GRAPH_WIDTH}
                height={EXPORT_GRAPH_HEIGHT}
                visible={false}
              />
            </div>
          )}
          {selectedView === VIEWS.table.label && (
            <TableWrapper key={VIEWS.table.label} table={table} />
          )}
          {infoBoxModal}
        </>
      )}
    </InternalPanel>
  );
}

export default CumulativeEventRateWrapper;
