import React from "react";

import Modal from "../modal";
import { Container } from "./styles";

function InfoModal({ title, toggleModal, isShowingModal, children }) {
  return (
    <div>
      <Modal title={title} isShowing={isShowingModal} hide={toggleModal}>
        <Container>{children}</Container>
      </Modal>
    </div>
  );
}

export default InfoModal;
