import React, { useState, useEffect, useRef } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";

import { Container, Select, DropDown } from "./styles";

import RangeSlider from "../basic-inputs/range-slider-input ";
import { isEqual } from "lodash";

export default function SelectCheckbox({
  defaultValue,
  value,
  onChange,
  label,
  min,
  max,
  step,
  marks,
}) {
  const [currentValues, setCurrentValues] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    setCurrentValues(value ? value : defaultValue);
  }, [value]);

  const toggleOptionList = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const handleClickOutside = function (event) {
    const isClickInside =
      wrapperRef.current && wrapperRef.current.contains(event.target);
    if (!isClickInside) {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const handleOnChange = function (_, value) {
    setCurrentValues(value);
    if (onChange) onChange(value);
  };

  return (
    <Container ref={wrapperRef}>
      <Select
        active={!isEqual(currentValues, defaultValue)}
        onClick={toggleOptionList}
      >
        <span>
          {label}
          {isEqual(currentValues, defaultValue)
            ? ""
            : ` [${currentValues[0]}-${currentValues[1]}]`}
        </span>
        <RiArrowDropDownLine size={25} />
      </Select>
      {!isOpen ? (
        ""
      ) : (
        <DropDown>
          <RangeSlider
            min={min}
            max={max}
            defaultValue={defaultValue}
            step={step ? step : 1}
            onChange={handleOnChange}
            value={currentValues}
            marks={marks}
            name={label}
            showInputsMinMax={false}
          />
        </DropDown>
      )}
    </Container>
  );
}
