import styled from "styled-components";

import { Tooltip } from "react-leaflet";

export const TooltipWrapper = styled(Tooltip)`
  text-align: left;
  width: auto;
  height: auto;
  padding: 11px;
  border-radius: 3px;
  border: 1px solid #c1c8d4;
  background: white;
  pointer-events: none;
  box-shadow: 1px 2px 3px 2px rgba(87, 147, 219, 0.2);

  &:before {
    display: none !important;
  }
`;
