import React, { useMemo, Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { find } from "lodash";

import { FaEdit } from "react-icons/fa";
import { RiArrowDropLeftLine } from "react-icons/ri";

import GuidelineAttributesOverview from "../../c-patient-attribute-overview";
import GuidelineAttributesForm from "../../c-patient-attribute-form";
import GuidelineHelper from "../../../../helpers/guideline-helper";
import GuidelineRulesTable from "../../c-guidelines-rules-table";
import Button from "../../../../components/button";
import Tabs from "../../../../components/tabs";
import Tab from "../../../../components/tabs/tab";
import LoadingHOC from "../../../../components/loadingHoc";

import { usePatientAttribute } from "../../../../hooks/usePatientAttributes";
import { useGuidelineRule } from "../../../../hooks/useGuidelineRules";

import { Container, Header, BackLink, HeaderRow } from "./styles";

function AttributeView() {
  const { attributeId } = useParams();
  const [isEditing, setIsEditing] = useState(false);

  const { guidelines } = useGuidelineRule();
  const { attributes } = usePatientAttribute();

  const history = useHistory();

  const attribute = useMemo(() => {
    const attribute = find(attributes, ["id", parseInt(attributeId)]);
    if (attribute) {
      return attribute;
    }

    history.push("/content-manager/patient-attributes/");
    return {};
  }, [attributeId, attributes]);

  const guidelinesAttachedToAttribute = useMemo(() => {
    return GuidelineHelper.getListOfRulesWithAttribute(attributeId, guidelines);
  }, [attributeId, guidelines]);

  const cancelEditingForm = () => {
    setIsEditing(false);
  };

  return (
    <Container>
      <Header>
        <BackLink to="/content-manager/patient-attributes/">
          <RiArrowDropLeftLine size={18} />
          back to patient attributes
        </BackLink>
        <HeaderRow>
          <div>
            <h3>{attribute.human_readable_name}</h3>
            <span>{attribute.name}</span>
          </div>
          {isEditing ? (
            ""
          ) : (
            <Button
              variant="outline"
              type="button"
              leftIcon={<FaEdit />}
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
          )}
        </HeaderRow>
      </Header>
      {isEditing ? (
        <GuidelineAttributesForm
          cancelFormAction={cancelEditingForm}
          attribute={attribute}
          usageNumber={guidelinesAttachedToAttribute.length}
        />
      ) : (
        <Tabs>
          <Tab label="Overview">
            <GuidelineAttributesOverview attribute={attribute} />
          </Tab>
          {guidelinesAttachedToAttribute.length == 0 ? null : (
            <Tab label={`Guidelines (${guidelinesAttachedToAttribute.length})`}>
              <GuidelineRulesTable
                isFiltersOn={false}
                guidelines={guidelinesAttachedToAttribute}
              />
            </Tab>
          )}
        </Tabs>
      )}
    </Container>
  );
}

export default LoadingHOC()(AttributeView);
