import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { countBy } from "lodash";

import GuidelineHelper from "../../../helpers/guideline-helper.js";
import Table from "../../../components/tables/simple-table";
import FilteredTable from "../../../components/tables/filtered-table";
import Button from "../../../components/button";

import { useGuidelineRule } from "../../../hooks/useGuidelineRules.js";
import { useMedicationMechanisms } from "../../../hooks/useMedicationMechanisms.js";

export default function MedicationIntentTable(props) {
  const { medicationIntents, isFiltersOn = true } = props;

  const { medicationMechanisms } = useMedicationMechanisms();
  const { guidelines } = useGuidelineRule();

  const columns = [
    {
      Header: "#",
      accessor: "id",
      disableGlobalFilter: true,
    },
    {
      accessor: "id",
      id: "view-btn",
      disableSortBy: true,
      disableGlobalFilter: true,
      Cell: ({ cell: { value } }) => (
        <Button variant="outline">
          <Link to={`/medication-manager/medication-intents/${value}`}>
            View
          </Link>
        </Button>
      ),
    },
    {
      Header: "Name",
      accessor: "human_readable_name",
      breakWord: true,
    },
    {
      Header: "# of Medication Mechanisms",
      accessor: (row) => {
        const usageCount = countBy(medicationMechanisms, function (mechanism) {
          return mechanism.parent;
        })[row.id];
        return usageCount || 0;
      },
      id: "medication-mechanism-usage",
      disableGlobalFilter: true,
    },
    {
      Header: "# of Rules",
      accessor: (row) =>
        GuidelineHelper.numberOfRulesWithAttribute(row.id, guidelines),
      id: "rule-usage",
      disableGlobalFilter: true,
    },
  ];

  if (isFiltersOn) {
    return (
      <FilteredTable columns={columns} data={medicationIntents} urlState />
    );
  }
  return <Table columns={columns} data={medicationIntents} />;
}
