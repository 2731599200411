import styled from "styled-components";

export const Container = styled.div`
  cursor: pointer;

  > small {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 5px;
  }
`;

export const SpaceBetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;
export const CenterRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AppliedItem = styled.div`
  margin: 0 -5px 10px -5px;
  padding: 5px 10px;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;

  display: flex;
  flex-direction: column;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
`;

export const Values = styled.span`
  b {
    text-transform: uppercase;
    color: #495f83;
  }
  b + b:before {
    content: "${(props) => ` ${props.content} `}";
    width: 10px;
    color: #666666;
    text-transform: lowercase;
    font-weight: 400;
  }
`;
