/*
* Re-usable actions
* TODOS:
*      1. Refactor all Actions type to be CONSTANTS instead of STRINGS
*      2. Diferentiate INTERNAL actions from API actions.
       3. Adopt more standard patterns. There is a risk o defining an action name that is already in use: http://redux.js.org/docs/recipes/ReducingBoilerplate.html
*/

const Actions = class {
  // TODO: organize this code.
  static initializeAppState(store) {
    store.dispatch({ type: "REFRESH_SESSION_STATE" });
    store.dispatch({ type: "REFRESH_ALL_USERS" });
  }
};

export default Actions;
