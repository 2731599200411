import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";

import Button from "../../../../../../components/button";
import { Heading4, Small } from "../../../../../../components/typography";

import { useIdentifyCohortConfigs } from "../../../../../../hooks/useIdentifyCohortConfig";
import {
  mixpanelIdentify,
  mixpanelTrack,
} from "../../../../../../services/mixpanel-tracking";

import { Container, SelectBox, CohortSelectList } from "./styles";

function CohortSelect() {
  const { cohortConfigs, dispatch } = useIdentifyCohortConfigs();
  const { cohortIndex, cohortIndexList } = cohortConfigs;
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const optionListRef = useRef(null);

  const toggleOptionList = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const handleClickOutside = function (event) {
    const isClickInside =
      optionListRef.current && optionListRef.current.contains(event.target);
    if (!isClickInside) {
      setIsOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const setCohortIndex = (cohortIndex) => {
    dispatch({
      type: "CHANGE_SELECTED_COHORT_INDEX",
      payload: cohortIndex,
    });
    setIsOpen(false);

    mixpanelIdentify(localStorage.email);
    mixpanelTrack("Change Cohort", {
      cohort: cohortIndex,
      urlPathname: location.pathname,
    });
  };
  return (
    <Container>
      <SelectBox>
        <div>
          <Small color="#666666">Cohort</Small>
          <Heading4>
            {cohortIndex.index_human_readable_name}{" "}
            {/* <Small color="#8C9CB5">
              N={cohortSize.toLocaleString("en-US")}
            </Small> */}
          </Heading4>
        </div>
        {!isOpen ? (
          <Button onClick={toggleOptionList} variant="outline">
            Change
          </Button>
        ) : (
          ""
        )}
      </SelectBox>
      {isOpen ? (
        <CohortSelectList ref={optionListRef}>
          {cohortIndexList.map((cohort) => {
            if (cohort.index_name !== cohortIndex.index_name)
              return (
                <div key={cohort.index_name}>
                  <span>
                    {cohort.index_human_readable_name}
                    {/* <Small style={{ display: "block" }} color="#8C9CB5">
                  N=0
                </Small> */}
                  </span>

                  <Button
                    onClick={() => setCohortIndex(cohort)}
                    variant="outline"
                  >
                    Apply to dashboard
                  </Button>
                </div>
              );
          })}
        </CohortSelectList>
      ) : (
        ""
      )}
    </Container>
  );
}

export default CohortSelect;
