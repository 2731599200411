import React, { createContext, useContext, useEffect, useState } from "react";
import { getMapsTopography } from "../services/insights-data-service";

const MapsTopographyContext = createContext({});

export function MapsTopographyProvider(props) {
  const { children } = props;

  const [mapsTopography, setMapsTopography] = useState([]);

  async function getTopography() {
    const response = await getMapsTopography();
    setMapsTopography(response);
  }

  useEffect(async () => {
    getTopography();
  }, []);

  return (
    // put here the variables that component need to share
    <MapsTopographyContext.Provider value={{ mapsTopography: mapsTopography }}>
      {children}
    </MapsTopographyContext.Provider>
  );
}

export function useMapsTopography() {
  const context = useContext(MapsTopographyContext);

  return context;
}
