import React from "react";

import {
  Heading1Container,
  Heading2Container,
  Heading3Container,
  Heading4Container,
  Heading5Container,
  SmallContainer,
  XSmallContainer,
  LargeContainer,
} from "./styles";

export function Heading1({
  children,
  color = "--primary",
  textTransform = "none",
  marginBottom = 0,
  marginTop = 0,
  ...rest
}) {
  return (
    <Heading1Container
      color={color}
      textTransform={textTransform}
      marginBottom={marginBottom}
      marginTop={marginTop}
      {...rest}
    >
      {children}
    </Heading1Container>
  );
}

export function Heading2({
  children,
  color = "--primary",
  textTransform = "none",
  marginBottom = 0,
  marginTop = 0,
  ...rest
}) {
  return (
    <Heading2Container
      color={color}
      textTransform={textTransform}
      marginBottom={marginBottom}
      marginTop={marginTop}
      {...rest}
    >
      {children}
    </Heading2Container>
  );
}

export function Heading3({
  children,
  color = "--primary",
  textTransform = "none",
  marginBottom = 0,
  marginTop = 0,
  ...rest
}) {
  return (
    <Heading3Container
      color={color}
      textTransform={textTransform}
      marginBottom={marginBottom}
      marginTop={marginTop}
      {...rest}
    >
      {children}
    </Heading3Container>
  );
}

export function Heading4({
  children,
  color = "--primary",
  textTransform = "none",
  marginBottom = 0,
  marginTop = 0,
  ...rest
}) {
  return (
    <Heading4Container
      color={color}
      textTransform={textTransform}
      marginBottom={marginBottom}
      marginTop={marginTop}
      {...rest}
    >
      {children}
    </Heading4Container>
  );
}

export function Heading5({
  children,
  color = "--primary",
  textTransform = "none",
  marginBottom = 0,
  marginTop = 0,
  ...rest
}) {
  return (
    <Heading5Container
      color={color}
      textTransform={textTransform}
      marginBottom={marginBottom}
      marginTop={marginTop}
      {...rest}
    >
      {children}
    </Heading5Container>
  );
}

export function Small({
  children,
  color = "--primary",
  textTransform = "none",
  marginBottom = 0,
  marginTop = 0,
  fontWeight = 400,
  ...rest
}) {
  return (
    <SmallContainer
      color={color}
      textTransform={textTransform}
      marginBottom={marginBottom}
      marginTop={marginTop}
      fontWeight={fontWeight}
      {...rest}
    >
      {children}
    </SmallContainer>
  );
}

export function XSmall({
  children,
  color = "--primary",
  textTransform = "none",
  marginBottom = 0,
  marginTop = 0,
  fontWeight = 400,
  ...rest
}) {
  return (
    <XSmallContainer
      color={color}
      textTransform={textTransform}
      marginBottom={marginBottom}
      marginTop={marginTop}
      fontWeight={fontWeight}
      {...rest}
    >
      {children}
    </XSmallContainer>
  );
}

export function Large({
  children,
  color = "--primary",
  textTransform = "none",
  marginBottom = 0,
  marginTop = 0,
  ...rest
}) {
  return (
    <LargeContainer
      color={color}
      textTransform={textTransform}
      marginBottom={marginBottom}
      marginTop={marginTop}
      {...rest}
    >
      {children}
    </LargeContainer>
  );
}
