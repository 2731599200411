import styled from "styled-components";

import Button from "../../../components/button";

export const TriggerCombinationBtn = styled.a`
  background: #c1c8d4;
  width: 30px;
  height: 30px;
  color: #26344c;
  border-radius: 50%;
  font-size: 9px;
  font-weight: bold;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormRow = styled.div`
  display: flex;
  margin-bottom: 15px;

  button:first-child {
    margin-left: 0;
  }
`;
export const TriggerGroup = styled.div`
  > label {
    display: block;
    padding: 0 0 2px 0;
    color: #495f83;
    &::after {
      content: " (required)";
    }
  }
`;

export const OutputGroup = styled.div`
  > label {
    display: block;
    padding: 0 0 2px 0;
    color: #495f83;
    &::after {
      content: " (required)";
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    > div {
      flex: 1;
    }

    > button,
    a {
      margin-bottom: 15px;
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;
