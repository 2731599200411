import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 12px;
`;

export const Header = styled.div`
  background-color: #fff;
  padding: 1.142rem;
  margin: -1.142rem;
  margin-bottom: 1.142rem;

  h3 {
    display: inline;
  }
`;

export const CenterRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const SpaceBetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
