import styled, { css } from "styled-components";

import TextInput from "../../basic-inputs/input";

export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.inline ? "row" : "column")};
  justify-content: center;
  align-items: ${(props) => (props.inline ? "center" : "flex-start")};
  flex-wrap: wrap;

  margin-bottom: 15px;

  label {
    padding: ${(props) => (props.inline ? "0 10px 0 0" : "0 0 2px 0")};
    color: #495f83;

    &::after {
      ${(props) => (props.required ? "content:' (required)'" : "")};
    }

    flex-basis: ${(props) => (props.inline ? "9rem" : "unset")};
    flex-shrink: ${(props) => (props.inline ? "0" : "unset")};
    flex-grow: ${(props) => (props.inline ? "0" : "unset")};
    text-align: ${(props) => (props.inline ? "right" : "unset")};
  }

  span {
    color: #dd2542;
    text-align: left;

    width: ${(props) => (props.inline ? "100%" : "unset")};
    margin-left: ${(props) => (props.inline ? "9rem" : "unset")};
  }
`;

export const Input = styled(TextInput)`
  flex: 1;
  align-self: stretch;

  ${(props) =>
    props.invisibleInput &&
    css`
      border: 0;
    `}
`;
