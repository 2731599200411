import React, { Fragment, useRef, useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Scope } from "@unform/core";
import { Form } from "@unform/web";
import { find, isEmpty } from "lodash";

import { CgClose } from "react-icons/cg";
import { MdDelete } from "react-icons/md";
import { BiPlusCircle } from "react-icons/bi";

import { usePatientAttribute } from "../../../hooks/usePatientAttributes";
import { useGuidelineRule } from "../../../hooks/useGuidelineRules";

import { CategoriesGroup, CategoryRow, ActionButtons } from "./styles";

import Input from "../../../components/unform-inputs/input";
import Select from "../../../components/unform-inputs/select";
import Button from "../../../components/button";
import Textarea from "../../../components/unform-inputs/textarea";
import GuidelineHelper from "../../../helpers/guideline-helper";

export default function GuidelineAttributesForm({
  attribute,
  cancelFormAction,
  usageNumber = 0,
}) {
  const { attributes, AddAttribute, EditAttribute, DeleteAttribute } =
    usePatientAttribute();
  const { guidelines } = useGuidelineRule();

  const history = useHistory();
  const formRef = useRef(null);
  const containerRef = useRef(null);

  const [categoricalOptions, setCategoricalOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultForm, setDefaultForm] = useState({});

  const dataTypeOptions = [
    { label: "Numerical", value: "numerical" },
    { label: "Categorical", value: "categorical" },
    { label: "Boolean", value: "boolean" },
  ];

  const [disableDataTypeEditing, setdisableDataTypeEditing] = useState(false);

  useEffect(() => {
    setdisableDataTypeEditing(usageNumber > 0);
  }, [usageNumber]);

  const ParentOptions = useMemo(() => {
    const options = attributes.map((attribute) => ({
      label: attribute.human_readable_name,
      value: attribute.id,
    }));
    return options;
  }, [attributes]);

  const categoryOptions = [
    { label: "Demographic", value: "demographic" },
    { label: "Diagnosis", value: "diagnosis" },
    { label: "Family History", value: "family_history" },
    { label: "Internal", value: "internal" },
    { label: "Labs", value: "labs" },
    { label: "Medical History", value: "medical_history" },
    { label: "Procedure", value: "procedure" },
    { label: "Risk Score", value: "risk-score" },
    { label: "Vitals", value: "vitals" },
  ];

  const editAttribute = useMemo(() => {
    if (!isEmpty(attribute)) {
      if (attribute.available_values.length > 0) {
        setCategoricalOptions([...attribute.available_values]);
      }
      return attribute;
    }
  }, [attribute]);

  useEffect(() => {
    async function defaultValues() {
      if (editAttribute) {
        await formRef.current.setData(editAttribute);
        setDefaultForm(formRef.current.getData());
      }
    }
    defaultValues();
  }, [editAttribute]);

  async function submit(data) {
    setErrorMessage(null);

    try {
      // Remove all previous errors
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required("This is a required field"),
        human_readable_name: Yup.string().required("This is a required field"),
        data_type: Yup.string().required("This is a required field"),
        category: Yup.string().required("This is a required field"),
        // available_values: Yup.mixed().when('data_type', {
        //   is: 'categorical',
        //   then: Yup.array().of(Yup.object().shape({
        //     label: Yup.string().required(),
        //     snomed_concept_id: Yup.string(),
        //   })).min(2),
        //   otherwise: Yup.mixed().strict(true),
        // })
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      data.id = attribute ? attribute.id : null;
      data = GuidelineHelper.removeEmptyStrings(data);

      if (attribute) {
        EditAttribute(data, data.id, successSave, errorSave);
      } else {
        AddAttribute(data, successSave, errorSave);
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const successSave = function () {
    cancelForm();
  };

  const errorSave = function (errorMessages) {
    setErrorMessage(errorMessages);
  };

  const cancelForm = () => {
    formRef.current.setData(defaultForm);
    if (
      attribute &&
      attribute.available_values &&
      attribute.available_values.length > 0
    ) {
      setCategoricalOptions([...attribute.available_values]);
    }
    if (cancelFormAction) {
      cancelFormAction();
    } else {
      history.goBack();
    }
  };

  const handleNameChange = function () {
    // funcion to handle specifically changes on the `name` field, to update the `variable` field based on the `name`
    // it is called only when the Name field is changed, which means it's possible to change the Variable field afterwards
    const nameString = formRef.current.getFieldValue("human_readable_name");
    let newAttributeString = nameString.replace(
      new RegExp(/[^a-zA-Z0-9]+/gi, "g"),
      "_"
    ); // symple regex to replace non-alphanumeric characters with underscores;
    newAttributeString = newAttributeString.toLowerCase();
    formRef.current.setFieldValue("name", newAttributeString);
  };

  const handleDataTypeChange = function () {
    if (formRef.current.getFieldValue("data_type") == "categorical") {
      setCategoricalOptions([{}]);
    } else {
      setCategoricalOptions([]);
    }
  };

  const disableCategoricalOptions = function (categoricalOption) {
    if (!attribute) return false;
    if (
      disableDataTypeEditing &&
      find(attribute.available_values, categoricalOption)
    ) {
      const guidelinesAttached = GuidelineHelper.getListOfRulesWithAttribute(
        attribute.id,
        guidelines
      );
      for (const guideline of guidelinesAttached) {
        for (const trigger of guideline.triggers) {
          if (
            trigger.attribute === attribute.id &&
            trigger.compare_value === categoricalOption.label
          )
            return true;
        }
      }
    }
    return false;
  };

  const deleteCategoricalOption = function (index) {
    formRef.current.setFieldValue(
      `available_values[${index}].label`,
      formRef.current.getFieldValue(`available_values[${index + 1}].label`)
    );
    formRef.current.setFieldValue(
      `available_values[${index}].snomed_concept_id`,
      formRef.current.getFieldValue(
        `available_values[${index + 1}].snomed_concept_id`
      )
    );
    const newCategoricalOptions = [...categoricalOptions];
    newCategoricalOptions.splice(index, 1);
    setCategoricalOptions(newCategoricalOptions);
  };

  const deleteAttribute = () => {
    const response = confirm(
      `Do you really want to delete ${attribute.human_readable_name} ?`
    );
    if (response == true) {
      DeleteAttribute(attribute.id, deleteSuccess);
    }
  };

  const deleteSuccess = () => {
    formRef.current.setData(defaultForm);
    history.push("/content-manager/patient-attributes");
  };

  return (
    <div ref={containerRef}>
      {errorMessage ? (
        <div className="patient-status__alert-message alert-message__warning">
          <p className="alert-message__paragraph u-color-denim-40">
            <b>Alert:</b> {errorMessage}
          </p>
        </div>
      ) : (
        ""
      )}
      <Form ref={formRef} onSubmit={submit} className="form-box">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <Input
              name="human_readable_name"
              required
              label="Name"
              onChange={() => handleNameChange()}
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Input name="name" required label="Variable" />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <Select
              required
              name="category"
              options={categoryOptions}
              label="Category"
              setDefaultValue="demographic"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <Select
              disabled={disableDataTypeEditing}
              name="data_type"
              options={dataTypeOptions}
              onChange={() => handleDataTypeChange()}
              required
              label="Data Type"
            />
          </div>
        </div>

        {categoricalOptions.length > 0 ? (
          <div className="row">
            <div className="col-xs-12">
              <CategoriesGroup>
                {categoricalOptions.map((o, i) => {
                  return (
                    <Scope key={i} path={`available_values[${i}]`}>
                      <CategoryRow>
                        <Input
                          disabled={disableCategoricalOptions(o)}
                          name="label"
                          required
                          label={`Category ${i + 1}`}
                        />
                        <Input name="snomed_concept_code" label="SNOMED" />
                        {categoricalOptions.length > 1 &&
                        !disableCategoricalOptions(o) ? (
                          <Button
                            variant="clear"
                            onClick={() => deleteCategoricalOption(i)}
                          >
                            <CgClose size={18} />
                          </Button>
                        ) : (
                          ""
                        )}
                      </CategoryRow>
                    </Scope>
                  );
                })}
                <Button
                  type="button"
                  variant="clear"
                  /* disabled={fieldIsLocked} */ onClick={() =>
                    setCategoricalOptions([...categoricalOptions, {}])
                  }
                  leftIcon={<BiPlusCircle size={18} />}
                >
                  Add category
                </Button>
              </CategoriesGroup>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-xs-12">
            <Textarea
              name="description"
              type="text"
              rows="1"
              label="Description"
            />
          </div>
        </div>

        <ActionButtons>
          <div>
            {attribute ? (
              <Button
                variant="clear"
                disabled={usageNumber > 0}
                leftIcon={<MdDelete />}
                type="button"
                onClick={() => deleteAttribute()}
              >
                Delete Attribute
              </Button>
            ) : (
              ""
            )}
          </div>
          <div>
            <Button
              variant="outline"
              type="button"
              onClick={() => cancelForm()}
            >
              Cancel
            </Button>
            <Button variant="confirm" type="submit">
              {attribute ? "Edit Attribute" : "Create Attribute"}
            </Button>
          </div>
        </ActionButtons>
      </Form>
    </div>
  );
}
