import styled, { css } from "styled-components";

export const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 10px;
`;

export const StatementsContainer = styled.div`
  line-height: 100%;
  padding: 10px 0;

  li {
    padding-bottom: 10px;
  }

  li:last-of-type {
    padding-bottom: 0;
  }
`;

export const StatementsCaption = styled.p`
  padding-bottom: 8px;
`;

export const StatementsList = styled.ul`
  list-style-type: square;
  padding-left: ${(props) => props.paddingLeft || "32px"};
`;

export const StatementComments = styled.div`
  p {
    padding-top: 8px;
  }
`;

export const ConsortDiagramContainer = styled.div`
  text-align: center;
  padding: 24px;

  img {
    max-width: 90%;
  }
`;

export const StepsDisclaimer = styled.p`
  padding-top: 16px;
  text-align: justify;
  line-height: 1;
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
`;

export const Tab = styled.div`
  border-bottom: 2px solid transparent;
  padding: 2px 5px;
  text-transform: uppercase;
  color: #cccccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  ${(props) =>
    props.active &&
    css`
      border-color: var(--primary);
      color: var(--primary);
    `}
`;

export const LoadingWrapper = styled.div`
  height: 300px;
`;

export const EmptyStateWrapper = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
