import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
`;

export const TabsList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  border-bottom: 1px solid #cccccc;
  padding: 0;
  list-style: none;
`;

export const Content = styled.div`
  margin: 15px auto;
  width: 100%;
`;
