/* eslint-disable no-restricted-syntax, no-continue */

const HEADERS = {
  subtable_name: "category",
  display_name: "attribute name",
  count: "N",
  percent: "%",
  median: "median",
  "interquartile_range[0]": "interquartile range 25%",
  "interquartile_range[1]": "interquartile range 75%",
  n_present: "N present",
  percent_present: "% present",
};
// const COHORT_IDS = ["main cohort", "subcohort a", "subcohort b", "subcohort c"];
// const COHORT_KEYS = ["cohort", "subcohort-a", "subcohort-b", "subcohort-c"];

function convertCohortToRows(name, id, filters, subtables) {
  const cohortId = id.toUpperCase();
  const cohortKey = id;
  const rows = [];

  // headers section
  rows.push(
    Array.from({ length: Object.keys(HEADERS).length }, (_, i) =>
      i === 0 ? cohortId : ""
    )
  );
  rows.push(
    Array.from({ length: Object.keys(HEADERS).length }, (_, i) => {
      if (i === 0) {
        return `${cohortId} name`;
      }
      if (i === 1) {
        return name;
      }
      if (i === 2 && filters !== "") {
        return "Filters";
      }
      if (i === 3 && filters !== "") {
        return filters;
      }
      return "";
    })
  );
  rows.push([...Object.values(HEADERS)]);

  // subtables section
  for (const subtable of subtables) {
    for (const row of subtable.rows) {
      const newRow = [];
      const cohortRow = row[cohortKey];

      for (const selector of Object.keys(HEADERS)) {
        // check first if selector is at the subtable level
        if (selector in subtable) {
          newRow.push(subtable[selector]);
          continue;
        }

        // deconstruct selector into key and index
        const match = selector.match(/(?<key>\w+)(\[(?<index>\d+)\])?/);
        const { key, index } = match.groups;

        if (!(key in cohortRow)) {
          newRow.push("");
          continue;
        }

        newRow.push(index ? cohortRow[key][index] : cohortRow[key]);
      }

      rows.push(newRow);
    }
  }

  return rows;
}

function mergeCohortRows(cohortRows) {
  const numCohorts = cohortRows.length;

  if (numCohorts === 1) {
    return cohortRows[0];
  }

  // XXX: this assumes that the main and sub cohorts will have the same number of rows
  const numRows = cohortRows[0].length;
  const mergedRows = [];

  for (let i = 0; i < numRows; i += 1) {
    let newRows = [];

    for (let j = 0; j < numCohorts; j += 1) {
      newRows = [...newRows, ...cohortRows[j][i]];
      if (j !== numCohorts - 1) {
        newRows.push("");
      }
    }

    mergedRows.push(newRows);
  }

  return mergedRows;
}

function convertRowsToCsvString(rows) {
  const str = rows
    .map((row) => row.map((cell) => `"${cell}"`).join(","))
    .join("\n");

  return str;
}

export function convertCohortsToCsvString(cohorts, subtables) {
  if (!cohorts || !subtables) {
    // eslint-disable-next-line
    console.error(
      "convertCohortsToCsvString: cohorts or subtables parameters missing"
    );

    return "";
  }

  const cohortsRows = cohorts.map((cohort) =>
    convertCohortToRows(cohort.label, cohort.name, cohort.filters, subtables)
  );
  const mergedRows = mergeCohortRows(cohortsRows);
  const csvString = convertRowsToCsvString(mergedRows);

  return csvString;
}
