import React, { useState, useMemo, Component } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { TabsContext } from "../../components/app-tab-nav/context.js";

import store from "../../store";

// Modals
// import Modal from './modal.jsx';

// Layout Elements
import TopNavbar from "../../components/app-top-navbar";
import Footer from "../../components/app-footer";

import { Container } from "./styles";

export default function DefaultLayout({ children }) {
  const [mainTabs, setMainTabs] = useState([]);
  const providerValue = useMemo(
    () => ({ mainTabs, setMainTabs }),
    [mainTabs, setMainTabs]
  );

  return (
    <Container>
      {/* <Modal store={store}/> */}
      <TabsContext.Provider value={providerValue}>
        <TopNavbar store={store} />
        {children}
      </TabsContext.Provider>
    </Container>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
